import { configureStore } from '@reduxjs/toolkit'
import { callApi } from './features/CallApi'
import tokenReducer from './features/tokenSlice'
import refreshTokenReducer from './features/refreshTokenSlice'
import clientTokenReducer from './features/tokenClientSlice'
import refreshClientTokenReducer from './features/refreshClientTokenSlice'
// import selectionReducer from './features/selectionSlice'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { callAppApi } from './features/CallAppApi'
// Or from '@reduxjs/toolkit/query/react'

export const store = configureStore({
  reducer: {
    token: tokenReducer,
    refreshToken: refreshTokenReducer,
    clientToken: clientTokenReducer,
    clientRefreshToken: refreshClientTokenReducer,
    // selection: selectionReducer,
    // Add the generated reducer as a specific top-level slice
    [callApi.reducerPath]: callApi.reducer,
    [callAppApi.reducerPath]: callAppApi.reducer,

  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(callApi.middleware).concat(callAppApi.middleware),
})

setupListeners(store.dispatch)
import { Container } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useGetDocumentsQuery, usePutDocumentsMutation } from "../features/CallApi"
import parse from 'html-react-parser';
import { Helmet } from "react-helmet";

export const Documents = () => {

    const [t] = useTranslation('global')
    const { data, isLoading } = useGetDocumentsQuery()

    return (
        <>
            <Helmet>
                <title>{t('general.documents')} - {t("mainTag.title")}</title>
                <meta name="og:title" content={`${t('general.documents')} - ${t("mainTag.title")}`} />
                <meta name="description" content={t('general.documents')} />
                <meta name="og:description" content={t('general.documents')} />
                <meta property="og:image" content={`/Show/091A8332.jpg`} />
            </Helmet>
            <div className="haed-layout">
                <div className="head-layout-img" style={{ backgroundImage: "url(/Show/091A8332.jpg" }}></div>

                <div className="head-mask">
                    <div className="rounded-3" style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem", inset: 0, margin: 'auto' }}>
                        <div className="text-white m-auto">
                            <div className="h1 text-center">
                                {t('general.documents')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Container>
                <div className="mt-5">
                    {!isLoading ?
                        <div>
                            {data && parse(data.html)}
                        </div> :
                        <div>Нет данных</div>
                    }
                </div>
            </Container>
        </>
    )
}
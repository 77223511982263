import { Circle, Group, Shape, Text } from "react-konva";
import { SEAT_SIZE } from "./Layout";
import { useEffect, useState } from "react";


function getColor(isBooked, isSelected, fill) {
  if (isSelected) {
    return "black";
  } else if (isBooked) {
    return "lightgrey";
  } else {
    return fill;
  }
}

const seatLeft = {
  id: 1,
  width: 30,
  height: 30,
  type: "shape",
  stroke: '#6b6b6bcf',
  strokeWidth: 1,
  ctxs: [
    {
      type: "line",
      x: 0,
      y: 0
    },

    {
      type: "line",
      x: 30,
      y: 0
    },
    {
      type: "line",
      x: 30,
      y: 30
    },
    {
      type: "line",
      x: 10,
      y: 30
    },
    {
      type: "curve",
      cpx: 0,
      cpy: 30,
      x: 0,
      y: 20
    },
    {
      type: "line",
      x: 0,
      y: 20
    },
    {
      type: "line",
      x: 0,
      y: 0
    },
  ]
}
const seatRigth = {
  id: 2,
  width: 30,
  height: 30,
  type: "shape",
  stroke: '#6b6b6bcf',
  strokeWidth: 1,
  ctxs: [
    {
      type: "line",
      x: 0,
      y: 0
    },

    {
      type: "line",
      x: 30,
      y: 0
    },
    {
      type: "line",
      x: 30,
      y: 20
    },
    {
      type: "curve",
      cpx: 30,
      cpy: 30,
      x: 20,
      y: 30
    },
    {
      type: "line",
      x: 25,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 0
    },
  ]
}
const seatSingl = {
  id: 3,
  width: 30,
  height: 30,
  type: "shape",
  stroke: '#6b6b6bcf',
  strokeWidth: 1,
  ctxs: [
    {
      type: "line",
      x: 0,
      y: 0
    },
    {
      type: "line",
      x: 30,
      y: 0
    },
    {
      type: "line",
      x: 30,
      y: 20
    },
    {
      type: "curve",
      cpx: 30,
      cpy: 30,
      x: 20,
      y: 30
    },
    {
      type: "line",
      x: 25,
      y: 30
    },
    {
      type: "line",
      x: 10,
      y: 30
    },
    {
      type: "curve",
      cpx: 0,
      cpy: 30,
      x: 0,
      y: 20
    },
    {
      type: "line",
      x: 0,
      y: 25
    },
    {
      type: "line",
      x: 0,
      y: 0
    },
  ]
}
const boxCenter = {
  id: 4,
  width: 30,
  height: 30,
  type: "shape",
  stroke: '#6b6b6bcf',
  strokeWidth: 1,
  ctxs: [
    {
      type: "line",
      x: 0,
      y: 0
    },
    {
      type: "line",
      x: 30,
      y: 0
    },
    {
      type: "line",
      x: 30,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 0
    },
  ]
}
const boxTopLeft = {
  id: 5,
  width: 30,
  height: 30,
  type: "shape",
  stroke: '#6b6b6bcf',
  strokeWidth: 1,
  ctxs: [
    {
      type: "line",
      x: 5,
      y: 0
    },
    {
      type: "line",
      x: 30,
      y: 0
    },
    {
      type: "line",
      x: 30,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 5
    },
    {
      type: "curve",
      cpx: 0,
      cpy: 0,
      x: 5,
      y: 0
    }
  ]
}
const boxTopRight = {
  id: 6,
  width: 30,
  height: 30,
  type: "shape",
  stroke: '#6b6b6bcf',
  strokeWidth: 1,
  ctxs: [
    {
      type: "line",
      x: 0,
      y: 0
    },

    {
      type: "line",
      x: 25,
      y: 0
    },
    {
      type: "line",
      x: 25,
      y: 0
    },
    {
      type: "curve",
      cpx: 30,
      cpy: 0,
      x: 30,
      y: 5
    },
    {
      type: "line",
      x: 30,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 0
    },
  ]
}
const topRight = {
  id: 7,
  width: 30,
  height: 30,
  type: "shape",
  stroke: '#6b6b6bcf',
  strokeWidth: 1,
  ctxs: [
    {
      type: "line",
      x: 5,
      y: 0
    },
    {
      type: "line",
      x: 30,
      y: 0
    },
    {
      type: "line",
      x: 30,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 5
    },
    {
      type: "curve",
      cpx: 0,
      cpy: 0,
      x: 5,
      y: 0
    }
  ]
}
const topLeft = {
  id: 8,
  width: 30,
  height: 30,
  type: "shape",
  stroke: '#6b6b6bcf',
  strokeWidth: 1,
  ctxs: [
    {
      type: "line",
      x: 0,
      y: 0
    },

    {
      type: "line",
      x: 25,
      y: 0
    },
    {
      type: "line",
      x: 25,
      y: 0
    },
    {
      type: "curve",
      cpx: 30,
      cpy: 0,
      x: 30,
      y: 5
    },
    {
      type: "line",
      x: 30,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 0
    },
  ]
}
const topSingl = {
  id: 9,
  width: 30,
  height: 30,
  type: "shape",
  stroke: '#6b6b6bcf',
  strokeWidth: 1,
  ctxs: [
    {
      type: "line",
      x: 5,
      y: 0
    },
    {
      type: "line",
      x: 25,
      y: 0
    },
    {
      type: "curve",
      cpx: 30,
      cpy: 0,
      x: 30,
      y: 5
    },
    {
      type: "line",
      x: 30,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 5
    },
    {
      type: "curve",
      cpx: 0,
      cpy: 0,
      x: 5,
      y: 0
    }
  ]
}

const turnLeft = {
  id: 9,
  width: 30,
  height: 30,
  type: "shape",
  stroke: '#6b6b6bcf',
  strokeWidth: 1,
  ctxs: [
    {
      type: "line",
      x: 5,
      y: 0
    },
    {
      type: "line",
      x: 30,
      y: 0
    },
    {
      type: "line",
      x: 30,
      y: 30
    },
    {
      type: "line",
      x: 30,
      y: 30
    },
    {
      type: "line",
      x: 5,
      y: 30
    },
    {
      type: "curve",
      cpx: 0,
      cpy: 30,
      x: 0,
      y: 25
    },
    {
      type: "line",
      x: 0,
      y: 5
    },
    {
      type: "curve",
      cpx: 0,
      cpy: 0,
      x: 5,
      y: 0
    }
  ]
}
const turnRight = {
  id: 9,
  width: 30,
  height: 30,
  type: "shape",
  stroke: '#6b6b6bcf',
  strokeWidth: 1,
  ctxs: [
    {
      type: "line",
      x: 0,
      y: 0
    },
    {
      type: "line",
      x: 25,
      y: 0
    },
    {
      type: "curve",
      cpx: 30,
      cpy: 0,
      x: 30,
      y: 5
    },
    {
      type: "line",
      x: 30,
      y: 25
    },
    {
      type: "curve",
      cpx: 30,
      cpy: 30,
      x: 25,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 30
    },
    {
      type: "line",
      x: 0,
      y: 0
    }
  ]
}

const mouseEnter = ({ e, props, isBooked, groupTag }) => {
  // console.log(props)
  e.target._clearCache();
  const container = e.target.getStage().container();
  if (isBooked) {
    container.style.cursor = "not-allowed";
  } else {
    container.style.cursor = "pointer";
    props.onHover(props.data.name, e.target.getAbsolutePosition(), props.data.nameRow, props.data.price, props.data.categoryName, props.data.categoryFill, groupTag);
  }
}

const GetFigureSeat = ({ props, isBooked, clickOnSeat, groupTag }) => {
  switch (props.data.type) {
    case 'left': return <RenderShape props={props} object={seatLeft} isBooked={isBooked} clickOnSeat={clickOnSeat} groupTag={groupTag} />
    case 'right': return <RenderShape props={props} object={seatRigth} isBooked={isBooked} clickOnSeat={clickOnSeat} groupTag={groupTag} />
    case 'boxCenter': return <RenderShape props={props} object={boxCenter} isBooked={isBooked} clickOnSeat={clickOnSeat} groupTag={groupTag} />
    case 'boxTopLeft': return <RenderShape props={props} object={boxTopLeft} isBooked={isBooked} clickOnSeat={clickOnSeat} groupTag={groupTag} />
    case 'boxTopRight': return <RenderShape props={props} object={boxTopRight} isBooked={isBooked} clickOnSeat={clickOnSeat} groupTag={groupTag} />
    case 'singl': return <RenderShape props={props} object={seatSingl} isBooked={isBooked} clickOnSeat={clickOnSeat} groupTag={groupTag} />
    case 'topRight': return <RenderShape props={props} object={topRight} isBooked={isBooked} clickOnSeat={clickOnSeat} groupTag={groupTag} />
    case 'topLeft': return <RenderShape props={props} object={topLeft} isBooked={isBooked} clickOnSeat={clickOnSeat} groupTag={groupTag} />
    case 'topSingl': return <RenderShape props={props} object={topSingl} isBooked={isBooked} clickOnSeat={clickOnSeat} groupTag={groupTag} />
    case 'turnLeft': return <RenderShape props={props} object={turnLeft} isBooked={isBooked} clickOnSeat={clickOnSeat} groupTag={groupTag} />
    case 'turnRight': return <RenderShape props={props} object={turnRight} isBooked={isBooked} clickOnSeat={clickOnSeat} groupTag={groupTag} />
    default: return <RenderShape props={props} object={seatSingl} isBooked={isBooked} clickOnSeat={clickOnSeat} groupTag={groupTag} />
  }
}

const RenderShape = ({ props, object, isBooked, clickOnSeat, groupTag }) => {
  switch (object.type) {
    case 'shape':
      return <Shape
        x={props.x}
        y={props.y}
        fill={getColor(isBooked, props.isSelected, props.data.fill)}
        width={object.width}
        height={object.height}
        sceneFunc={(context, shape) => {
          context.beginPath();
          object.ctxs.forEach(ctx => {
            switch (ctx.type) {
              case 'move':
                return context.moveTo(ctx.x, ctx.y);
              case 'line':
                return context.lineTo(ctx.x, ctx.y);
              case 'curve':
                return context.quadraticCurveTo(ctx.cpx, ctx.cpy, ctx.x, ctx.y)
              default: return null;
            }
          });
          context.closePath();
          context.fillStrokeShape(shape);

        }}
        onMouseEnter={e => {
          mouseEnter({ e, props, isBooked, groupTag })
        }}
        onMouseLeave={e => {
          props.onHover(null);
          const container = e.target.getStage().container();
          container.style.cursor = "";
        }}
        onClick={() => {
          clickOnSeat()
        }}
        onTap={() => {
          clickOnSeat()
        }}
      />;
    default:
      return console.log("Bad shape");
  }
}

export const Seat = props => {
  const [groupTag, setGroupTag] = useState()

  useEffect(() => {
    if (props.data.groupTag) {
      setGroupTag(props.data.groupTag)
    }
  }, [])

  // useEffect(() => {
  //   if (groupTag) {
  //     // console.log(groupTag)
  //   }
  // }, [groupTag])

  const isBooked = props.data.status === "booked";
  // const groupTag = props.data.
  const clickOnSeat = () => {
    if (isBooked) {
      return;
    }
    if (props.isSelected) {
      props.onDeselect(props.data.id, groupTag);
    } else {
      // console.log(groupTag)
      // console.log(props.data)
      props.onSelect(props.data.id, groupTag, props.data);
    }
  }


  return (
    <Group>
      <GetFigureSeat props={props} isBooked={isBooked} clickOnSeat={clickOnSeat} groupTag={groupTag} />
      {!groupTag && <>
        {(props.isSelected === false && !isBooked) &&
          <Text
            text={props.data.name}
            x={props.x}
            y={props.y}
            fill="#fff"
            fontSize={SEAT_SIZE / 1.6}
            align="center"
            verticalAlign="middle"
            // fill={getColor(isBooked, props.isSelected)}
            width={SEAT_SIZE}
            height={SEAT_SIZE}
            onMouseEnter={e => {
              mouseEnter({ e, props, isBooked, groupTag })
            }}
            onMouseLeave={e => {
              props.onHover(null);
              const container = e.target.getStage().container();
              container.style.cursor = "";
            }}
            onClick={() => {
              clickOnSeat()
            }}
            onTap={() => {
              clickOnSeat()
            }} />
        }</>
      }
      {props.isSelected &&
        <Circle
          width={15}
          height={15}
          fill="#fff"
          x={props.x + 15}
          y={props.y + 15}
          onMouseEnter={e => {
            mouseEnter({ e, props, isBooked, groupTag })
          }}
          onMouseLeave={e => {
            props.onHover(null);
            const container = e.target.getStage().container();
            container.style.cursor = "";
          }}
          onClick={() => {
            clickOnSeat()
          }}
          onTap={() => {
            clickOnSeat()
          }} />}
    </Group>
  )
};


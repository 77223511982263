import { Suspense, useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Outlet, useLocation } from "react-router-dom"
import { NavMenu } from "./NavMenu"
import { AboutMenu } from "./ProjectLC/MenuAbout"
import { MobileNavMenu } from "./MobileNavMenu"
import { Footer } from "./Footer/Footer"
import { Metrik } from "../features/Metrik"
import { LoadScreenMin } from "./UI/LoadScreenMin"
import { TableReservationForm } from "./UI/TableReservationForm"
import { Helmet } from "react-helmet"




export const LayoutAbout = ({ changanLanguage, selLanguage, reserFormShow, setReserFormShow, aboutMobMenu, setAboutMobMenu, loadingSite, clientToken }) => {
    const [t] = useTranslation('global')
    const [headerData, setHeaderData] = useState()
    const location = useLocation()
    const [loading, setLoading] = useState(loadingSite);
    const footerRef = useRef()

    const [minHeightContent, setMinHeigthContent] = useState(100)
    useEffect(() => {
        const resizeHandeler = () => {
            setMinHeigthContent(window.innerHeight - footerRef?.current.clientHeight)
        }
        setMinHeigthContent(window.innerHeight - footerRef?.current.clientHeight)
        window.addEventListener('resize', resizeHandeler)
        return function () {
            window.addEventListener('resize', resizeHandeler)
        }
    }, [])

    const title = (pathname) => {
        const result = {};
        if (pathname === "/about-project") {
            result.title = "about.aboutproject";
            result.aboutDecs = "about.aboutdecs";
            result.urlImg = "/AboutProjcet/mainProject.jpg";
        } else if (pathname === "/about-project/history") {
            result.title = "about.history";
            result.aboutDecs = "about.historydecs";
            result.urlImg = "/AboutProjcet/fasadLC.jpg";
            result.desktopUrlImg = "/AboutProjcet/fasadLC.jpg";
        }
        else if (pathname === "/about-project/stage") {
            result.title = "about.stage";
            result.aboutDecs = "";
            result.urlImg = "/AboutProjcet/091A8154.jpg";
        }
        else if (pathname === "/about-project/troupe") {
            result.title = "about.troupe";
            result.aboutDecs = "";
            result.urlImg = "/AboutProjcet/troupe.jpg";
            result.desktopUrlImg = "/AboutProjcet/troupe.jpg";
        }
        else if (pathname === "/about-project/restaurant") {
            result.title = "about.restaurant";
            result.aboutDecs = "";
            result.urlImg = "/AboutProjcet/blok.jpg";
        }
        else if (pathname === "/about-project/shows") {
            result.title = "about.shows";
            result.aboutDecs = "";
            result.urlImg = "/AboutProjcet/Shows.jpg";
        }
        else if (pathname === "/about-project/exhibitions") {
            result.title = "menu.exhibitions";
            result.aboutDecs = "";
            result.urlImg = "/AboutProjcet/091A8297.jpg";
        }
        else if (pathname === "/about-project/bars") {
            result.title = "about.bars";
            result.aboutDecs = "";
            result.urlImg = "/AboutProjcet/blackBar.jpg";
        }
        else if (pathname === "/about-project/certificates") {
            result.title = "about.certificates";
            result.aboutDecs = "";
            result.urlImg = "/Cert/headerCert2.jpg";
        }
        else if (pathname === "/about-project/qualitymark") {
            result.title = "about.qualitymark";
            result.aboutDecs = "about.qualitymarkdecs";
            result.urlImg = "/AboutProjcet/qualitymark.png";
        }
        else if (pathname === "/about-project/gallery") {
            result.title = "menu.gallery";
            result.aboutDecs = "";
            result.urlImg = "/AboutProjcet/091A8297.jpg";
        }
        else if (pathname === "/about-project/partners") {
            result.title = "about.partners";
            result.aboutDecs = "";
            result.urlImg = "/AboutProjcet/about-project.jpg";
        }
        else if (pathname === "/about-project/corporate") {
            result.title = "about.corporate";
            result.aboutDecs = "";
            result.urlImg = "/AboutProjcet/about-project.jpg";
        }
        else {
            result.title = "about.aboutproject";
            result.aboutDecs = "";
            result.urlImg = "/AboutProjcet/about-project.jpg";
        }

        return result;
    }


    useEffect(() => {
        setHeaderData(title(location.pathname))
    }, [location.pathname])

    return (

        <>
            <Helmet>
                <title>{t(headerData?.title)} - {t("mainTag.title")}</title>
                <meta name="og:title" content={`${t(headerData?.title)} - ${t("mainTag.title")}`} />
                <meta name="description" content={"До революции в нем проходили выставки Императорского российского общества садоводства, а в середине XX столетия здесь разместился панорамный кинотеатр «Ленинград». В начале 2000-х здание реконструировал живая легенда архитектуры Рикардо Боффил. Сохранив культурную функцию здания, он наполнил его современным содержанием, которое соответствует самым актуальным мировым тенденциям в сфере городских культурных площадок."} />
                <meta name="og:description" content={"До революции в нем проходили выставки Императорского российского общества садоводства, а в середине XX столетия здесь разместился панорамный кинотеатр «Ленинград». В начале 2000-х здание реконструировал живая легенда архитектуры Рикардо Боффил. Сохранив культурную функцию здания, он наполнил его современным содержанием, которое соответствует самым актуальным мировым тенденциям в сфере городских культурных площадок."} />
                {/* <meta property="og:image" content={data.headLayoutImageType ? `/api/shows/head-wide-img/${data.id})` : "/Show/091A8332.jpg"} /> */}
            </Helmet>
            <Metrik />
            <div className='main-content' style={{ minHeight: minHeightContent + 'px' }}>

                <NavMenu changanLanguage={changanLanguage} selLanguage={selLanguage} setAboutMobMenu={setAboutMobMenu} />
                <div className="about-haed-layout mb-3">
                    {headerData?.desktopUrlImg != null ? <div className="about-head-layout-img" style={{ backgroundImage: `url("${headerData?.desktopUrlImg}")`, backgroundPosition: 'bottom' }}>
                    </div>
                        :
                        <div className="about-head-layout-img" style={{ backgroundImage: `url("${headerData?.urlImg}")` }}></div>
                    }

                    <div className="about-head-mask">
                        <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3" style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                            <div className="text-white ms-auto justify-content-end">
                                <div className="h1 text-center text-lg-start ">{t(headerData?.title)}</div>
                                <div style={{ maxWidth: "70%" }} className='d-none d-lg-block' >{t(headerData?.aboutDecs)}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <Container fluid={"lg"}>
                    <Row>
                        <Col className='d-none d-lg-block' lg={"auto"}>
                            <AboutMenu t={t} />
                        </Col>
                        <Col>
                            <Suspense fallback={<LoadScreenMin />}>
                                <Outlet />
                            </Suspense>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer footerRef={footerRef} />
            <TableReservationForm reserFormShow={reserFormShow} setReserFormShow={setReserFormShow} />
            <MobileNavMenu
                clientToken={clientToken}
                setReserFormShow={setReserFormShow}
                aboutMobMenu={aboutMobMenu}
                setAboutMobMenu={setAboutMobMenu} />
        </>)
}
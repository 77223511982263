import { Button, ButtonGroup, Card, Col, Container, Image, Row, Table } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next";
import { useGetSessionDetailsQuery } from "../../features/CallApi";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { TicketsOrderStatus } from "./TicketsOrderStatus";
import Countdown from "react-countdown";
import axios from "axios";
import { NavLink, useParams } from "react-router-dom";
import { LoadScreenMin } from "../UI/LoadScreenMin";
import { data } from "jquery";


const HeaderOrder = (props) => {


    useEffect(() => {
        console.log(props)
    }, [])


    if (props.status === 1) {
        return (
            <Row className="justify-content-center">
                <Col lg={10} xl={8} className="border-bottom mb-3">
                    <h3>
                        {props.t("orderTickets.awaitingPayment")}
                    </h3>
                    <div className="fs-5 mb-3">
                        {props.t("orderTickets.infoPrePay")}
                    </div>
                </Col>
            </Row>
        )
    }
    if (props.status === 2) {
        return (
            <Row className="justify-content-center">
                <Col lg={10} xl={8} className="border-bottom mb-3">
                    <h3>
                        {props.t("orderTickets.paidCompleted")}
                    </h3>
                    <div className="fs-5 mb-3">
                        {props.t("orderTickets.infoPaid")}
                    </div>
                </Col>
            </Row>
        )
    }
    if (props.status === 4) {
        <Row className="justify-content-center">
            <Col lg={10} xl={8} className="border-bottom mb-3">
                <h3>
                    {props.t("orderTickets.awaitingPayment")}
                </h3>
                <div className="fs-5 mb-3">
                    {props.t("orderTickets.infoPrePay")}
                </div>
            </Col>
        </Row>
    }
}

export const OrderTickets = () => {
    const { t } = useTranslation("global");
    const [summOrder, setSummOrder] = useState(0)
    const [showPayButton, setShowPayButton] = useState(true)
    const [showId, setShowId] = useState();
    const [isLoading, setIsloading] = useState(true)
    const { id } = useParams()
    const [dataOrder, setDataOrder] = useState()
    const [orderToken, setOrderToken] = useState()
    const [error, setError] = useState()

    const getData = (orderToken) => {
        axios.get(`/api/shows/get-session-details/${orderToken.sessionId}?selLng=ru`).then(
            (r) => {
                setShowId(r.data.showId)
                axios.get(`https://app.leningradcenter.ru/api/ticketsorders/get-order-client/${id}`, {
                    headers: { Authorization: `Bearer ${orderToken.tokenOrder}` }
                }).then(
                    (r) => {
                        setDataOrder(r.data)
                        if (r.data.status > 1) {
                            setShowPayButton(false)
                        }
                        setIsloading(false)
                    }
                ).catch((e) => {
                    console.log(e)
                    setError("У вас нет доступа к этой странице")
                    // setError(e)
                })
            }
        ).catch((e) => {
            console.log(e.data)
            setError("Ошибка данных")
        })
    }


    const renderer = ({ formatted }) => {
        return <span>{formatted.minutes}:{formatted.seconds}</span>;
    }

    // const { data: dataSession, isLoading } = useGetSessionDetailsQuery()

    useEffect(() => {
        const orderTokenS = localStorage.getItem("tokenOrder")
        const orderTokenJ = JSON.parse(orderTokenS);
        setOrderToken(orderTokenJ)
        if (orderTokenJ == null) {
            return setError("У вас нет доступа к этой странице")
        }
        getData(orderTokenJ)
    }, [])

    useEffect(() => {
        if (dataOrder) {
            const sum = dataOrder.ticketsOrderItems.reduce((summa, item) => {
                return summa + (parseFloat(item.price));
            }, 0);
            setSummOrder(sum)
            if (dataOrder.ticketsOrderItems.length < 1) {
                // setShowOrderModal(false)
            }
        }
    }, [dataOrder])

    const goToPay = () => {
        // axios.get(`https://app.leningradcenter.ru/api/ticketsorders/go-to-pay/${id}`
        axios.get(`https://app.leningradcenter.ru/api/ticketsorders/go-to-pay/${id}`
            , {
                headers: { Authorization: `Bearer ${orderToken.tokenOrder}` }
            }).then((r) => {
                console.log(r)
                const bankUrl = r.data.bankUrl;
                window.location.href = bankUrl;
            }).catch((e) => {
                console.log(e)
            })
    }

    const cancelOrder = () => {
        // axios.get(`https://app.leningradcenter.ru/api/ticketsorders/go-to-pay/${id}`
        axios.get(`https://app.leningradcenter.ru/api/ticketsorders/cancel-order/${id}`
            , {
                headers: { Authorization: `Bearer ${orderToken.tokenOrder}` }
            }).then((r) => {
                getData(orderToken)
            })
    }
    //Покупки, ожидающие оплаты 
    //Крайне важно оплатить их за промежуток времени, оставшийся до автоматической отмены резервирования мест. Выберите способ оплаты и нажмите кнопку "Перейти к оплате". Для отказа от покупки билетов используйте кнопку "Отменить".

    return (
        <>
            <Helmet>
                <title>{t("mainTag.title")}</title>
                <meta name="description" content='Первое в России шоу-пространство "Ленинград Центр"'></meta>
                <meta name="og:title" content={`${t("mainTag.title")}`} />
                <meta name="og:description" content='Первое в России шоу-пространство "Ленинград Центр"' />
                <meta name="keywords" content="шоу- пространство, шоу пространство, ленинград центр, центр ленинград, мюзикл, шоу, представление, афиша" c />
                {/* <meta property="og:image" content={data.headLayoutImageType ? `/api/shows/head-wide-img/${data.id})` : "/Show/091A8332.jpg"} /> */}
            </Helmet>
            <div className="about-haed-layout mb-3">
                <div className="about-head-layout-img" style={{ backgroundImage: `url("/AboutProjcet/about-project.jpg")` }}>
                </div>
                <div className="about-head-mask">
                    <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3"
                        style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                    </div>
                </div>
            </div>
            {(!isLoading && !error) ? <>
                <Container>
                    <HeaderOrder t={t} status={dataOrder.status} />
                    <Row className="justify-content-center">
                        <Col lg={4} xl={3} className="d-none d-lg-block">
                            <Image src={`/api/shows/afisha-img/${showId}`} fluid />
                        </Col>
                        <Col lg={6} xl={5}>
                            <h4>{dataOrder.nameInPremiera}</h4>
                            <div className="mb-1">
                                {t("payscheme.eventDate")}: <b>{format(new Date(dataOrder.startOfEvent), "HH:mm dd.MM.yyyy")}</b>
                            </div>

                            <div className="mb-1">{t("payscheme.venue")}: <b>{dataOrder.hallName}</b></div>
                            <div className="mt-2 mb-1">{t("form.seatsInOrder")}:</div>
                            {dataOrder.ticketsOrderItems.map(item =>
                                <div key={item.id} className="border-bottom ps-3">
                                    <b>{item.category}, {item.row}, {item.place}. {item.price} ₽.</b>
                                </div>
                            )}
                            <div className="mb-1">{t("payscheme.total")}: <b>{summOrder} </b>₽</div>
                            <div className="mb-1">{t("form.status")}: <TicketsOrderStatus status={dataOrder.status} t={t} /></div>

                            {showPayButton ?
                                <>
                                    <div className="mb-1">
                                        {t("payscheme.orderExpiration")}: <Countdown
                                            date={new Date(dataOrder.createDateTime).getTime() + 1000 * 60 * 17}
                                            autoStart={true}
                                            renderer={renderer}
                                            zeroPadTime={2}
                                            onComplete={() => {
                                                setShowPayButton(false)
                                                // getData(orderToken)
                                            }} />
                                    </div>
                                    <div>
                                        <ButtonGroup>
                                            <Button className="btn-lc" onClick={() => { goToPay(id) }}>Оплатить</Button>
                                            <Button variant="light" onClick={() => { if (window.confirm('Вы уверены что хотите отменить заказ?')) { cancelOrder(id) } }}>Отменить заказ</Button>
                                        </ButtonGroup>
                                    </div>
                                </>
                                :
                                <>
                                    {dataOrder.status === 4 &&
                                        <NavLink role="button" className="btn btn-lc" to={`/paytickets/${orderToken.sessionId}`} >{t("orderTickets.goToScheme")}</NavLink>
                                    }
                                </>
                            }
                        </Col>
                        <Col lg={4} xl={3} className="d-lg-none">
                            <Image src={`/api/shows/afisha-img/${showId}`} className="mt-3" fluid />
                        </Col>
                    </Row>
                </Container>
            </>
                :
                <>
                    {!error ?
                        <LoadScreenMin />
                        :
                        <div>
                            <h1 className="mt-3 mb-3 text-center">{error}</h1>
                        </div>
                    }
                </>
            }
        </>

    )
}
import { Accordion, Button } from "react-bootstrap";
import { SeatList } from "../Seats/SeatsList";


export const SectorList = (props) => {
    return (
        <Accordion>
            {props.sections.map(item =>
                <Accordion.Item key={item.id} eventKey={item.id}>
                    <Accordion.Button className="p-1">
                        Сектор: {item.name}
                    </Accordion.Button>
                    <Accordion.Body className="p-1 pt-2 pb-2">
                        <Button size="sm" className="w-100 mb-1 mt-1" onClick={() => {
                            props.setSectionsId(item.id);
                            props.setAddRowShow(true);
                        }}>Добавить ряд</Button>
                        <Accordion>
                            {item.seatsByRows.map(row => <SeatList
                                key={`row-${row.id}`}
                                addSeatsShow={props.addSeatsShow}
                                setAddSeatsShow={props.setAddSeatsShow}
                                setRowId={props.setRowId}
                                row={row}
                                schemeHallId={props.schemeHallId}
                                refetch={props.refetch} />)}
                        </Accordion>

                    </Accordion.Body>
                </Accordion.Item>
            )}
        </Accordion>
    );
}

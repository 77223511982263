export const RoleToText = ({ roleId }) => {
    let result;
    switch (roleId) {
        case 0: result = "Администратор";
        break;
        case 1: result = "Маркетолог";
        break;
        case 2: result = "Event-менеджер";
        break;
        default: result = <span>Некорректные данные</span>
    }
    return result;
}
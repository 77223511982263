import { useRef } from 'react';
import JoditEditor from 'jodit-react';

export const ReactQuillLC = ({ valueEditor, setValueEditor }) => {

    const editor = useRef(null);
    return (
        <>
            <JoditEditor
                ref={editor}
                value={valueEditor}
                // config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => setValueEditor(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => { }}
            />

        </>

    )
}
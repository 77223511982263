import { useEffect, useState } from "react"
import { Button, ButtonGroup, Col, Container, Dropdown, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap"
import { useGetCopySchemeMutation, useGetSchemesHallQuery } from "../../../features/CallApi"
import { LoadScreenMin } from "../../UI/LoadScreenMin"
import { SchemeAdd } from "./AddScheme"
import { Gear } from "react-bootstrap-icons"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"


const CopyForm = ({ id, show, setShow, name, refetch }) => {

    const [getCopyScheme, { isLoading }] = useGetCopySchemeMutation()
    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    useEffect(() => {
        // console.log(id)
        setValue("id", id)
    }, [show])
    const submit = (data) => {
        // console.log(data)
        getCopyScheme(data).then(()=>{
            reset()
            refetch()
            setShow(false)
        })
    }

    return (
        <>
            <Modal show={show} onHide={() => { setShow(false) }}>
                <Modal.Header closeButton>Создание копии схемы "<b>{name}</b>"</Modal.Header>
                <Form onSubmit={handleSubmit(submit)}>
                    <Modal.Body>
                        <FloatingLabel label={"Название"}>
                            <Form.Control placeholder={"Название"} {...register("name", { required: { value: true, message: "Поле обязательно" } })} />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        {!isLoading ?
                            <ButtonGroup size="sm">
                                <Button variant="primary" onClick={() => {
                                    reset()
                                    setShow(!show)
                                }
                                }>Отменить</Button>
                                <Button type="submit" variant="success">
                                    Добавить
                                </Button>
                            </ButtonGroup>
                            :
                            <Spinner />
                        }
                    </Modal.Footer>
                </Form >
            </Modal>
        </>
    )
}


function HallItem(props) {
    const [show, setShow] = useState(false)

    return (
        <>
            <Row className="gy-2 border-bottom mt-3 pb-3">
                <Col xs={'auto'} className="p-2">
                    <div># {props.item.id}</div>
                </Col>
                <Col className="p-2">
                    {props.item.name}
                </Col>
                <Col xs={'auto'} className="p-2">
                    <Dropdown className="mt-1">
                        <Dropdown.Toggle variant="light" split={false}>
                            <Gear className='' size='14' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => {
                                props.navigate(`/admin/schemeedit/${props.item.id}`);
                            }}>Редактировать</Dropdown.Item>
                            <Dropdown.Item onClick={() => {
                                setShow(!show);
                            }}>Создать копию схемы</Dropdown.Item>
                            {
                                /*<Dropdown.Item onClick={() => {
                                   setNewsId(item.id)
                                   setNewsGallery(!newsGallery)
                                   setNewsTitle(item.title)
                                }}>Фото галерея</Dropdown.Item> */
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            {show && <CopyForm id={props.item.id} show={show} setShow={setShow} name={props.item.name} refetch={props.refetch}/>}
        </>
    );
}


export const SchemesHall = () => {
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [stringFilter, setStringFilter] = useState("")
    const { data, isLoading, refetch } = useGetSchemesHallQuery({ pageIndex, pageSize, stringFilter })
    const [schemeHalls, setSchemeHalls] = useState([])
    const [schemeAddShow, setSchemeAddShow] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        if (data) {
            setSchemeHalls(data.getSchemeHalls)
        }
    }, [data])

    return (
        <Container>
            <div className="fs-3">
                Схемы зала
            </div>
            <Button size="sm" variant="success" onClick={() => { setSchemeAddShow(!schemeAddShow) }}>Добавить</Button>
            {schemeAddShow && <SchemeAdd schemeAddShow={schemeAddShow} setSchemeAddShow={setSchemeAddShow} refetch={refetch} />}
            {/* {show && <CopyForm id, show, setShow />} */}
            <div className="d-flex mt-3">
                {/* <label htmlFor="stringFilter" className="form-label">E-Mail</label> */}
                <input type={`text`} id="stringFilter" onChange={
                    (e) => setTimeout(() => { setStringFilter(e.target.value) }, 2000)}
                    placeholder="Поиск.." name="stringFilter" className="form-control" />
            </div>
            <div>
                <Container>
                    {!isLoading ? <>
                        {schemeHalls.length > 0 ?
                            <>
                                {schemeHalls.map(item =>
                                    <HallItem key={item.id} navigate={navigate} item={item} refetch={refetch} />
                                )}
                            </> : <div className="text-center mt-5">Список пуст</div>
                        }
                    </>
                        :
                        <LoadScreenMin height={30} />
                    }
                </Container>
            </div>
        </Container>
    )
}
import { useRef, useEffect, useState, useCallback } from 'react';
import { Stage, Layer } from 'react-konva';
import { Section } from './Section';
import { useImportScheme } from './useImportScheme';
import SeatPopup from "./SeatPopup";
import { Button, ButtonGroup } from 'react-bootstrap';


export const StageV2 = ({
    data,
    basket,
    setBasket,
    deleteSeatData,
    setDeleteSeatData,
    // schemeSize,
    setSchemeSize,
    selLng,
    // categoriesSeat
    // sessionDetails
}) => {
    const { setUrl, schemeData, setSchemeData } = useImportScheme();
    const stageRef = useRef(null);
    const containerRef = useRef(null);
    const [pos, setPos] = useState({ x: 0, y: 0 })
    const [lastCenter, setLastCenter] = useState(null);
    const [lastDist, setLastDist] = useState(0);
    const [lastScale, setLastScale] = useState(1);
    const [isScaling, setIsScaling] = useState(false);
    const [scale, setScale] = useState(1);
    const [size, setSize] = useState({
        width: 1000,
        height: 1000,
        virtualWidth: 800
    });
    const [selectedSeatsIds, setSelectedSeatsIds] = useState([]);
    const [popup, setPopup] = useState({ seat: null, row: null });
    const [heightBox, setHeightBox] = useState(500)
    const [virtualWidth, setVirtualWidth] = useState(1000);
    const [virtualHeight, setVirtualHeight] = useState(1000);
    const [scaleToFit, setScaleToFit] = useState(5);




    useEffect(() => {
        if (containerRef.current) {
            const containerSize = containerRef
            console.log(containerRef)
            setSize({
                width: containerSize.current.offsetWidth,
                height: containerSize.current.offsetHeight,
                virtualWidth: 800
            })
        }
    }, [containerRef])

    useEffect(() => {
        if (data) {
            setSchemeData(data)
        }
        else {
            setUrl("/seats-data-2.json")
        }
    }, [data])

    const setSizeScheme = () => {
        if (!stageRef.current) {
            return;
        }
        // console.log(size)
        const stage = stageRef.current;
        const clientRect = stage.getClientRect({ skipTransform: true });
        const clientRectTranform = stage.getClientRect();
        const scaleToFit = size.width / clientRect.width / 1.1;
        setHeightBox(clientRectTranform.height * 1.1)
        setScale(scaleToFit);
        setScaleToFit(scaleToFit);
        setVirtualWidth(clientRect.width);
        setVirtualHeight(clientRect.height);
        const newSize = {
            width: containerRef.current.offsetWidth,
            height: containerRef.current.offsetHeight
        };
        if (newSize.width !== size.width || newSize.height !== size.height) {
            setSize(newSize);
        }
        if (clientRect.height > 0) {
            setSchemeSize(containerRef.current.clientHeight)
        }
    }

    useEffect(() => {
        setSizeScheme()
    }, [schemeData, size]);

    // useEffect(() => {
    //     const resizeHandeler = () => {
    //         setSizeScheme()
    //     }

    //     window.addEventListener('resize', resizeHandeler)
    //     return function () {
    //         window.addEventListener('resize', resizeHandeler)
    //     }
    // }, [])

    const handleCursor = useCallback((pos) => {
        // setCursorPos(pos)
    })

    const handleSelect = useCallback((seatId, groupTag, seatData) => {
        if(basket.length >= 10){
            return window.alert("Максимальное количество мест в заказе не может превышать 10.")
        }
        if (groupTag) {
            var newAddIds = []
            var newAddSeats = []
            data.sections[0].seatsByRows.forEach(rows =>
                rows.seats.filter(seat => seat.groupTag === groupTag).forEach(seat => {
                    newAddIds = newAddIds.concat([seat.id])
                    newAddSeats = newAddSeats.concat([seat])
                })
            );
            newAddIds = selectedSeatsIds.concat(newAddIds)
            newAddSeats = basket.concat(newAddSeats)
            setSelectedSeatsIds(newAddIds);
            setBasket(newAddSeats)
            return;
        }
        const newIds = selectedSeatsIds.concat([seatId]);
        // const seat = data.sections[0].seatsByRows.forEach(rows =>
        //     rows.seats.filter(seat => seat.groupTag === groupTag).forEach(seat => {
        //     })
        // );
        const seat = basket.concat([seatData])

        setSelectedSeatsIds(newIds);
        setBasket(seat)

    },
        [selectedSeatsIds]
    );
    const handleDeselect = useCallback(
        (seatId, groupTag) => {
            if (groupTag) {
                var newAddIds = []
                var newAddSeats = []

                data.sections[0].seatsByRows.forEach(rows =>
                    rows.seats.filter(seat => seat.groupTag === groupTag).forEach(seat => {
                        newAddIds = newAddIds.concat([seat.id])
                        newAddSeats = newAddSeats.concat([seat])
                    })
                );
                const ids = selectedSeatsIds.slice();
                const basketSlice = basket.slice();
                newAddIds.forEach(item => {
                    ids.splice(ids.indexOf(item), 1)
                })
                newAddSeats.forEach(seat => {
                    const itemsIndex = basket.map(item => item.id).indexOf(seat.id)
                    basketSlice.splice(basketSlice.indexOf(itemsIndex), 1)
                })
                setSelectedSeatsIds(ids);
                setBasket(basketSlice)
                return;
            }
            const ids = selectedSeatsIds.slice();
            const indexItem = basket.map(item => item.id).indexOf(seatId)
            const basketSlice = basket.slice();
            // console.log(basket.indexOf(indexItem))
            basketSlice.splice(indexItem, 1)
            ids.splice(ids.indexOf(seatId), 1);
            setBasket(basketSlice)
            setSelectedSeatsIds(ids);
        },
        [selectedSeatsIds]
    );

    const getDistance = (p1, p2) => Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
    const getCenter = (p1, p2) => ({ x: (p1.x + p2.x) / 2, y: (p1.y + p2.y) / 2, });

    const handleTouchStart = (event) => {
        const touches = event.evt.touches;
        if (touches.length === 2) {
            setIsScaling(true);
            setLastScale(lastScale)
            const touch1 = touches[0];
            const touch2 = touches[1];

            var p1 = {
                x: touch1.clientX,
                y: touch1.clientY
            }
            var p2 = {
                x: touch2.clientX,
                y: touch2.clientY
            }
            const lastCenterData = getCenter(p1, p2);
            const lastDistData = getDistance(p1, p2)
            setLastDist(lastDistData)
            setLastCenter(lastCenterData)
        }
    };
    // Handle touch move
    const handleTouchMove = (event) => {
        if (isScaling) {
            const touches = event.evt.touches;
            if (touches.length === 2) {
                // var lastCenter = null
                if (!lastCenter) {
                    return;
                }

                const touch1 = touches[0];
                const touch2 = touches[1];

                var p1 = {
                    x: touch1.clientX,
                    y: touch1.clientY
                }
                var p2 = {
                    x: touch2.clientX,
                    y: touch2.clientY
                }


                var newCenter = getCenter(p1, p2)

                var dist = getDistance(p1, p2);

                var pointTo = {
                    x: (newCenter.x - stageRef.current.attrs.x) / stageRef.current.attrs.scaleX,
                    y: (newCenter.y - stageRef.current.attrs.y) / stageRef.current.attrs.scaleX,
                };

                // console.log(lastDist)

                var newScale = stageRef.current.attrs.scaleX * (dist / lastDist);

                var dx = newCenter.x - lastCenter.x;
                var dy = newCenter.y - lastCenter.y;

                var newPos = {
                    x: newCenter.x - pointTo.x * newScale + dx,
                    y: newCenter.y - pointTo.y * newScale + dy,
                };

                setLastDist(dist)
                setLastCenter(newCenter)
                if (window.innerWidth < 760) {
                    if (newScale >= 0.3 && newScale <= 0.7) {
                        setScale(newScale)
                        setPos(newPos)
                        return
                    }
                }
                if (newScale >= 0.5 && newScale <= 1.4) {
                    setScale(newScale)
                    setPos(newPos)
                }

            }
        }
    };
    // Handle touch end
    const handleTouchEnd = () => {
        setIsScaling(false);
    };

    const handleHover = useCallback((seat, pos, row, price, category, categoryFill, groupTag) => {
        if (groupTag) {
            price = 0
            data.sections[0].seatsByRows.forEach(rows =>
                rows.seats.filter(seat => seat.groupTag === groupTag).forEach(seat => {
                    price = price + seat.price
                })
            );
            // newAddIds = selectedSeatsIds.concat(newAddIds)
            // setSelectedSeatsIds(newAddIds);
            // return;
        }
        setPopup({
            seat: seat,
            row: row,
            position: pos,
            price: price,
            category: category,
            categoryFill: categoryFill,
            groupTag: groupTag
        });
    }, []);

    useEffect(() => {
        if (deleteSeatData) {
            handleDeselect(deleteSeatData.id, deleteSeatData.groupTag)
            setDeleteSeatData(null)
        }
    }, [deleteSeatData])

    const zoomMinuses = () => {
        var newScale = scale - 0.10;
        if (window.innerWidth < 760) {
            if (newScale >= 0.3 && newScale <= 0.7) {
                setScale(newScale)
            }
        }
        if (newScale >= 0.5 && newScale <= 1.4) {
            setScale(newScale)
        }
    }

    const zoomPluse = () => {
        var newScale = scale + 0.10;
        if (window.innerWidth < 760) {
            if (newScale >= 0.3 && newScale <= 0.7) {
                setScale(newScale)
            }
        }
        if (newScale >= 0.5 && newScale <= 1.4) {
            setScale(newScale)
        }
    }

    return (
        <>
            {schemeData !== null ?
                <div ref={containerRef}
                    style={{
                        position: "relative",
                        backgroundColor: "#f3f3f3",
                        width: "100%",
                        height: heightBox,
                    }}
                    id={"schemeHellcontainer"}
                >
                    <div className="ps-1 pt-1 position-absolute" style={{ left: 0, right: 0, zIndex: 100, width: 'fit-content' }}>
                        <ButtonGroup>
                            <Button size="sm" className="btn-lc" onClick={() => {
                                zoomPluse()// setSizeScheme()
                            }
                            }>+</Button>
                            <Button size="sm" className="btn-lc" onClick={() => { zoomMinuses() }}>-</Button>
                        </ButtonGroup>
                    </div>
                    <Stage
                        ref={stageRef}
                        width={size.width}
                        height={size.height}
                        draggable={!isScaling}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                        x={pos.x}
                        y={pos.y}
                        scaleX={scale}
                        scaleY={scale}
                    >

                        <Layer>
                            {schemeData.sections.map(section => {
                                const offset = 30;
                                return (
                                    <Section
                                        handleCursor={handleCursor}
                                        x={offset}
                                        y={schemeData.offsetTop}
                                        width={schemeData.width}
                                        height={schemeData.height}
                                        texts={schemeData.texts}
                                        objects={schemeData.staticObjects}
                                        key={section.id}
                                        section={section.seatsByRows}
                                        staticObjects={section.staticObjects}
                                        selectedSeatsIds={selectedSeatsIds}
                                        onHoverSeat={handleHover}
                                        onSelectSeat={handleSelect}
                                        onDeselectSeat={handleDeselect}
                                    />
                                );
                            })}
                        </Layer>
                    </Stage>
                    {popup.seat && (
                        <SeatPopup
                            position={popup.position}
                            seatId={popup.seat}
                            nameRow={popup.row}
                            price={popup.price}
                            category={popup.category}
                            categoryFill={popup.categoryFill}
                            groupTag={popup.groupTag}
                            selLng={selLng}
                            onClose={() => {
                                setPopup({ seat: null });
                            }}
                        />
                    )}
                </div>
                : <></>}
        </>
    );
};

import { useState } from "react";
import { Button, ButtonGroup, FloatingLabel, Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import { useGetNewsDetailsQuery, usePutNewsByIdMutation } from "../../../features/CallApi";
import { LoadScreenMin } from "../../UI/LoadScreenMin";
import { useEffect } from "react";
import { useRTL } from "yet-another-react-lightbox";
import { ReactQuillLC } from "../../UI/ReactQuillLC";


export const NewsEdit = ({ newsEditForm, setNewsEditForm, newsId, setNewsId }) => {

    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [valueEditor, setValueEditor] = useState()
    const [valueEditorEN, setValueEditorEN] = useState()
    const [isLoading, setIsLoading] = useState()

    const { data, isLoading: isLoadingData, refetch } = useGetNewsDetailsQuery(newsId)
    const [putNewsById, { isLoading: isLoadingPut }] = usePutNewsByIdMutation()

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        console.log(isLoadingPut)
        if (isLoadingPut) {
            setIsLoading(true)
        }
        else {
            setIsLoading(false)
        }
    }, [isLoadingPut])

    useEffect(() => {
        console.log(isLoadingData)
        if (isLoadingData) {
            setIsLoading(true)
        }
        else {
            setIsLoading(false)
        }
    }, [isLoadingData])

    useEffect(() => {
        if (data) {
            setValue("title", data.title)
            setValue("titleEN", data.titleEN)
            setValue("typeNews", data.typeNews)
            setValue("publishDate", data.publishDate)
            setValueEditor(data.content)
            setValueEditorEN(data.contentEN)
        }
    }, [data])

    const onSubmit = (data) => {

        data.id = newsId
        data.content = valueEditor;
        data.contentEN = valueEditorEN;
        console.log(data)
        putNewsById({ id: newsId, body: data }).unwrap().then(() => {
            refetch()
        })
    }

    return (
        <Modal show={newsEditForm}
            onHide={() => {
                setNewsEditForm(false)
            }}
            size="xl"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton><h3>Редактировать новость</h3></Modal.Header>
            {!isLoading ? <>
                {data &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Body>
                            <FloatingLabel
                                controlId="floatingTitile"
                                label="Заголовок"
                                className="mb-3"
                            >
                                <Form.Control type="text" isInvalid={errors.title}
                                    isValid={isValid.title}
                                    placeholder="Заголовок" {...register("title", { required: { value: true } })} />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingTitile"
                                label="Заголовок EN"
                                className="mb-3"
                            >
                                <Form.Control type="text" placeholder="Заголовок EN" {...register("titleEN")} />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingSelect"
                                label="Тип новости"
                                className="mb-3">
                                <Form.Select isInvalid={errors.typeNews} onChange={() => { alert() }} defaultValue={""}
                                    {...register("typeNews", { required: { value: true } })}
                                    aria-label="Тип новости">
                                    <option disabled hidden value={""}>--- Выберите тип ---</option>
                                    <option value="0">Новость</option>
                                    <option value="1">СМИ о нас</option>
                                </Form.Select>
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingSelect"
                                label="Тип новости"
                                className="mb-3">
                                <Form.Control type="datetime-local" isInvalid={errors.typeNews} onChange={() => { alert() }} defaultValue={""} {...register("publishDate")}
                                    aria-label="Тип новости" />
                            </FloatingLabel>
                            <div className="mb-3">
                                <label>Содержание</label>
                                <ReactQuillLC valueEditor={valueEditor} setValueEditor={setValueEditor} />

                            </div>
                            <div className="mb-3">
                                <label>Содержание EN</label>
                                <ReactQuillLC valueEditor={valueEditorEN} setValueEditor={setValueEditorEN} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonGroup size="sm">
                                <Button variant="primary" onClick={() => {
                                    reset()
                                    setNewsEditForm(!newsEditForm)
                                }
                                }>Отменить</Button>
                                <Button type="submit" variant="success">
                                    Добавить
                                </Button>
                            </ButtonGroup>
                        </Modal.Footer>
                    </form>
                }
            </> : <LoadScreenMin height={10} />}
        </Modal>
    )
}
import { useState } from "react"
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap"
import { Gear } from "react-bootstrap-icons"
import { LoadScreenMin } from "../../UI/LoadScreenMin"
import { useDeleteArtistMutation, useGetTroupeListQuery } from "../../../features/CallApi"
import { ArtistAdd } from "./ArtistAdd"
import { ArtistEdit } from "./ArtistEdit"


export const TroupeList = () => {

    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [stringFilter, setStringFilter] = useState("")
    const [artistsAddForm, setArtistsAddForm] = useState(false)
    const [artistsEditForm, setArtistsEditForm] = useState(false)
    // const [showSchedule, setShowSchedule] = useState(false)
    const [artistsId, setArtistsId] = useState()
    const { data, isLoading, refetch } = useGetTroupeListQuery({ pageIndex, pageSize, stringFilter })
    const [deleteArtist] = useDeleteArtistMutation()


    return (
        <Container>
            <div className="fs-3">
                Труппа
            </div>
            <div>
                <Button size="sm" variant="success" onClick={() => { setArtistsAddForm(!artistsAddForm) }}>Добавить</Button>
                {artistsAddForm &&
                    <>
                        <ArtistAdd
                            artistsAddForm={artistsAddForm}
                            setArtistsAddForm={setArtistsAddForm}
                        />
                    </>
                }
                {artistsEditForm &&
                    <ArtistEdit artistsEditForm={artistsEditForm} setArtistsEditForm={setArtistsEditForm} artistsId={artistsId} setArtistsId={setArtistsId} />
                }

            </div>
            <div className="d-flex mt-3">
                {/* <label htmlFor="stringFilter" className="form-label">E-Mail</label> */}
                <input type={`text`} id="stringFilter" onChange={
                    (e) => setTimeout(() => { setStringFilter(e.target.value) }, 2000)}
                    placeholder="Поиск.." name="stringFilter" className="form-control" />
            </div>
            <div>
                {!isLoading ?
                    <>
                        {data.troupe.length > 0 ?
                            <>
                                {data.troupe.map(item =>
                                    <Row key={item.id} className="border-bottom mt-3 pb-3">
                                        <Col xs={'auto'}>
                                            <div>
                                                # {item.id}
                                            </div>
                                        </Col>

                                        <Col>
                                            <div>
                                                {item.name}
                                            </div>
                                        </Col>

                                        <Col xs={'auto'}>
                                            <Dropdown className="mt-1">
                                                <Dropdown.Toggle variant="light" split={false}>
                                                    <Gear className='' size='14' />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => {
                                                        setArtistsId(item.id)
                                                        setArtistsEditForm(!artistsEditForm)
                                                    }}>Редактировать</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => {
                                                        if (window.confirm(`Удалить ${item.name}?`)) {
                                                            deleteArtist(item.id)
                                                        }
                                                    }}>Удалить</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                )}

                            </>
                            : <div className="text-center">Список пуст
                            </div>
                        }
                    </>
                    :
                    <LoadScreenMin />}
            </div>

            {!isLoading ? <></> : <LoadScreenMin />}
            <div className="d-flex flex-row justify-content-center align-content-center mt-3">
                <div className="btn-group">
                    <button
                        className={pageIndex == 1 ? `btn btn-primary disabled` : `btn btn-primary`}
                        onClick={() => {
                            if (pageIndex > 1) {
                                setPageIndex(pageIndex - 1)
                            }
                        }}>Назад</button>
                    <button
                        className={pageIndex == data?.totalPages || data?.totalPages === 0 ? `btn btn-primary disabled` : `btn btn-primary`}
                        onClick={
                            () => {
                                if (pageIndex <= data?.totalPages) {
                                    setPageIndex(pageIndex + 1)
                                }
                            }}
                    >Вперед</button>
                </div>
            </div>
        </Container>
    )
}
import { useForm } from "react-hook-form";
import { useBaseTokenMutation, usePostRequestResetPasswordMutation } from "../../features/CallAppApi";
import { Button, Col, Container, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


export const ClientRecovery = () => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [baseToken] = useBaseTokenMutation()
    const [postRequestResetPassword, { isLoading }] = usePostRequestResetPasswordMutation()
    const { t } = useTranslation("global");
    const navigate = useNavigate();



    const requestResetPassword = (data) => {
        baseToken().unwrap().then((r) => {
            postRequestResetPassword({ data, baseToken: r.token }).unwrap().then((r) => {
                if (r.code === 4010) {
                    reset()
                    return navigate(`/client/resetpass?infoMsgShow=true&login=${data.email}`)
                }
                reset()
            }).catch((e) => {
                window.alert("Error")
                console.error(e)
            })
        }).catch((r) => { console.error(r) })
    }


    return (
        <>
            <div className="about-haed-layout mb-3">
                <div className="about-head-layout-img" style={{ backgroundImage: `url("/AboutProjcet/about-project.jpg")` }}>
                </div>
                <div className="about-head-mask">
                    <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3"
                        style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                    </div>
                </div>
            </div>
            <Container>
                <form style={{ minHeight: '50vh', alignItems: 'center', display: 'grid' }} onSubmit={handleSubmit(requestResetPassword)}>
                    <Row className={'justify-content-center'}>
                        <Col md={8}>
                            <h3 className="text-center">{t("form.passwordRecovery")}</h3>
                            <div className="bg-white shadow p-3 mb-3">
                                <p>
                                    С целью обеспечения дополнительной безопасности, пароль, указанный Вами при регистрации, хранится в базе данных системы в зашифрованном виде.
                                </p>
                                <p>
                                    Пароль не может быть расшифрован системой и отправлен на Ваш e-mail, но может быть Вами изменен. Для этого на адрес электронной почты, указанный Вами при регистрации, будет отправлено письмо, содержащее уникальную ссылку.
                                </p>
                                <p>
                                    При переходе по ней система отобразит форму ввода нового пароля. Пароль, используемый для доступа к системе в настоящий момент перестанет действовать только после того, как будет введен и сохранен новый.
                                </p>
                                <p>
                                    В системе хранится e-mail, указанный Вами при регистрации и, возможно, номер Вашего телефона, т.к. это поле не является обязательным.
                                </p>
                                <p>
                                    Вы можете использовать любое из приведенных ниже полей ввода для подачи запроса на изменение пароля. Независимо от того, какое поле использовано, письмо будет отправлено на адрес электронной почты. По SMS пароль не высылается. Убедитесь, что у Вас есть доступ к почтовому ящику, указанному при регистрации.
                                </p>
                            </div>

                        </Col>
                    </Row>
                    <Row className={'justify-content-center'}>
                        <Col md={8} lg={4}>
                            <h4>Укажите данные для выстановления:</h4>
                            <FloatingLabel className="mb-1" controlId="email" label={`E-Mail`} >
                                <Form.Control type="email" placeholder={`E-Mail`}
                                    {...register("email", { required: { value: true, message: "Поле E-Mail обязательное" }, pattern: /^\S+@\S+$/i })} isInvalid={errors?.email?.message != null} />
                            </FloatingLabel>
                            <div className={'d-flex justify-content-end mt-3'}>
                                <Button type="submit" className="btn-lc btn-lc-rounded">{t("form.confirm")} {isLoading && <Spinner size="sm" />}</Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </Container >
        </>
    )
}
import { useEffect, useState } from "react";
import { Accordion, Button, ButtonGroup, Col, FloatingLabel, Form, FormFloating, FormGroup, InputGroup, Row, Spinner } from "react-bootstrap"
import { Check, Gear, Trash, X } from "react-bootstrap-icons"
import { useForm } from "react-hook-form";
import { usePutEditCategorySeatMutation } from "../../../../features/CallApi";




const EditForm = (props) => {
    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [putEditCategorySeat, { isLoading }] = usePutEditCategorySeatMutation()
    useEffect(() => {
        console.log(props)
        setValue("id", props.category.id)
        setValue("name", props.category.name)
        setValue("nameEn", props.category.nameEn)
        setValue("fill", props.category.fill)
        setValue("description", props.category.description)
        setValue("descriptionEn", props.category.descriptionEn)
    }, [props.showEdit])

    const submit = (data) => {
        console.log(data)
        putEditCategorySeat(data).then(() => {
            reset()
            props.refetch()
            props.setShowEdit(false)
        }).catch(() => {
            window.alert("Error")
        })

    }

    return (
        <Form className="mb-3 mt-2 border-top" onSubmit={handleSubmit(submit)}>
            <div className=" mt-2">Редактирование категории</div>
            <Row>
                <Col>
                    <FormGroup className="mb-1">
                        <InputGroup>
                            <InputGroup.Text>Название</InputGroup.Text>
                            <Form.Control {...register("name", { required: { value: true } })} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-1">
                        <InputGroup>
                            <InputGroup.Text>Название EN</InputGroup.Text>
                            <Form.Control {...register("nameEn", { required: { value: true } })} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-1">
                        <InputGroup>
                            <InputGroup.Text>Цвет</InputGroup.Text>
                            <Form.Control {...register("fill")} type="color" />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-1">
                        <FloatingLabel
                            controlId="floatingTextarea"
                            label="Описание"
                        >
                            <Form.Control as="textarea" placeholder="Описание" {...register("description")} />
                        </FloatingLabel>
                    </FormGroup>
                    <FormGroup className="mb-1">
                        <FloatingLabel
                            controlId="floatingTextarea"
                            label="Описание EN"
                        >
                            <Form.Control as="textarea" placeholder="Описание EN" {...register("descriptionEn")} />
                        </FloatingLabel>
                    </FormGroup>
                </Col>
                <Col xs={"auto"} style={{ alignContent: "center" }}>
                    {!isLoading ? 
                    <ButtonGroup>
                        <Button variant="outline-success" style={{
                            lineHeight: '16px'
                        }} size="sm" className="p-1" type="submit"><Check size={16} /></Button>
                        <Button variant="outline-secondary" style={{
                            lineHeight: '16px'
                        }} size="sm" className="p-1" onClick={() => {
                            props.setShowEdit(!props.showEdit);
                        }}><X size={16} /></Button>
                    </ButtonGroup>
                    : <Spinner />}
                </Col>
            </Row>
        </Form>
    );
}


const CategoryItem = (props) => {

    const [showEdit, setShowEdit] = useState(false)


    return (
        <div className="border-bottom mb-1 p-1">
            <Row className="mb-1">
                <Col>
                    <Row>
                        {
                            /* <Col xs={'auto'}></Col> */
                        }
                        <Col style={{
                            color: props.category.fill
                        }}>{props.category.name}</Col>
                        <Col xs={12}>цена: {props.category.price}</Col>
                    </Row>
                </Col>
                <Col xs={'auto'} style={{
                    alignContent: 'center'
                }}>
                    <ButtonGroup>
                        <Button variant="outline-secondary" style={{
                            lineHeight: '16px'
                        }} size="sm" className="p-1" onClick={() => {
                            setShowEdit(!showEdit)
                        }}><Gear size={16} /></Button>
                        {/* <Button variant="outline-danger" style={{
                            lineHeight: '16px'
                        }} size="sm" className="p-1" onClick={() => {
                            window.confirm("Вы уверены?");
                        }}>
                            <Trash size={16} />
                        </Button> */}
                    </ButtonGroup>
                </Col>
            </Row>
            {showEdit &&
                <EditForm showEdit={showEdit} refetch={props.refetch} setShowEdit={setShowEdit} category={props.category}></EditForm>
            }
        </div>
    );
}


export const Categores = ({ data, refetch }) => {

    return (
        <Accordion>
            <Accordion.Item eventKey="cat">
                <Accordion.Button className="p-1">Категории</Accordion.Button>
                <Accordion.Body className="p-1">
                    {data.map(category =>
                        <CategoryItem key={category.id} refetch={refetch} category={category}></CategoryItem>
                    )}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}
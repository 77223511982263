import { Button, ButtonGroup } from "react-bootstrap"
import { PhotoGallery } from "../UI/PhotoGallery"
import { useTranslation } from "react-i18next";


export const Bars = ({ selLanguage, reserFormShow, setReserFormShow }) => {

    const [t] = useTranslation("global");

    const photosBlack = [
        { src: "/AboutProjcet/091A8275.jpg", width: 2500, height: 1667, alt: "Черный бар Лениград Центра" },
        { src: "/AboutProjcet/091A8278.jpg", width: 2500, height: 1667, alt: "Черный бар Лениград Центра" },
        { src: "/AboutProjcet/091A8303.jpg", width: 2500, height: 1667, alt: "Черный бар Лениград Центра" },
    ]
    const photosWhite = [
        { src: "/AboutProjcet/091A8242-1.jpg", width: 2500, height: 1667, alt: "Белый бар Лениград Центра" },
        { src: "/AboutProjcet/091A8245-1.jpg", width: 2500, height: 1667, alt: "Белый бар Лениград Центра" },
        { src: "/AboutProjcet/091A8242-1.jpg", width: 2500, height: 1667, alt: "Белый бар Лениград Центра" },
    ]
    return (
        <>
            <div>
                <ButtonGroup className="mb-3">
                    {/* <Button size="sm" variant="light" className="btn-lc">{t("general.blackMenu")}</Button> */}
                    <Button size="sm" href="https://leningradcenter.ru/api/redirecturls/menu-bar" target="_blank" variant="light" className="btn-lc">{t("general.barMenu")}</Button>
                    {/* <Button size="sm" variant="light" className="btn-lc"></Button> */}
                </ButtonGroup>
            </div>
            <div>
                <ButtonGroup className="mb-3">
                    {/* <Button size="sm" variant="light" className="btn-lc">{t("general.blackMenu")}</Button> */}
                    <Button size="sm" onClick={() => { setReserFormShow(!reserFormShow) }} variant="light" className="btn-lc">{t("menu.reservation")}</Button>
                    {/* <Button size="sm" variant="light" className="btn-lc"></Button> */}
                </ButtonGroup>
            </div>
            {selLanguage === "en" ?
                <>
                    <h2>Black bar</h2>
                    <p>
                        A music bar. A place with the ambiance of cinema and fashion. The Black Bar is the venue of choice for stylish parties, concerts, and unusual performances.
                    </p>
                    <p>
                        The Black Bar in the right wing of the theater hall is an experimental interior design project of famous Russian fashion designer Valentin Yudashkin. The main elements of decor present an eclectic combination of metal and crystal, leather and velvet, dark and light tones.
                    </p>
                    <p>
                        Come here to enjoy the atmosphere of theater wings. Stopping by the Black Bar before the show you can run into actresses all ready in their makeup, or a conferencier at the bar counter.
                    </p>
                    <p>
                        The cocktail menu of the Black Bar presents classical old school cocktails, as well as new drinks mixed by our baristas according to their own original mixology.If desired, cold drinks can be served to your table in the theater hall.
                    </p>
                </>
                :
                <>
                    <h2>Черный бар</h2>
                    <p>
                        Черный бар Ленинград Центра – это экспериментальный проект известного российского модельера Валентина Юдашкина в сфере интерьерного дизайна. Главными приемами декора выбраны смешение стилей и эклектика сочетаний металла и хрусталя, кожи и бархата, темного и светлого.
                    </p>
                    <p>
                        Здесь всегда царит атмосфера театрального закулисья. Зайдя в Черный бар Ленинград Центра до спектакля, можно застать актрис в гриме или конферансье за барной стойкой.
                    </p>
                    <p>
                        В коктейльной карте Черного бара представлены нетривиальные авторские коктейли. По желанию холодные напитки могут быть поданы к Вашему столику в театральном зале.
                    </p>
                </>
            }
            <div className="mb-3">
                <PhotoGallery photos={photosBlack} />
            </div>
            {
                selLanguage === "en" ?
                    <>
                        <h2>
                            White bar
                        </h2>
                        <p>
                            A music bar. A place with the ambiance of theater. The White Bar is the venue of art performances, recitals and shows by popular theater actors.
                        </p>
                        <p>
                            The White Bar is located in the left wing of theater auditorium. The main theme of design of the White Bar are mirrors from makeup rooms, and the interior is open and solemn-looking.
                        </p>
                        <p>
                            There is quiet music playing in the White Bar during entractes, with the theme of mysteriousness and theatre giving way to that of elegance.
                        </p>
                        <p>
                            The White Bar menu focuses on entrés and apéritifs, which can be delivered to your table in the theater auditorium.
                        </p>
                    </>
                    :
                    <>
                        <h2>Белый бар</h2>
                        <p>
                            Музыкальный бар, место, где царит атмосфера театра. Здесь проходят художественные вечера, авторские чтения, выступления популярных артистов театра. Белый бар расположен в левом крыле театрального зала. Здесь «звучит» тема зеркал из гримерных, а интерьер решен в парадном, открытом формате.
                        </p>
                        <p>
                            В антрактах в Белом баре играет спокойная музыка, а тема таинственности и театральности уступает элегантности.
                        </p>
                        <p>
                            В меню Белого бара большое внимание уделено аперитивам, которые могут быть поданы к Вашему столику в театральном зале.
                        </p>
                    </>
            }
            <div className="mb-3">
                <PhotoGallery photos={photosWhite} />
            </div>
        </>
    )
}
import { useState } from "react"
import { Button, ButtonGroup, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Check, DistributeHorizontal, DistributeVertical, PlusSquare, TextareaResize, X } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import { usePostAddTextMutation } from "../../../../features/CallApi";


export const AddText = ({ schemeId, refetch }) => {

    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [showAddText, setShowAddText] = useState()
    const [postAddText, { isLoading }] = usePostAddTextMutation()
    const submit = (data) => {
        data.schemeId = schemeId
        console.log(data)
        postAddText(data).then(()=>{
            reset()
            refetch()
            setShowAddText(false)
        })
    }

    return (
        <>
            <ButtonGroup className="mb-1 mt-1">
                <Button size="sm" variant="outline-secondary" onClick={() => {
                    setShowAddText(!showAddText)
                }}><PlusSquare /></Button>
                {/* <Button variant="outline-secondary" onClick={() => { }}><Clipboard2 /></Button> */}
            </ButtonGroup>
            {showAddText &&
                <Form className="mb-3 border-bottom" onSubmit={handleSubmit(submit)}>
                    <Row>
                        <Col>
                            <Row className="mb-1">
                                <Col>
                                    <Col>
                                        <Form.Group className="d-flex">
                                            <InputGroup style={{ lineHeight: '18px' }}>
                                                <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">Текст</InputGroup.Text>
                                                <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                    {...register("text", { required: { value: true } })}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Col>

                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <Col>
                                        <Form.Group className="d-flex">
                                            <InputGroup style={{ lineHeight: '18px' }}>
                                                <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">Текст EN</InputGroup.Text>
                                                <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                    {...register("textEn", { required: { value: true } })}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">X</InputGroup.Text>
                                            <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                {...register("x", { required: { value: true } })}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">Y</InputGroup.Text>
                                            <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                {...register("y", { required: { value: true } })}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">Цвет</InputGroup.Text>
                                            <Form.Control type="color" size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                {...register("fill", { required: { value: true } })}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">
                                                <TextareaResize />
                                            </InputGroup.Text>
                                            <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                {...register("width", { required: { value: true } })}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">Размер</InputGroup.Text>
                                            <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                {...register("fontSize", { required: { value: true } })}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">
                                                <DistributeHorizontal />
                                            </InputGroup.Text>
                                            <Form.Select size="sm" style={{ lineHeight: '18px' }} className="p-1" defaultValue={"center"}
                                                {...register("align", { required: { value: true } })}
                                            >
                                                <option value={"left"}>По левому</option>
                                                <option value={"center"}>По центру</option>
                                                <option value={"right"}>По правому</option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">
                                                <DistributeVertical />
                                            </InputGroup.Text>
                                            <Form.Select size="sm" style={{ lineHeight: '18px' }} className="p-1" defaultValue={"middle"}
                                                {...register("verticalAlign", { required: { value: true } })}
                                            >
                                                <option value={"top"}>По верхнему</option>
                                                <option value={"middle"}>По центру</option>
                                                <option value={"bottom"}>По правому</option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={'auto'} style={{ alignContent: 'center' }}>
                            <ButtonGroup>
                                <Button style={{ lineHeight: '10px' }} type="submit" className="p-1" variant="success"><Check size={16} /></Button>
                                <Button style={{ lineHeight: '10px' }} onClick={() => { setShowAddText(false); reset(); }} className="p-1" variant="ligth"><X size={16} /></Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Form>
            }
        </>
    )
}
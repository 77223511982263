import { Fragment, useEffect, useState } from "react"
import { useGetclientDataQuery, usePutClientMutation } from "../../features/CallAppApi"
import { useForm } from "react-hook-form";
import { Alert, Button, ButtonGroup, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { LoadScreenMin } from "../UI/LoadScreenMin";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import axios from "axios";



const DownloadTicket = ({ orderId, payService }) => {
    // const [getDownloadTickets, { isLoading }] = useGetDownloadTicketsMutation()
    const [isLoading, setIsLoading] = useState(false)
    const downloadTicket = () => {
        setIsLoading(true)
        const clientData = JSON.parse(sessionStorage.getItem('clientToken'))
        fetch(`https://app.leningradcenter.ru/api/clients/download-tickets/${orderId}?payService=${payService}`, {
            headers: { Authorization: `Bearer ${clientData.Token}` }
        })
            .then(res => res.blob())
            .then(blob => {
                setIsLoading(false)
                var file = window.URL.createObjectURL(blob);
                let tab = window.open();
                return tab.location.href = file;
            })
            .catch(() => {
                setIsLoading(false)
                window.alert("Error")
            })

    }

    return (
        <>
            {!isLoading ? <Button className="btn-lc" onClick={() => {
                console.log(orderId);
                downloadTicket()
            }} disabled={isLoading}>Билеты</Button> : <Spinner />}
        </>
    );
}


export const ClientProfile = ({ t }) => {

    const { data, isLoading, refetch } = useGetclientDataQuery()
    const [putClient, { isLoading: isPutClient }] = usePutClientMutation()
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const [receiveNewsletter, setReceiveNewsletter] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const navigate = useNavigate()
    const update = (data) => {
        putClient(data).unwrap().then(() => {
            setShowAlert(true)
            setTimeout(() => { setShowAlert(false) }, 3000)
        })
    }
    useEffect(() => {
        if (data) {
            setValue("email", data.email)
            setValue("firstName", data.firstName)
            setValue("lastName", data.lastName)
            setValue("patronymic", data.patronymic)
            setValue("phone", data.phone)
            setReceiveNewsletter(data.receiveNewsletter)
            setValue("receiveNewsletter", data.receiveNewsletter)
        }
    }, [data])

    const goToPay = (data) => {
        const clientData = JSON.parse(sessionStorage.getItem('clientToken'))

        axios.get(`https://app.leningradcenter.ru/api/ticketsorders/go-to-pay/${data.id}`
            , {
                headers: { Authorization: `Bearer ${clientData.Token}` }
            }).then((r) => {
                console.log(r)
                const bankUrl = r.data.bankUrl;
                window.location.href = bankUrl;
            }).catch((e) => {
                console.log(e)
            })
    }

    const cancelOrder = (data) => {
        const clientData = JSON.parse(sessionStorage.getItem('clientToken'))

        axios.get(`https://app.leningradcenter.ru/api/ticketsorders/cancel-order/${data.id}`
            , {
                headers: { Authorization: `Bearer ${clientData.Token}` }
            }).then((r) => {
                refetch()
            })
    }

    return (
        <>
            {!isLoading ?
                <>
                    <Row className={'justify-content-center'}>
                        <Col md={6} lg={4}>
                            <h4>{t("form.mycontact")}</h4>
                            {showAlert &&
                                <Alert variant={'success'} onClose={() => setShowAlert(showAlert)} dismissible>
                                    {t("form.updated")}
                                </Alert>
                            }
                            <form onSubmit={handleSubmit(update)}>
                                <Row className="g-1">
                                    <Col>
                                        <FloatingLabel className="mb-1" controlId="firstName" label={`${t('form.firstName')} *`}>
                                            <Form.Control type="text" placeholder={`${t('form.firstName')} *`}
                                                {...register("firstName", { required: { value: true, message: `Поле ${t('form.firstName')} обязательное` } })}
                                                isInvalid={errors?.firstName?.message != null} />
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel className="mb-1" controlId="lastName" label={`${t('form.lastName')} *`}>
                                            <Form.Control type="text" placeholder={t('form.lastName')}
                                                {...register("lastName", { required: { value: true, message: `Поле ${t('form.lastName')} обязательное` } })}
                                                isInvalid={errors?.lastName?.message != null} />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <FloatingLabel className="mb-1" controlId="patronymic" label={t('form.patronymic')}>
                                    <Form.Control type="text" placeholder={t('form.patronymic')}  {...register("patronymic")} />
                                </FloatingLabel>
                                <FloatingLabel className="mb-1" controlId="email" label={`E-Mail`}>
                                    <Form.Control type="email" placeholder={"E-Mail"} {...register("email", { required: { value: true, message: "Поле E-Mail обязательное" }, pattern: /^\S+@\S+$/i })} isInvalid={errors?.email?.message != null} />
                                </FloatingLabel>
                                <FloatingLabel className="mb-1" controlId="phone" label={t("form.phone")}>
                                    <Form.Control type="phone" placeholder={t("form.phone")}  {...register("phone", {
                                        required: { value: true, message: `Поле ${t('form.phone')} обязательное` },
                                    })}
                                        isInvalid={errors?.phone?.message != null} />
                                </FloatingLabel>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="rememberMe"
                                    label={t("form.receiveNewsletter")}
                                    className="form-check-lc-input mb-3"
                                    checked={receiveNewsletter} onChange={() => { setReceiveNewsletter(!receiveNewsletter); setValue("receiveNewsletter", !receiveNewsletter) }}
                                />
                                <div className='d-flex justify-content-between'>
                                    <Button onClick={() => { navigate("/logout") }} className="btn-lc-no-bg btn-lc-rounded">{t("form.logout")}
                                        {isPutClient && <Spinner size="sm" />}
                                    </Button>
                                    <Button type="submit" className="btn-lc btn-lc-rounded">{t("form.update")}
                                        {isPutClient && <Spinner size="sm" />}
                                    </Button>
                                </div>
                            </form>
                        </Col>
                        <Col className="d-none d-md-block" md={6} lg={4}>
                            <h4>{t("form.orders")}</h4>
                            {data?.ordersTickets.length > 0 ?
                                <>
                                    {data.ordersTickets.map(item =>
                                        <Fragment key={item.orderId}>
                                            <div className="border-bottom g-1 p-3 d-flex" >
                                                <div className="me-3 flex-fill">
                                                    <div className="me-1"> Заказ <span>#</span> <b>{item.orderId}</b></div>
                                                    <div>
                                                        {item.eventName}
                                                    </div>
                                                    <div>
                                                        <div>
                                                            Дата: <b>{format(new Date(item.eventDate), "dd.MM.yy")}</b>
                                                        </div>
                                                        <div>
                                                            Начало: <b>{format(new Date(item.eventDate), "HH:mm")}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                {item.status === 2 &&
                                                    <div className="me-0 ms-auto" style={{ display: 'grid', alignItems: "center" }}>
                                                        <DownloadTicket orderId={item.orderId} payService={item.payService} />
                                                    </div>
                                                }
                                                {item.payService === "Nikalas" &&
                                                    <div className="me-0 ms-auto" style={{ display: 'grid', alignItems: "center" }}>
                                                        <DownloadTicket orderId={item.orderId} payService={item.payService} />
                                                    </div>
                                                }
                                            </div>
                                            {item.status === 1 &&
                                                <div className="me-0 ms-auto g-1 border-bottom" style={{ display: 'grid', alignItems: "center" }}>
                                                    <b className="text-center">
                                                        Заказ ожидающие оплату
                                                    </b>
                                                    <ButtonGroup className="pb-3 pt-1">
                                                        <Button className="btn-lc" onClick={() => { goToPay(item) }}>Оплатить</Button>
                                                        <Button variant="light" onClick={() => { if (window.confirm('Вы уверены что хотите отменить заказ?')) { cancelOrder(item) } }}>Отменить заказ</Button>
                                                    </ButtonGroup>
                                                </div>
                                            }
                                        </Fragment>
                                    )}
                                </>
                                :
                                <div className="text-center">Список заказов пуст</div>}
                        </Col>
                    </Row>

                </>
                :
                <>
                    <LoadScreenMin height={50} />
                </>
            }
        </>
    )
}

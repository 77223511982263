import { useEffect, useRef, useState } from "react";

const isClickedInside = (e, element) => {
  let node = e.target;
  while (node) {
    if (node === element) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

const Popup = ({ position, seatId, nameRow, onClose, price, category, categoryFill, groupTag, selLng }) => {
  const containerRef = useRef();
  const [offsetHeight, setOffsetHeight] = useState(100)
  const [offsetWidth, setOffsetWidth] = useState(0)
  useEffect(() => {
    // console.log(category)
    const onClick = e => {
      if (!isClickedInside(e, containerRef.current)) {
        onClose();
      }
    };
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, []);
  useEffect(() => {
    // console.log(containerRef.current.offsetHeight)
    // console.log(containerRef.current.offsetWidth)
    setOffsetHeight(containerRef.current.offsetHeight + 5)
    setOffsetWidth(containerRef.current.offsetWidth / 2 - 15)
    // console.log(containerRef.current.height)
  }, [containerRef])
  return (
    <div
      ref={containerRef}
      style={{
        position: "absolute",
        // top: position.y - 68 + "px",
        top: position.y - offsetHeight + "px",
        left: position.x - offsetWidth + "px",
        padding: "10px",
        borderRadius: "3px",
        boxShadow: "0 0 10px black",
        zIndex: 10,
        backgroundColor: "#000",
        // width: "100px",
        textAlign: "center",
        transition: 0.2
      }}
    >
      {groupTag == null ?
        <div style={{ color: 'white' }}>
          {nameRow} <>{selLng === "en" ? "seat" : "место"} {seatId}</>
        </div> : <div style={{ color: categoryFill }}>{category}</div>
      }
      {price &&
        <div style={{ color: 'white' }}>{price} ₽</div>
      }
      {category &&
        <>
          {groupTag == null &&
            <div style={{ color: categoryFill }}>{category}</div>
          }
        </>
      }
    </div>
  );
};

export default Popup;

import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useChengePasswordUserMutation } from "../../../features/CallApi";
import { LoadScreenMin } from "../../UI/LoadScreenMin";

export const UserResetPass = ({ userId, showResetPass, setShowResetPass }) => {
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const [chengePasswordUser, { isLoading }] = useChengePasswordUserMutation();
    const onSubmit = (data) => {
        data.id = userId
        chengePasswordUser(data).unwrap()
            .then(() => {
                reset()
                setShowResetPass(false)
            })
            .catch((r) => {
                alert(r.data?.detail)
            })
    }

    return (
        <Modal
            show={showResetPass}
            onHide={() => {
                reset()
                setShowResetPass(false)
            }}
            backdrop="static"
            keyboard={false}
        >

            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>Смена пароля пользователя</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isLoading ?
                        <>
                            <div className="form-floating mb-3">
                                <input autoComplete="off" type="password" className="form-control" id="password" placeholder="Новый пароль" {...register("password", { required: true, min: 6 })} />
                                <label htmlFor="password">Новый пароль</label>
                            </div> </>
                        :
                        <LoadScreenMin height="30" />
                    }

                </Modal.Body>

                <Modal.Footer>
                    <ButtonGroup size="sm">
                        <Button variant="primary" onClick={() => {
                            reset()
                            setShowResetPass(!showResetPass)
                        }
                        }>Отменить</Button>
                        <Button type="submit" variant="success">
                            Сохранить
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </form>

        </Modal>
    )
}
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap"
import { useGetNewsListQuery } from "../../../features/CallApi"
import { useEffect, useState } from "react"
import { LoadScreenMin } from "../../UI/LoadScreenMin"
import { NewsAdd } from "./NewsAdd"
import { format } from "date-fns";
// import { ru } from "date-fns/locale";
import { Gear } from "react-bootstrap-icons"
import { NewsGallery } from "./NewsGallery"
import { NewsEdit } from "./NewsEdit"

export const NewsList = () => {
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [stringFilter, setStringFilter] = useState("")
    const [newsAddForm, setNewsAddForm] = useState(false)
    const [newsEditForm, setNewsEditForm] = useState(false)
    // const [showSchedule, setShowSchedule] = useState(false)
    const [newsGallery, setNewsGallery] = useState(false)
    const [newsTitle, setNewsTitle] = useState()
    const [newsId, setNewsId] = useState()
    const { data, isLoading, refetch } = useGetNewsListQuery({ pageIndex, pageSize, stringFilter, selLng: "ru" })

    return (
        <Container>
            <div className="fs-3">
                Новости
            </div>
            <div>
                <Button size="sm" variant="success" onClick={() => { setNewsAddForm(!newsAddForm) }}>Добавить</Button>
                {newsAddForm &&
                    <NewsAdd newsAddForm={newsAddForm} setNewsAddForm={setNewsAddForm} setNewsEditForm={setNewsEditForm} setNewsId={setNewsId} />
                }
                {newsEditForm &&
                    <NewsEdit newsEditForm={newsEditForm} setNewsEditForm={setNewsEditForm} newsId={newsId} setNewsId={setNewsId} />
                }
                {newsGallery &&
                    <NewsGallery newsGallery={newsGallery} setNewsGallery={setNewsGallery} newsId={newsId} setNewsId={setNewsId} newsTitle={newsTitle} setNewsTitle={setNewsTitle} />
                }
            </div>
            <div className="d-flex mt-3">
                {/* <label htmlFor="stringFilter" className="form-label">E-Mail</label> */}
                <input type={`text`} id="stringFilter" onChange={
                    (e) => setTimeout(() => { setStringFilter(e.target.value) }, 2000)}
                    placeholder="Поиск.." name="stringFilter" className="form-control" />
            </div>
            <div>
                {!isLoading ? <>
                    {data.getNews.length > 0 ? <>
                        {data.getNews.map(item =>
                            <Row key={item.id} className="border-bottom mt-3 pb-3">
                                <Col xs={'auto'}>
                                    <div>
                                        # {item.id}
                                    </div>
                                    <div className="text-muted">
                                        {format(new Date(item.publishDate), "HH:mm dd.MM.yy")}
                                    </div>
                                </Col>

                                <Col>
                                    <div>
                                        {item.title}
                                    </div>
                                    <div className="text-muted">
                                        {item.typeNews === 0 && "Новости"}
                                        {item.typeNews === 1 && "СМИ о нас"}
                                    </div>
                                </Col>

                                <Col xs={'auto'}>
                                    <Dropdown className="mt-1">
                                        <Dropdown.Toggle variant="light" split={false}>
                                            <Gear className='' size='14' />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => {
                                                setNewsId(item.id)
                                                setNewsEditForm(!newsEditForm)
                                            }}>Редактировать</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                setNewsId(item.id)
                                                setNewsGallery(!newsGallery)
                                                setNewsTitle(item.title)
                                            }}>Фото галерея</Dropdown.Item>
                                        </Dropdown.Menu>

                                    </Dropdown>
                                </Col>
                            </Row>
                        )}
                    </>
                        : <div className="text-center">Список пуст
                        </div>
                    }
                </>
                    :
                    <LoadScreenMin />}
            </div>

            {!isLoading ? <></> : <LoadScreenMin />}
            <div className="d-flex flex-row justify-content-center align-content-center mt-3">
                <div className="btn-group">
                    <button
                        className={pageIndex === 1 ? `btn btn-primary disabled` : `btn btn-primary`}
                        onClick={() => {
                            if (pageIndex > 1) {
                                setPageIndex(pageIndex - 1)
                            }
                        }}>Назад</button>
                    <button
                        className={pageIndex == data?.totalPages || data?.totalPages === 0 || data === null  ? `btn btn-primary disabled` : `btn btn-primary`}
                        onClick={
                            () => {
                                if (pageIndex <= data?.totalPages) {
                                    setPageIndex(pageIndex + 1)
                                }
                            }}
                    >Вперед</button>
                </div>
            </div>
        </Container>
    )
}
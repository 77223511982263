import { Button, ButtonGroup, FloatingLabel, Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useAddSchemeHallMutation } from "../../../features/CallApi";
import { LoadScreenMin } from "../../UI/LoadScreenMin";

export const SchemeAdd = ({ schemeAddShow, setSchemeAddShow, refetch }) => {
    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [addSchemeHall, { isLoading }] = useAddSchemeHallMutation()
    const onSubmit = (data) => {
        console.log(data)
        addSchemeHall(data).then(() => {
            refetch()
            reset()
            setSchemeAddShow(false)
        })
    }
    return (
        <Modal show={schemeAddShow}
            onHide={() => {
                setSchemeAddShow(false)
            }}
        >
            <Modal.Header closeButton><h3>Добавить схему зала</h3></Modal.Header>
            {!isLoading ?
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <FloatingLabel
                            controlId="floatingTitile"
                            label="Название"
                            className="mb-3"
                        >
                            <Form.Control type="text" isInvalid={errors.name} isValid={isValid.name} placeholder="Заголовок" {...register("name", { required: { value: true } })} />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup size="sm">
                            <Button variant="primary" onClick={() => {
                                reset()
                                setSchemeAddShow(!schemeAddShow)
                            }
                            }>Отменить</Button>
                            <Button type="submit" variant="success">
                                Добавить
                            </Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </form>
                : <Modal.Body>
                    <LoadScreenMin height={30} />
                </Modal.Body>
            }
        </Modal>
    )
}
import { Button, Container, Form, Spinner } from "react-bootstrap"
import { ReactQuillLC } from "../../UI/ReactQuillLC"
import { useEffect, useRef, useState } from "react"
import { useDeleteUploadFileMutation, useGetDocumentsQuery, useGetUploadFilesQuery, usePutDocumentsMutation } from "../../../features/CallApi"
import axios from "axios"
import { Trash, X } from "react-bootstrap-icons"


export const DocumentationsEdit = () => {

    const [valueEditor, setValueEditor] = useState()
    const fileInput = useRef()
    const { data, isLoading, refetch } = useGetUploadFilesQuery()
    const { data: dataHtml, isLoading: dataHtmlLoading } = useGetDocumentsQuery()
    const [putDocuments, { isLoading: isLoadingPut }] = usePutDocumentsMutation()

    const [deleteDocumentation] = useDeleteUploadFileMutation()
    const postingFiles = async (e) => {
        const formData = new FormData()

        const files = fileInput.current.files
        for (let i = 0; i < files.length; i++) {
            formData.append(`files`, files[i])
        }
        const token = JSON.parse(sessionStorage.getItem("token"));
        console.log(formData)
        const response = await axios.post(`/api/uploadfiles`, formData, {
            headers: {
                method: 'POST',
                Authorization: `Bearer ${token.Token}`
            }
        })
        refetch()
        fileInput.current.value = null;
        e.preventDefault()
    }
    // useEffect(() => { refetch() }, [])
    useEffect(() => {
        if (dataHtml) {
            console.log(dataHtml)
            setValueEditor(dataHtml.html)
        }
    }, [dataHtml])
    return (
        <>
            <Container>
                <div className="fs-3">Раздел документы</div>
                <Form.Group onSubmit={(e) => {
                    postingFiles(e)
                }} controlId="formFileMultiple" className="mb-3">
                    <Form.Label>Добавить новый файл</Form.Label>
                    <Form.Control ref={fileInput} type="file" multiple />
                    <Button size="sm" variant="light" className="mt-3" onClick={(e) => { postingFiles(e) }}>Загрузить</Button>
                </Form.Group>
                {!dataHtmlLoading &&
                    <ReactQuillLC valueEditor={valueEditor} setValueEditor={setValueEditor} />
                }
                {!isLoadingPut ? <Button size="sm" className="mt-3" variant="success" onClick={() => { putDocuments({ html: valueEditor }) }}>Сохранить</Button> :
                    <Button size="sm" className="mt-3" disabled variant="success"><Spinner /></Button>
                }

                <div>
                    {data?.length > 0 &&
                        <ul>
                            {data.map(item =>
                                <li key={item.id} className="mt-3"><a href={item.link} target={'_blank'}>{item.name}</a>
                                    <Button className="ms-1"
                                        size="sm"
                                        variant='danger'
                                        onClick={() => {
                                            if (window.confirm("Удалить?")) {
                                                deleteDocumentation(item.id)
                                            }
                                        }}><Trash /></Button></li>
                            )}
                        </ul>
                    }
                </div>
            </Container>
        </>
    )
}
import { useEffect, useState } from "react";
import ReactSelect from "react-select"
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { useAddSchemeToSessionMutation, useGetSchemesHallQuery } from "../../../../features/CallApi";

const GetData = (data) => {
    return {
        value: data.id,
        label: `# ${data.id} ${data.name}`
    }
}

export const SchemeSessionSelect = ({ sessionId, refetch, schemeId }) => {
    // const [isClearable, setIsClearable] = useState(true);
    // const [isSearchable, setIsSearchable] = useState(true);
    // const [isDisabled, setIsDisabled] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    // const [isRtl, setIsRtl] = useState(false);
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(5)
    const [stringFilter, setStringFilter] = useState("")
    const [selectData, setSelectData] = useState()
    const [addSchemeToSession, { isLoading }] = useAddSchemeToSessionMutation()

    const { data } = useGetSchemesHallQuery({ pageIndex, pageSize, stringFilter })
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();


    useEffect(() => {
        if (data) {
            const dataMap = data.getSchemeHalls.map(item => GetData(item))
            setSelectData(dataMap)
        }
        setValue("sessionId", sessionId)
    }, [data])

    const submit = (data) => {
        // console.log(data)
        if (data.schemeId !== null) {

            addSchemeToSession(data).then(() => { refetch() })
            return;
        }
        else {
            return window.alert("Выберите схему")
        }
    }


    return (
        <Form onSubmit={handleSubmit(submit)}>
            <ReactSelect
                name="schemeselect"
                className="basic-single mb-1"
                placeholder={"Схема зала..."}
                classNamePrefix="Схема зала..."
                // isLoading={isLoading}
                // isClearable={isClearable}
                // isRtl={isRtl}
                // isSearchable={isSearchable}
                defaultValue={schemeId}
                options={selectData}
                onInputChange={(e) => { setStringFilter(e) }}
                onChange={(target) => {
                    // console.log(target)
                    if (target) {
                        setValue("schemeId", target.value)
                    }
                }}
                required={true}
            />
            <Button className="mb-1" size="sm" type={"submit"}>Связать схему</Button>
        </Form>
    )
}
import { useState, useCallback, useEffect, useRef } from "react";
import { Layer, Stage } from "react-konva";
import SeatPopup from "./SeatPopup";
import { Section } from "./Section";
import { useImportScheme } from "./useImportScheme";
import { LoadScreenMin } from "../UI/LoadScreenMin";
import { Button, ButtonGroup } from "react-bootstrap";

export const EditSessionScheme = ({ data, setCursorPos }) => {
    const { setUrl, schemeData, setSchemeData } = useImportScheme();
    const stageRef = useRef()
    const containerRef = useRef()
    const [scale, setScale] = useState(1);
    const [scaleToFit, setScaleToFit] = useState(5);
    const [popup, setPopup] = useState({ seat: null, row: null });
    const [selectedSeatsIds, setSelectedSeatsIds] = useState([]);
    const [size, setSize] = useState({
        width: 1000,
        height: 2000,
        virtualWidth: 800
    });
    const [virtualWidth, setVirtualWidth] = useState(1000);
    useEffect(() => {
        const newSize = {
            width: containerRef.current.offsetWidth,
            height: containerRef.current.offsetHeight
        };
        if (newSize.width !== size.width || newSize.height !== size.height) {
            setSize(newSize);
        }
    });
    const toggleScale = useCallback(() => {
        if (scale === 1) {
            setScale(scaleToFit);
        } else {
            setScale(1);
        }
    }, [scale, scaleToFit]);

    useEffect(() => {
        if (data) {
            setSchemeData(data)
        }
        else {
            setUrl("/seats-data-2.json")
        }
    }, [data])

    useEffect(() => {
        if (!stageRef.current) {
            return;
        }
        const stage = stageRef.current;
        const clientRect = stage.getClientRect({ skipTransform: true });
        // console.log(stage)
        // console.log(clientRect)
        // console.log(size.width / clientRect.width)
        const scaleToFit = size.width / clientRect.width / 2;

        setScale(scaleToFit);
        setScaleToFit(scaleToFit);
        setVirtualWidth(clientRect.width);
        // console.log(containerRef.current.offsetWidth)
        const newSize = {
            width: containerRef.current.offsetWidth,
            height: containerRef.current.offsetHeight
        };
        if (newSize.width !== size.width || newSize.height !== size.height) {
            setSize(newSize);
        }
    }, [schemeData, size]);

    useEffect(() => {
        const resizeHandeler = (e) => {
            const stage = stageRef.current;
            const clientRect = stage.getClientRect({ skipTransform: true });
            const scaleToFit = size.width / clientRect.width / 2;
            setScale(scaleToFit);
            setScaleToFit(scaleToFit);
            setVirtualWidth(clientRect.width);
        }

        window.addEventListener('resize', resizeHandeler)
        return function () {
            window.addEventListener('resize', resizeHandeler)
        }
    }, [])

    const handleHover = useCallback((seat, pos, row, price, category, categoryFill, groupTag) => {
        // console.log(groupTag)
        if (groupTag) {
            price = 0
            data.sections[0].seatsByRows.forEach(rows =>
                rows.seats.filter(seat => seat.groupTag === groupTag).forEach(seat => {
                    price = price + seat.price
                })
            );
            // newAddIds = selectedSeatsIds.concat(newAddIds)
            // setSelectedSeatsIds(newAddIds);
            // return;
        }
        setPopup({
            seat: seat,
            row: row,
            position: pos,
            price: price,
            category: category,
            categoryFill: categoryFill,
            groupTag: groupTag
        });
    }, []);

    const handleCursor = useCallback((pos) => {
        setCursorPos(pos)
    })

    const handleSelect = useCallback((seatId, groupTag) => {
        if (groupTag) {
            var newAddIds = []
            data.sections[0].seatsByRows.forEach(rows =>
                rows.seats.filter(seat => seat.groupTag === groupTag).forEach(seat => {
                    newAddIds = newAddIds.concat([seat.id])
                })
            );
            newAddIds = selectedSeatsIds.concat(newAddIds)
            setSelectedSeatsIds(newAddIds);
            return;
        }
        const newIds = selectedSeatsIds.concat([seatId]);
        setSelectedSeatsIds(newIds);
    },
        [selectedSeatsIds]
    );
    const handleDeselect = useCallback(
        (seatId, groupTag) => {
            if (groupTag) {
                var newAddIds = []
                data.sections[0].seatsByRows.forEach(rows =>
                    rows.seats.filter(seat => seat.groupTag === groupTag).forEach(seat => {
                        newAddIds = newAddIds.concat([seat.id])
                    })
                );
                const ids = selectedSeatsIds.slice();
                newAddIds.forEach(item => {
                    ids.splice(ids.indexOf(item), 1)
                })
                setSelectedSeatsIds(ids);
                return;
            }
            // console.log(seatId)
            const ids = selectedSeatsIds.slice();
            ids.splice(ids.indexOf(seatId), 1);
            setSelectedSeatsIds(ids);
        },
        [selectedSeatsIds]
    );

    if (schemeData === null) {
        return <LoadScreenMin refcontanet={containerRef} height={20} />;
    }

    return (
        <div style={{
            position: "relative",
            backgroundColor: "#f3f3f3",
            width: "100%",
            height: "70vh",
        }}
            ref={containerRef}
            id={"schemeHellcontainer"}
        >
            <div className="ps-3 pt-3 position-absolute" style={{ left: 0, right: 0, zIndex: 100, width: 'fit-content' }}>
                <ButtonGroup>
                    <Button onClick={() => setScale(scale + 0.10)}>+</Button>
                    <Button onClick={() => { setScale(scale - 0.10) }}>-</Button>
                </ButtonGroup>
            </div>
            <Stage

                ref={stageRef}
                width={size.width}
                height={size.height}
                draggable
                x={size.width / 4}
                // y={200}
                dragBoundFunc={pos => {
                    pos.x = Math.min(
                        size.width / 2,
                        Math.max(pos.x, -virtualWidth * scale + size.width / 2)
                    );
                    pos.y = Math.min(size.height / 2, Math.max(pos.y, -size.height / 2));
                    return pos;
                }}
                onDblTap={toggleScale}
                onDblClick={toggleScale}
                scaleX={scale}
                scaleY={scale}
            >
                <Layer>

                    {schemeData.sections.map(section => {
                        const offset = 30;
                        return (
                            <Section
                                handleCursor={handleCursor}
                                x={offset}
                                y={schemeData.offsetTop}
                                width={schemeData.width}
                                height={schemeData.height}
                                texts={schemeData.texts}
                                objects={schemeData.staticObjects}
                                key={section.id}
                                section={section.seatsByRows}
                                staticObjects={section.staticObjects}
                                selectedSeatsIds={selectedSeatsIds}
                                onHoverSeat={handleHover}
                                onSelectSeat={handleSelect}
                                onDeselectSeat={handleDeselect}
                            />
                        );
                    })}
                </Layer>
            </Stage>
            {popup.seat && (
                <SeatPopup
                    position={popup.position}
                    seatId={popup.seat}
                    nameRow={popup.row}
                    price={popup.price}
                    category={popup.category}
                    categoryFill={popup.categoryFill}
                    groupTag={popup.groupTag}
                    onClose={() => {
                        setPopup({ seat: null });
                    }}
                />
            )}
        </div>
    )
}


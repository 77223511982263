import classes from './Certificate.module.css';
import Axios from 'axios';
import { useState } from 'react';
import { LoadScreenMin } from '../UI/LoadScreenMin';

export const CertConfirm = ({ dataOrder, t }) => {

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [errorMesage, setErrorMesage] = useState();


    const OrderConfirm = async () => {
        setIsDataLoading(true)
        await Axios.get(`https://app.leningradcenter.ru/api/certificateorders/pay/${dataOrder.orderId}`
            , {
                headers: {
                    Authorization: "Bearer " + dataOrder.token,
                }
            }).catch((error) => {
                console.log(error)
                setErrorMesage("Ошибка связи с банком. Попробует позднее или свяжитесь с гостевой службой.");
                setIsDataLoading(false)
            }).then((response) => {
                setIsDataLoading(false)
                window.location.href = response.data
            }
            )
    }
    const Cancel = () => {
        sessionStorage.removeItem("certToken")
        sessionStorage.removeItem("dataOrder")
        window.location.reload()
    }
    return (
        <div className="container">
            {isDataLoading ? <LoadScreenMin /> :
                <div>
                    <br />
                    <div className="offset-md-2 col-sm-8">
                        <h2>{t("form.confirmOrder")}</h2>
                        <hr />
                    </div>
                    <div className="justify-content-center d-md-flex d-lg-flex d-xl-flex">
                        <div className="p-3 lc-rem-right">
                            <div>
                                <h4>{t("form.order")} {dataOrder.orderId}</h4>
                            </div>
                            <div>
                                <div className={classes.lcCartSelectBoxOrder}>
                                    <div className={classes.lcCartSelectBoxContent}>
                                        <div className={`${classes.lcCartSelectLabel, classes.lcCardImgOrder}`} style={{ fontSize: "28px" }}>{dataOrder.amount} ₽</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-3 lc-rem-left">
                            <h4 className="h-cert" >{t("form.cetFormTitle")}</h4>
                            <div className='mb-1'>
                                <label>{t("form.firstName")}:</label>
                                {dataOrder.lastName}
                            </div>
                            <div className='mb-1'>
                                <label>{t("form.lastName")}:</label>
                                {dataOrder.firstName}
                            </div>
                            {(dataOrder.patronymic != null || dataOrder.patronymic != "") &&
                                <div className='mb-1'>
                                    <label>{t("form.patronymic")}:</label>
                                    {dataOrder.patronymic}
                                </div>
                            }
                            <div className='mb-1'>
                                <label>E-Mail:</label>
                                {dataOrder.email}
                            </div>
                            <div className='mb-1'>
                                <label>{t("form.phone")}:</label>
                                {dataOrder.phone}
                            </div>

                            <div className="text-right mt-5">
                                <div className="text-center">
                                    {errorMesage && <span className='text-danger'>{errorMesage}</span>}
                                    <button className="btn btn-lc btn-lc-cert" onClick={() => OrderConfirm()}>{t("form.payBankCard")}</button>
                                </div>
                                <div className="text-center mt-1">
                                    <a style={{ cursor: "pointer" }} onClick={() => Cancel()}>{t("form.cancel")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            }
        </div>
    )
}
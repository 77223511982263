import { useState } from "react"
import { useGetUsersQuery } from "../../../features/CallApi"
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap"
import { LoadScreen } from "../../UI/LoadScreen"
import { useEffect } from "react"
import { BookmarkCheckFill, BookmarkX, Gear } from "react-bootstrap-icons"
import { RoleToText } from "./RoleToText"
import { UserAdd } from "./UserAdd"
import { UserEdit } from "./UserEdit"
import { UserResetPass } from "./UserResetPass"


export const Users = () => {

    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [stringFilter, setStringFilter] = useState("")
    const [showAddForm, setShowAddForm] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false)
    const [showResetPass, setShowResetPass] = useState(false)
    const [userId, setUserId] = useState()

    const dataRole = [{
        id: 0,
        name: "Администратор"
    },
    {
        id: 1,
        name: "Маркетолог"
    },
    {
        id: 2,
        name: "Event-менеджер"
    },]

    const { data: usersList, isLoading: loadingUsersList, refetch: refetchUsersList } = useGetUsersQuery({ pageIndex, pageSize, stringFilter })

    useEffect(() => { refetchUsersList() }, [])

    return (
        <Container className="mt-3">
            <div className="fs-5">Список пользоватей</div>
            <div>
                <Button size="sm" variant="success" onClick={() => { setShowAddForm(!showAddForm) }}>Добавить</Button>
                {showAddForm &&
                    <UserAdd dataRole={dataRole} showAddForm={showAddForm} setShowAddForm={setShowAddForm} />
                }
                {showEditForm &&
                    <UserEdit dataRole={dataRole} userId={userId} showEditForm={showEditForm} setShowEditForm={setShowEditForm} />
                }
                {showResetPass &&
                    <UserResetPass userId={userId} showResetPass={showResetPass} setShowResetPass={setShowResetPass} />
                }
            </div>
            <div className="d-flex mt-3">
                {/* <label htmlFor="stringFilter" className="form-label">E-Mail</label> */}
                <input type={`text`} id="stringFilter" onChange={
                    (e) => setTimeout(() => { setStringFilter(e.target.value) }, 2000)}
                    placeholder="Поиск.." name="stringFilter" className="form-control" />
            </div>
            <div className="mt-3">
                {!loadingUsersList ? <>
                    {usersList &&
                        usersList.getUsers?.map(user =>
                            <Row key={user.id} className='border-bottom'>
                                <Col xs={'auto'}>
                                    <div>
                                        {user.status ? <div className='link-success'><BookmarkCheckFill /></div> : <div className='link-danger'><BookmarkX /></div>}
                                    </div>
                                </Col>
                                <Col xs={'auto'}>
                                    # {user.id}
                                </Col>
                                <Col>
                                    <div>
                                        {user.lastname} {user.firstname}
                                    </div>
                                    <div className="w-100">
                                        {user.email}
                                    </div>
                                    <div className='text-muted'>
                                        <RoleToText roleId={user.roleId} />
                                    </div>
                                </Col>
                                <Col xs={'auto'}>
                                    <Dropdown className="mt-1">
                                        <Dropdown.Toggle variant="light" split={false}>
                                            <Gear className='' size='14' />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => {
                                                setUserId(user.id)
                                                setShowEditForm(!showEditForm)
                                            }}>Редактировать</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                setUserId(user.id)
                                                setShowResetPass(!showResetPass)
                                            }}>Сменить пароль</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        )
                    }
                </> : <LoadScreen pages height="30" />
                }
            </div>
            <div className="d-flex flex-row justify-content-center align-content-center mt-3">
                <div className="btn-group">
                    <button
                        className={pageIndex == 1 ? `btn btn-primary disabled` : `btn btn-primary`}
                        onClick={() => {
                            if (pageIndex > 1) {
                                setPageIndex(pageIndex - 1)
                            }
                        }}>Назад</button>
                    <button
                        className={pageIndex == usersList?.totalPages ? `btn btn-primary disabled` : `btn btn-primary`}
                        onClick={
                            () => {
                                if (pageIndex <= usersList?.totalPages) {
                                    setPageIndex(pageIndex + 1)
                                }
                            }}
                    >Вперед</button>
                </div>
            </div>
        </Container>
    )
}
import { useState } from "react"
import { useGetExhibitionsListQuery } from "../../../features/CallApi"
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap"
import { BookmarkCheckFill, BookmarkX, Gear } from "react-bootstrap-icons"
import { LoadScreenMin } from "../../UI/LoadScreenMin"
import { ExhibitionAdd } from "./ExhibitionAdd"
import { ExhibitionEdit } from "./ExhibitionEdit"


export const ExhibitionsList = () => {

    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize] = useState(10)
    const [stringFilter, setStringFilter] = useState("")
    const [exhibitionAddForm, setExhibitionAddForm] = useState(false)
    const [exhibitionEditForm, setExhibitionEditForm] = useState(false)
    const [exhibitionId, setExhibitionId] = useState()
    const { data, isLoading } = useGetExhibitionsListQuery({ pageIndex, pageSize, stringFilter })

    return (
        <>
            <Container>
                <div className="fs-3">
                    Выставки
                </div>
                <div>
                    <Button size="sm" variant="success" onClick={() => { setExhibitionAddForm(!exhibitionAddForm) }}>Добавить</Button>
                    {exhibitionAddForm &&
                        <ExhibitionAdd
                            exhibitionAddForm={exhibitionAddForm}
                            setExhibitionAddForm={setExhibitionAddForm}
                            setExhibitionEditForm={setExhibitionEditForm}
                            setExhibitionId={setExhibitionId} />
                    }
                    {exhibitionEditForm &&
                        <ExhibitionEdit
                            exhibitionEditForm={exhibitionEditForm}
                            setExhibitionEditForm={setExhibitionEditForm}
                            exhibitionId={exhibitionId}
                            setExhibitionId={setExhibitionId} />
                    }
                    {/*{showSchedule &&
                        <ShowSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} showId={showId} setShowId={setShowId} showTitle={showTitle} setShowTitle={setShowTitle} />
                    }
                    {showGallery &&
                        <ShowGallery showGallery={showGallery} setShowGallery={setShowGallery} showId={showId} setShowId={setShowId} showTitle={showTitle} setShowTitle={setShowTitle} />
                    }*/}
                </div>
                <div className="d-flex mt-3">
                    {/* <label htmlFor="stringFilter" className="form-label">E-Mail</label> */}
                    <input type={`text`} id="stringFilter" onChange={
                        (e) => setTimeout(() => { setStringFilter(e.target.value) }, 2000)}
                        placeholder="Поиск.." name="stringFilter" className="form-control" />
                </div>
                {!isLoading ? <>
                    {data?.getExhibitions ?
                        <div className="pt-3">
                            {data.getExhibitions.map(exhibition =>
                                <Row key={exhibition.id} className="border-bottom mt-3 pb-3">
                                    <Col xs={'auto'}>
                                        <div>
                                            {exhibition.isHidden ? <div className='link-danger'><BookmarkX /></div> : <div className='link-success'><BookmarkCheckFill /></div>}
                                        </div>
                                    </Col>
                                    <Col>
                                        {exhibition.name}
                                    </Col>
                                    <Col xs={'auto'}>
                                        <Dropdown className="mt-1">
                                            <Dropdown.Toggle variant="light" split={false}>
                                                <Gear className='' size='14' />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => {
                                                    setExhibitionId(exhibition.id)
                                                    setExhibitionEditForm(!exhibitionEditForm)
                                                }}>Редактировать</Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    // setShowId(show.id)
                                                    // setShowSchedule(!showSchedule)
                                                    // setShowTitle(show.name)
                                                }}>Расписание</Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    // setShowId(show.id)
                                                    // setShowGallery(!showGallery)
                                                    // setShowTitle(show.name)
                                                }}>Фото галерея</Dropdown.Item>
                                            </Dropdown.Menu>

                                        </Dropdown>
                                    </Col>
                                </Row>
                            )}
                        </div>
                        : <div className="text-center">Список пуст</div>
                    }
                </> : <LoadScreenMin />}
                <div className="d-flex flex-row justify-content-center align-content-center mt-3">
                    <div className="btn-group">
                        <button
                            className={pageIndex == 1 ? `btn btn-primary disabled` : `btn btn-primary`}
                            onClick={() => {
                                if (pageIndex > 1) {
                                    setPageIndex(pageIndex - 1)
                                }
                            }}>Назад</button>
                        <button
                            className={`btn btn-primary ${(pageIndex == data?.totalPages || data?.totalPages === 1 || data?.getExhibitions.length === 0) && "disabled"}`}
                            onClick={
                                () => {
                                    if (pageIndex <= data?.totalPages) {
                                        setPageIndex(pageIndex + 1)
                                    }
                                }}
                        >Вперед</button>
                    </div>
                </div>
            </Container>
        </>
    )

}
import { Col, Container, Row } from "react-bootstrap"
import Slider from "react-slick"


export const Qualitymark = () => {

    const iconCheck = 30
    const settings = {
        className: "slick-arrow-black m-auto slider-box mb-3 ms-2 me-2",
        dots: true,
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        centerPadding: "40px",
        speed: 750,
        responsive: [
            {
                breakpoint: 1080,
                settings: {
                    slidesToShow: 2,
                }
            },

            {
                breakpoint: 480,
                settings: {
                    // centerMode: false,
                    slidesToShow: 1,
                }
            }
        ]
    };

    const qmSliderContent = [
        {
            key: 1,
            title: "Курорт «Игора»",
            desc: "Всесезонный курорт с уникальной инфраструктурой, включающей в себя 10 горнолыжных трасс, Ледовый Дворец, zipline, детский клуб, рестораны, отель, апарт-отель и коттеджи.",
            img: "https://leningradcenter.ru/api/redirecturls/zk-igora-kurort",
            href: ""
        },
        {
            key: 2,
            title: "Парк-отель \n«Дача Винтера»",
            desc: "Окружённый спускающимся к Ладоге хвойным лесом, парк-отель воплощает всё, что мы ожидаем от загородного отдыха: богатую фактами историю, близость к достопримечательностям Республики Карелия.",
            img: "https://leningradcenter.ru/api/redirecturls/zk-DV",
            href: ""
        },
        {
            key: 3,
            title: "Сеть отелей \n«Точка на карте»",
            desc: "Четыре отеля вокруг Ладоги — вдохновение и гармония с природой! Панорамные номера с видом на лес и озеро. Кафе и рестораны с авторской подачей блюд. Активный отдых, достопримечательности рядом.",
            img: "https://leningradcenter.ru/api/redirecturls/zk-tnk-p",
            href: ""
        },
        {
            key: 4,
            title: "Центр активного отдыха «Терра Нордика»",
            desc: "Открывайте для себя захватывающие места региона! Выбирайте развлечение по душе — поездки на квадроциклах, снегоходах, прогулки на высоте в верёвочном парке.",
            img: "https://leningradcenter.ru/api/redirecturls/qm-terra",
            href: ""
        },
        {
            key: 5,
            title: "Ресторан «Фаворит»",
            desc: "Новый гастрономический опыт в сочетании с панорамным видом на гоночную трассу мирового класса. Изысканная авторская кухня, атмосфера скорости и драйва.",
            img: "https://leningradcenter.ru/api/redirecturls/qm-fovarit",
            href: ""
        },
        {
            key: 6,
            title: "Ресторан «Блок»",
            desc: "Российское мясо с высокой степенью мраморности — важнейшая гастрономическая философия ресторана. Атмосферу задает интерьер с произведениями художников-авангардистов начала ХХ века.",
            img: "https://leningradcenter.ru/api/redirecturls/qm-blok",
            href: ""
        },
        {
            key: 6,
            title: "Шоу-пространство Ленинград центр",
            desc: "Центр современной культуры развлечений, где есть место импровизации и экспериментам. Программа лояльности распространяется на услуги Черного и Белого баров шоу-пространства «Ленинград Центр».",
            img: "https://leningradcenter.ru/api/redirecturls/qm-lc",
            href: ""
        },
        {
            key: 7,
            title: "Яхт-клуб «Лагуна»",
            desc: "В яхт-клубе все предусмотрено для отдыха, который необходим экипажу после долгих плаваний. «Лагуна» вмещает несколько десятков судов размером до 45 метров в акватории, полностью защищенной от ветрового воздействия и волнений.",
            img: "https://leningradcenter.ru/api/redirecturls/qm-laguna",
            href: ""
        }]

    return (
        <>
            <div>
                <h3>О проекте</h3>
                <div>
                    <p>
                        <strong>«Знак Качества Группы Банка «Россия» </strong> объединяет 11 партнёров в Санкт-Петербурге, Ленинградской области, Республике Карелия.
                    </p>
                    <p>
                        <strong>Программа лояльности</strong> позволяет участникам получать скидки и вести накопительную статистику покупок для повышения статуса участия.
                    </p>
                    <p>
                        Накопительная статистика действует на бронирование отелей, посещение центра активного отдыха, приобретение дополнительных услуг и за посещение кафе, ресторанов партнёров**.
                        <div className="loyalty-q">
                            **С даты регистрации в программе лояльности.
                        </div>
                    </p>

                    <p>
                        <strong>Чтобы стать участником программы лояльности</strong>, переходите по ссылке на сайт <a href="https://rossiya-group.ru/">https://rossiya-group.ru/</a>. После регистрации вы получите
                        индивидуальную цифровую карту, которую вы сможете предъявлять каждый раз при покупках.
                    </p>
                </div>
            </div>
            {/* <h5 className="mt-5 text-center">
                Присоединяясь к программе лояльности «Знак Качества Группы Банка «Россия», вы всегда получаете надежность и качество, уникальный опыт,
                незабываемые впечатления, комфорт и высокий уровень сервиса.
            </h5> */}
            <div className="text-center mt-5">
                <a className="btn btn-lc" href="https://rossiya-group.ru/">
                    Стать участником
                </a>
            </div>
            <h5 className="mt-5 text-center">
                Преимущества программы лояльности
            </h5>
            <div className="container">
                <Row className="justify-content-around">
                    <Col lg={'auto'}>
                        <div className="d-flex">
                            <svg style={{ fill: "#be9100" }} className="me-3" width={iconCheck} height={iconCheck}>
                                <use xlinkHref='lc-logo.svg#CheckQM'></use>
                            </svg>
                            <p className="ml-3">
                                Мгновенный выпуск карты
                            </p>
                        </div>
                        <div className="d-flex">
                            <svg style={{ fill: "#be9100" }} className="me-3" width={iconCheck} height={iconCheck}>
                                <use xlinkHref='lc-logo.svg#CheckQM'></use>
                            </svg>
                            <p className="ml-3">
                                Не придётся ждать курьера или куда-то идти
                            </p>
                        </div>
                        <div className="d-flex">
                            <svg style={{ fill: "#be9100" }} className="me-3" width={iconCheck} height={iconCheck}>
                                <use xlinkHref='lc-logo.svg#CheckQM'></use>
                            </svg>
                            <p className="ml-3">
                                Расширенный список партнёров
                            </p>
                        </div>
                    </Col>
                    <Col lg={'auto'}>
                        <div className="d-flex">
                            <svg style={{ fill: "#be9100" }} className="me-3" width={iconCheck} height={iconCheck}>
                                <use xlinkHref='lc-logo.svg#CheckQM'></use>
                            </svg>
                            <p className="ml-3">
                                Виртуальная карта
                            </p>
                        </div>
                        <div className="d-flex">
                            <svg style={{ fill: "#be9100" }} className="me-3" width={iconCheck} height={iconCheck}>
                                <use xlinkHref='lc-logo.svg#CheckQM'></use>
                            </svg>
                            <p className="ml-3">
                                Карта всегда с собой и не занимает места
                            </p>
                        </div>
                        <div className="d-flex">
                            <svg style={{ fill: "#be9100" }} className="me-3" width={iconCheck} height={iconCheck}>
                                <use xlinkHref='lc-logo.svg#CheckQM'></use>
                            </svg>
                            <p className="ml-3">
                                Карту можно использовать при первой покупке
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
            <h3 className="text-center mt-5">Партнёры программы лояльности</h3>
            <Container>
                <Slider {...settings}>
                    {qmSliderContent.map(item =>
                        <div className="pe-2 ps-2" key={item.key}>
                            <img src={item.img} className="img-fluid p-1" alt={item.title} />
                            <div className="text-center">
                                <h5>{item.title}</h5>
                                <p style={{ maxWidth: "408px" }} className="mt-3">
                                    {item.desc}
                                </p>
                            </div>
                        </div>
                    )}
                </Slider>
            </Container>
        </>
    )

}
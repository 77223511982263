import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";


export const ShowSchedulesItem = ({ item }) => {

    const [link, setLink] = useState()

    const getSchemeLink = (id) => {

        switch (id) {
            case 1: return "tickets";
                break;
            case 2: return "paytickets";
                break;
            default: return "tickets";
        }
    }

    useEffect(() => {
        console.log(item.usageScheme)
        const data = getSchemeLink(item.usageScheme)
        console.log(data)
        setLink(data)
    }, [item])


    return (
        <Card key={item.sessionId} className='mb-3 shadow' body>
            <Row>
                <Col>
                    <div>
                        Дата: {format(new Date(item.startOfEvent), 'dd.MM')} ({format(new Date(item.startOfEvent), 'EEEE', { locale: ru })})
                    </div>
                    <div>
                        Начало: {format(new Date(item.startOfEvent), 'HH:mm')}
                    </div>
                </Col>
                <Col xs={"auto"}>
                    <NavLink to={`/${link}/${item.sessionId}`} role="button" className={"btn btn-afisha border-0"}>
                        {/* <Button className="btn-afisha border-0"> */}
                        Выбрать
                        {/* </Button> */}
                    </NavLink>
                </Col>
            </Row>
        </Card>
    )
}

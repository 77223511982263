import { Container } from "react-bootstrap"
import { MainStage } from "./MainStage"
import { Fragment } from "react"


export const SchemeHall = () => {

    return (
        <Fragment>
            <Container className="mt-5">
                <MainStage />
            </Container>
        </Fragment>
    )
}
import Axios from "axios"
import { useEffect, useRef, useState } from "react"
import { Button, Col, Form, Image, Modal, Row, Spinner } from "react-bootstrap"
import { useDeletePhotoGalleryMutation, useGetShowGalleryQuery } from "../../../features/CallApi"
import { X } from "react-bootstrap-icons"
import { LoadScreenMin } from "../../UI/LoadScreenMin"
import imageCompression from 'browser-image-compression';



export const ShowGallery = ({ showGallery, setShowGallery, showId, setShowId, showTitle, setShowTitle }) => {

    const ImageCompressionExample = async (data) => {
        const imageFile = data;
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile;
        } catch (error) {
            console.error('Error compressing image:', error);
        }

        return;
    };
    const fileInput = useRef()
    const { data, isLoading, refetch } = useGetShowGalleryQuery(showId)
    const [deletePhotoGallery] = useDeletePhotoGalleryMutation()
    const [uploading, setUploading] = useState(false)
    const postingImg = async () => {
        setUploading(true)
        const formData = new FormData()
        // console.log(fileInput.current.files)

        const files = fileInput.current.files
        for (let i = 0; i < files.length; i++) {
            // console.log(files[i])
            const file = await ImageCompressionExample(files[i])
            // console.log(file)
            if (file) {
                formData.append(`files`, file)
            }
        }
        const token = JSON.parse(sessionStorage.getItem("token"));
        console.log(formData)
        const response = await Axios.post(`/api/shows/add-photo/${showId}`, formData, {
            headers: {
                method: 'POST',
                Authorization: `Bearer ${token.Token}`
            }
        })
        setUploading(false)
        refetch()
        fileInput.current.value = null;
    }




    return (
        <Modal
            show={showGallery}
            size={"xl"}
            onHide={() => {
                setShowId(null)
                setShowGallery(false)
                setShowTitle()
            }}
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <h3>{showTitle}</h3>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label></Form.Label>
                    <Form.Control className="mb-3" ref={fileInput} accept=".jpg, .jpeg, .png" multiple type="file" />
                    {!uploading ?
                        <Button type="submit" onClick={() => { postingImg() }}>Загрузить</Button> :
                        <Spinner />
                    }
                </Form.Group>
                {!isLoading ? <>
                    {data && <>
                        {
                            <Row>
                                {data.map(item =>
                                    <Col md={4} className="" key={item.id}>
                                        <Row className="border m-3">
                                            <Col style={{ inset: 0, margin: 'auto' }}>
                                                <Image style={{ maxHeight: "30vh" }} src={`/api/shows/gallery-photo/${item.id}`} className="m-1" fluid />
                                            </Col>
                                            <Col style={{ inset: 0, margin: 'auto' }} xs={'auto'} className="text-end">
                                                <Button onClick={() => { deletePhotoGallery(item.id).unwrap().then(() => refetch()) }} size="sm" variant="danger">
                                                    <X size={25} />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                            </Row>
                        }
                    </>
                    }
                </> : <LoadScreenMin height={10} />}
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    )
}
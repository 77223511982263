import { useEffect } from "react";
import { Button, ButtonGroup, FloatingLabel, Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { usePostSchemeSectorMutation } from "../../../../features/CallApi";
import { LoadScreenMin } from "../../../UI/LoadScreenMin";


export const SectorAdd = ({ setReloadScheme, refetch, schemeHallId, showSectorAdd, setShowSectorAdd }) => {

    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [postSchemeSector, { isLoading }] = usePostSchemeSectorMutation()

    useEffect(() => {
        setValue("SchemeHallId", schemeHallId)
    }, [])

    const submit = (data) => {
        postSchemeSector(data)
            .unwrap()
            .then(() => {
                refetch()
                // setReloadScheme(true)
                setShowSectorAdd(false)
            })
    }

    return (
        <Modal show={showSectorAdd} onHide={() => { setShowSectorAdd(false) }}>
            <Modal.Header closeButton>Добавить сектор</Modal.Header>
            {!isLoading ?
                <Form onSubmit={handleSubmit(submit)}>
                    <Modal.Body>
                        <FloatingLabel
                            controlId="floatingTitile"
                            label="Название"
                            className="mb-3"
                        >
                            <Form.Control type="text" isInvalid={errors.name} isValid={isValid.name} placeholder="Заголовок" {...register("name", { required: { value: true } })} />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button variant="warning" onClick={() => { setShowSectorAdd(false) }}>
                                Отменить
                            </Button>
                            <Button variant="success" type="submit">
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Form> : <LoadScreenMin height={30} />}
        </Modal>
    )
}
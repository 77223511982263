import { useParams } from "react-router-dom"
import "./News.css"
import { useGetNewsPostQuery } from "../../features/CallApi"
import { NewsPost } from "./NewsPost"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"


export const NewsPostPage = ({ selLanguage }) => {

    const [t] = useTranslation("global");
    const { seoUrl } = useParams()
    const { data, isLoading } = useGetNewsPostQuery({ seoUrl: seoUrl, selLng: selLanguage })

    return (
        <>
            <Helmet>
                <title>{t("general.news")} - {t("mainTag.title")}</title>
                <meta name="description" content='Первое в России шоу-пространство "Ленинград Центр"'></meta>
                <meta name="og:title" content={`${data ? data.title : "News is loading..."} - ${t("mainTag.title")}`} />
                <meta name="og:description" content='Первое в России шоу-пространство "Ленинград Центр"' />
                <meta name="keywords" content="шоу- пространство, шоу пространство, ленинград центр, центр ленинград, мюзикл, шоу, представление, афиша" c />
                {/* <meta property="og:image" content={data.headLayoutImageType ? `/api/shows/head-wide-img/${data.id})` : "/Show/091A8332.jpg"} /> */}
            </Helmet>
            <div className="news-haed-layout">
                <div className="news-head-layout-img" style={{ backgroundImage: 'url("/News/news.jpg")' }}>
                </div>
                <div className="news-head-mask">
                    <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3" style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                        <div className="text-white ms-auto justify-content-end">
                            <div className="h1 text-center">{t("menu.news")}</div>
                        </div>
                    </div>
                </div>
            </div>
            <Container></Container>
            {!isLoading ? <NewsPost item={data} /> : <></>}
        </>

    )
}
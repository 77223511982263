import { useState } from "react";
import { Accordion, Button, ButtonGroup, Col, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap"
import { format } from 'date-fns'
import { ru } from 'date-fns/locale';
import { useAddAllScheduleMutation, useAddScheduleMutation, useDeleteSessionShowMutation, useGetScheduleFromPrimeraMutation, useGetScheduleShowQuery, useGetShowFromPrimeraQuery, useSetNewStatusMutation, useSetUsageSchemeMutation } from "../../../features/CallApi";
import { LoadScreenMin } from "../../UI/LoadScreenMin";
import { FileEarmark, FileEarmarkRuled, Gear, Trash, X } from "react-bootstrap-icons";
import { useEffect } from "react";
import { SchemeSelect } from "../SchemeHall/SchemeSelect";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";



const SessionItem = (props) => {
    const [setNewStatus, { isLoading: loadingNewStatus }] = useSetNewStatusMutation()
    const [setUsageScheme, { isLoading: loadingUsageScheme }] = useSetUsageSchemeMutation()
    const [deleteSessionShow, { isLoading: deletingSession }] = useDeleteSessionShowMutation()
    const navigate = useNavigate()
    const deleteSession = (id) => {
        if (window.confirm("Удалить сеанс из списка?")) {
            deleteSessionShow(id).unwrap().finally(() => { props.refetch() })
        }
    }
    useEffect(() => {
        console.log(props.session)
    }, [props])
    return (
        <>
            <div className="pb-3 pt-3">
                <Row>
                    <Col>
                        <Row className="border-top">
                            <Col xs={'auto'}>
                                ID: {props.session.sessionId}
                            </Col>
                            <Col xs={`auto`}>
                                <div className="fw-bold">
                                    Дата: {format(new Date(props.session.startOfEvent), "dd.MM.yy HH:mm")}
                                </div>
                            </Col>
                            <Col xs>
                                {!loadingNewStatus ?
                                    <Form.Check // prettier-ignore
                                        type="switch" id={`IsShow-${props.session.id}`} label={props.session.isShow ? "активен" : "не активен"} onChange={e => {
                                            setNewStatus({
                                                id: props.session.id,
                                                newStatus: e.target.checked
                                            }).unwrap().then(() => {
                                                props.refetch();
                                            });
                                        }}
                                        // defaultChecked={props.session.isShow}
                                        checked={props.session.isShow} /> :
                                    <Spinner />
                                }

                            </Col>
                        </Row>
                        <Row>
                            <Col>Id схемы: {props.session.schemeHallId}</Col>
                            <Col>
                                {!loadingUsageScheme ?
                                    <Form.Select size="sm" onChange={(e) => {
                                        setUsageScheme(
                                            {
                                                id: props.session.id,
                                                usageScheme: e.target.value
                                            }).unwrap().then(() => {
                                                props.refetch();
                                            })
                                    }} value={props.session.usageScheme ? props.session.usageScheme : ""}>
                                        <option value={""} disabled hidden>-- Выбрать --</option>
                                        <option value={"1"}>Nikolas</option>
                                        <option value={"2"}>Сайт ЛЦ</option>
                                    </Form.Select>
                                    :
                                    <Spinner />
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={'auto'} style={{ alignContent: "center" }}>
                        <ButtonGroup>
                            <Button style={{
                                lineHeight: '16px'
                            }}
                                size="sm"
                                className="p-1"
                                variant="outline-secondary"
                                onClick={() => { navigate(`/admin/schemesession/${props.session.sessionId}`) }} >
                                <FileEarmarkRuled />
                            </Button>
                            {/* <Button style={{
                                lineHeight: '16px'
                            }}
                                size="sm"
                                className="p-1"
                                variant="outline-secondary" >
                                <Gear />
                            </Button> */}
                            <Button style={{
                                lineHeight: '16px'
                            }} size="sm" className="p-1" onClick={() => {
                                deleteSession(props.session.id);
                            }} variant="danger">
                                <Trash />
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </div>
        </>
    );
}

const AddManualSchedule = ({ showId, refetch }) => {
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const [showForm, setShowForm] = useState(false)
    const [startOfEvent, setStartOfEvent] = useState()
    const [duration, setDuration] = useState()
    useEffect(() => { setValue("showId", showId) }, [showForm])
    const [addSchedule, { isLoading }] = useAddScheduleMutation()

    const addManualSchedule = (data) => {
        // public int SessionId { get; set; }
        // public string? NameInPremiera { get; set; }
        // public DateTime StartOfEvent { get; set; }
        // public int? Duration { get; set; }
        // public DateTime EndOfEvent { get; set; }
        // public int? PremieraShowId {  get; set; }
        console.log(data)
        addSchedule(data).unwrap().then(
            (r) => {
                console.log(r)
                if (r.status === "success") {
                    window.alert("Успешно добавлено")
                    refetch()
                }
                if (r.status === "already") {
                    window.alert("Уже есть в расписание")
                    refetch()
                }
            }
        )
    }

    useEffect(() => {
        if (startOfEvent !== null && duration > 0) {
            const sDate = new Date(startOfEvent)
            const cDuration = duration * 60 * 1000
            const cDate = new Date(sDate.getTime() + cDuration)
            const formatDate = format(cDate, "yyyy-MM-dd") + "T" + format(cDate, "HH:mm")
            setValue("endOfEvent", formatDate)
        }
    }, [duration, startOfEvent])

    return (
        <>
            <div className="mb-1 border-bottom">
                <Button onClick={() => { setShowForm(!showForm) }} size="sm" className="mb-1">
                    Добавить вручну
                </Button>
                {showForm &&
                    <Form onSubmit={handleSubmit(addManualSchedule)}>
                        <FloatingLabel className="mb-1" controlId="floatingSelect" label="Id шоу (Число)">
                            <Form.Control type="number" autoComplete="off" defaultValue={""} placeholder="Id шоу Премьере (Число)" {...register("premieraShowId", { required: { value: true } })} />
                        </FloatingLabel>
                        <FloatingLabel className="mb-1" controlId="floatingSelect" label="Id сессии (Число)">
                            <Form.Control type="number" autoComplete="off" defaultValue={""} placeholder="Id сессии Премьере (Число)" {...register("sessionId", { required: { value: true } })} />
                        </FloatingLabel>
                        <FloatingLabel className="mb-1" controlId="floatingSelect" label="Название Шоу в Премьере">
                            <Form.Control type="text" autoComplete="off" defaultValue={""} placeholder="nameInPremiera" {...register("nameInPremiera", { required: { value: true } })} />
                        </FloatingLabel>
                        <FloatingLabel className="mb-1" controlId="floatingSelect" label="Дата и время начала">
                            <Form.Control type="datetime-local" autoComplete="off" defaultValue={""} placeholder="Дата и время начала" {...register("startOfEvent", { onChange: (e) => { setStartOfEvent(e.target.value) } })} />
                        </FloatingLabel>
                        <FloatingLabel className="mb-1" controlId="floatingSelect" label="Продолжительность">
                            <Form.Control type="number" autoComplete="off" defaultValue={""} placeholder="Продолжительность" {...register("duration", { onChange: (e) => { setDuration(e.target.value) } })} />
                        </FloatingLabel>
                        <FloatingLabel className="mb-1" controlId="floatingSelect" label="Дата и время завершения">
                            <Form.Control type="datetime-local" disabled autoComplete="off" defaultValue={""} placeholder="Дата и время завершения" {...register("endOfEvent")} />
                        </FloatingLabel>
                        <Button type="submit" variant="success" size="sm">Добавить</Button>
                    </Form>
                }
            </div>
        </>
    )
}


export const ShowSchedule = ({ showSchedule, setShowSchedule, showId, setShowId, showTitle, setShowTitle }) => {
    const [schedule, setSchedule] = useState()
    const [selSchedule, setSelSchedule] = useState()
    const [selSession, setSelSession] = useState()
    const { data: shows, isLoading } = useGetShowFromPrimeraQuery()
    const { data: scheduleShow, isLoading: isLoadingSchedule, refetch } = useGetScheduleShowQuery(showId)
    const [getScheduleFromPrimera, { isLoading: loadingSchedule }] = useGetScheduleFromPrimeraMutation()
    const [addSchedule, { isLoading: additionSchedule }] = useAddScheduleMutation()
    const [addAllSchedule, { isLoading: additionAllSchedule }] = useAddAllScheduleMutation()
    const [loadingScreen, setLoadingScreen] = useState();

    useEffect(() => {
        if (additionSchedule || additionAllSchedule) {
            return setLoadingScreen(true)
        }
        return setLoadingScreen(false)
    }, [isLoading, loadingSchedule, additionSchedule, additionAllSchedule])

    useEffect(() => {
        if (isLoadingSchedule) {
            setSchedule()
        }
    }, [isLoadingSchedule])

    const addSession = () => {
        const show = shows.find(show => {
            return show.showId === Number(selSchedule)
        })
        const session = schedule.find(session => {
            return session.sessionId === Number(selSession)
        }
        )

        console.log(session.startOfEvent)
        console.log(new Date(session.startOfEvent))

        const body = {
            sessionId: session.sessionId,
            nameInPremiera: show.nameInPremiera,
            startOfEvent: new Date(session.startOfEvent),
            duration: 0,
            endOfEvent: new Date(session.endOfEvent),
            showId: showId,
            premieraShowId: selSchedule
        }
        console.log(body)
        // addSchedule(body).unwrap().then(
        //     (r) => {
        //         if (r.status === "success") {
        //             console.log(r.status)
        //             window.alert("Успешно добавлено")
        //             refetch()
        //         }
        //         if (r.status === "already") {
        //             console.log(r.status)
        //             window.alert("Уже есть в расписание")
        //             refetch()
        //         }
        //     }
        // )

    }
    const addAll = () => {
        const show = shows.find(show => {
            return show.showId === Number(selSchedule)
        })
        const body = {
            showIdInPremiera: selSchedule,
            nameInPremiera: show.nameInPremiera,
            showId: showId,
        }
        addAllSchedule(body).unwrap().then((r) => {
            if (r.commetns.length > 0) {
                window.alert(r.commetns.map(item => item + "\n"))
            }
            refetch()
        }).catch(() => {
            refetch()
        })

    }


    const getSchedule = (id) => {
        getScheduleFromPrimera(id)
            .unwrap()
            .then((r) => {
                // console.log(r)
                setSchedule(r)
            })
    }




    return (<>
        <Modal
            show={showSchedule}
            size={"xl"}
            onHide={() => {
                setShowId(null)
                //  reset()
                setShowSchedule(false)
                setShowTitle()
            }}
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <h3>{showTitle}</h3>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={6}>
                        <AddManualSchedule showId={showId} refetch={refetch} />
                        {!isLoading ? <>
                            {shows &&
                                <FloatingLabel className="mb-1" controlId="floatingSelect" label="Шоу">
                                    <Form.Select onChange={(e) => { getSchedule(e.target.value); setSelSchedule(e.target.value) }} defaultValue={""} aria-label="">
                                        <option value={""} disabled hidden>--- Выбрать шоу ---</option>
                                        {shows.map(show => <option key={show.showId} value={show.showId}>{show.nameInPremiera} (id: {show.showId})</option>)}
                                    </Form.Select>
                                </FloatingLabel>
                            }
                        </> :
                            <LoadScreenMin />
                        }
                        {!loadingSchedule ? <>
                            {schedule && <>
                                <FloatingLabel className="mb-2" controlId="floatingSelect" label="Сеанс">
                                    <Form.Select onChange={(e) => { setSelSession(e.target.value) }} defaultValue={""} aria-label="">
                                        <option value={""} disabled hidden>--- Выбрать сеанс ---</option>
                                        {schedule.map(schedule => <option key={schedule.sessionId} value={schedule.sessionId}>Дата: {format(new Date(schedule.startOfEvent), "dd.MM.yy")} начала:{format(new Date(schedule.startOfEvent), "HH:mm")}  (id: {schedule.sessionId})</option>)}
                                    </Form.Select>
                                </FloatingLabel>
                                <ButtonGroup className="mb-2">
                                    {selSession &&
                                        <Button variant="success" onClick={() => {
                                            if (window.confirm(`Добавить сеанс с id ${selSession}?`)) {
                                                addSession()
                                            }

                                        }}>Добавить</Button>}
                                    <Button variant="warning" onClick={() => { addAll() }}>
                                        Добавить все расписание
                                    </Button>
                                </ButtonGroup>
                            </>
                            }
                        </>
                            :
                            <LoadScreenMin height={15} />
                        }

                    </Col>
                    {!loadingScreen ?

                        <Col lg={6} className="">
                            {!isLoadingSchedule ? <>{scheduleShow && scheduleShow?.length > 0 ? <>
                                <div className="g-2">

                                    <SchemeSelect showId={showId} refetch={refetch} selSchedule={selSchedule} />
                                    {scheduleShow.map(session =>
                                        <SessionItem key={session.id} refetch={refetch} session={session} />
                                    )}
                                </div>
                            </> :
                                <div className="text-center fw-bold">Список расписания пуст</div>}</> : <></>}
                        </Col>
                        :
                        <Col>
                            <LoadScreenMin height={30} />
                        </Col>
                    }
                </Row>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    </>)
}
import { Button, Col, FloatingLabel, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import { LoadScreenMin } from "../UI/LoadScreenMin"
import { Check2All } from "react-bootstrap-icons";


export const TableReservationForm = ({ reserFormShow, setReserFormShow }) => {
    const [agreement, setAgreement] = useState(false)
    const [posting, setPosting] = useState(false)
    const [isSend, setIsSend] = useState(false)
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = async (data) => {
        setPosting(true)
        console.log(data)
        await axios.post("https://app.leningradcenter.ru/api/reservetables", data, {
            headers: {
                method: 'POST'
            }
        }).then((r) => {
            console.log(r)
            setIsSend(true)
        })
    }

    const closeModal = () => {
        reset()
        setPosting(false)
        setAgreement(false)
        setIsSend(false)
        setReserFormShow(false)
    }

    const { t } = useTranslation("global");
    return (
        <Modal onHide={() => {
            closeModal()
        }} show={reserFormShow}>
            <ModalHeader closeButton>
                <h3>{t('form.reserForm')}</h3>
            </ModalHeader>
            {!isSend ? <>
                {
                    !posting ?
                        <>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <ModalBody>
                                    {/* {errors &&
                        <div className="mb-3">
                            <span className="text-danger">Заполните все обязательные поля</span>
                        </div>
                    } */}
                                    <Row className="g-1">
                                        <Col>
                                            <FloatingLabel className="mb-1" controlId="firstName" label={`${t('form.firstName')} *`}>
                                                <Form.Control type="text" placeholder={`${t('form.firstName')} *`}
                                                    {...register("firstName", { required: { value: true, message: `Поле ${t('form.firstName')} обязательное` } })}
                                                    isInvalid={errors?.firstName?.message != null} />
                                            </FloatingLabel>
                                        </Col>
                                        <Col>
                                            <FloatingLabel className="mb-1" controlId="lastName" label={`${t('form.lastName')} *`}>
                                                <Form.Control type="text" placeholder={t('form.lastName')}
                                                    {...register("lastName", { required: { value: true, message: `Поле ${t('form.lastName')} обязательное` } })}
                                                    isInvalid={errors?.lastName?.message != null} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                    <FloatingLabel className="mb-1" controlId="patronymic" label={t('form.patronymic')}>
                                        <Form.Control type="text" placeholder={t('form.patronymic')} {...register("patronymic")} />
                                    </FloatingLabel>
                                    <FloatingLabel className="mb-1" controlId="email" label={`E-Mail`}>
                                        <Form.Control type="email" placeholder={"E-Mail"} {...register("email", { required: { value: true, message: "Поле E-Mail обязательное" }, pattern: /^\S+@\S+$/i })} isInvalid={errors?.email?.message != null} />
                                    </FloatingLabel>
                                    <FloatingLabel className="mb-1" controlId="phone" label={t("form.phone")}>
                                        <Form.Control type="phone" placeholder={t("form.phone")}  {...register("phone", {
                                            required: { value: true, message: `Поле ${t('form.phone')} обязательное` },
                                        })}
                                            isInvalid={errors?.phone?.message != null} />
                                    </FloatingLabel>
                                    <FloatingLabel className="mb-1" controlId="numberPersons" label={t("form.numberPersons")}>
                                        <Form.Control type='number' min={1} max={8} placeholder={t("form.numberPersons")}
                                            {...register("numberPersons", {
                                                required: { value: true, message: `Поле ${t('form.numberPersons')} обязательное` },
                                                min: { value: 1, message: "Укажите минимум кол-во 1" },
                                                max: { value: 8, message: "Укажите максимум кол-во 8" }
                                            })}
                                            isInvalid={errors?.numberPersons?.message != null} />
                                    </FloatingLabel>
                                    <FloatingLabel className="mb-1" controlId="Comments" label={t("form.comments")}>
                                        <Form.Control
                                            as="textarea"
                                            placeholder={t("form.comments")}
                                            style={{ height: '100px' }}
                                            {...register("comment")}
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel className="mb-1" controlId="numberPersons" label={t("form.date")}>
                                        <Form.Control type='datetime-local' placeholder={t("form.date")} {...register("startReserve", {
                                            required: { value: true, message: `Поле ${t('form.date')} обязательное` },
                                        })}
                                            isInvalid={errors?.startReserve?.message != null} />
                                    </FloatingLabel>
                                    {/* <Col>
                            <FloatingLabel className="mb-1" controlId="numberPersons" label={t("form.time")}>
                                <Form.Control type='time' min="07:00:00" max="18:00:00" placeholder={t("form.time")} />
                            </FloatingLabel>
                        </Col> */}
                                    <div className="form-check form-switch mt-3">
                                        <input className={`form-check-input ${errors.agreement && "is-invalid"}`} onClick={() => { setAgreement(!agreement) }}
                                            checked={agreement}
                                            type="checkbox"
                                            id="agreement" {...register("agreement", { required: true })} />
                                        <label className="form-check-label" htmlFor="rememberMe">
                                            Согласие на обработку персональных данных <br /> <a href="https://leningradcenter.ru/api/redirecturls/privacy-policy">Политика конфиденциальности</a>
                                        </label>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button className="btn-lc btn-lc-rounded" type="submit">Отправить</Button>
                                </ModalFooter>
                            </form>
                        </>
                        :
                        <ModalBody>
                            <LoadScreenMin height={65} />
                        </ModalBody>
                }
            </> :
                <>
                    <ModalBody>
                        <div>
                            <div className="offset-md-2 col-sm-8">
                                <h2 className="text-center mt-5">Заявка на резервирование стола успешно отправлена!</h2>
                                <div className="text-center">
                                    <Check2All size={80} className={"text-success"} />
                                </div>
                                <div className="text-center mb-3">
                                    <Button className="btn-lc btn-lc-rounded" onClick={() => { closeModal() }}>Закрыть окно</Button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </>
            }
        </Modal >
    )
} 
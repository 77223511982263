import { useEffect, useState } from "react"
import { Button, ButtonGroup, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap"
import { Check, DistributeHorizontal, DistributeVertical, Gear, TextareaResize, Trash, X } from "react-bootstrap-icons"
import { useForm } from "react-hook-form";
import { useDeleteTextMutation, usePutEditTextMutation } from "../../../../features/CallApi";


export const SchemeText = ({ text, refetch }) => {
    const { register, handleSubmit, reset, setValue } = useForm();
    const [putEditText, { isLoading }] = usePutEditTextMutation()
    const [deleteText, { isLoading: isTextDeletening }] = useDeleteTextMutation()

    const [editing, setEditing] = useState(false)
    const submit = (data) => {
        data.id = text.id
        // console.log(data)
        putEditText(data).then(() => {
            reset()
            refetch()
            setEditing(false)
        })
    }

    useEffect(()=>{
        setValue("text", text.text)
        setValue("textEn", text.textEn)
        setValue("x", text.x)
        setValue("y", text.y)
        setValue("fill", text.fill)
        setValue("width", text.width)
        setValue("fontSize", text.fontSize)
        setValue("align", text.align)
        setValue("verticalAlign", text.verticalAlign)
    },[editing])

    // useEffect(()=>{
    //     setValue()
    // },[text])

    return (
        <>
            {!editing ?
                <div className={"border-bottom pt-1 pb-1"}>
                    <Row>
                        <Col>
                            {text.text}
                        </Col>
                        <Col>
                            X: {text.x}
                        </Col>
                        <Col>
                            Y: {text.y}
                        </Col>
                        <Col>
                            <ButtonGroup>
                                <Button
                                    size="sm"
                                    style={{ lineHeight: '10px' }}
                                    variant="light"
                                    className="p-1"
                                    onClick={() => { setEditing(!editing) }}
                                >
                                    <Gear size={16} />
                                </Button>
                                <Button
                                    size="sm"
                                    style={{ lineHeight: '10px' }}
                                    variant="outline-danger"
                                    className="p-1"
                                    onClick={() => {
                                        if (window.confirm("Вы уверены?")) {
                                            deleteText(text.id)
                                                .then(() => { refetch() })
                                        }
                                    }} disabled={isTextDeletening}>
                                    {!isTextDeletening ?
                                        <Trash size={16} /> :
                                        <Spinner size="8" style={{ width: "1rem", height: "1rem" }} />
                                    }
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </div >
                :
                <Form onSubmit={handleSubmit(submit)}>
                    <Row>
                        <Col>
                            <Row className="mb-1 mt-1">
                                <Col>
                                    <Col>
                                        <Form.Group className="d-flex">
                                            <InputGroup style={{ lineHeight: '18px' }}>
                                                <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">Текст</InputGroup.Text>
                                                <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                    {...register("text", { required: { value: true } })}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Col>

                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <Col>
                                        <Form.Group className="d-flex">
                                            <InputGroup style={{ lineHeight: '18px' }}>
                                                <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">Текст EN</InputGroup.Text>
                                                <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                    {...register("textEn", { required: { value: true } })}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">X</InputGroup.Text>
                                            <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                {...register("x", { required: { value: true } })}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">Y</InputGroup.Text>
                                            <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                {...register("y", { required: { value: true } })}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">Цвет</InputGroup.Text>
                                            <Form.Control type="color" size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                {...register("fill", { required: { value: true } })}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">
                                                <TextareaResize />
                                            </InputGroup.Text>
                                            <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                {...register("width", { required: { value: true } })}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">Размер</InputGroup.Text>
                                            <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                {...register("fontSize", { required: { value: true } })}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">
                                                <DistributeHorizontal />
                                            </InputGroup.Text>
                                            <Form.Select size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                {...register("align", { required: { value: true } })}
                                            >
                                                <option value={"left"}>По левому</option>
                                                <option value={"center"}>По центру</option>
                                                <option value={"right"}>По правому</option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">
                                                <DistributeVertical />
                                            </InputGroup.Text>
                                            <Form.Select size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                {...register("verticalAlign", { required: { value: true } })}
                                            >
                                                <option value={"top"}>По верхнему</option>
                                                <option value={"middle"}>По центру</option>
                                                <option value={"bottom"}>По правому</option>
                                            </Form.Select>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={'auto'} style={{ alignContent: 'center' }}>
                            {!isLoading ?
                                <ButtonGroup>
                                    <Button style={{ lineHeight: '10px' }} type="submit" className="p-1" variant="success"><Check size={16} /></Button>
                                    <Button style={{ lineHeight: '10px' }} onClick={() => { setEditing(false); reset(); }} className="p-1" variant="ligth"><X size={16} /></Button>
                                </ButtonGroup>
                                :
                                <Spinner />
                            }
                        </Col>
                    </Row>
                </Form>
            }
        </>
    )
}
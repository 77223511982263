import { Accordion, Button, ButtonGroup, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap"
import { Check, Clipboard2, Gear, InputCursorText, Trash, X } from "react-bootstrap-icons";
import { useDeleteSessionSeatMutation, useSetGroupTagMutation } from "../../../../features/CallApi";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";



const Seat = (props) => {


    const [deleteSessionSeat, { isLoading: isDeleting }] = useDeleteSessionSeatMutation()
    const [showEdit, setShowEdit] = useState(false)
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const [setGroupTag, { isLoading }] = useSetGroupTagMutation()
    useEffect(() => {
        setValue("SeatsIds", [props.seat.id])
        setValue("groupTag", props.seat.groupTag)
    }, [showEdit])
    const submit = (data) => {
        setGroupTag(data).then(() => {
            reset()
            setShowEdit(false)
            props.refetch()
        })
    }

    return (
        <div className="mb-1 border-bottom pb-1 pt-1">
            <Row>
                <Col xs={2}>{props.seat.name}</Col>
                <Col style={{
                    color: props.seat.fill
                }}>{props.seat.categoryName}</Col>
                <Col xs={'auto'}>
                    <ButtonGroup>
                        <Button variant="outline-secondary" style={{
                            lineHeight: '16px'
                        }} size="sm" className="p-1" onClick={() => {
                            setShowEdit(!showEdit)
                        }}><Gear size={16} /></Button>
                        <Button variant="outline-danger" style={{
                            lineHeight: '16px'
                        }} size="sm" className="p-1" onClick={() => {
                            if (window.confirm("Вы уверены?")) {
                                deleteSessionSeat(props.seat.id).then(() => {
                                    props.refetch()
                                })
                            };
                        }}>
                            <Trash size={16} />
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            {showEdit &&
                <Form className="mb-3 mt-1" onSubmit={handleSubmit(submit)}>
                    <InputGroup>
                        <InputGroup.Text>
                            #
                        </InputGroup.Text>
                        <Form.Control placeholder="Групповой тэг" {...register("groupTag")} />
                        <ButtonGroup>
                            <Button variant="succese" style={{
                                lineHeight: '16px'
                            }} size="sm" className="p-1" type="submit"><Check size={16} /></Button>
                            <Button variant="secondary" style={{
                                lineHeight: '16px'
                            }} size="sm" className="p-1" onClick={() => {
                                // if (window.confirm("Вы уверены?")) {
                                reset()
                                setShowEdit(!showEdit)
                                // };
                            }}>
                                <X size={16} />
                            </Button>
                        </ButtonGroup>
                    </InputGroup>
                </Form>
            }
        </div>
    );
}

const EditRow = ({ seats, refetch }) => {
    const [showEdit, setShowEdit] = useState(false)
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const [setGroupTag, { isLoading }] = useSetGroupTagMutation()
    useEffect(() => {
        if (showEdit) {
            const seatsIds = seats.map(seat => seat.id)
            setValue("seatsIds", seatsIds)
        }
    }, [showEdit])
    const submit = (data) => {
        setGroupTag(data).then(() => {
            reset()
            setShowEdit(false)
            refetch()
        })
    }
    return (
        <>
            <ButtonGroup className="mt-2 mb-2">
                {/* <Button variant="outline-secondary" size="sm" onClick={() => { }}><InputCursorText /></Button> */}
                <Button variant="outline-secondary" size="sm" onClick={() => { setShowEdit(!showEdit) }}><Gear /></Button>
                {/* <Button variant="outline-secondary" size="sm" onClick={() => { }}><Clipboard2 /></Button> */}
                <Button variant="outline-danger" size="sm" onClick={() => {
                    // if (window.confirm("Вы уверены?")) {
                    // }
                }}><Trash />
                    {/* {!isDeleting ? <Trash /> : <Spinner />} */}
                </Button>
            </ButtonGroup>
            {showEdit &&
                <Form className="mb-3" onSubmit={handleSubmit(submit)}>
                    {!isLoading ?

                        <InputGroup>
                            <InputGroup.Text>
                                #
                            </InputGroup.Text>
                            <Form.Control placeholder="Групповой тэг" {...register("groupTag")} />
                            <ButtonGroup>
                                <Button variant="succese" style={{
                                    lineHeight: '16px'
                                }} size="sm" className="p-1" type="submit"><Check size={16} /></Button>
                                <Button variant="secondary" style={{
                                    lineHeight: '16px'
                                }} size="sm" className="p-1" onClick={() => {
                                    // if (window.confirm("Вы уверены?")) {
                                    reset()
                                    setShowEdit(!showEdit)
                                    // };
                                }}>
                                    <X size={16} />
                                </Button>
                            </ButtonGroup>

                        </InputGroup>
                        :
                        <Spinner />
                    }
                </Form>
            }
        </>
    )
}


export const SessionSeats = ({ data, refetch }) => {

    return (
        <Accordion>
            <Accordion.Item eventKey="seat">
                <Accordion.Button className="p-1">Места</Accordion.Button>
                <Accordion.Body className="p-1">
                    <Accordion>
                        {data.length > 0 &&
                            data.map(row =>
                                <Accordion.Item eventKey={row.id} key={row.id}>
                                    <Accordion.Button className="p-1">{row.nameRow}</Accordion.Button>
                                    <Accordion.Body className="p-1">
                                        <EditRow seats={row.seats} refetch={refetch} />
                                        {row.seats.map(seat =>
                                            <Seat key={seat.id} seat={seat} refetch={refetch}></Seat>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        }
                    </Accordion>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )

}
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const callAppApi = createApi({
    reducerPath: 'callAppApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://app.leningradcenter.ru/api',
        //baseUrl: 'https://localhost:44415/api/',
        // baseUrl: 'https://192.168.4.13:44415/api/',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().clientToken.value;
            if (token) {
                headers.set("Authorization", `Bearer ${token.Token}`);
            }
            return headers;
        },
    }),
    keepUnusedDataFor: 0,
    refetchOnMountOrArgChange: 0,
    tagTypes: ['Client'],
    endpoints: (builder) => ({
        baseToken: builder.mutation({ query: () => ('login/basic-token') }),
        refreshClientToken: builder.mutation({
            query: (refTokrn) => ({
                url: 'login/refreshtoken',
                method: `POST`,
                body: refTokrn
            })
        }),
        clientLogin: builder.mutation({
            query: ({ data, baseToken }) => ({
                url: `login/client`,
                method: `POST`,
                headers: { "Authorization": `Bearer ${baseToken}` },
                body: data
            })
        }),
        getclientData: builder.query({
            query: () => `clients/getclientdata`,
            providesTags: ['Client']
        }),
        getDownloadTickets: builder.mutation({
            query: (orderId) => `clients/download-tickets/${orderId}`
        }),
        putClient: builder.mutation({
            query: (data) => ({
                url: `clients/putclientdata`,
                method: `PUT`,
                body: data
            }),
            invalidatesTags: ['Client']
        }),
        postCheckStatusToken: builder.mutation({
            query: (data) => ({
                url: `login/client/check-status-token`,
                method: 'POST',
                body: data
            })
        }),
        postRecoverPassword: builder.mutation({
            query: (data) => ({
                url: "login/client/recover-password",
                method: 'POST',
                body: data
            })
        }),
        postRequestResetPassword: builder.mutation({
            query: ({ data, baseToken }) => ({
                url: `login/client/request-reset-password?email=${data.email}`,
                method: 'POST',
                headers: { "Authorization": `Bearer ${baseToken}` },
            })
        }),
        postClientRegister: builder.mutation({
            query: ({ data, baseToken }) => ({
                url: `login/client/register`,
                method: 'POST',
                headers: { "Authorization": `Bearer ${baseToken}` },
                body: data
            })
        }),
        createCertificateOrder: builder.mutation(
            {
                query: ({ ...body }) => ({
                    url: 'certificateorders',
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })

            }
        ),
        createCorporateRequest: builder.mutation({
            query: ({ ...body }) => ({
                url: `requests/corporate`,
                method: `POST`,
                body: body,
            })
        }),
        createRentRequest: builder.mutation({
            query: ({ ...body }) => ({
                url: `requests/rent`,
                method: `POST`,
                body: body,
            })
        }),
        getShows: builder.query({ query: () => `shows` }),
        createNewTicketsOrder: builder.mutation({
            query: ({ ...body }) => ({
                url: `ticketsorders/create-new-order`,
                method: `POST`,
                body: body
            })
        }),
        postWorksheet: builder.mutation(
            {
                query: ({ ...form }) => ({
                    url: `worksheets`,
                    method: `POST`,
                    body: form,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
            }
        ),
    })
})

export const {
    useBaseTokenMutation,
    useRefreshClientTokenMutation,
    useClientLoginMutation,
    useGetclientDataQuery,
    usePutClientMutation,
    usePostCheckStatusTokenMutation,
    usePostRecoverPasswordMutation,
    usePostRequestResetPasswordMutation,
    usePostClientRegisterMutation,
    useGetDownloadTicketsMutation,
    useCreateCertificateOrderMutation,
    useCreateCorporateRequestMutation,
    useCreateRentRequestMutation,
    useGetShowsQuery,
    useCreateNewTicketsOrderMutation,
    usePostWorksheetMutation,
} = callAppApi
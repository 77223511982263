import { Fragment } from "react"
import { Circle, Line, Rect, Shape, Text } from "react-konva"


export const StaticObjects = ({ texts, objects }) => {
    return (
        <Fragment>
            {objects && objects.map(item => {
                return <RenderObject key={item.id} object={item} />
            })}
            {/* {object1.map(item => {
                return <RenderShape key={item.id} object={item} />
            })} */}
            {texts && texts.map(text =>
                <Text
                    key={text.id}
                    width={text.width}
                    align={text.align ? text.align : "center"}
                    verticalAlign={text.verticalAlign ? text.verticalAlign : "middle"}
                    x={text.x}
                    y={text.y}
                    fontSize={text.fontSize}
                    fill={text.fill}
                    text={text.text}
                />
            )}
        </Fragment>
    )
}

const GetPoints = (ctxs) => {
    const points = [];
    ctxs.forEach(ctx => {
        points.push(ctx.x);
        points.push(ctx.y);
    })
    return points;
}

const RenderObject = ({ object }) => {
    switch (object.type) {
        case 'shape':
            return <Shape
                strokeWidth={object.strokeWidth}
                stroke={object.stroke}
                x={object.x}
                y={object.y}
                width={object.width}
                fill={object.fill}
                sceneFunc={(context, shape) => {
                    context.beginPath();
                    object.ctxs.forEach(ctx => {
                        switch (ctx.type) {
                            case 'move':
                                return context.moveTo(ctx.x, ctx.y);
                            case 'line':
                                return context.lineTo(ctx.x, ctx.y);
                            case 'curve':
                                return context.quadraticCurveTo(ctx.cpx, ctx.cpy, ctx.x, ctx.y)
                            default: return null;
                        }
                    });
                    context.closePath();
                    context.fillStrokeShape(shape);

                }}
            />;
        case 'line': return <Line
            x={object.x}
            y={object.y}
            points={GetPoints(object.ctxs)}
            tension={1}
            strokeWidth={object.strokeWidth}
            // closed
            stroke={object.fill}
        />
        case 'circle':
            return <Circle
                x={object.x}
                y={object.y}
                radius={object.width}
                strokeWidth={object.strokeWidth}
                stroke={object.stroke}
                fill={object.fill}
            />
        case 'rect':
            return <Rect
                x={object.x}
                y={object.y}
                width={object.ctxs[0].x}
                height={object.ctxs[0].y}
                fill={object.fill}
                stroke={object.stroke}
                strokeWidth={object.strokeWidth}
            />
        default:
            return null;
    }
}



import { useState } from "react"
import { Col, Nav, Navbar, Offcanvas, Row } from "react-bootstrap"
import {
    Newspaper, PersonCircle, Bank,
    People, ArrowThroughHeart, PatchCheck, Gift,
    TicketDetailed,
    EnvelopePlus,
    ClipboardPlus,
    TicketPerforated,
    House,
    AppIndicator,
    Easel,
    ImageAlt,
    BoxArrowInRight
} from "react-bootstrap-icons"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"


export const MobileNavMenu = ({ setReserFormShow, aboutMobMenu: show, setAboutMobMenu: setShow, dataShow, setDataShow, clientToken }) => {

    const [showService, setShowService] = useState(false)
    const [t] = useTranslation("global")
    const toNav = (link) => {
        setShow(false)
        setShowService(false)
        navigete(link)
    }


    const navigete = useNavigate()
    return (
        <>
            <Offcanvas className="offcanvas-project" show={show} scroll={false} placement={"bottom"} onHide={() => { setShow(false) }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>О проекте</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row className='g-3 justify-content-center'>
                        <Col onClick={() => { toNav("/about-project") }} className='text-center' xs={"auto"}>
                            <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                <svg width={40} height={40}>
                                    <use xlinkHref='lc-logo.svg#logo-small'></use>
                                </svg>
                            </div>
                            <div style={{ fontSize: "10px" }}>{t("menu.about")}</div>
                        </Col>
                        <Col onClick={() => { toNav("/about-project/history") }} className='text-center' xs={"auto"}>
                            <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                <Bank size={40} />
                            </div>
                            <div style={{ fontSize: "10px" }}>{t("menu.history")}</div>
                        </Col>
                        <Col onClick={() => { toNav("/about-project/stage") }} className='text-center' xs={"auto"}>
                            <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                <svg width={40} height={40}>
                                    <use xlinkHref='lc-logo.svg#stage'></use>
                                </svg>
                            </div>
                            <div style={{ fontSize: "10px" }}>{t("menu.stage")}</div>
                        </Col>
                        <Col onClick={() => { toNav("/about-project/gallery") }} className='text-center' xs={"auto"}>
                            <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                <Easel size={40} />
                            </div>
                            <div style={{ fontSize: "10px" }}>{t("menu.gallery")}</div>
                        </Col>
                        <Col onClick={() => { toNav("/about-project/troupe") }} className='text-center' xs={"auto"}>
                            <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                <People size={40} />
                            </div>
                            <div style={{ fontSize: "10px" }}>{t("menu.troupe")}</div>
                        </Col>
                        <Col onClick={() => { toNav("/about-project/shows") }} className='text-center' xs={"auto"}>
                            <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                <ArrowThroughHeart size={40} />
                            </div>
                            <div style={{ fontSize: "10px" }}>{t("menu.shows")}</div>
                        </Col>
                        <Col onClick={() => { toNav("/about-project/exhibitions") }} className='text-center' xs={"auto"}>
                            <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                <ImageAlt size={40} />
                            </div>
                            <div style={{ fontSize: "10px" }}>{t("menu.exhibitions")}</div>
                        </Col>
                        <Col onClick={() => { toNav("/about-project/bars") }} className='text-center' xs={"auto"}>
                            <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                <svg width={40} height={40}>
                                    <use xlinkHref='lc-logo.svg#drink'></use>
                                </svg>
                            </div>
                            <div style={{ fontSize: "10px" }}>{t("menu.bars")}</div>
                        </Col>
                        <Col onClick={() => { toNav("/about-project/restaurant") }} className='text-center' xs={"auto"}>
                            <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                <svg width={40} height={40}>
                                    <use xlinkHref='lc-logo.svg#blok'></use>
                                </svg>
                            </div>
                            <div style={{ fontSize: "10px" }}>{t("menu.restaurant")}</div>
                        </Col>
                        <Col onClick={() => { toNav("/about-project/certificates") }} className='text-center' xs={"auto"}>
                            <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                <Gift size={40} />
                            </div>
                            <div style={{ fontSize: "10px" }}>{t("menu.certificates")}</div>
                        </Col>
                        <Col onClick={() => { toNav("/about-project/znak-kachestva") }} className='text-center' xs={"auto"}>
                            <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                <svg width={40} height={40}>
                                    <use xlinkHref='lc-logo.svg#ABRgold'></use>
                                </svg>
                            </div>
                            <div style={{ fontSize: "10px" }}>{t("menu.qualitymarkMobi")}</div>
                        </Col>

                        <Col onClick={() => { toNav("/about-project/partners") }} className='text-center' xs={"auto"}>
                            <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                <PatchCheck size={40} />
                            </div>
                            <div style={{ fontSize: "10px" }}>{t("menu.partners")}</div>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas >
            <Offcanvas className="offcanvas-service" show={showService} scroll={false} placement={"bottom"} onHide={() => { setShowService(false) }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Мои сервисы</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row className='g-3 justify-content-center'>
                        {clientToken ? <>
                            <Col onClick={() => { toNav("/client/profile") }} className='text-center' xs={"auto"}>
                                <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                    <PersonCircle size={40} />
                                </div>
                                <div style={{ fontSize: "10px" }}>{t("menu.profile")}</div>
                            </Col>
                            <Col onClick={() => { toNav("/client/orders") }} className='text-center' xs={"auto"}>
                                <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                    <TicketDetailed size={40} />
                                </div>
                                <div style={{ fontSize: "10px" }}>{t("menu.mytickets")}</div>
                            </Col>
                        </>
                            :
                            <>
                                <Col onClick={() => { toNav("/client/profile") }} className='text-center' xs={"auto"}>
                                    <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                        <BoxArrowInRight size={40} />
                                    </div>
                                    <div style={{ fontSize: "10px" }}>{t("form.singIn")}</div>
                                </Col>
                            </>
                        }
                        <Col onClick={() => { setShow(false); setShowService(false); setReserFormShow(true) }} className='text-center' xs={"auto"}>
                            <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                <ClipboardPlus size={40} />
                            </div>
                            <div style={{ fontSize: "10px" }}>{t("menu.reservation")}</div>
                        </Col>
                        <Col onClick={() => { toNav('/about-project/requests') }} className='text-center' xs={"auto"}>
                            <div className='border p-2 mb-1 rounded text-center shadow-sm mobile-button' style={{ width: "60px", height: "60px" }}>
                                <EnvelopePlus size={40} />
                            </div>
                            <div style={{ fontSize: "10px" }}>{t("menu.contactform")}</div>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas >
            <Navbar fixed="bottom" className={"d-lg-none bg-white pb-3"} variant="white" style={{ borderTop: "1px solid rgb(141 141 141 / 94%)" }}>
                <Nav className={"w-100"} style={{ justifyContent: "space-around" }}>
                    <Nav.Link className="text-center p-0"
                        onClick={() => { toNav("/") }}
                    >
                        <House className="mobile-icon" operator='over'
                            size={26} />
                        <div className="mobile-icon-title">{t("menu.home")}</div>
                    </Nav.Link>

                    <Nav.Link className="text-center p-0"
                        onClick={() => { toNav("/news") }}>
                        <Newspaper className="mobile-icon" size={26} />
                        <div className="mobile-icon-title">{t("menu.news")}</div>
                    </Nav.Link>
                    <Nav.Link className="text-center p-0"
                        onClick={() => {
                            toNav("/afisha")
                        }}
                    >
                        <TicketPerforated className="mobile-icon" size={26} />
                        <div className="mobile-icon-title">{t("menu.tickets")}</div>
                    </Nav.Link>
                    <Nav.Link className="text-center p-0"
                        onClick={() => { setShow(true) }}>
                        <svg className='img-fluid mobile-icon' style={{ maxWidth: "26px", maxHeight: "26px" }}>
                            <use xlinkHref='lc-logo.svg#logo-small'></use>
                        </svg>
                        <div className="mobile-icon-title">{t("menu.about")}</div>
                    </Nav.Link>
                    <Nav.Link className="text-center p-0"
                        onClick={() => { setShowService(true) }}>
                        {/* <svg className="img-fluid mobile-icon" style={{ maxWidth: "26px", maxHeight: "26px" }}>
                            <use xlinkHref="lc-logo.svg#Service2"></use>
                        </svg> */}
                        <AppIndicator className="mobile-icon" size={26} />
                        {/* <PersonCircle className="mobile-icon" size={26} /> */}
                        <div className="mobile-icon-title">{t("menu.service")}</div>
                    </Nav.Link>
                </Nav>
            </Navbar>
        </>

    )

}
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useGetShowsQuery } from "../../features/CallApi"
import { SwiperAfisha } from "../UI/SwiperAfisha"


export const Repertoire = ({ selLanguage }) => {

    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [selLng, setSelLng] = useState('')
    const navigate = useNavigate()

    const toNav = (link) => {
        window.scrollTo(0, 0)
        navigate(link)
    }

    useEffect(() => {
        if (selLanguage) {
            setSelLng(selLanguage)
        }
    }, [selLanguage])

    const { data, isLoading } = useGetShowsQuery({ pageIndex, pageSize, selLng })

    return (
        <>
            {!isLoading &&
                <SwiperAfisha items={data.getShows} />
            }
        </>
    )
}
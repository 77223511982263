

import { Accordion } from "react-bootstrap";
import { ObjectItem } from "./ObjectItem";
import { StatictObjectsAdd } from "./StatictObjectAdd";


export const StatictObjectsList = (props) => {
    return (
        <Accordion>
            <Accordion.Item eventKey={"texts"} >
                <Accordion.Button className="p-1">
                    Объекты
                </Accordion.Button>
                <Accordion.Body className="p-1 pt-2 pb-2">
                    <StatictObjectsAdd refetch={props.refetch} schemeId={props.schemeId} />
                    {props.staticObjects.length > 0 &&
                        <>
                            {props.staticObjects.map(item =>
                                <ObjectItem schemeId={props.schemeId} refetch={props.refetch} key={item.id} item={item} />
                            )}
                        </>
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );

}

import { Seat } from "./Seat";
import { Fragment } from "react";

export const SubSection = ({
    data,
    onHoverSeat,
    onSelectSeat,
    onDeselectSeat,
    selectedSeatsIds,
    nameRow,
}) => {

    return (
        <Fragment>
            {data.map(seat =>
                <Seat
                    key={`place-${seat.id}`}
                    nameRow={nameRow}
                    x={seat.x}
                    y={seat.y}
                    data={seat}
                    onHover={onHoverSeat}
                    onSelect={onSelectSeat}
                    onDeselect={onDeselectSeat}
                    isSelected={selectedSeatsIds.indexOf(seat.id) >= 0}
                />
            )}
        </Fragment>
    )

};
import { Button, ButtonGroup, FloatingLabel, Form, Modal } from "react-bootstrap"
import { useGetUserByIdQuery, usePutUserByIdMutation } from "../../../features/CallApi"
import { useForm } from "react-hook-form"
import { useState } from "react"
import { useEffect } from "react"
import { LoadScreenMin } from "../../UI/LoadScreenMin"


export const UserEdit = ({ dataRole, userId, showEditForm, setShowEditForm }) => {

    const { data, isLoading } = useGetUserByIdQuery(userId)
    const [putUserById, { isLoading: putLoading }] = usePutUserByIdMutation()
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const [status, setStatus] = useState()

    const [role, setRole] = useState("")

    const onSubmit = (data) => {
        setValue()
        putUserById({ id: userId, data }).unwrap()
            .then(() => {
                reset()
                setShowEditForm(false)
            }
            ).catch((r) => {
                alert(r.data?.detail)
            })
    }

    useEffect(() => {
        if (data) {
            setValue("email", data.email)
            setValue("firstname", data.firstname)
            setValue("lastname", data.lastname)
            setValue("roleId", data.roleId)
            setStatus(data.status)
            setRole(data.roleId)
        }
    }, [data])

    return (
        <Modal
            show={showEditForm}
            onHide={() => {
                reset()
                setShowEditForm(false)
            }}
            backdrop="static"
            keyboard={false}
        >

            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>Редактирование пользователя</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isLoading ?
                        <>
                            {data &&
                                <>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="email" className="form-control" id="email" placeholder="name@leningradcenter.ru"  {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                                        <label htmlFor="email">Email</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="text" className="form-control" id="firstname" placeholder="Имя"  {...register("firstname", { required: true })} />
                                        <label htmlFor="firstname">Имя</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input autoComplete="off" type="text" className="form-control" id="lastname" placeholder="Фамилия" {...register("lastname", { required: true })} />
                                        <label htmlFor="lastname">Фамилия</label>
                                    </div>
                                    <FloatingLabel controlId="floatingSelect" label="Роль" {...register("roleId", {})}>
                                        <Form.Select value={role} onChange={(e) => {
                                            setValue("roleId", e.target.value)
                                            setRole(e.target.value)
                                        }} aria-label="Floating label select example">
                                            {dataRole.map(role =>
                                                <option key={role.id} value={role.id}>{role.name}</option>
                                            )}
                                        </Form.Select>
                                    </FloatingLabel>
                                    <div className="form-check form-switch mt-1">
                                        <input className="form-check-input" onClick={() => { setStatus(!status) }} checked={status} type="checkbox" id="status" {...register("status", {})} />
                                        <label className="form-check-label" htmlFor="rememberMe">Активен</label>
                                    </div>
                                </>
                            }
                        </> :
                        <LoadScreenMin height="30" />
                    }

                </Modal.Body>

                <Modal.Footer>
                    <ButtonGroup size="sm">
                        <Button variant="primary" onClick={() => {
                            reset()
                            setShowEditForm(!showEditForm)
                        }
                        }>Отменить</Button>
                        <Button type="submit" variant="success">
                            Сохранить
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </form>

        </Modal>
    )
}
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"


export const Corporate = ({ selLanguage }) => {

    const navigete = useNavigate()

    return (
        <>

            <>
                <h3>
                    <b>Корпоративным клиентам:</b>
                </h3>
                <p>Подарочные сертификаты и билеты «Ленинград Центра» могут приобретать юридические и физические лица.</p>
                <p>Более подробную информацию вы можете узнать по телефону: 8 (812) 242 99 99 или заполнив форму обратной связи, наш менеджер свяжется с вами в течение 48 часов.</p>
                <Button className="btn-lc mb-3" onClick={() => { navigete("/about-project/requests") }}>Форма обращения</Button>
                <p>
                    <b>
                        Организация мероприятий:
                    </b>
                </p>
                <p>
                    «Ленинград Центр» предоставляет возможность реализации самых разнообразных проектов. На базе высокотехнологичного шоу-пространства с удобным зонированием можно удовлетворить запросы самой взыскательной публики.
                </p>
                <p>
                    Наш опыт позволяет проводить мероприятия разного уровня сложности и масштаба – от корпоративных праздников и бизнес-тренингов до конференций и торжественных премий – этим список не ограничивается, ведь в «Ленинград Центре» работает креативная команда, которая может воплотить самые смелые идеи в жизнь.
                </p>
                <p>
                    <b>ОСНОВНАЯ СЦЕНА</b>
                </p>
                <p>
                    Вместимость — 400 человек: мягкая мебель в партере и стулья на балконе. Возможность изменения пространства под банкетную рассадку, рассчитанную максимально на 170 мест.
                </p>
                <p>
                    Пространство зрительного зала решено в классическом театральном стиле: партер, два яруса и ложи. Архитектурная акустика зала, эргономика зрительских мест вкупе с современным техническим оснащением позволяют назвать «Ленинград Центр» одной из самых технологичных сценических площадок страны.
                </p>
                <p>
                    <b>ГАЛЕРЕЯ</b>
                </p>
                <p>
                    Основной зал и два мини-зала (лекционная посадка на 60 человек в каждом зале) в боковых крыльях пространства оснащены специализированным галерейным светом и мультимедийным оборудованием. Современное технологическое оснащение галереи позволяет принимать и организовывать трансмедийные и кросс-культурные проекты различного уровня сложности.
                </p>
                <p>
                    Организация пространства возможна в форматах:
                </p>
                <ul>
                    <li>Фуршет</li>
                    <li>Конференция (лекция, семинар)</li>
                    <li>Вернисаж</li>
                    <li>Авторская конфигурация заказчика</li>
                </ul>

                <p>
                    <b>МАЛАЯ СЦЕНА</b>
                </p>
                <p>
                    Малая сцена «Ленинград Центра» предназначена для акустических концертов, малых театральных форм и экспериментальных форматов. В центре зала расположена большая сцена, оснащенная современным оборудованием и тремя проекционными экранами.
                </p>
                <p>
                    Организация пространства возможна в форматах:
                    <ul>
                        <li>Фуршет</li>
                        <li>Ужин</li>
                        <li>Конференция (лекция, семинар)</li>
                        <li>Концерт</li>
                        <li>Театрализованное представление</li>
                        <li>Авторская конфигурация заказчика</li>
                    </ul>
                </p>

                <p>Малая сцена может принять мероприятие до 50 персон.</p>
                <p>
                    По всем интересующим вопросам вы можете обратиться по телефону
                </p>
                <p>
                    8 (812) 242 9999 или заполнив форму обратной связи:
                </p>
                <Button className="btn-lc mb-3" onClick={() => { navigete("/about-project/requests") }}>Форма обращения</Button>
            </>
        </>
    )
}
import { useEffect, useState } from "react"
import ContentLoader from "react-content-loader";


export const ImageLoader = ({ src, name, mHeight, wide, viewBoxCustom }) => {

    const [imageLoading, setImageLoading] = useState(true);
    const [boxWide, setBoxWide] = useState()

    useEffect(() => {
        if (viewBoxCustom) {
            setBoxWide(viewBoxCustom)
        }
        else {
            if (wide) {
                setBoxWide("0 0 1920 1080")
            }
            else {
                setBoxWide("0 0 1080 1620")
            }
        }
    }, [])

    useEffect(() => {

        const img = new Image();
        img.onload = () => {
            setTimeout(() => {
                setImageLoading(false)
            })
        }
        img.src = src;
        img.onerror = () => {
            console.log("image not load")
        }
    }, [src])

    return (
        <>
            {imageLoading &&
                <ContentLoader style={{ maxHeight: mHeight }}
                    className={'img-fluid'}
                    backgroundColor="#dedede"
                    foregroundColor="#ecebeb"
                    speed={0.5}
                    viewBox={boxWide}
                >
                    {/* Only SVG shapes */}
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height='100%' />
                </ContentLoader >
            }
            {
                imageLoading === false &&
                <img src={src} style={{ maxHeight: mHeight }} alt={name} className="img-fluid" />
                // <Image src={src} style={{ maxHeight: '65vh' }} alt={name} fluid />
            }

        </>
    )
} 
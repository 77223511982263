import { Button, ButtonGroup, Col, Form, FormGroup, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { LoadScreenMin } from "../../../UI/LoadScreenMin";
import { useEffect } from "react";
import { usePostAddSeatMutation } from "../../../../features/CallApi";
import { AddSeatForm } from "./AddSeatForm";


export const AddSeats = ({ addSeatsShow, setAddSeatsShow, rowId, refetch, schemeHallId }) => {
    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();

    const [postAddSeat, { isLoading }] = usePostAddSeatMutation()

    // const isLoading = false;
    useEffect(() => {
        setValue("schemeHallId", schemeHallId)
        setValue("sectionSeatsId", rowId)
        setValue("Status", "free")
    }, [])

    const submit = (data) => {
        data.X = parseInt(data.X)
        data.Y = parseInt(data.Y)
        // console.log(data)
        postAddSeat(data).then(() => {
            refetch()
            setAddSeatsShow(false)
        })
    }

    return (
        <Modal show={addSeatsShow} onHide={() => { setAddSeatsShow(false) }}>
            <Modal.Header closeButton>Добавление мест</Modal.Header>
            {!isLoading ?
                <AddSeatForm register={register} handleSubmit={handleSubmit} submit={submit} setAddSeatsShow={setAddSeatsShow} />
                :
                <LoadScreenMin height={30} />}
        </Modal>
    )
}
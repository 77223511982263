import { Button, Card, Col, Container, Modal, Ratio, Row } from "react-bootstrap"
import "./Show.css"
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetShowByIdQuery, useGetShowGalleryDetailsQuery } from "../../features/CallApi";
import { LoadScreenMin } from "../UI/LoadScreenMin";
import parse from 'html-react-parser';
import PhotoAlbum from 'react-photo-album';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { Helmet } from "react-helmet";
import { ShowSchedulesItem } from "../UI/ShowSchedulesItem";



const HaedLayout = (props) => {
    return (<div className="haed-layout">
        <div className="head-layout-img" style={{
            backgroundImage: props.data.headLayoutImageType ? `url(api/shows/head-wide-img/${props.data.id})` : "url(/Show/091A8332.jpg"
        }}>
        </div>
        <div className="head-mask">
        </div>
    </div>);
}



const DataShow = (props) => {
    return (
        <>
            <h3><strong>{props.data.name}</strong></h3>
            <p>
                <strong>
                    {props.data.shortDescription}
                </strong>
            </p>
            <p>
                {props.t("show.director")} — <strong>
                    {props.data.director}
                </strong>
            </p>
            <p>
                {props.t("show.duration")}: {props.data.duration}
            </p>
            <p>
                {props.t("show.age")} <strong>{props.data.ageCategory}+</strong>
            </p>

            <div className="mt-3 mb-3 d-none d-lg-block">
                <div>
                    <div className='d-grid gap-1' size='sm'>
                        {props.data?.showSchedules?.length > 0 &&
                            <>
                                <div>
                                    <span className="text-muted">{props.t("afisha.selectdate")}</span>
                                </div>
                                <Button className='border btn-afisha w-100' style={{ borderColor: "#272929" }} onClick={() => {
                                    props.setShowModel(true)
                                }} variant='light'>{props.t("afisha.buytickets")}</Button>
                            </>
                        }
                        {props.data?.showSchedules == null &&
                            <>
                                {props.data?.buyButtonStatus ?
                                    <>
                                        <div>
                                            <span className="text-muted">{props.data.quote}</span>
                                        </div>
                                        <Button className='border shadow btn-afisha' style={{ borderColor: "#272929" }} onClick={() => {
                                            window.open(props.data.buyButtonLink, '_blank').focus();
                                        }} variant='light'>{props.data.buyButtonText}</Button>
                                    </>
                                    :
                                    <>
                                        <span className="text-muted">{props.t("afisha.selectdate")}</span>
                                        <Button className='border btn-afisha w-100' variant='light'>{props.t("afisha.subscribe")}</Button>
                                    </>
                                }

                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}


export const Show = () => {

    const { seoUrl } = useParams("seoUrl")
    const [t] = useTranslation("global");
    const [selLng, setSelLng] = useState('')
    const navigate = useNavigate()
    let [searchParams, SetSearchParams] = useSearchParams();

    useEffect(() => {
        if (localStorage.getItem("selLng") !== null) {
            setSelLng(localStorage.getItem("selLng"))
        }
    }, [t])

    const { data, isLoading, status } = useGetShowByIdQuery({ seoUrl, selLng });
    const { data: photosData, refetch } = useGetShowGalleryDetailsQuery(seoUrl)

    const [showModel, setShowModel] = useState(false)
    const [dateList, setDateList] = useState()
    const [index, setIndex] = useState(-1);
    const [photos, setPhotos] = useState()

    const loaction = useLocation()
    const [wide, setWide] = useState();
    const [wideHead, setWideHead] = useState();

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setWide(false)
        }
        if (window.innerWidth > 768) {
            setWide(true)
        }
        if (window.innerWidth <= 1160) {
            setWideHead(false)
        }
        if (window.innerWidth > 1160) {
            setWideHead(true)
        }
        refetch()
    }, [])

    useEffect(() => {
        if (photosData?.length > 0 && data != null) {
            const links = [];
            (photosData).forEach(item => {
                links.push({ src: `/api/shows/gallery-photo/${item.id}`, width: item.photoWidth, height: item.photoHeight, alt: data.name })
            });
            setPhotos(links)
        }
        // setDataShow(data)
        // console.log((data?.linkVideo !== null && data?.linkVideo !== ""))
    }, [photosData, data])

    useEffect(() => {
        const resizeHandeler = (e) => {
            if (e.target.window.innerWidth <= 768) {
                setWide(false)
            }
            if (e.target.window.innerWidth > 768) {
                setWide(true)
            }
            if (e.target.window.innerWidth <= 1160) {
                setWideHead(false)
            }
            if (e.target.window.innerWidth > 1160) {
                setWideHead(true)
            }
            // console.log(e.target.window.innerWidth)
            // console.log(e.target.window.innerHeight)
        }

        window.addEventListener('resize', resizeHandeler)
        return function () {
            window.addEventListener('resize', resizeHandeler)
        }
    }, [loaction])

    useEffect(() => {
        if (status === "rejected") {
            navigate("/notfonund")
        }
    }, [status])

    useEffect(() => {
        if (data) {
            if (searchParams.get('showModal') === 'true') {
                setShowModel(true)
                setDateList(data.showSchedules)
            }
        }
    }, [data])

    const setParam = (seoUrl) => {
        console.log("showModal")
        console.log(seoUrl)
        searchParams.set("showModal", 'true')
        searchParams.append("selectShow", seoUrl)
        SetSearchParams(searchParams)
    }

    const hideModal = () => {
        console.log("hiddenModal")
        setShowModel(false)
        setDateList(null)
        searchParams.delete("selectShow")
        searchParams.delete("showModal")
        SetSearchParams(searchParams)
    }


    return (
        <>
            {showModel &&
                <Modal
                    show={showModel}
                    size="md"
                    fullscreen={"sm-down"}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    // backdrop="static"
                    onHide={() => {
                        hideModal()
                    }}
                    onShow={() => {
                        setParam(data.id)
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {t("afisha.selectdate")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {data?.showSchedules &&
                            <>
                                {data.showSchedules.map(item =>
                                    <ShowSchedulesItem key={item.id} item={item} />
                                )}
                            </>
                        }
                    </Modal.Body>
                    {/* <Modal.Footer>
                    <Button onClick={() => setShowModel(false)}>{t("general.close")}</Button>
                    
                </Modal.Footer> */}
                </Modal>
            }
            {!isLoading ? <>
                {data && <>
                    <Helmet>
                        <title>{data.name} - {t("mainTag.title")}</title>
                        <meta property="og:url" content={`https://leningradcenter.ru/about-project/show/${data.seoUrl}`} />
                        <meta property="og:type" content="theatre" />
                        <meta property="og:title" content='Шоу-пространство "Ленинград Центр"' />
                        <meta property="og:description" content='Первое в России шоу-пространство "Ленинград Центр"' />
                        <meta property="og:image" content={`https://leningradcenter.ru/api/shows/head-img/${data.id}`} />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta property="twitter:domain" content="leningradcenter.ru" />
                        <meta property="twitter:url" content={`https://leningradcenter.ru/about-project/show/${data.seoUrl}`} />
                        <meta name="twitter:title" content='Шоу-пространство "Ленинград Центр"' />
                        <meta name="twitter:description" content='Первое в России шоу-пространство "Ленинград Центр"' />
                        <meta name="twitter:image" content={`https://leningradcenter.ru/api/shows/head-img/${data.id}`} />
                    </Helmet>
                    <HaedLayout data={data}></HaedLayout>
                    <Container className="pt-3">
                        <Row className="justify-content-center">
                            {data?.linkVideo ?
                                <>
                                    {wide === true &&
                                        <Col lg={7}>
                                            <Ratio aspectRatio="16x9" className="mb-3">
                                                <iframe className="embed-responsive-item" src={data.linkVideo} allowFullScreen={true}>
                                                </iframe>
                                            </Ratio>
                                        </Col>
                                    }
                                </>
                                :
                                // <Col lg={5} className="text-center d-none d-lg-block">
                                //     <img src={`/api/shows/head-img/${id}`} alt={`Афиша ${data.name}`} style={{ maxHeight: '55vh' }} className="img-fluid" />
                                // </Col>
                                <Col lg={6}>
                                    {photosData &&
                                        <div className="mb-3">
                                            <PhotoAlbum columns={3} layout="masonry" photos={photos} onClick={({ index }) => setIndex(index)} />
                                            <Lightbox
                                                slides={photos}
                                                open={index >= 0}
                                                index={index}
                                                close={() => setIndex(-1)}
                                                // enable optional lightbox plugins
                                                plugins={[Slideshow, Thumbnails, Zoom, Captions]}
                                            />
                                        </div>
                                    }
                                </Col>
                            }
                            <Col lg={data?.linkVideo ? 5 : 6}>
                                {(data?.linkVideo !== null && data?.linkVideo !== "") ?
                                    <div className="mb-3">
                                        <PhotoAlbum columns={3} layout="masonry" photos={photos} onClick={({ index }) => setIndex(index)} />
                                        <Lightbox
                                            slides={photos}
                                            open={index >= 0}
                                            index={index}
                                            close={() => setIndex(-1)}
                                            // enable optional lightbox plugins
                                            plugins={[Slideshow, Thumbnails, Zoom, Captions]}
                                        />
                                    </div> :
                                    <DataShow t={t} data={data} setShowModel={setShowModel} />
                                }
                            </Col>

                        </Row>
                        {/* <Row className="justify-content-around">
                            <Col lg={"auto"} className="text-center">

                            </Col>
                            <Col lg={6}>

                            </Col>
                        </Row> */}
                        {data?.linkVideo && <>
                            {
                                wide === false && <>
                                    <Ratio aspectRatio="16x9 mb-3">
                                        <iframe className="embed-responsive-item" src={data.linkVideo} allowFullScreen={true}>
                                        </iframe>
                                    </Ratio>
                                </>
                            }
                        </>
                        }
                        <div className="mt-3">
                            {(data?.linkVideo !== null && data?.linkVideo !== "") ?
                                <>
                                    <Row>
                                        {!wide &&
                                            <Col lg={5}>
                                                <DataShow t={t} data={data} setShowModel={setShowModel} />
                                            </Col>
                                        }
                                        <Col>
                                            {data.description && parse(data.description)}
                                            <p>
                                                <i>{t("show.afterStart")}</i>
                                            </p>
                                        </Col>
                                        {wide &&
                                            <Col lg={5}>
                                                <DataShow t={t} data={data} setShowModel={setShowModel} />
                                            </Col>
                                        }
                                    </Row>
                                </>
                                :
                                <>
                                    {data.description && parse(data.description)}
                                    <p>
                                        <i>{t("show.afterStart")}</i>
                                    </p>
                                </>
                            }
                        </div>
                    </Container>
                    {/* {navbarRef && */}
                    <div className="position-fixed p-2 w-100 bg-white d-lg-none text-center"
                        style={{ bottom: 64, zIndex: 1000 }}
                    >
                        {data?.showSchedules?.length > 0 &&
                            <>
                                <span className="text-muted">{t("afisha.selectdate")}</span>
                                <Button className='border btn-afisha w-100' style={{ borderColor: "#272929" }} onClick={() => {
                                    setShowModel(true)
                                }} variant='light'>{t("afisha.buytickets")}</Button>
                            </>
                        }
                        {data?.showSchedules == null &&
                            <>
                                {data?.buyButtonStatus ?
                                    <>
                                        <div>
                                            <span className="text-muted">{data.quote}</span>
                                        </div>
                                        <Button className='border shadow btn-afisha' style={{ borderColor: "#272929" }} onClick={() => {
                                            window.open(data.buyButtonLink, '_blank').focus();
                                        }} variant='light'>{data.buyButtonText}</Button>
                                    </>
                                    :
                                    <>
                                        <span className="text-muted">{t("afisha.selectdate")}</span>
                                        <Button className='border btn-afisha w-100' variant='light'>{t("afisha.subscribe")}</Button>
                                    </>
                                }
                            </>
                        }
                    </div>
                    {/* } */}
                </>
                }
            </> : <LoadScreenMin />}
        </>
    )
}
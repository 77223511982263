import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom"
import { useGetSessionDetailsQuery } from "../../features/CallApi";
import { LoadScreenMin } from "../UI/LoadScreenMin";
import { useTranslation } from "react-i18next";
import { XCircle } from "react-bootstrap-icons";

export const Nikalas = ({ selLanguage }) => {

    const { id } = useParams()
    const [updated, setUpdated] = useState(false)
    const { data, isLoading } = useGetSessionDetailsQuery({ id, selLng: selLanguage })
    const [t] = useTranslation("global");
    const [reloadSeances, setReloadSeances] = useState(false)
    const navigate = useNavigate()


    useEffect(() => {
        if (data) {
            // setDateList(data.showSchedules)
            {addJN()}
            setUpdated(true)
        }
        if (updated) {
            setReloadSeances(true)
            setTimeout(() => {
                setReloadSeances(false)
            }, 100)
        }
    }, [data])

    const addJN = () => {

        const script = document.createElement("script");
        script.async = true;
        //https://buy.leningradcenter.etobilet.ru
        script.src = "https://buy.leningradcenter.ru/js/loadnikolas.js";
        setTimeout(() => {
            document.head.appendChild(script);
            // setStatusLoading(false)
        }, 100)
    }

    return (
        <>
            {!isLoading ? <>
                {data && <>
                    <div className="auth-nikolas" style={{ position: "absolute", }}></div>
                    <div className="haed-layout-tickets">
                        <div className="head-layout-img" style={{ backgroundImage: data.headLayoutImageType ? `url(api/shows/head-wide-img/${data.showId})` : "url(/Show/091A8332.jpg" }}>
                        </div>
                        <div className="head-mask"></div>

                    </div>
                    <Container fluid className="lc-gutter">
                        <div className="d-flex d-lg-none">
                            <h5 className="flex-fill">
                            </h5>
                            <div>
                                <div>
                                    <Button
                                        onClick={() => { navigate(`/about-project/show/${data.seoUrl}`) }}
                                        variant="light"
                                        size=""
                                        className="mt-2"
                                        style={{ inlineSize: 17, borderRadius: '100%', padding: 0, width: 24, height: 24 }}>
                                        <XCircle size={15} style={{ borderRadius: '100%' }} /></Button>
                                </div>
                            </div>
                        </div>
                        <div className="seances loading" data-id={id}>
                        </div>
                    </Container>
                </>
                }
            </> : <LoadScreenMin height={50} />}
        </>
    )
}


export const Restaurant = () => {
    return (
        <>
            <div>
                <h1>Ресторан «Блок»</h1>
                <p>На крыше «Ленинград Центра» расположился ресторан Александра Раппопорта «Блок» с панорамным видом на Таврический сад. Концепция ресторана — с одной стороны, современная российская кухня, с другой — высочайшего качества российское мясо, которое по многим параметрам превосходит все существующие зарубежные аналоги. Атмосферу в ресторане задает интерьер в стиле северного модерна и ленинградского авангардизма — светлое, многоуровневое и торжественное пространство, залитое рекой света.
                </p>
                <p><strong>Телефон: </strong><span>8 (812) 415-40-40</span></p>
                <p><a href="http://blok.restaurant/" target="_blank">http://blok.restaurant/</a></p>
                <p className="text-muted" style={{fontSize: "8px"}}>erid: 2SDnjeZXbsQ</p>
            </div>
        </>
    )
}
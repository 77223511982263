import classes from './Certificate.module.css';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useCreateCertificateOrderMutation } from '../../features/CallAppApi';
import { LoadScreenMin } from '../UI/LoadScreenMin';


export const CertForm = ({ setDataOrder, setCertToken, t }) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [createCertificateOrder, { isLoading: isLoading }] = useCreateCertificateOrderMutation();
    const [amount, setAmount] = useState();
    const [amountError, setAmountError] = useState(null);
    const [postError, setPostError] = useState();

    const submitForm = async (data) => {
        console.log(amount)
        if (!amount) {
            setAmountError('Выберите номинал')
        }
        else {
            const body = {
                amount: amount,
                firstName: data.firstName,
                lastName: data.lastName,
                patronymic: data.patronymic,
                email: data.email,
                phone: data.phone
            }
            const jsonBody = JSON.stringify(body)
            console.log(jsonBody)
            createCertificateOrder(body).unwrap().catch((e) => {
                console.log(e)
                setPostError(e)
            }).then((response) => {
                reset()
                console.log(response)
                sessionStorage.setItem("certToken", response.token)
                sessionStorage.setItem("dataOrder", JSON.stringify(response))
                setCertToken(response.token)
                setDataOrder(response)
            })
        }
    }
    useEffect(() => {
        if (amount) {
            setAmountError(null)
        }
    }, [amount])

    const amountData = [
        {
            id: 1,
            defaultValue: 8000,
            text: '8 000'
        },
        {
            id: 2,
            defaultValue: 10000,
            text: '10 000'
        },
        {
            id: 3,
            defaultValue: 12000,
            text: '12 000'
        },
        {
            id: 4,
            defaultValue: 14000,
            text: '14 000'
        },
        {
            id: 5,
            defaultValue: 16000,
            text: '16 000'
        }
    ]

    return (
        <div>
            {isLoading ? <LoadScreenMin /> :
                <form onSubmit={handleSubmit(submitForm)} className='mb-5 m-auto' style={{ maxWidth: "976px" }}>
                    <div className="justify-content-center d-md-flex d-lg-flex d-xl-flex">
                        <div className="p-3">
                            <h6 className="h-cert">{t("form.selValueCert")}</h6>
                            {amountError && <span className='text-danger'>{amountError}</span>}
                            <div>
                                <Row>
                                    {amountData.map(amount =>
                                        <Col key={amount.id}>
                                            <div>
                                                <div id={`CardBox${amount.id}`} className={classes.lcCartSelectBox}>
                                                    <div className={classes.lcCartSelectBoxContent}>
                                                        <input type="radio" id={`box${amount.id}`} name="Amount" defaultValue={amount.defaultValue} onClick={(e) => {
                                                            setAmount(e.target.value)
                                                        }} />
                                                        <label className={`${classes.lcCardImg} ${classes.lcCartSelectLabel}`} htmlFor={`box${amount.id}`}>{amount.text} ₽</label>
                                                        <div className="check"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </div>
                        <div className='p-3'>
                            <h4 className="h-cert" style={{ fontSize: "2rem" }}><b>{t("form.cetFormTitle")}</b></h4>
                            <div className="mb-1 d-flex">
                                <div className="form-group flex-fill m-1">
                                    <label htmlFor="firstName" className="form-label">{t("form.firstName")}</label>
                                    <input type="text" className="form-control" placeholder={t("form.firstName")} {...register("firstName",
                                        { required: { value: true, message: "Поле \"Имя\" не может быть пустым" } })} />
                                    {errors.firstName && <span className='text-danger'>{errors.firstName?.message}</span>}
                                </div>
                                <div className="form-group flex-fill m-1">
                                    <label htmlFor="lastName" className="form-label">{t("form.lastName")}</label>
                                    <input type="text" placeholder={t("form.lastName")} className="form-control"
                                        {...register("lastName", { required: { value: true, message: "Поле \"Фамилия\" не может быть пустым" } })} />
                                    {errors.lastName && <span className='text-danger'>{errors.lastName?.message}</span>}
                                </div>

                            </div>
                            <div className="mb-1 d-flex">
                                <div className="form-group flex-fill m-1">
                                    <label htmlFor="patronymic" className="form-label">{t("form.patronymic")}</label>
                                    <input type="text" className="form-control" placeholder={t("form.patronymic")} {...register("patronymic", {})} />
                                </div>
                            </div>
                            <div className="mb-3 d-flex">
                                <div className="form-group flex-fill m-1">
                                    <label className="form-label">{t("form.phone")}</label>
                                    <input type="tel" className="form-control" placeholder="+79999999999" {...register("phone",
                                        { required: { value: true, message: "Поле \"Телефон\" не может быть пустым" } })} />
                                    {errors.phone && <span className='text-danger'>{errors.phone?.message}</span>}
                                </div>
                                <div className="form-group flex-fill m-1">
                                    <label className="form-label">E-Mail</label>
                                    <input type="email" className="form-control" placeholder="Email" {...register("email", {
                                        required: { value: true, message: "Поле \"E-Mail\" не может быть пустым" },
                                        pattern: { value: /^\S+@\S+$/i, message: "Некорректно указан E-Mail" },
                                        // maxLength: {value: 100}
                                    })}
                                    />
                                    {errors.email && <span className='text-danger'>{errors.email?.message}</span>}
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className=" form-check form-switch">
                                    <label className="form-check-label"><a href='https://leningradcenter.ru/api/redirecturls/privacy-policy' target={"_blank"} >{t("form.agreement")}</a></label>
                                    <input type="checkbox" className="form-check-input check-input-lc" {...register("policy", { required: { value: true, message: "Необходимо согласие на обработку персональных данных" } })} />
                                    <br />
                                    {errors.policy && <span className='text-danger'>{errors.policy?.message}</span>}
                                </div>

                            </div>
                            <div className="mb-2 d-flex">
                                <div className=" form-check form-switch">
                                    <label className="form-check-label"><a href='https://leningradcenter.ru/api/redirecturls/public-offer-certificates' target={"_blank"} >{t("form.giftRules")}</a></label>
                                    <input type="checkbox" className="form-check-input check-input-lc" {...register("pravilo", { required: { value: true, message: "Необходимо согласие c правилами обращения подарочных сертификатов" } })} />
                                    <br />
                                    {errors.pravilo && <span className='text-danger'>{errors.pravilo?.message}</span>}
                                </div>
                            </div>
                            <div className="mb-2 d-flex">
                                <div className=" form-check form-switch">
                                    <label className="form-check-label ">{t("form.receiveNewsletter")}</label>
                                    <input type="checkbox" className="form-check-input check-input-lc" {...register("receiveNewsletter", {})} />
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-row-reverse'>
                        <input type={'submit'} value={t("form.confirm")} className='btn btn-lc btn-lc-cert w-auto' />
                    </div>
                </form>
            }
        </div>
    )
}
import { Button, ButtonGroup, Col, Container, Row, Spinner } from "react-bootstrap"
import { useGetclientDataQuery } from "../../features/CallAppApi"
import { useTranslation } from "react-i18next";
import { LoadScreenMin } from "../UI/LoadScreenMin";
import { format } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const DownloadTicket = ({ orderId, payService }) => {
    // const [getDownloadTickets, { isLoading }] = useGetDownloadTicketsMutation()
    const [isLoading, setIsLoading] = useState(false)
    const downloadTicket = () => {
        setIsLoading(true)
        const clientData = JSON.parse(sessionStorage.getItem('clientToken'))
        fetch(`https://app.leningradcenter.ru/api/clients/download-tickets/${orderId}?payService=${payService}`, {
            headers: { Authorization: `Bearer ${clientData.Token}` }
        })
            .then(res => res.blob())
            .then(blob => {
                setIsLoading(false)
                var file = window.URL.createObjectURL(blob);
                let tab = window.open();
                return tab.location.href = file;
            })
            .catch(() => {
                setIsLoading(false)
                window.alert("Error")
            })

    }

    return (
        <>
            {!isLoading ? <Button className="btn-lc" onClick={() => {
                console.log(orderId);
                downloadTicket()
            }} disabled={isLoading}>Билеты</Button> : <Spinner />}
        </>
    );
}


export const ClientTicketsOrders = () => {
    const navigate = useNavigate()
    const { data, isLoading, error, refetch } = useGetclientDataQuery()
    useEffect(() => {
        console.log(error)
        if (error) {
            return navigate("/client/profile")
        }
    }, [isLoading])
    const { t } = useTranslation("global");


    const goToPay = (data) => {
        const clientData = JSON.parse(sessionStorage.getItem('clientToken'))

        axios.get(`https://app.leningradcenter.ru/api/ticketsorders/go-to-pay/${data.id}`
            , {
                headers: { Authorization: `Bearer ${clientData.Token}` }
            }).then((r) => {
                console.log(r)
                const bankUrl = r.data.bankUrl;
                window.location.href = bankUrl;
            }).catch((e) => {
                console.log(e)
            })
    }

    const cancelOrder = (data) => {
        const clientData = JSON.parse(sessionStorage.getItem('clientToken'))

        axios.get(`https://app.leningradcenter.ru/api/ticketsorders/cancel-order/${data.id}`
            , {
                headers: { Authorization: `Bearer ${clientData.Token}` }
            }).then((r) => {
                refetch()
            })
    }

    return (
        <>
            <div className="about-haed-layout mb-3">
                <div className="about-head-layout-img" style={{ backgroundImage: `url("/AboutProjcet/about-project.jpg")` }}>
                </div>
                <div className="about-head-mask">
                    <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3"
                        style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                    </div>
                </div>
            </div>
            <Container>
                {!isLoading ?
                    <Row className="justify-content-center">
                        <Col md={6} lg={4}>
                            <h4>{t("form.orders")}</h4>
                            {data?.ordersTickets.length > 0 ?
                                <>
                                    {data.ordersTickets.map(item =>
                                        <Fragment key={item.orderId}>
                                            <div className="border-bottom g-1 p-3 d-flex" >
                                                <div className="me-3 flex-fill">
                                                    <div className="me-1"> Заказ <span>#</span> <b>{item.orderId}</b></div>
                                                    <div>
                                                        {item.eventName}
                                                    </div>
                                                    <div>
                                                        <div>
                                                            Дата: <b>{format(new Date(item.eventDate), "dd.MM.yy")}</b>
                                                        </div>
                                                        <div>
                                                            Начало: <b>{format(new Date(item.eventDate), "HH:mm")}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                {item.status === 2 &&
                                                    <div className="me-0 ms-auto" style={{ display: 'grid', alignItems: "center" }}>
                                                        <DownloadTicket orderId={item.orderId} payService={item.payService} />
                                                    </div>
                                                }
                                                {item.payService === "Nikalas" &&
                                                    <div className="me-0 ms-auto" style={{ display: 'grid', alignItems: "center" }}>
                                                        <DownloadTicket orderId={item.orderId} payService={item.payService} />
                                                    </div>
                                                }
                                            </div>
                                            {item.status === 1 &&
                                                <div className="me-0 ms-auto g-1 border-bottom" style={{ display: 'grid', alignItems: "center" }}>
                                                    <b className="text-center">
                                                        Заказ ожидающие оплату
                                                    </b>
                                                    <ButtonGroup className="pb-3 pt-1">
                                                        <Button className="btn-lc" onClick={() => { goToPay(item) }}>Оплатить</Button>
                                                        <Button variant="light" onClick={() => { if (window.confirm('Вы уверены что хотите отменить заказ?')) { cancelOrder(item) } }}>Отменить заказ</Button>
                                                    </ButtonGroup>
                                                </div>
                                            }
                                        </Fragment>
                                    )}
                                </>
                                :
                                <div className="text-center">Список заказов пуст</div>}
                        </Col>
                    </Row>
                    :
                    <>
                        <LoadScreenMin height={50} />
                    </>
                }
            </Container>
        </>
    )
}
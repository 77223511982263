import { useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateCorporateRequestMutation, useCreateRentRequestMutation, useGetShowsQuery } from "../../features/CallAppApi";
import { Button, ButtonGroup, Card, Container } from "react-bootstrap";
import { LoadScreenMin } from "../UI/LoadScreenMin";
import { Check2All, XCircle } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

const CorporateForm = ({ register, errors, setValue, clearErrors }) => {

    const { data } = useGetShowsQuery()
    const [selectedShow, setSelectedShow] = useState()
    return (
        <div>
            <div className="row g-2 mb-2">
                <div className="col-md">
                    <div className="form-floating ">
                        <input type="text"
                            {...register("lastName", { required: { value: true, message: "Укажите фамилию" } })}
                            className={`form-control ${errors.lastName && "is-invalid"}`}
                            id="lastName" placeholder="Фамилия" />
                        <label htmlFor="lastName">Фамилия<span className="text-danger">*</span></label>
                    </div>
                    {errors.lastName && <span className='text-danger'>{errors.lastName?.message}</span>}
                </div>
                <div className="col-md">
                    <div className="form-floating">
                        <input type="text"
                            {...register("firstName", { required: { value: true, message: "Укажите имя" } })}
                            className={`form-control ${errors.firstName && "is-invalid"}`}
                            id="firstName" placeholder="Имя" />
                        <label htmlFor="firstName">Имя<span className="text-danger">*</span></label>
                    </div>
                    {errors.firstName && <span className='text-danger'>{errors.firstName?.message}</span>}
                </div>
                <div className="col-md">
                    <div className="form-floating">
                        <input type="text"
                            {...register("patronymic")}
                            className="form-control" id="patronymic" placeholder="Отчество" />
                        <label htmlFor="patronymic">Отчество</label>
                    </div>
                </div>
            </div>
            <div className="row g-2 mb-2">
                <div className="col-md">
                    <div className="form-floating">
                        <input type="tel"
                            {...register("phone", { required: { value: true, message: "Укажите телефон" } })}
                            className={`form-control ${errors.phone && "is-invalid"}`} id="phone" placeholder="Телефон" />
                        <label htmlFor="phone">Телефон<span className="text-danger">*</span></label>
                    </div>
                    {errors.phone && <span className='text-danger'>{errors.phone?.message}</span>}

                </div>
                <div className="col-md">
                    <div className="form-floating">
                        <input type="email" {...register("email", { required: { value: true, message: "Укажите телефон" } })}
                            className={`form-control ${errors.email && "is-invalid"}`} id="email" placeholder="Email" />
                        <label htmlFor="email">Email<span className="text-danger">*</span></label>
                    </div>
                    {errors.email && <span className='text-danger'>{errors.email?.message}</span>}
                </div>
            </div>
            <div className="row g-2 mb-2">
                <div className="col-md">
                    <div className="form-floating">
                        <input type="text"
                            {...register("companyName", { required: { value: true, message: "Укажите название компании" } })}
                            className={`form-control ${errors.companyName && "is-invalid"}`} id="companyName" placeholder="Компания" />
                        <label htmlFor="companyName">Компания<span className="text-danger">*</span></label>
                    </div>
                    {errors.companyName && <span className='text-danger'>{errors.companyName?.message}</span>}
                </div>
            </div>
            <div className="row g-2 mb-2">
                <div className="col-md">
                    <div className="form-floating">
                        <textarea type="date"
                            {...register("requisites", { required: { value: true, message: "Укажите реквизиты компании" } })}
                            className={`form-control ${errors.requisites && "is-invalid"}`} id="requisites" placeholder="Реквизиты*" style={{ height: "200px" }}></textarea>
                        <label htmlFor="requisites">Реквизиты<span className="text-danger">*</span></label>
                    </div>
                    {errors.requisites && <span className='text-danger'>{errors.requisites?.message}</span>}
                </div>
            </div>
            <div className="row g-2 mb-2">
                <div className="col-md">
                    <div className="form-floating w-100">
                        <select name="service"
                            {...register("show", { required: { value: true, message: "Выберите шоу" } })}
                            onChange={(e) => {
                                setSelectedShow(e.target.value)
                                setValue("show", e.target.value ? e.target.value : "")
                                clearErrors("show")
                            }}
                            id="show" className={`form-select ${errors.show && "is-invalid"}`}>
                            <option hidden value="">-- Выберете шоу --</option>
                            {data?.length > 0 ? data.map(show => <option key={show.id} value={show.id}>{show.name}</option>) : <option disabled>Нет доступных шоу</option>}
                        </select>
                        <label htmlFor="show">Выберете шоу<span className="text-danger">*</span></label>
                    </div>
                    {errors.show && <span className='text-danger'>{errors.show?.message}</span>}
                </div>
                <div className="col-md">
                    <div className="form-floating w-100">
                        <select name="session"
                            {...register("session", { required: { value: true, message: "Выберите дату шоу" } })}
                            onChange={(e) => {
                                console.log(e.target.value)
                                setValue("session", e.target.value ? e.target.value : "")
                                clearErrors("session")
                            }}
                            id="session" className={`form-select ${errors.session && "is-invalid"}`}>
                            <option hidden value="">-- Выберете дату --</option>
                            {selectedShow ?
                                data.filter(show => show.id == selectedShow)[0].showSchedules?.map(
                                    session =>
                                        <option
                                            key={session.id}
                                            value={session.id}>
                                            Дата: {format(new Date(session.startOfEvent), 'dd.MM.yy')}, начало: {format(new Date(session.startOfEvent), 'HH:mm')}</option>
                                )
                                : <option disabled>Нет доступных дат</option>}
                        </select>
                        <label htmlFor="session">Выберете дату<span className="text-danger">*</span></label>
                    </div>
                    {errors.session && <span className='text-danger'>{errors.session?.message}</span>}
                </div>
                <div className="col-md">
                    <div className="form-floating">
                        <input
                            {...register("nop", {
                                required: { value: true, message: "Укажите количество человек" },
                                min: { value: 1, message: "Минимальное количество 1" },
                                // max: { value: 10, message: "Максимальное количество 100" }
                            })}
                            type="number" className={`form-control is-session ${errors.nop && "is-invalid"}`} id="nop" placeholder="Количество человек" />
                        <label htmlFor="nop">Количество человек<span className="text-danger">*</span></label>
                    </div>
                    {errors.nop && <span className='text-danger'>{errors.nop?.message}</span>}
                </div>
            </div>
            <div className="row g-2 mb-2">
                <div className="col-md">
                    <div className="form-floating">
                        <textarea type="date"
                            {...register("comments")}
                            className="form-control" id="comments" placeholder="Описание запроса*" style={{ height: "100px" }}></textarea>
                        <label htmlFor="comments">Описание запроса</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

const RentForm = ({ register, errors, setValue, clearErrors }) => {
    return (
        <div>
            <div className="row g-2 mb-2">
                <div className="col-md">
                    <div className="form-floating ">
                        <input type="text"
                            {...register("lastName", { required: { value: true, message: "Укажите фамилию" } })}
                            className={`form-control ${errors.lastName && "is-invalid"}`}
                            id="lastName" placeholder="Фамилия" />
                        <label htmlFor="lastName">Фамилия<span className="text-danger">*</span></label>
                    </div>
                    {errors.lastName && <span className='text-danger'>{errors.lastName?.message}</span>}
                </div>
                <div className="col-md">
                    <div className="form-floating">
                        <input type="text"
                            {...register("firstName", { required: { value: true, message: "Укажите имя" } })}
                            className={`form-control ${errors.firstName && "is-invalid"}`}
                            id="firstName" placeholder="Имя" />
                        <label htmlFor="firstName">Имя<span className="text-danger">*</span></label>
                    </div>
                    {errors.firstName && <span className='text-danger'>{errors.firstName?.message}</span>}
                </div>
                <div className="col-md">
                    <div className="form-floating">
                        <input type="text"
                            {...register("patronymic")}
                            className="form-control" id="patronymic" placeholder="Отчество" />
                        <label htmlFor="patronymic">Отчество</label>
                    </div>
                </div>
            </div>
            <div className="row g-2 mb-2">
                <div className="col-md">
                    <div className="form-floating">
                        <input type="tel"
                            {...register("phone", { required: { value: true, message: "Укажите телефон" } })}
                            className={`form-control ${errors.phone && "is-invalid"}`} id="phone" placeholder="Телефон" />
                        <label htmlFor="phone">Телефон<span className="text-danger">*</span></label>
                    </div>
                    {errors.phone && <span className='text-danger'>{errors.phone?.message}</span>}

                </div>
                <div className="col-md">
                    <div className="form-floating">
                        <input type="email" {...register("email", { required: { value: true, message: "Укажите телефон" } })}
                            className={`form-control ${errors.email && "is-invalid"}`} id="email" placeholder="Email" />
                        <label htmlFor="email">Email<span className="text-danger">*</span></label>
                    </div>
                    {errors.email && <span className='text-danger'>{errors.email?.message}</span>}
                </div>
            </div>
            <div className="row g-2 mb-2">
                <div className="col-md">
                    <div className="form-floating">
                        <input type="text"
                            {...register("companyName")}
                            className={`form-control ${errors.companyName && "is-invalid"}`} id="companyName" placeholder="Компания" />
                        <label htmlFor="companyName">Компания</label>
                    </div>
                    {errors.companyName && <span className='text-danger'>{errors.companyName?.message}</span>}
                </div>
            </div>
            <div className="row g-2 mb-2">
                <div className="col-md">
                    <div className="form-floating">
                        <input type="date"
                            {...register("eventDate", { required: { value: true, message: "Укажите дату мероприятия" } })}
                            className={`form-control is-session ${errors.eventDate && "is-invalid"}`} id="eventDate" placeholder="Телефон" />
                        <label htmlFor="eventDate">Дата мероприятия<span className="text-danger">*</span></label>
                    </div>
                    {errors.eventDate && <span className='text-danger'>{errors.eventDate?.message}</span>}

                </div>
                <div className="col-md">
                    <div className="form-floating">
                        <input
                            {...register("nop", {
                                required: { value: true, message: "Укажите количество человек" },
                                min: { value: 1, message: "Минимальное количество 1" },
                                // max: { value: 10, message: "Максимальное количество 100" }
                            })}
                            type="number" className={`form-control is-session ${errors.nop && "is-invalid"}`} id="nop" placeholder="Количество человек" />
                        <label htmlFor="nop">Количество человек<span className="text-danger">*</span></label>
                    </div>
                    {errors.nop && <span className='text-danger'>{errors.nop?.message}</span>}
                </div>
            </div>
            <div className="row g-2 mb-2">
                <div className="col-md">
                    <div className="form-floating">
                        <textarea type="date"
                            {...register("comment", { required: { value: true, message: "Опиште Ваш запрос" } })}
                            className={`form-control is-session ${errors.comment && "is-invalid"}`} id="comment" placeholder="Описание запроса*" style={{ height: "100px" }}></textarea>
                        <label htmlFor="comment">Описание запроса<span className="text-danger">*</span></label>
                    </div>
                    {errors.comment && <span className='text-danger'>{errors.comment?.message}</span>}
                </div>
            </div>
        </div>
    )
}

const RequestsCompletion = ({ requestStatus, posting, restForm }) => {
    return (
        <div>
            <div className="hader-requests"></div>
            {!posting ?
                <div>
                    {
                        requestStatus === true &&
                        <div>
                            <div className="offset-sm-2 col-sm-8">
                                <h2 className="text-center mt-5">Запрос успешно отправлен.</h2>
                                <div className="text-center">
                                    <Check2All size={80} className={"text-success"} />
                                </div>
                                <div className="text-center">
                                    <p>
                                        Спасибо за Ваше обращение!
                                        <br />
                                        Ждем вас в Ленинград Центре.
                                    </p>
                                    <button className="btn btn-lc btn-lc-cert mb-3" onClick={() => { restForm() }}>Оформить новое обращение</button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        requestStatus === false &&
                        <div>
                            <div className="offset-sm-2 col-sm-8">
                                <h2 className="text-center mt-5">Возникла ошибка!</h2>
                                <div className="text-center ">
                                    <XCircle size={80} className={"text-danger mt-3 mb-3"} />
                                </div>
                                <div className="text-center">
                                    <p className="text-center">Попробуйте повторно оформиться обращение или свяжитесь с
                                        гостевой службой <br /> по электронной почте: <a href="mailto:reservation@leningradcenter.ru">reservation@leningradcenter.ru</a></p>

                                    <button className="btn btn-lc btn-lc-cert mb-3" onClick={() => { restForm() }}>Оформить новое обращение</button>
                                </div>
                            </div>
                        </div>
                    }
                </div >
                :
                <LoadScreenMin />
            }
        </div>
    )
}

export const Requests = () => {

    const [t] = useTranslation("global")
    const [serviceType, setServiceStype] = useState()
    const [requestStatus, setRequestStatus] = useState(null)
    const [posting, setPosting] = useState(null)
    const { register, handleSubmit, reset, formState: { errors }, setValue, clearErrors } = useForm();
    const [createCorporateRequest, { isLoading: postingCorporateForm }] = useCreateCorporateRequestMutation()
    const [createRentRequest, { isLoading: postingRentForm }] = useCreateRentRequestMutation()

    const [loadingAnim, setLoadiingAnim] = useState(false)
    const selectForm = (typeId) => {
        setLoadiingAnim(true)
        setServiceStype(typeId)
        setTimeout(() => {
            setLoadiingAnim(false)
        }, 1000)
        reset()
        setValue("service", typeId ? typeId : "")
    }

    const sendRequst = (data) => {
        console.log(data)
        setPosting(true)
        setTimeout(() => {
            data.service = parseInt(data.service)
            data.nop = parseInt(data.nop)
            console.log(data)
            if (data.service === 0) {
                createRentRequest(data).unwrap()
                    .then((response) => {
                        setRequestStatus(true)
                        console.log(response)
                        setPosting(postingRentForm)
                    })
                    .catch(() => {
                        setRequestStatus(false)
                        setPosting(postingRentForm)
                    })
            }
            if (data.service === 1) {
                data.session = parseInt(data.session)
                createCorporateRequest(data).unwrap()
                    .then((response) => {
                        setRequestStatus(true)
                        console.log(response)
                        setPosting(postingCorporateForm)
                    })
                    .catch(() => {
                        setRequestStatus(false)
                        setPosting(postingCorporateForm)
                    })
            }
        }, 500)
    }

    const restForm = () => {
        reset()
        setPosting(null)
        setRequestStatus(null)
        setServiceStype(null)
    }


    return (
        <>
            <Container>
                <div>
                    {(posting || requestStatus != null) ?
                        <RequestsCompletion restForm={restForm} posting={posting} requestStatus={requestStatus} /> :
                        <div>
                            <div className="hader-requests"></div>
                            <form onSubmit={handleSubmit(sendRequst)}>
                                <Card className="mt-3">
                                    <Card.Header><h3>Форма обращения</h3></Card.Header>
                                    <Card.Body>
                                        <p><span className="text-danger">*</span> - поля обязательные для заполнения</p>
                                        <div className="row mb-2">
                                            <div className="col-md">
                                                <div className="form-floating w-100">
                                                    <select defaultValue="" name="service"
                                                        {...register("service", { required: { value: true, message: "Выбирете тему обращения" } })}
                                                        onChange={(e) => {
                                                            selectForm(e.target.value)
                                                        }}
                                                        id="service" className={`form-select ${errors.service && "is-invalid"}`}>
                                                        <option hidden value="">-- Выберете тему обращения --</option>
                                                        <option value="0">Организация частных мероприятий</option>
                                                        <option value="1">Продажа билетов юридическим лицам</option>
                                                    </select>
                                                    <label htmlFor="service">Тема запроса<span className="text-danger">*</span></label>
                                                </div>
                                                {errors.service && <span className='text-danger'>{errors.service?.message}</span>}
                                            </div>
                                        </div>
                                        {!loadingAnim ?
                                            <>
                                                {/* {!serviceType ? <p>Выберите тему обращения</p> : <></>} */}
                                                {serviceType === "0" ? <RentForm register={register} errors={errors} setValue={setValue} /> : <></>}
                                                {serviceType === "1" ? <CorporateForm register={register} errors={errors} setValue={setValue} clearErrors={clearErrors} /> : <></>}
                                                <div className="form-check">
                                                    <label className="form-check-label">
                                                        <input type="checkbox" className="form-check-input" {...register("policy",
                                                            { required: { value: true, message: "Необходимо согласие на обработку персональных данных" } })} />
                                                        Согласие на обработку персональных данных <br /> <a href="https://leningradcenter.ru/api/redirecturls/privacy-policy">Политика конфиденциальности</a>
                                                    </label>
                                                    <div>
                                                        {errors.policy && <span className='text-danger'>{errors.policy?.message}</span>}
                                                    </div>
                                                </div>
                                            </>
                                            : <LoadScreenMin height={35} />}
                                    </Card.Body>
                                    <Card.Footer>
                                        <ButtonGroup>
                                            <Button disabled={posting} type="submit" color="success">Отправить</Button>
                                        </ButtonGroup>
                                    </Card.Footer>
                                </Card>
                            </form>
                        </div>
                    }
                </div>
            </Container>
        </>
    )
}
import { useEffect, useState } from "react";
import { Accordion, Button, ButtonGroup, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { Check, Clipboard2, DistributeHorizontal, DistributeVertical, Gear, Link, PlusSquare, TextareaResize, Trash, X } from "react-bootstrap-icons";
import { useDeleteCtxMutation, useDeleteObjectMutation, usePostAddCtxMutation, usePostCopyObjectMutation, usePutEditCtxMutation, usePutEditObjectMutation } from "../../../../features/CallApi";
import { useForm } from "react-hook-form";

const AddCtx = ({ objectId, objectType, refetch }) => {
    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [show, setShow] = useState()
    const [typeSel, setTypeSel] = useState()
    const [postAddCtx, { isLoading }] = usePostAddCtxMutation()
    useEffect(() => {
        setValue("id", objectId)
    }, [show])

    const submit = (data) => {
        if (data.type == null) {
            data.type = "line";
        }
        postAddCtx(data).then(() => {
            reset()
            refetch()
            setShow(false)
        })
    }

    return (
        <>
            <ButtonGroup className="mt-1 mb-1">
                <Button size="sm" variant="light" onClick={() => { setShow(!show) }}>
                    <PlusSquare />
                </Button>
            </ButtonGroup>
            {show &&
                <Form onSubmit={handleSubmit(submit)}>
                    {objectType === "shape" &&
                        <Form.Select className="mb-1" {...register("type", { required: { value: true }, onChange: ((e) => { setTypeSel(e.target.value) }) })}>
                            <option value={"line"}>Прямая</option>
                            <option value={"curve"}>Кривая</option>
                        </Form.Select>
                    }

                    <Row className="mb-3 pb-2 border-bottom">
                        <Col>
                            <Row>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Text>x</InputGroup.Text>
                                        <Form.Control defaultValue={0} placeholder="x" {...register("x", { required: { value: true } })} />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup>
                                        <InputGroup.Text>y</InputGroup.Text>
                                        <Form.Control defaultValue={0} placeholder="y" {...register("y", { required: { value: true } })} />
                                    </InputGroup>
                                </Col>
                            </Row>

                            {typeSel === "curve" &&
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text>cpx</InputGroup.Text>
                                            <Form.Control defaultValue={0} placeholder="cpx" {...register("cpx", { required: { value: true } })} />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text>cpy</InputGroup.Text>
                                            <Form.Control defaultValue={0} placeholder="cpy" {...register("cpy", { required: { value: true } })} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            }
                        </Col>


                        <Col xs={'auto'} style={{ alignContent: 'center' }}>
                            {!isLoading ?
                                <ButtonGroup>
                                    <Button style={{ lineHeight: '10px' }} type="submit" className="p-1" variant="success"><Check size={16} /></Button>
                                    <Button style={{ lineHeight: '10px' }} onClick={() => { setShow(false); reset(); }} className="p-1" variant="ligth"><X size={16} /></Button>
                                </ButtonGroup>
                                :
                                <Spinner />
                            }
                        </Col>
                    </Row>
                </Form>
            }
        </>
    )
}

const CtxItem = ({ item, refetch, objectType }) => {
    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [editing, setEditing] = useState()
    const [typeSel, setTypeSel] = useState()
    const [putEditCtx, { isLoading }] = usePutEditCtxMutation()
    const [deleteCtx, { isDeleting }] = useDeleteCtxMutation()

    useEffect(() => {
        setValue("id", item.id)
        setValue("type", item.type)
        setValue("x", item.x)
        setValue("y", item.y)
        setValue("cpx", item.cpx)
        setValue("cpy", item.cpy)
        setTypeSel(item.type)
    }, [editing])
    useEffect(() => {
        console.log(item)
    }, [item])

    const submit = (data) => {
        putEditCtx(data).then(() => {
            reset()
            refetch()
            setEditing(false)
        })
    }

    return (
        <>
            {!editing ?
                <Row key={item.id} className="mb-1 pb-1 border-bottom">
                    {/* <Col>
                        {item.type}
                    </Col> */}

                    <Col>
                        {item.x}
                    </Col>
                    <Col>
                        {item.y}
                    </Col>
                    {objectType === "shape" && <>
                        <Col>
                            {item.type === "curve" ?
                                <> {item.cpx}</> : <>-</>
                            }
                        </Col>
                        <Col>
                            {item.type === "curve" ?
                                <> {item.cpy}</> : <>-</>
                            }
                        </Col>
                    </>}
                    <Col style={{ alignContent: "center" }}>
                        <ButtonGroup>
                            <Button
                                size="sm"
                                style={{ lineHeight: '10px' }}
                                variant="light"
                                className="p-1"
                                onClick={() => { setEditing(!editing) }}
                            >
                                <Gear size={16} />
                            </Button>
                            <Button
                                onClick={() => {
                                    if (window.confirm("Вы уверены?"))
                                        deleteCtx(item.id).then(() => {
                                            refetch()
                                        })
                                }}
                                size="sm"
                                style={{ lineHeight: '10px' }}
                                variant="outline-danger"
                                className="p-1">
                                <Trash size={16} />
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                :
                <>
                    <Form onSubmit={handleSubmit(submit)}>
                        {objectType === "shape" &&
                            <Form.Select className="mb-1" {...register("type", { required: { value: true }, onChange: ((e) => { setTypeSel(e.target.value) }) })}>
                                <option value={"line"}>Прямая</option>
                                <option value={"curve"}>Кривая</option>
                            </Form.Select>
                        }

                        <Row className="mb-3 pb-2 border-bottom">
                            <Col>
                                <Row>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text>x</InputGroup.Text>
                                            <Form.Control  {...register("x", { required: { value: true } })} />
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text>y</InputGroup.Text>
                                            <Form.Control  {...register("y", { required: { value: true } })} />
                                        </InputGroup>
                                    </Col>
                                </Row>

                                {typeSel === "curve" &&
                                    <Row>
                                        {console.log(typeSel === "curve" && objectType === "shape")}
                                        <Col>
                                            <InputGroup>
                                                <InputGroup.Text>cpx</InputGroup.Text>
                                                <Form.Control  {...register("cpx", { required: { value: true } })} />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup>
                                                <InputGroup.Text>cpy</InputGroup.Text>
                                                <Form.Control {...register("cpy", { required: { value: true } })} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                }
                            </Col>


                            <Col xs={'auto'} style={{ alignContent: 'center' }}>
                                {!isLoading ?
                                    <ButtonGroup>
                                        <Button style={{ lineHeight: '10px' }} type="submit" className="p-1" variant="success"><Check size={16} /></Button>
                                        <Button style={{ lineHeight: '10px' }} onClick={() => { setEditing(false); reset(); }} className="p-1" variant="ligth"><X size={16} /></Button>
                                    </ButtonGroup>
                                    :
                                    <Spinner />
                                }
                            </Col>
                        </Row>
                    </Form>
                </>}


        </>
    )
}


const CopyForm = ({ refetch, objectId, setShowCopyObject, schemeHallId }) => {

    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [postCopyObject, { isLoading }] = usePostCopyObjectMutation()

    useEffect(() => {
        setValue("objecId", objectId)
        setValue("schemeHallId", schemeHallId)
    }, [objectId])

    const submit = (data) => {
        postCopyObject(data).then(() => {
            reset()
            refetch()
            setShowCopyObject(false)
        }).catch((e) => { window.alert(e.message) })
    }


    return (
        <Form className="mb-3 mt-1" onSubmit={handleSubmit(submit)}>
            {!isLoading ? <>
                <div>Создание копии Объекта</div>
                <InputGroup className="mb-1">
                    <InputGroup.Text>Название</InputGroup.Text>
                    <Form.Control defaultValue={null} {...register("name")} type='text' />
                </InputGroup>
                <InputGroup className="mb-1">
                    <InputGroup.Text>X±</InputGroup.Text>
                    <Form.Control defaultValue={0} {...register("offsetX")} type="number" />
                    <InputGroup.Text>Y±</InputGroup.Text>
                    <Form.Control defaultValue={0} {...register("offsetY")} type="number" />
                    <Button type="submit"><Check /></Button>
                </InputGroup>
            </> : <Spinner />}
        </Form>
    );
}


export const ObjectItem = (props) => {
    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [showEdit, setShowEdit] = useState()
    const [deleteObject, { isLoading }] = useDeleteObjectMutation()
    const [putEditObject, { isLoading: isPutObject }] = usePutEditObjectMutation()
    const [showCopyObject, setShowCopyObject] = useState()


    useEffect(() => {
        setValue("id", props.item.id)
        setValue("name", props.item.name)
        setValue("x", props.item.x)
        setValue("y", props.item.y)
        setValue("fill", props.item.fill)
        setValue("width", props.item.width)
        setValue("strokeWidth", props.item.strokeWidth)
        setValue("stroke", props.item.stroke)
    }, [props.item, showEdit])

    const submit = (data) => {
        putEditObject(data).then(() => {
            props.refetch()
            reset()
            setShowEdit(false)
        })
        // console.log(data)
    }

    return (
        <div className={`border p-1 ${showEdit && "shadow-sm"}`}>
            {!showEdit ?
                <>
                    <Row>
                        <Col>
                            {props.item.name}
                        </Col>
                        <Col>
                            x: {props.item.x}
                        </Col>
                        <Col>
                            y: {props.item.y}
                        </Col>
                        <Col style={{ alignContent: "center" }}>
                            <ButtonGroup>
                                <Button
                                    size="sm"
                                    style={{ lineHeight: '10px' }}
                                    variant="light"
                                    className="p-1"
                                    onClick={() => { setShowEdit(!showEdit) }}
                                >
                                    <Gear size={16} />
                                </Button>
                                <Button className="p-1" variant="light" onClick={() => { setShowCopyObject(!showCopyObject) }}><Clipboard2 /></Button>
                                <Button
                                    size="sm"
                                    style={{ lineHeight: '10px' }}
                                    variant="outline-danger"
                                    className="p-1"
                                    onClick={() => {
                                        if (window.confirm("Вы уверены?")) {
                                            deleteObject(props.item.id)
                                                .then(() => { props.refetch() })
                                        }
                                    }} disabled={isLoading}>
                                    {!isLoading ?
                                        <Trash size={16} /> :
                                        <Spinner size="8" style={{ width: "1rem", height: "1rem" }} />
                                    }
                                </Button>

                            </ButtonGroup>
                        </Col>
                    </Row>
                    {showCopyObject &&
                        <CopyForm refetch={props.refetch} objectId={props.item.id} setShowCopyObject={setShowCopyObject} schemeHallId={props.schemeId} />
                    }
                </>
                :
                <>
                    <Form onSubmit={handleSubmit(submit)}>
                        <Row>
                            <Col>
                                <Row className="mb-1">
                                    <Col>
                                        <Col>
                                            <Form.Group className="d-flex">
                                                <InputGroup style={{ lineHeight: '18px' }}>
                                                    <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">Название</InputGroup.Text>
                                                    <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1"
                                                        {...register("name", { required: { value: true } })}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Col>

                                </Row>
                                <Row className="mb-1">
                                    <Col>
                                        <Form.Group className="d-flex">
                                            <InputGroup style={{ lineHeight: '18px' }}>
                                                <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">X</InputGroup.Text>
                                                <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1" defaultValue={props.item.x}
                                                    {...register("x", { required: { value: true } })}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="d-flex">
                                            <InputGroup style={{ lineHeight: '18px' }}>
                                                <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">Y</InputGroup.Text>
                                                <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1" defaultValue={props.item.y}
                                                    {...register("y", { required: { value: true } })}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row className="mb-1">
                                    <Col>
                                        <Form.Group className="d-flex">
                                            <InputGroup style={{ lineHeight: '18px' }}>
                                                <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">
                                                    <TextareaResize />
                                                </InputGroup.Text>
                                                <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1" defaultValue={props.item.width}
                                                    {...register("width", { required: { value: true } })}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="d-flex">
                                            <InputGroup style={{ lineHeight: '18px' }}>
                                                <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">Цвет</InputGroup.Text>
                                                <Form.Control type="color" size="sm" style={{ lineHeight: '18px' }} className="p-1" defaultValue={props.item.fill}
                                                    {...register("fill", { required: { value: true } })}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-1">
                                    <Col>
                                        <Form.Group className="d-flex">
                                            <InputGroup style={{ lineHeight: '18px' }}>
                                                <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">
                                                    <DistributeHorizontal />
                                                </InputGroup.Text>
                                                <Form.Control size="sm" style={{ lineHeight: '18px' }}
                                                    className="p-1"
                                                    defaultValue={props.item.strokeWidth}
                                                    {...register("strokeWidth", { required: { value: true } })} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="d-flex">
                                            <InputGroup style={{ lineHeight: '18px' }}>
                                                <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">
                                                    <DistributeVertical />
                                                </InputGroup.Text>
                                                <Form.Control type="color" size="sm" style={{ lineHeight: '18px' }} className="p-1" defaultValue={props.item.stroke}
                                                    {...register("stroke", { required: { value: true } })} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={'auto'} style={{ alignContent: 'center' }}>
                                {!isPutObject ?
                                    <ButtonGroup>
                                        <Button style={{ lineHeight: '10px' }} type="submit" className="p-1" variant="success"><Check size={16} /></Button>
                                        <Button style={{ lineHeight: '10px' }} onClick={() => { setShowEdit(false); reset(); }} className="p-1" variant="ligth"><X size={16} /></Button>
                                    </ButtonGroup>
                                    :
                                    <Spinner />
                                }
                            </Col>
                        </Row>
                    </Form>

                    {props.item.ctxs.length > 0 &&
                        <Accordion className="shadow-sm mt-1">
                            <Accordion.Button className="p-1">Точки</Accordion.Button>
                            <Accordion.Body className="p-1">
                                <Row className="mb-2 border-bottom">
                                    <Col>
                                        x
                                    </Col>
                                    <Col>
                                        y
                                    </Col>
                                    {props.item.type === "shape" &&
                                        <>
                                            <Col>
                                                cpx
                                            </Col>
                                            <Col>
                                                cpy
                                            </Col>
                                            <Col>
                                            </Col>
                                        </>
                                    }
                                </Row>
                                <div>

                                    {props.item.ctxs.map(item =>
                                        <CtxItem key={item.id} item={item} refetch={props.refetch} objectType={props.item.type} />
                                    )}
                                </div>
                            </Accordion.Body>
                        </Accordion>
                    }
                    {(props.item.type === "shape" || props.item.type === "line") &&
                        <AddCtx objectId={props.item.id} objectType={props.item.type} refetch={props.refetch} />
                    }
                </>
            }
        </div>);
}
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"


export const NotFound = () => {
    const [t, i18n] = useTranslation("global")
    const navigete = useNavigate()

    return (
        <div className="haed-layout">
            <div className="head-layout-img" style={{ backgroundImage: 'url("/Show/1920x1080_moon_2.jpg")' }}>
            </div>
            <div className="head-mask">
                <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3" style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                    <div className="text-white ms-auto text-center">
                        <div className="h1">{t('notFoundPage.notFound')}</div>
                    </div>
                    <div className="text-center"><Button variant="light" onClick={() => { navigete("/") }}>{t("notFoundPage.btnTomain")}</Button></div>
                </div>
            </div>
        </div>
    )
}
import { Accordion, Button, ButtonGroup } from "react-bootstrap"
import { Clipboard2, Gear, PlusSquare } from "react-bootstrap-icons"
import { SchemeText } from "./SchemeText"
import { AddText } from "./AddText"


export const TextList = ({ texts, schemeId, refetch }) => {

    return (
        <Accordion>
            <Accordion.Item eventKey={"texts"} >
                <Accordion.Button className="p-1">
                    Текст
                </Accordion.Button>
                <Accordion.Body className="p-1 pt-2 pb-2">
                    <AddText schemeId={schemeId} refetch={refetch} />
                    {texts.length > 0 &&
                        <>
                            {texts.map(item =>
                                <SchemeText refetch={refetch} key={item.id} text={item} />
                            )}
                        </>
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}
import { useForm } from "react-hook-form";
import { useBaseTokenMutation, useClientLoginMutation } from "../../features/CallAppApi";
import { useState } from "react";
import { Button, ButtonGroup, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { LoadScreenMin } from "../UI/LoadScreenMin";
import { useNavigate } from "react-router-dom";


export const ClientLogin = ({ setClientTokenData, t, setAuthorized }) => {
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const [baseToken] = useBaseTokenMutation()
    const [clientLogin, { isLoading: isLoging }] = useClientLoginMutation()
    const [rememberMe, setRememberMe] = useState(false)
    const navigate = useNavigate()


    const login = (data) => {
        setValue("rememberMe", rememberMe)
        baseToken().unwrap().then((r) => {
            clientLogin({ data, baseToken: r.token }).unwrap().then((r) => {
                if (r.code === 4010) {
                    reset()
                    return navigate(`/client/resetpass?infoMsgShow=true&login=${data.email}`)
                }
                setClientTokenData(r)
                reset()
                setAuthorized(true)
            }).catch((e) => {
                // if(e.)
                window.alert(`Code: ${e.status}\nTitle: ${e.data.title}`)
                console.error(e)
            })
        }).catch((r) => { console.error(r) })
    }
    return (
        <>
            {!isLoging ?
                <>
                    <form style={{ minHeight: '50vh', alignItems: 'center', display: 'grid' }} onSubmit={handleSubmit(login)}>
                        <Row className={'justify-content-center'}>
                            <Col md={6} lg={4}>
                                <h4 className="text-center">{t("form.loginToAccount")}</h4>
                                <FloatingLabel className="mb-1" controlId="email" label={`E-Mail`} >
                                    <Form.Control type="email" placeholder={`E-Mail`}
                                        {...register("email", { required: { value: true, message: "Поле E-Mail обязательное" }, pattern: /^\S+@\S+$/i })} isInvalid={errors?.email?.message != null} />
                                </FloatingLabel>
                                <FloatingLabel className="mb-2" controlId="email" label={`${t("form.password")}`} >
                                    <Form.Control type="password" placeholder={`${t("form.password")}`} autoComplete={"on"}
                                        {...register("password", { required: { value: true, message: "Поле пароль обязательное" } })} isInvalid={errors?.email?.message != null} />
                                </FloatingLabel>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="rememberMe"
                                    label={t("form.rememberMe")}
                                    className="form-check-lc-input"
                                    checked={rememberMe} onChange={() => { setRememberMe(!rememberMe) }}
                                />
                                <div className={'d-flex justify-content-between mt-3'}>
                                    <ButtonGroup>
                                        <Button className="btn-lc-no-bg btn-lc-rounded" onClick={() => { navigate("/client/registration") }} >{t("form.registration")}</Button>
                                        <Button className="btn-lc-no-bg btn-lc-rounded" onClick={() => { navigate("/client/clientrecovery") }} >{t("form.passwordRecovery")}</Button>
                                    </ButtonGroup>
                                    <Button type="submit" className="btn-lc btn-lc-rounded">{t("form.singIn")} {isLoging && <Spinner size="sm" />}</Button>
                                </div>
                            </Col>
                        </Row>
                    </form>

                </>
                :
                <LoadScreenMin />
            }
        </>
    )
}

import { useEffect, useState } from "react"
import { Button, ButtonGroup, FloatingLabel, Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useAddUserMutation } from "../../../features/CallApi";
import { LoadScreenMin } from "../../UI/LoadScreenMin";



export const UserAdd = ({ showAddForm, setShowAddForm, dataRole }) => {
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const [addUser, { isLoading }] = useAddUserMutation()
    const [status, setStatus] = useState(true)
    const onSubmit = (data) => {
        setValue("status", status)
        addUser(data).unwrap().then(() => {
            reset()
            setShowAddForm(false)
        }).catch(
            (r) => {
                alert(r.data?.detail)
            }
        )
    }



    return (
        <Modal
            show={showAddForm}
            onHide={() => { setShowAddForm(false) }}
            backdrop="static"
            keyboard={false}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>Добавить пользователя</Modal.Title>
                </Modal.Header>
                {!isLoading ?
                    <>
                        <Modal.Body>
                            <div className="form-floating mb-3">
                                <input autoComplete="off" type="email" className={`form-control ${errors.email && "is-invalid"}`} id="email" placeholder="name@leningradcenter.ru"  {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                                <label htmlFor="email">Email</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input autoComplete="off" type="password" className="form-control" id="password" placeholder="Пароль" {...register("password", { required: true, min: 6 })} />
                                <label htmlFor="password">Пароль</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input autoComplete="off" type="text" className="form-control" id="firstname" placeholder="Имя"  {...register("firstname", { required: true })} />
                                <label htmlFor="firstname">Имя</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input autoComplete="off" type="text" className="form-control" id="lastname" placeholder="Фамилия" {...register("lastname", { required: true })} />
                                <label htmlFor="lastname">Фамилия</label>
                            </div>
                            <FloatingLabel controlId="floatingSelect" label="Роль" {...register("roleId", {})}>
                                <Form.Select aria-label="Floating label select example">
                                    {dataRole.map(role =>
                                        <option key={role.id} value={role.id}>{role.name}</option>
                                    )}
                                </Form.Select>
                            </FloatingLabel>
                            <div className="form-check form-switch mt-1">
                                <input className="form-check-input" onClick={() => { setStatus(!status) }} checked={status} type="checkbox" id="status" {...register("status", {})} />
                                <label className="form-check-label" htmlFor="rememberMe">Активен</label>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonGroup size="sm">
                                <Button variant="primary" onClick={() => {
                                    setShowAddForm(!showAddForm)
                                    reset()
                                }
                                }>Отменить</Button>
                                <Button type="submit" variant="success">
                                    Добавить
                                </Button>
                            </ButtonGroup>
                        </Modal.Footer>
                    </>
                    :
                    <LoadScreenMin />
                }
            </form>

        </Modal>
    )
}
import { Button, Col, Container, Image, Modal, Row } from "react-bootstrap"
import './Footer.css'
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Casting } from "../Admin/Casting/Casting"
import { useState } from "react"



export const Footer = (props) => {

    const [t] = useTranslation('global')
    const [showWorkshitCasting, setShowWorkshitCasting] = useState(false)
    const navigete = useNavigate()
    const toNav = (link) => {
        window.scrollTo(0, 0)
        navigete(link)
    }

    return (
        <>
            <div ref={props.footerRef} className="footer pt-5 pb-3 mt-3 pb-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col className="text-center text-lg-start" xs={"auto"}>
                            {t("footer.street")}
                            <div>{t("footer.openig")}</div>
                            <div className="d-lg-none" style={{ cursor: 'pointer' }}>
                                <a onClick={() => { toNav("/documents") }} here="#">{t('footer.ducuments')}</a>
                            </div>
                            <div className="d-lg-none" style={{ cursor: 'pointer' }}>
                                <a className="" onClick={() => { setShowWorkshitCasting(!showWorkshitCasting) }} here="#">{t('menu.casting')}</a>
                            </div>
                            {t("footer.questions")}
                            <div><a href="tel:8 (812) 242 9999">8 (812) 242 9999 </a></div>
                            <div><a href="mailto:reservation@leningradcenter.ru">reservation@leningradcenter.ru</a></div>
                            <Button className="btn btn-lc-contact mt-2" onClick={() => toNav("/about-project/requests")}>{t("footer.contactform")}</Button>
                            <div className="d-flex justify-content-center mt-3 d-lg-none">
                                <a target="_blank" href="https://vk.com/leningradcenter">
                                    <div className="ms-2 me-2" style={{ maxHeight: "32px", maxWidth: "32px", cursor: "pointer" }}>
                                        <Image fluid src="/VK.com-logo.png" alt="icon-vk" />
                                    </div>
                                </a>
                                <a target="_blank" href="https://t.me/leningradcentershow">
                                    <div className="ms-2 me-2" style={{ maxHeight: "32px", maxWidth: "32px", cursor: "pointer" }}>
                                        <Image fluid src="/telegramm.png" alt="ico-tg" />
                                    </div>
                                </a>
                            </div>
                            {/* <a href="https://online.leningradcenter.ru/Requests/Create" className="btn btn-lc mt-2"><b>Форма обращения</b></a> */}
                        </Col>
                        <Col className="d-none d-lg-block">
                            <Row className="g-2 justify-content-evenly">
                                <Col lg='auto'>
                                    <ul className="footer-menu me-5">
                                        <li onClick={() => {
                                            toNav("/afisha")
                                        }}>{t('footer.afishatickets')}</li>
                                        <li
                                            onClick={() => {
                                                toNav("/about-project/shows")
                                            }}
                                        >{t('footer.shows')}</li>
                                        {/* <li>{t('footer.movies')}</li> */}
                                        <li onClick={() => { toNav("/about-project/exhibitions") }}>{t('footer.exhibitions')}</li>
                                    </ul>
                                </Col>
                                <Col lg='auto'>
                                    <ul className="footer-menu me-5">
                                        <li onClick={() => {
                                            toNav("/about-project")
                                        }}>{t('footer.aboutproject')}</li>
                                        <li
                                            onClick={() => {
                                                toNav("/about-project/history")
                                            }}>{t('footer.history')}</li>
                                        <li onClick={() => { toNav("/documents") }}>{t('footer.ducuments')}</li>
                                    </ul>
                                </Col>
                                <Col lg='auto'>
                                    <ul className="footer-menu mb-0">
                                        <li onClick={() => { toNav("/about-project/corporate") }}>{t('footer.corporate')}</li>
                                        {/* <li>{t('footer.corporatetickets')}</li> */}
                                        {/* <li>{t('footer.events')}</li> */}
                                    </ul>
                                    <ul className="footer-menu  mb-0">
                                        <li className="" onClick={() => { toNav("/about-project/certificates") }}>{t('menu.gifCertificates')}</li>
                                    </ul>
                                    <ul className="footer-menu">
                                        <li className="" onClick={() => { setShowWorkshitCasting(!showWorkshitCasting) }}>{t('menu.casting')}</li>
                                    </ul>
                                </Col>
                                <Col lg='auto'>
                                    <div className="d-flex telegramm.png">
                                        <a target="_blank" href="https://vk.com/leningradcenter">
                                            <div className="ms-2 me-2" style={{ maxHeight: "24px", maxWidth: "24px", cursor: "pointer" }}>

                                                <Image fluid src="/VK.com-logo.png" />
                                            </div>
                                        </a>
                                        <a target="_blank" href="https://t.me/leningradcentershow">
                                            <div className="ms-2 me-2" style={{ maxHeight: "24px", maxWidth: "24px", cursor: "pointer" }}>
                                                <Image fluid src="/telegramm.png" />
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* <div className="d-lg-none text-center">
                        Санкт-Петербург, Потемкинская, д.4, лит. А
                        <div>Время работы кассы с 12:00 до 22:00</div>
                        <div>
                            <a href="/contact">Схема проезда</a>
                        </div>
                        По всем вопросам:
                        <div><a href="tel:8 (812) 242 9999">8 (812) 242 9999 </a></div>
                        <div><a href="mailto:reservation@leningradcenter.ru">reservation@leningradcenter.ru</a></div>
                        <Button className="btn btn-lc-contact mt-2">Форма обращения</Button>
                        <a href="https://online.leningradcenter.ru/Requests/Create" className="btn btn-lc mt-2"><b>Форма обращения</b></a>
                    </div> */}

                    {showWorkshitCasting &&
                        <Modal size="lg" show={showWorkshitCasting} onHide={() => setShowWorkshitCasting(false)}>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <Casting />
                            </Modal.Body>
                        </Modal>
                    }

                </Container>
            </div>
            {(props.clearfix === true || props.clearfix === null) != true &&
                <div className={"d-lg-none bg-white"} style={{ height: "54px" }}></div>
            }
        </>

    )
}
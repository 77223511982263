import { setTokenInStore } from "../features/tokenSlice";
import { setRefreshTokenInStore } from "../features/refreshTokenSlice"
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect, useState } from "react";

const useToken = () => {
  const [refreshing, setRefreshing] = useState(true)
  const [token, setToken] = useState(JSON.parse(sessionStorage.getItem("token")))
  const [timerRefresh, setTimerRefresh] = useState()

  const setTokenData = (userToken) => {
    if (userToken) {
      const dataToken = userToken
      const tokenData = {
        Token: dataToken.Token,
        ExpiresIn: dataToken.ExpiresIn
      }
      setTimerRefresh(dataToken.ExpiresIn)
      const refreshTokenData = {
        RefreshToken: dataToken.RefreshToken,
        RefreshExpiresIn: dataToken.RefreshExpiresIn
      }
      sessionStorage.setItem("token", JSON.stringify(userToken));
      if (tokenData != null) {
        setTimeout(() => {
          setRefreshing(false)
        }, 1000);
      }
      localStorage.setItem("tokenRefresh", JSON.stringify(refreshTokenData))
      dispatch(setTokenInStore(dataToken));
      dispatch(setRefreshTokenInStore(refreshTokenData));
      setToken(dataToken)
    }
  };
  const getRefreshToken = async () => {
    if (!token || Date >= token?.ExpiresIn) {
      // setRefreshing(true)
      const refTokrn = JSON.parse(localStorage.getItem("tokenRefresh"))
      try {
        if (refTokrn?.RefreshExpiresIn < Date) {
          const response = await axios.post(
            '/api/login/refreshtoken', refTokrn,
            {
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
              }
            }

          )
          setTokenData(response.data)
          setToken(JSON.parse(sessionStorage.getItem("token")))
        }
        setTimeout(() => {
          setRefreshing(false)
        }, 1000);
      } catch (e) {
        if (e.response.status !== 200) {
          console.log(sessionStorage.getItem('token'))
          sessionStorage.removeItem('token');
          localStorage.removeItem('refreshToken')
          setToken(null)
        }
        setTimeout(() => {
          setRefreshing(false)
        }, 1000);
      }
    }
  }
  const tokenInStore = useSelector((store) => store.token.value);
  // const token = getToken();
  useEffect(() => { getRefreshToken(); }, [])
  const dispatch = useDispatch();
  if (token && !tokenInStore) setTokenData(token);
  useEffect(() => {
    // console.log(token && timerRefresh)
    if (token && timerRefresh) {
      const dateNow = new Date(Date.now())
      const expiresIn = new Date(token?.ExpiresIn)
      const timerTime = expiresIn - dateNow
      // console.log(timerTime)
      if (timerTime > 0) {
        setTimeout(() => {
          console.log("Token is timeout!")
          getRefreshToken()
          const newDataToken = JSON.parse(sessionStorage.getItem("token"))
          if (newDataToken) {
            setToken(newDataToken)
          }
        }, timerTime)
      }
      else if (timerTime <= 0) {
        console.log("Token is null!")
        getRefreshToken()
        const newDataToken = JSON.parse(sessionStorage.getItem("token"))
        console.log(newDataToken)
        if (newDataToken) {
          setToken(newDataToken)
        }
      }
    }
  }, [timerRefresh])

  return {
    tokenData: token,
    token: token?.Token,
    role: token?.Role,
    fullName: token?.FullName,
    setTokenData,
    loadingSite: refreshing
  };
}

export default useToken;
import { Button, ButtonGroup, Col, Form, FormGroup, Modal, Row } from "react-bootstrap";




export const AddSeatForm = (props) => {
    return (<Form onSubmit={props.handleSubmit(props.submit)}>
        <Modal.Body className="g-1">
            <FormGroup>
                <Form.Label>Название</Form.Label>
                <Form.Control type="text" {...props.register("name", {
                    required: {
                        value: true
                    }
                })} />
            </FormGroup>
            <FormGroup>
                <Form.Label>Название в Премьере</Form.Label>
                <Form.Control type="text" {...props.register("nameSeatPremiera", {
                    required: {
                        value: true
                    }
                })} />
            </FormGroup>
            <Row className="d-flex">
                <Col>
                    <FormGroup>
                        <Form.Label>X</Form.Label>
                        <Form.Control type="number" {...props.register("X", {
                            required: {
                                value: true
                            }
                        })} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Form.Label>Y</Form.Label>
                        <Form.Control type="number" {...props.register("Y", {
                            required: {
                                value: true
                            }
                        })} />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Form.Label>Название</Form.Label>
                <Form.Select {...props.register("type", {
                    required: {
                        value: true
                    }
                })}>
                    <option value={"singl"}>Одиночное</option>
                    <option value={"topSingl"}>Вниз одиночное</option>
                    <option value={"left"}>Левый</option>
                    <option value={"right"}>Правый</option>
                    <option value={"turnLeft"}>Влево</option>
                    <option value={"turnRight"}>Вправо</option>
                    <option value={"boxCenter"}>Центр ложи</option>
                    <option value={"boxTopLeft"}>Верх-лева ложи</option>
                    <option value={"boxTopRight"}>Верх-право ложи</option>
                    <option value={"topLeft"}>Вниз левый</option>
                    <option value={"topRight"}>Вниз правый </option>

                </Form.Select>
            </FormGroup>
        </Modal.Body>
        <Modal.Footer>
            <ButtonGroup>
                <Button onClick={() => {
                    props.setAddSeatsShow(false);
                }}>Закрыть</Button>
                <Button type="submit" variant="success">Добавить</Button>
            </ButtonGroup>
        </Modal.Footer>
    </Form>);
}
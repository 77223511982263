import { useEffect, useState } from "react"
import { Button, ButtonGroup, FloatingLabel, Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAddNewsMutation } from "../../../features/CallApi";


export const NewsAdd = ({ newsAddForm, setNewsAddForm, setNewsEditForm, setNewsId }) => {
    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [valueEditor, setValueEditor] = useState('')
    const [valueEditorEN, setValueEditorEN] = useState('')
    const [addNews, { isLoading }] = useAddNewsMutation()

    const onSubmit = (data) => {

        data.content = valueEditor
        data.contentEN = valueEditorEN
        data.typeNews = Number.parseInt(data.typeNews)
        console.log(data)
        addNews(data).unwrap().then((e) => {
            console.log(e.id)
            setNewsId(e.id)
            reset()
            setNewsEditForm(true)
            setNewsAddForm(false)
        })
    }

    return (
        <Modal show={newsAddForm}
            onHide={() => {
                setNewsAddForm(false)
            }}
        >
            <Modal.Header closeButton><h3>Добавить новости</h3></Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <FloatingLabel
                        controlId="floatingTitile"
                        label="Заголовок"
                        className="mb-3"
                    >
                        <Form.Control type="text" isInvalid={errors.title} isValid={isValid.title} placeholder="Заголовок" {...register("title", { required: { value: true } })} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingTitile"
                        label="Заголовок EN"
                        className="mb-3"
                    >
                        <Form.Control type="text" placeholder="Заголовок EN" {...register("titleEN")} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingSelect"
                        label="Тип новости"
                        className="mb-3">
                        <Form.Select isInvalid={errors.typeNews} defaultValue={""} {...register("typeNews", { required: { value: true } })}
                            aria-label="Тип новости">
                            <option disabled hidden value={""}>--- Выберите тип ---</option>
                            <option value="0">Новость</option>
                            <option value="1">СМИ о нас</option>
                        </Form.Select>
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingSelect"
                        label="Дата публикации"
                        className="mb-3">
                        <Form.Control type="datetime-local" isInvalid={errors.typeNews} defaultValue={""} {...register("publishDate")}
                            aria-label="Дата публикации" />
                    </FloatingLabel>
                    <div className="mb-3">
                        <label>Содержание</label>
                        <ReactQuill modules={{
                            toolbar: [
                                [{ header: [1, 2, 3, 4, 5, false] }],
                                ['bold', 'italic', 'underline'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                [{ 'align': [] }],
                                ['code-block'],
                                ['list'],
                            ]
                        }} theme="snow" value={valueEditor} onChange={setValueEditor} />
                    </div>
                    <div className="mb-3">
                        <label>Содержание EN</label>
                        <ReactQuill modules={{
                            toolbar: [
                                [{ header: [1, 2, 3, 4, 5, false] }],
                                ['bold', 'italic', 'underline'],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                [{ 'align': [] }],
                                ['code-block'],
                                ['list'],
                            ]
                        }} required theme="snow" value={valueEditorEN} onChange={setValueEditorEN} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup size="sm">
                        <Button variant="primary" onClick={() => {
                            reset()
                            setNewsAddForm(!newsAddForm)
                        }
                        }>Отменить</Button>
                        <Button type="submit" variant="success">
                            Добавить
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
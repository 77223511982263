import { Suspense, useState } from "react";
import PhotoAlbum from 'react-photo-album';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { Col, Row } from "react-bootstrap";


export const Gallery = ({ selLanguage }) => {
    const [index, setIndex] = useState(-1);
    const photos = [
        { src: "/AboutProjcet/091A8287.jpg", width: 2500, height: 1667, alt: "Фойе Лениград Центра" },
        { src: "/AboutProjcet/091A8294.jpg", width: 2500, height: 1667, alt: "Фойе Лениград Центра" },
        { src: "/AboutProjcet/091A8301.jpg", width: 2500, height: 1667, alt: "Фойе Лениград Центра" },
        { src: "/AboutProjcet/LC141022_001.jpg", width: 2500, height: 1667, alt: "Фойе Лениград Центра" },
        { src: "/AboutProjcet/LC141022_011.jpg", width: 2500, height: 1667, alt: "Фойе Лениград Центра" },
        { src: "/AboutProjcet/Photo_014.jpg", width: 2500, height: 1667, alt: "Фойе Лениград Центра" },
    ]
    return (
        <>
            {selLanguage === "en" ?
                <p>The space is located on the second floor of the Leningrad Center. The interior was designed by Ricardo Bofill in a minimalist manner, which allows it to be easily transformed for various creative projects. The gallery is divided into three zones: the main hall and two side wings. All three halls are equipped with specialized gallery lighting and multimedia equipment. The total area of ​​the gallery is 384 sq.m.</p>
                :
                <p>
                    Пространство расположено на втором этаже «Ленинград Центра». Интерьер выполнен по проекту Рикардо Бофилла в минималистичном ключе, что позволяет ему легко трансформироваться под различные креативные проекты.
                    Галерея поделена на три зоны: основной зал и два боковых крыла.
                    Все три зала оснащены специализированным галерейным светом и мультимедийным оборудованием.
                    Общая площадь галереи составляет 384 кв.м.
                </p>
            }
            <Row>
                {/* <Col xs={"12"} xl={"6"}>
                    <div className="ratio ratio-16x9 mb-3">
                        <iframe src="https://www.youtube.com/embed/n21M0gUbqrA?si=9h7TH4E0rts74LM4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                </Col> */}
                <Col xs={"12"}>
                    {selLanguage === "en" ?
                        <>
                            <p>
                                In the gallery space, world photography stars Pete Buhler and Kevin Ohana presented their projects, in whose grandiose shootings artists from the show space took part. The first exhibition in Russia of Pierre Henri Matisse (2017) took place here - the grandson of the great master, the artist who took part in the creation of the iconic show “Beautiful M”. In 2018 and 2019 Together with the Petersburg gallery, exhibitions of famous artists from the World of Art association were opened with works by Valentin Serov, Boris Kustodiev, Philip Malyavin and other masters.
                            </p>
                            <p>10 years later, the space continues to develop dynamically: on the one hand, it reflects the theatrical processes and stage program of the Leningrad Center, on the other hand, it does not ignore the current processes of the cultural life of St. Petersburg.</p>
                        </>
                        :
                        <>
                            <p>
                                В пространстве галереи свои проекты представляли звезды мировой фотографии Пит Бюлер и Кевина Охана, в чьих грандиозных съемках принимали участие артисты шоу-пространства.
                                Здесь состоялась первая в России выставка Пьера Анри Матисса (2017 г.) — внука великого мастера, художника, принимавшего участие в создании знакового шоу «Прекрасная М».
                                В 2018 и 2019 гг. совместно с галерей «Петербург» были открыты экспозиции прославленных художников объединения «Мир искусства» с работами Валентина Серова, Бориса Кустодиева, Филиппа Малявина и других мастеров.
                            </p>
                            <p>Спустя 10 лет пространство продолжает динамично развиваться: с одной стороны, отражает театральные процессы и сценическую программу «Ленинград Центра», с другой — не оставляет без внимания актуальные процессы культурной жизни Петербурга.</p>
                        </>
                    }
                </Col>
            </Row>

            <div>
                <Suspense>
                    <PhotoAlbum columns={3} layout="masonry" photos={photos} onClick={({ index }) => setIndex(index)} />
                    <Lightbox
                        slides={photos}
                        open={index >= 0}
                        index={index}
                        close={() => setIndex(-1)}
                        // enable optional lightbox plugins
                        plugins={[Slideshow, Thumbnails, Zoom, Captions]}
                    />
                </Suspense>
            </div>
        </>
    )
}
import { useNavigate, useParams } from "react-router-dom"
import { useGetPaySchemeQuery, useGetSessionDetailsAdminQuery } from "../../../features/CallApi"
import { useEffect, useState } from "react"
import { Accordion, Button, Card, Col, Container, Modal, Row } from "react-bootstrap"
import { PayScheme } from "../../SchemeHall/PayScheme"
import { LoadScreenMin } from "../../UI/LoadScreenMin"
import { Categores } from "./Category/Categores"
import { SessionSeats } from "./Seats/SessionSeats"
import { SchemeSelect } from "./SchemeSelect"
import { ru, enUS } from "date-fns/locale";
import { SchemeSessionSelect } from "./SchemeSession/SchemeSessionSelect"
import { EditSessionScheme } from "../../SchemeHall/EditSessionScheme"
import { format } from "date-fns"

const SchedulesModal = ({ showModal, schedules, setSchedulesModal, id }) => {

    const navigate = useNavigate()

    return (
        <>
            <Modal
                show={showModal}
                size="md"
                fullscreen={"sm-down"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // backdrop="static"
                onHide={() => {
                    setSchedulesModal(false)
                }}
                onShow={() => {
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Другие сеансы
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {schedules &&
                        <>
                            {schedules.map(item =>
                                <Card key={item.sessionId} className='mb-3 shadow' body>
                                    <Row>
                                        <Col>
                                            <div>
                                                Дата: {format(new Date(item.startOfEvent), 'dd.MM')} ({format(new Date(item.startOfEvent), 'EEEE', { locale: ru })})
                                            </div>
                                            <div>
                                                Начало: {format(new Date(item.startOfEvent), 'HH:mm')}
                                            </div>
                                        </Col>
                                        <Col xs={"auto"}>
                                            <Button disabled={item.sessionId === parseInt(id)} onClick={() => {
                                                navigate(`/admin/schemesession/${item.sessionId}`)
                                                setSchedulesModal(false)
                                            }} className='btn-lc border-0'>{item.sessionId === parseInt(id) ? "Текущий сеанс" : "Выбрать"}</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            )}
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export const SchemeSession = () => {


    const { id } = useParams()
    const { data, refetch, isLoading, error, } = useGetPaySchemeQuery({ id })
    const { data: sessionDetails, isLoading: isLoadingDetails } = useGetSessionDetailsAdminQuery({ id })
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 })
    const [reloadScheme, setReloadScheme] = useState(true)
    const [schedules, setSchedules] = useState([])
    const [schedulesModal, setSchedulesModal] = useState(false)


    useEffect(() => {
        refetch()
        console.log(id)
    }, [id])
    useEffect(() => {
        if (!reloadScheme) {
            setReloadScheme(true)
        }

    }, [data])
    useEffect(() => {
        if (error) {
            console.log(error)
        }
    }, [error])

    useEffect(() => {
        if (reloadScheme) {
            setTimeout(() => { setReloadScheme(false) }, 500)
        }
    }, [reloadScheme])
    useEffect(() => {
        if (sessionDetails) {
            setSchedules(sessionDetails.showSchedules)
        }
    }, [sessionDetails])
    if (error?.status === 404) {
        return (
            <div className="mt-3 text-center fs-3">
                Сеанс завершён или не найден в системе
            </div>
        )
    }


    return (
        <Container fluid>
            {schedulesModal &&
                <SchedulesModal setSchedulesModal={setSchedulesModal} showModal={schedulesModal} schedules={schedules} id={id} />
            }
            {/* <div style={{ paddingTop: "75px" }}></div> */}
            {!isLoading ?
                <Row>
                    <Col lg={4} md={5} xl={3} className={'border-end border-bottom pb-2'}>
                        <Button onClick={() => { setSchedulesModal(true) }}>Выбор сеанса</Button>
                        <Row className="mb-2">
                            <Col xs={12}>Кординаты курсора</Col>
                            <Col>x: {Math.round(cursorPos.x)}</Col>
                            <Col>y: {Math.round(cursorPos.y)}</Col>
                        </Row>
                        <div style={{ overflowY: "scroll", overflowX: "hidden", maxHeight: "65vh", minHeight: "40vh" }}>
                            {data ?
                                <>
                                    <Accordion>
                                        <Accordion.Item eventKey="select">
                                            <Accordion.Button className="p-1">Настройки сесии</Accordion.Button>
                                            <Accordion.Body className="p-1">
                                                <SchemeSessionSelect sessionId={id} refetch={refetch} schemeId={data.getSchemeHallModel.id} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    <Categores data={data.getCategoriesSeat} refetch={refetch} />
                                    <SessionSeats data={data.getSchemeHallModel.sections[0]?.seatsByRows} refetch={refetch} />
                                </>
                                :
                                <>

                                    <Accordion>
                                        <Accordion.Item eventKey="select">
                                            <Accordion.Button className="p-1">Настройки сесии</Accordion.Button>
                                            <Accordion.Body className="p-1">
                                                <SchemeSessionSelect sessionId={id} refetch={refetch} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </>
                            }
                        </div>
                    </Col>
                    <Col lg={8} md={7} xl={9}>
                        {!reloadScheme ?
                            <>
                                {data ?
                                    <EditSessionScheme data={data.getSchemeHallModel}
                                        setCursorPos={setCursorPos} />
                                    :
                                    <div>Выберите схему зала</div>
                                }
                            </>
                            :
                            <LoadScreenMin height={30} />}

                    </Col>
                </Row>
                :
                <LoadScreenMin height={40} />


            }
        </Container>
    )
}
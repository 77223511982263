import { useState } from "react";
import { useForm } from "react-hook-form";
import { usePostWorksheetMutation } from "../../../features/CallAppApi";
import { LoadScreenMin } from "../../UI/LoadScreenMin";


export const Casting = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [postWorksheet, { isLoading: isUpdating }] = usePostWorksheetMutation()
    //const { data: statusCasting } = useGetStatusCastingQuery()
    const [registred, setRegistred] = useState(false);
    const [postError, setPostError] = useState();

    const [closeCasting, setCloseCacting] = useState(false)

    //useEffect(() => {
    //    if (statusCasting != null) {
    //        setCloseCacting(statusCasting)
    //    }
    //}, [statusCasting])

    const [imgSize, setImgSize] = useState();

    const submitForm = async (data) => {
        if (data.photo[0].size < 2097153) {
            setImgSize(null)
            console.log(data.photo[0].type)
            const reader = new FileReader();
            // console.log(photo)

            reader.onloadend = () => {
                const body = {
                    name: data.name,
                    biography: data.biography,
                    fullYears: data.fullYears,
                    education: data.education,
                    email: data.email,
                    height: data.height,
                    phone: data.phone,
                    whatCity: data.whatCity,
                    photo: reader.result,
                    PhotoType: data.photo[0].type
                }
                postWorksheet(body).unwrap().catch((e) => {
                    console.log(e)
                    setPostError(e)
                }).then((response) => {
                    reset()
                    setRegistred(true)
                })

            }
            reader.readAsDataURL(data.photo[0])

        } else {
            setImgSize("Максимальный размер фото 2 Мб")
        }
    }

    return (
        <div>
            {closeCasting ? <div><h1 className="text-center mt-5">Приём заявок на кастинг закрыт</h1></div> : <>
                {registred ?
                    <div className="jumbotron mt-5">
                        {postError ?
                            <div className="d-flex flex-column justify-content-center align-content-center">
                                <div>Code error: {postError.originalStatus}</div>
                                <div>error:{postError.error}</div>
                                <div>Ошибка запроса, свяжитесь с администратором сервиса</div>
                            </div>
                            :
                            <div>
                                <div className="d-flex justify-content-center">
                                    <img alt="Logo-LC" src="/lc-logo-5-season.png" className={'img-fluid'} style={{ maxHeight: '60px' }} />
                                </div>
                                <hr />
                                <div className="text-center">
                                    <h3>
                                        Спасибо за регистрацию!
                                    </h3>
                                    <br />
                                    <div className="d-flex justify-content-center">
                                        <button className="btn btn-secondary" onClick={() => { setRegistred(false) }}>Открыть анкету</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div> : <div>
                        {isUpdating ? <LoadScreenMin /> :
                            <div className="container">
                                <form onSubmit={handleSubmit(submitForm)}>
                                    <div className="card">
                                        <div className="card-header text-center">
                                            <h3 className="mt-3">
                                                Анкета для сотрудничества с шоу-пространством
                                            </h3>
                                            <h3>
                                                «Ленинград Центр»
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="mb-1">
                                                <label htmlFor="name" className="form-label">Ф.И.О.</label>
                                                <input type="text" className="form-control form-control-sm" {...register("name",
                                                    { required: { value: true, message: "Поле \"Ф.И.О.\" не может быть пустым" } })} />
                                                {errors.name && <span className='text-danger'>{errors.name?.message}</span>}
                                            </div>
                                            <div className="mb-1">
                                                <label htmlFor="whatCity" className="form-label">Город проживания:</label>
                                                <input type="text" className="form-control form-control-sm " id="whatCity" {...register("whatCity", {
                                                    required: { value: true, message: "Укажите город проживания" }
                                                })} />
                                                {errors.whatCity && <span className='text-danger'>{errors.whatCity?.message}</span>}
                                            </div>
                                            <div className="row mb-1">
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label htmlFor="phone">Телефон:</label>
                                                        <input type="tel" className="form-control form-control-sm" {...register("phone",
                                                            { required: { value: true, message: "Укажите контакный номер телефона" } })} />
                                                        {errors.phone && <span className='text-danger'>{errors.phone?.message}</span>}

                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>E-Mail:</label>
                                                        <input type="email" id='email' className="form-control form-control-sm"
                                                            {...register("email", {
                                                                required: { value: true, message: "Укажите E-Mail" },
                                                            })} />
                                                        {errors.email && <span className='text-danger'>{errors.email?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-1">
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label htmlFor="birthdayDate">Полных лет:</label>
                                                        <input type="number" className="form-control form-control-sm" {...register("fullYears",
                                                            {
                                                                required: { value: true, message: "Укажите возраст" },
                                                                min: { value: 18, message: "Минимальный возраст 18 лет" }
                                                            })} />
                                                        {errors.fullYears && <span className='text-danger'>{errors.fullYears?.message}</span>}

                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label>Ваш рост:</label>
                                                        <input type="number" id='height' className="form-control form-control-sm"
                                                            {...register("height", {
                                                                required: { value: true, message: "Укажите рост" },
                                                                min: { value: 100, message: "Минимальное занчание 100 см" },
                                                                // max: { value: 230, message: "Максимальное значение 230 см" }
                                                            })} />
                                                        {errors.height && <span className='text-danger'>{errors.height?.message}</span>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-1">
                                                <label>Ваше образование:</label>
                                                <input className="form-control form-control-sm"
                                                    id="education" name="education" type="text"  {...register("education", {
                                                        required: { value: true, message: "Укажите образование" }
                                                    })} />
                                                {errors.education && <span className='text-danger'>{errors.education?.message}</span>}
                                            </div>

                                            <div className="m-1">
                                                <div className="mb-1">
                                                    <label>Опишите опыт работы:</label>
                                                    <textarea className="form-control form-control-sm" id="biography" name="biography" rows="3"{...register("biography", {
                                                        required: { value: true, message: "Опишите опыт работы" }
                                                    })}>
                                                    </textarea>
                                                    {errors.biography && <span className='text-danger'>{errors.biography?.message}</span>}
                                                </div>
                                                <div className="mb-1">
                                                    <div className="form-group m-1">
                                                        <label htmlFor="photo">Загрузите Ваше фото</label>
                                                        <input className="form-control form-control-sm" accept=".jpg, .jpeg, .png" type="file"
                                                            {...register("photo", {
                                                                required: { value: true, message: "Загрузите фото" },
                                                                maxLength: { value: 100, message: "Размер очень большой" }
                                                            })} />
                                                        {errors.photo && <span className='text-danger'>{errors.photo?.message}</span>}
                                                        {imgSize && <span className='text-danger'>{imgSize}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="checkbox" className="form-check-input" {...register("policy",
                                                        { required: { value: true, message: "Необходимо согласие на обработку персональных данных" } })} />
                                                    Согласие на обработку персональных данных <br /> <a href="https://leningradcenter.ru/api/redirecturls/privacy-policy">Политика конфиденциальности</a>
                                                </label>
                                                <div>
                                                    {errors.policy && <span className='text-danger'>{errors.policy?.message}</span>}
                                                </div>
                                            </div>
                                            <div>
                                                <input type="submit" id="submit" value="Зарегистрироваться" className={"btn btn-outline-success m-1"} />
                                            </div>
                                        </div>
                                    </div>
                                </form >
                            </div >
                        }
                    </div>
                }
            </>}
        </div>
    )
}


export const CertTitle = ({ selLanguage }) => {
    return (
        <div className="mt-2">
            {selLanguage === "en" ? <>
                <h1>Gift Certificates</h1>
                <p>
                    What kind of gift can be given to make it stay with a person forever? The answer is simple — give emotions! Leningrad Center gift certificates will allow you to give a visit to any show within 1 year from the date of purchase.
                </p>
                <p>
                    Certificates are cards in branded packaging, decorated in four colors: red, gold, blue and black. Denominations: 8 000 ₽, 10 000 ₽, 12 000 ₽, 14 000 ₽, 16 000 ₽.
                </p>
                <div>
                </div>

            </>
                :
                <>
                    <h1>Сертификаты</h1>
                    <p>
                        Какой подарок можно подарить, чтобы он навсегда остался с человеком? Ответ прост — дарите эмоции! Сертификаты «Ленинград Центра» позволят вам подарить визит на любое шоу в течение 1-го года с момента приобретения.
                    </p>
                    <p>
                        Сертификаты представляют собой карты в фирменной упаковке, оформленные в четырёх цветах: красный, золотой, синий и чёрный.
                        Номиналы: 8 000 ₽., 10 000 ₽., 12 000 ₽., 14 000 ₽., 16 000 ₽.
                    </p>
                    <div>
                    </div>
                </>
            }
        </div>
    )
}
import { useEffect, useState } from 'react';
import { Person, BoxArrowLeft } from 'react-bootstrap-icons';
import { Button, ButtonGroup, Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './NavMenu.css';

export const NavMenuAdmin = ({ role }) => {
    const [isOpen, setIsOpen] = useState(false);
    // const toggle = () => setIsOpen(!isOpen);
    const navigate = useNavigate();
    const navTo = (link) => {
        setIsOpen(false)
        navigate(link)
    }
    const loaction = useLocation()
    useEffect(() => {
        setIsOpen(false)
        window.scrollTo(0, 0)
    }, [loaction])

    return (
        <>
            <Navbar expanded={isOpen} onToggle={() => { setIsOpen(!isOpen) }} sticky='top' expand="md" className="bg-body-tertiary">
                <Container fluid>
                    <Navbar.Brand onClick={() => {
                        navigate("/admin")
                        setIsOpen(false)
                    }
                    }><img style={{ maxHeight: "50px" }} src="../../lc-logo-5-season.png" alt='Logo-LC' /></Navbar.Brand>
                    {role && <>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end text-center text-md-start'>
                            <Nav className="me-0">
                                <Nav.Link>Главная</Nav.Link>

                                <NavDropdown title="Разделы" id="basic-nav-dropdown">
                                    <NavDropdown.Item onClick={() => { navigate("/admin/shows") }}>Шоу</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => { navigate("/admin/exhibition") }}>
                                        Выставки
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => { navigate("/admin/news") }}>Новости</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => { navigate("/admin/troupe") }}>Труппа</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => { navigate("/admin/documentations") }}>Документы</NavDropdown.Item>
                                    {/* <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action/3.4">
                                        Separated link
                                    </NavDropdown.Item> 
                                    */}
                                    <Link className='dropdown-item' to={"/admin/redirecturls"}>Redirect Urls</Link>
                                </NavDropdown>
                                <NavDropdown title="Залы и продажи" id="basic-nav-dropdown">
                                    <NavDropdown.Item onClick={() => { navigate("/admin/schemeshall") }}>Схемы</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => { navigate("/admin/schemeshall") }}>Категории мест</NavDropdown.Item>
                                    {/* <NavDropdown.Item onClick={()=>{navigate("/admin/exhibition")}}>
                                        Выставки
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={()=>{navigate("/admin/news")}}>Новости</NavDropdown.Item>
                                    <NavDropdown.Item onClick={()=>{navigate("/admin/troupe")}}>Труппа</NavDropdown.Item>
                                    <NavDropdown.Item onClick={()=>{navigate("/admin/documentations")}}>Документы</NavDropdown.Item> */}
                                    {/* <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action/3.4">
                                        Separated link
                                    </NavDropdown.Item> 
                                    */}
                                </NavDropdown>
                                {(role === "Admins") &&
                                    <Nav.Link onClick={() => navTo("/admin/users")}>Пользователи</Nav.Link>
                                }
                                <Nav.Item>
                                    <ButtonGroup className='flex-fill ms-3'>

                                        <Button variant="info" size='sm' className='' onClick={() => navigate(".")}>
                                            <Person className='me-1' size='14' />Профиль
                                        </Button>
                                        <Button variant="outline-danger" size='sm' onClick={() => navigate("/logout")}>
                                            <BoxArrowLeft className='me-1' size='14' />Выход
                                        </Button>
                                    </ButtonGroup>
                                </Nav.Item>

                            </Nav>
                        </Navbar.Collapse>
                    </>
                    }
                </Container>
            </Navbar>
        </>
    )
}
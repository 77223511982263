import { Col, NavLink, Row } from "react-bootstrap"
import { useGetNewsListQuery } from "../../features/CallApi"
import { NewsPost } from "./NewsPost"
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { LoadScreenMin } from "../UI/LoadScreenMin";


export const NewsHomePage = ({selLng}) => {
    const [t] = useTranslation("global");
    const { data, isLoading } = useGetNewsListQuery({ pageIndex: 1, pageSize: 3, stringFilter: "", selLng: selLng })


    return (
        <>
            {!isLoading ? <>
                {data &&
                    <div className="mt-1 mb-1 pt-3 pb-3 border-bottom">
                        <h2>{t("menu.news")}</h2>
                        <Row className="justify-content-center">
                            {data.getNews.map(item =>
                                <Col key={item.id} lg={4}>
                                    <NewsPost item={item} />
                                </Col>
                            )}
                        </Row>
                    </div>
                }
            </> : <LoadScreenMin height={20}/>}
            <div className="justify-content-end">
                <NavLink onClick={()=>{}}>
                    
                </NavLink>
            </div>
        </>
    )
}
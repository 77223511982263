export const TicketsOrderStatus = ({ status , t }) => {
    let result;
    switch (status) {
        case 0: result = <span className="text-info">{t("orderStatus.newOrder")}</span>;
        break;
        case 1: result = <span className="text-warning">{t("orderStatus.awaitingPayment")}</span>;
        break;
        case 2: result = <span className="text-success">{t("orderStatus.paid")}</span>;
        break;
        case 3: result = <span className="text-warning">{t("orderStatus.returnCompleted")}</span>;
        break;
        case 4: result = <span className="text-warning">{t("orderStatus.rejected")}</span>;
        break;
        case 5: result = <span className="text-warning">{t("orderStatus.RefundPayInProgress")}</span>;
        break;
        default: result = <span>{t("orderStatus.incorrectData")}</span>
    }
    return result;
}

// NewOrder,
// InPayProgress,
// Paid,
// Refund,
// Cencal,
// RefundPayProgress,
//orderStatus
// "newOrder": "New order",
// "awaitingPayment": "In pay progress",
// "paid": "Paid",
// "returnCompleted": "Refund",
// "rejected": "Cencal",
// "RefundPayInProgress": "RefundPayProgress",
// "incorrectData": "Incorrect data"
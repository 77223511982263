import { Outlet } from 'react-router-dom';
import { NavMenu } from './NavMenu';
import '../custom.css';
import { MobileNavMenu } from './MobileNavMenu';
import { Footer } from './Footer/Footer'
import { Metrik } from '../features/Metrik';
import { Policy } from './Policy';
import { TableReservationForm } from './UI/TableReservationForm';
import { ServiceButton } from './UI/ServiceButton';
import { LoadScreen } from './UI/LoadScreen';
import { useEffect, useRef, useState } from 'react';

export const Layout = ({ changanLanguage, selLanguage, reserFormShow, setReserFormShow, aboutMobMenu, setAboutMobMenu, clientToken }) => {

  const footerRef = useRef()
  const [minHeightContent, setMinHeigthContent] = useState(100)
  useEffect(() => {
    const resizeHandeler = () => {
      setMinHeigthContent(window.innerHeight - footerRef?.current.clientHeight)
    }
    setMinHeigthContent(window.innerHeight - footerRef?.current.clientHeight)
    window.addEventListener('resize', resizeHandeler)
    return function () {
      window.addEventListener('resize', resizeHandeler)
    }
  }, [])

  return (
    <>
      <Policy />
      <Metrik />
      <div className='main-content' style={{ minHeight: minHeightContent + 'px' }}>
        <NavMenu changanLanguage={changanLanguage} selLanguage={selLanguage} setAboutMobMenu={setAboutMobMenu} />
        <Outlet />
      </div>
      <Footer footerRef={footerRef} />
      {/* <ServiceButton /> */}
      {/* {reserFormShow && */}
      <TableReservationForm reserFormShow={reserFormShow} setReserFormShow={setReserFormShow} />
      {/* } */}
      <MobileNavMenu
        clientToken={clientToken}
        setReserFormShow={setReserFormShow}
        aboutMobMenu={aboutMobMenu}
        setAboutMobMenu={setAboutMobMenu} />
    </>
  );
}

import classes from './LoadScreen.module.css';

export const LoadScreenMin = (props) => {
    return (
        <div ref={props.refcontanet} className={`d-flex flex-column justify-content-center align-content-center ${classes.loadBody}`} style={{ minHeight: '100px', height: props?.height + "vh" }} {...props}>
            <div className="d-flex justify-content-center align-content-center">
                <div className={classes.loadLogo}>
                </div>
            </div>
            <div className="d-flex justify-content-center align-content-center">
                <div className={classes.loadArrow}>
                </div><span className={classes.textLoading}>Loading...</span>
            </div>
        </div>
    )
}
import { useEffect, useState } from "react";


export const useImportScheme = () => {
    const [schemeData, setSchemeData] = useState(null)
    const [paySchemeData, setPaySchemeData] = useState(null)
    const [url, setUrl] = useState()
    const [payUrl, setPayUrl] = useState()

    const getDataScheme = (url) => {
        fetch(url)
            .then(data => data.json())
            .then(json => setSchemeData(json))
    }

    useEffect(() => {
        if (url != null) {
            getDataScheme(url)
        }
    }, [url])

    useEffect(() => {
        if (payUrl != null) {
            getDataScheme(payUrl)
        }
    }, [payUrl])
    
    return {
        schemeData: schemeData,
        setSchemeData: setSchemeData,
        paySchemeData: paySchemeData,
        setPaySchemeData: setPaySchemeData,
        setUrl: setUrl,
        setPayUrl: setPayUrl
    };
}
import { Button, Col, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ImageLoader } from './ImageLoader';
import { useEffect } from 'react';

export const AfishaItem = ({ item, setShowModel, setDateList, itemsSize, setParam, searchParams, hideModal }) => {
    const [t] = useTranslation('global')
    const navigete = useNavigate();
    const toNav = (link) => {
        window.scrollTo(0, 0)
        navigete(link)
    }
    useEffect(() => {
        if (searchParams.get("selectShow") === item.seoUrl) {
            setDateList(item.showSchedules)
        }
        else {
            // hideModal()
        }
    }, [searchParams])

    return (
        <div className='position-relative' >
            {itemsSize > 1 ?
                <div style={{ maxWidth: '400px', inset: 0, margin: 'auto' }}>
                    <Link to={`/about-project/show/${item.seoUrl}`} className='link-decor'>
                        <div className='position-relative text-center'>
                            {/* <Image src={`/api/shows/afisha-img/${item.id}`} style={{maxHeight: '65vh'}} alt={item.name} fluid /> */}
                            <ImageLoader src={`/api/shows/afisha-img/${item.id}`} name={item.name} mHeight={'65vh'} />
                        </div>
                        <h4 className='afisha-event-title mt-3 mb-0 d-none d-md-block'>{item.name}</h4>
                        <h6 className='afisha-event-title mt-3 mb-0 d-md-none'>{item.name}</h6>
                        <div className='afisha-event-desc mt-0 mb-1 ms-1' style={{ fontSize: "0.75rem" }}>{item.description}</div>
                    </Link>
                    <div>
                        <div className='d-grid gap-1' size='sm'>
                            {item.showSchedules?.length > 0 &&
                                <Button className='border shadow btn-afisha' style={{ borderColor: "#272929" }} onClick={() => {
                                    setParam(item.seoUrl)
                                    setShowModel(true)
                                    setDateList(item.showSchedules)
                                }} variant='light'>{t("afisha.buytickets")}</Button>
                                // :
                                // <Button className='border shadow btn-afisha' variant='light'>{t("afisha.subscribe")}</Button>
                            }
                            {item.buyButtonStatus &&
                                <Button className='border shadow btn-afisha' style={{ borderColor: "#272929" }} onClick={() => {
                                    window.open(item.buyButtonLink, '_blank').focus();
                                }} variant='light'>{item.buyButtonText}</Button>
                            }
                            {/* <Button><BoxArrowInRight size={18} /></Button> */}
                        </div>
                    </div>
                </div>
                :
                <>
                    <Row className='justify-content-md-center'>
                        <Col>
                            <Link to={`/about-project/show/${item.seoUrl}`} className='link-decor'>
                                <div onClick={() => { toNav(`/about-project/show/${item.seoUrl}`) }} className='position-relative text-center'>
                                    <div className='d-md-none'>
                                        <ImageLoader src={`/api/shows/afisha-img/${item.id}`} name={item.name} mHeight={'60vh'} />
                                        {/* <Image alt={item.name} className='' src={`/api/shows/afisha-img/${item.id}`} fluid style={{ maxHeight: "60vh" }} /> */}
                                    </div>
                                    <div className='d-none d-md-block'>
                                        <ImageLoader src={`/api/shows/head-img/${item.id}`} wide={true} name={item.name} mHeight={'70vh'} />
                                        {/* <Image alt={item.name} src={`/api/shows/head-img/${item.id}`} fluid style={{ maxHeight: "70vh" }} /> */}
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col lg={4}>
                            {/* <h4 onClick={() => { toNav(`/about-project/show/${item.id}`) }} className='afisha-event-title mt-3 mb-0'>{item.name}</h4> */}
                            {/* <div className='afisha-event-desc mt-0 mb-1 ms-1' style={{ fontSize: "0.75rem" }}>{item.description}</div> */}
                            <Link to={`/about-project/show/${item.seoUrl}`} className='link-decor'>
                                <h3 className='afisha-event-title mt-3 mb-0'>
                                    <strong>{item.name}</strong>
                                </h3>
                                <p>
                                    <strong>
                                        {item.shortDescription}
                                    </strong>
                                </p>
                                <p>
                                    {t("show.director")} — <strong>
                                        {item.director}
                                    </strong>
                                </p>
                                {/* <p className="d-none d-lg-block">
                                    {t("show.duration")}: {item.duration}
                                </p> */}
                                <p>
                                    {t("show.age")} <strong>{item.ageCategory}+</strong>
                                </p>
                            </Link>
                            <div>
                                <div className='d-grid gap-1' size='sm'>
                                    {item.showSchedules?.length > 0 &&
                                        <Button className='border shadow btn-afisha' style={{ borderColor: "#272929" }} onClick={() => {
                                            setShowModel(true)
                                            setDateList(item.showSchedules)
                                            setParam(item.seoUrl)
                                        }} variant='light'>{t("afisha.buytickets")}</Button>
                                        // :
                                        // <Button className='border shadow btn-afisha' variant='light'>{t("afisha.subscribe")}</Button>
                                    }
                                    {item.buyButtonStatus &&
                                        <Button className='border shadow btn-afisha' style={{
                                            borderColor: "#272929"
                                        }} onClick={() => {
                                            window.open(item.buyButtonLink, '_blank').focus();
                                        }} variant='light'>{item.buyButtonText}</Button>
                                    }
                                    {/* <Button><BoxArrowInRight size={18} /></Button> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
            }
        </div>
    )
}
import { useRef, useState } from "react"
import { useGetExhibitionsAfishaQuery, useGetExhibitionsArchiveQuery } from "../../features/CallApi"
import { useEffect } from "react"
import { useTranslation } from "react-i18next";
import { LoadScreenMin } from "../UI/LoadScreenMin";
import { Button, Col, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ImageLoader } from "../UI/ImageLoader";

const ExhibitionsAfisha = ({ maxCount, t, toNav }) => {

    const [pageIndex] = useState(1)
    const [pageSize] = useState(maxCount ? maxCount : 1)
    const [stringFilter, setStringFilter] = useState("")
    const [exhibitions, setExhibitions] = useState()
    const { data, isLoading, refetch } = useGetExhibitionsAfishaQuery({ pageIndex, pageSize, stringFilter })


    useEffect(() => {
        refetch()
    }, [])


    useEffect(() => {
        if (data) {
            const delElement = data.getExhibitions[0]
            const newData = data.getExhibitions.filter((item) => item !== delElement);
            setExhibitions(newData)
        }
    }, [data])
    return (
        <>
            {!isLoading ? <>
                {data.getExhibitions.length > 0 &&
                    <>
                        <div className="mt-1 mb-1 pt-3 pb-3 border-bottom">
                            <h2>{t("afisha.exhibition")}</h2>
                            <Row className="justify-content-center">
                                <Col key={data.getExhibitions[0].id} lg={7}>
                                    <div onClick={() => { toNav(`/about-project/exhibition/${data.getExhibitions[0].seoUrl}`) }} style={{ cursor: 'pointer' }} className='position-relative text-center'>
                                        <div className='d-md-none'>
                                            <ImageLoader
                                                src={`/api/exhibitions/afisha-img/${data.getExhibitions[0].id}`}
                                                name={data.getExhibitions[0].name}
                                                wide={true}
                                                mHeight={'60vh'} />
                                        </div>
                                        <div className='d-none d-md-block'>
                                            <ImageLoader
                                                src={`/api/exhibitions/afisha-img/${data.getExhibitions[0].id}`}
                                                name={data.getExhibitions[0].name}
                                                wide={true}
                                                mHeight={'70vh'} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </>
                }
            </> : <LoadScreenMin height={20} />
            }
        </>
    )
}

const ExhibitionsArchive = ({ t, toNav, maxCount }) => {

    const [pageIndex, setPageIndex] = useState(1)
    const pageNext = useRef()
    const [pageSize] = useState(maxCount ? maxCount : 10)
    const [stringFilter, setStringFilter] = useState("")
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState([]);
    const { data: exhibitions, isLoading } = useGetExhibitionsArchiveQuery({ pageIndex, pageSize, stringFilter })

    useEffect(() => {
        if (data.length === 0 && exhibitions) {
            setData([...exhibitions.getExhibitions])
        }
        else if (data.length > 0) {
            setData([...data, ...exhibitions.getExhibitions])
        }
        setFetching(false)
        // setFiltering(false)
    }, [exhibitions])

    useEffect(() => {
        if (exhibitions && !fetching) {
            const scrollHandeler = (e) => {
                if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 15 && pageIndex < exhibitions.totalPages) {
                    // console.log("loading...")
                    setFetching(true)
                    setPageIndex(pageIndex => pageIndex + 1)
                }
            }
            document.addEventListener('scroll', scrollHandeler)
            return function () {
                document.removeEventListener('scroll', scrollHandeler)
            }

        }
    }, [exhibitions, fetching])

    // useEffect(() => { console.log(pageNext) }, [pageNext])

    return (
        <>
            {data.length > 0 &&
                <div>
                    <h3>{t("afisha.exhibitionHeld")}</h3>
                    <Row className="justify-content-center">

                        {data?.map(item =>
                            <Col key={item.id} sm={6} lg={4} className="mb-1">
                                <div style={{ cursor: 'pointer' }} onClick={() => { toNav(`/about-project/exhibition/${item.seoUrl}`) }}>
                                    <Image src={`/api/exhibitions/afisha-img/${item.id}`} fluid />
                                    <h4 className="mt-3">
                                        {item.name}
                                    </h4>
                                    <p>
                                        {item.shortDescription}
                                    </p>
                                </div>
                            </Col>
                        )}
                    </Row>
                    {fetching && <LoadScreenMin height={20} />}
                    {pageIndex < exhibitions?.totalPages && <div className={'w-100 text-center'}><Button ref={pageNext} variant="light" onClick={() => { setPageIndex(pageIndex => pageIndex + 1) }}>Показать другие выставки</Button></div>}
                </div>
            }
        </>
    )
}

export const ExhibitionsAll = ({ maxCount }) => {
    const navigete = useNavigate()
    const [t] = useTranslation("global");
    const toNav = (link) => {
        window.scrollTo(0, 0)
        navigete(link)
    }

    return (
        <>
            <ExhibitionsAfisha maxCount={maxCount} t={t} toNav={toNav} />
            <ExhibitionsArchive t={t} toNav={toNav} />
        </>
    )
} 
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { CertTitle } from "./CertTitle"
import classes from './Certificate.module.css';
import { CertCompletion } from "./CertCompletion";
import { CertForm } from "./CertForm";
import { CertConfirm } from "./CertConfirm";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";



export const Certificate = ({ selLanguage }) => {

    const navigate = useNavigate()
    const [t] = useTranslation("global");
    const [dataOrder, setDataOrder] = useState()
    const [orderStatus, setOrderStatus] = useState()
    const [certToken, setCertToken] = useState()
    const [responseUrl, setResponseUrl] = useSearchParams();
    const [payStatus, setPayStatus] = useState()
    const resetForm = () => {
        sessionStorage.removeItem("certToken")
        sessionStorage.removeItem("dataOrder")
        setPayStatus(null)
        navigate("/about-project/certificates")
        window.location.reload()
    }

    useEffect(() => {
        setPayStatus(responseUrl.get('paystatus'))
        setCertToken(sessionStorage.getItem("certToken"))
        setDataOrder(JSON.parse(sessionStorage.getItem("dataOrder")))
    }, []
    )
    useEffect(() => {
        if (payStatus === "payed") {
            if (dataOrder === null) {
                navigate("/about-project/certificates")
            }
            else {
                axios.get(`https://app.leningradcenter.ru/api/certificateorders/completionorder/${dataOrder.orderId}`,
                    {
                        headers: {
                            Authorization: "Bearer " + dataOrder.token,
                        }
                    }).catch((error) => {
                        if (error) {
                            resetForm()
                        }
                    }).then((response) => {
                        setOrderStatus(response)
                    })
                console.log(orderStatus)
            }
        }

    }, [payStatus])

    return (
        <>
            <Helmet>
                <title>{t("menu.certificates")} - {t("mainTag.title")}</title>
                <meta name="og:title" content={`${t("menu.certificates")} - ${t("mainTag.title")}`} />
                <meta name="description" content={"Какой подарок можно подарить, чтобы он навсегда остался с человеком? Ответ прост — дарите эмоции! Сертификаты «Ленинград Центра» позволят вам подарить визит на любое шоу в течение 1-го года с момента приобретения."} />
                <meta name="og:description" content={"Какой подарок можно подарить, чтобы он навсегда остался с человеком? Ответ прост — дарите эмоции! Сертификаты «Ленинград Центра» позволят вам подарить визит на любое шоу в течение 1-го года с момента приобретения."} />
                {/* <meta property="og:image" content={data.headLayoutImageType ? `/api/shows/head-wide-img/${data.id})` : "/Show/091A8332.jpg"} /> */}
            </Helmet>
            {payStatus ?
                <CertCompletion t={t} dataOrder={dataOrder} orderStatus={orderStatus} resetForm={resetForm} selLanguage={selLanguage} />
                :
                <div>
                    {certToken ? null :
                        <div>
                            <CertTitle t={t} selLanguage={selLanguage} />
                            <CertForm t={t} setDataOrder={setDataOrder} setCertToken={setCertToken} selLanguage={selLanguage} />
                        </div>
                    }
                    {dataOrder ? <CertConfirm t={t} dataOrder={dataOrder} serDataOrder={setDataOrder} selLanguage={selLanguage} /> : null
                    }
                </div>
            }
            <div className="d-flex justify-content-center">
                <div className="logoabr text-center"><img className="img-fluid" src="/Cert/logoabr.png" /></div>
                <div className={`${classes.cardPay} text-center`}>
                    <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            </div>
        </>
    )
}
import { Swiper, SwiperSlide } from 'swiper/react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { FreeMode, Scrollbar, Navigation } from 'swiper/modules';
import { AfishaItem } from './AfishaItem';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ShowSchedulesItem } from './ShowSchedulesItem';

export const SwiperAfisha = ({ items }) => {
    const [showModel, setShowModel] = useState(false)
    const [dateList, setDateList] = useState()
    let [searchParams, SetSearchParams] = useSearchParams();


    const [t] = useTranslation('global')
    const navigate = useNavigate()

    const sizeSlider = () => {
        const itemsSize = items.length
        const result = {
            '@0.00': {
                slidesPerView: itemsSize > 1 ? 2 : 'auto',
                centeredSlides: itemsSize > 1 ? false : true,
                spaceBetween: 40,
            },
            "@0.50": {
                slidesPerView: itemsSize > 1 ? 2 : 1,
                centeredSlides: itemsSize > 1 ? false : true,
                spaceBetween: 20,
            },
            '@1.00': {
                slidesPerView: itemsSize > 3 ? 3 : itemsSize,
                centeredSlides: itemsSize > 1 ? false : true,
                spaceBetween: 40,
            },
            '@1.50': {
                slidesPerView: itemsSize > 4 ? 4 : itemsSize,
                centeredSlides: itemsSize > 1 ? false : true,
                spaceBetween: 50,
            },
        }
        return (result)
    }

    const setParam = (seoUrl) => {
        console.log(seoUrl)
        searchParams.set("showModal", 'true')
        searchParams.append("selectShow", seoUrl)
        SetSearchParams(searchParams)
    }
    const hideModal = () => {
        setShowModel(false)
        searchParams.delete("selectShow")
        searchParams.delete("showModal")
        SetSearchParams(searchParams)
        setDateList(null)
    }

    useEffect(() => {
        if (searchParams.get('showModal') === 'true') {
            setShowModel(true)
        }
    }, [])

    return (
        <div>
            <Modal
                show={showModel}
                size="md"
                fullscreen={"sm-down"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // backdrop="static"
                onHide={() => hideModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("afisha.selectdate")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {dateList &&
                        <>
                            {dateList.map(item =>
                                <ShowSchedulesItem key={item.id} item={item} />
                            )}
                        </>
                    }
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={() => setShowModel(false)}>{t("general.close")}</Button>
                </Modal.Footer> */}
            </Modal>
            {/* <div style={{ maxWidth: items.length === 1 ? "70vw" : "100vw", margin: "0 auto" }}> */}
            <div style={{ margin: "0 auto" }}>
                <Swiper
                    slidesPerView={'auto'}
                    // centeredSlides={true}
                    spaceBetween={30}
                    scrollbar={true}
                    // loop={true}
                    // freeMode={true}
                    navigation={true}
                    modules={[Scrollbar, FreeMode, Navigation]}
                    className="afisha-swiper"
                    breakpoints={sizeSlider()}
                >
                    {items.map((item) =>
                        <SwiperSlide key={item.name} className='mb-3 afisha-swiper-slide'>
                            {/* {searchParams.get('selectShow') === item.seoUrl && setDateList(item.showSchedules)} */}
                            <AfishaItem
                                itemsSize={items.length}
                                setDateList={setDateList}
                                SwiperSlide={SwiperSlide}
                                setShowModel={setShowModel}
                                item={item}
                                setParam={setParam}
                                searchParams={searchParams}
                                hideModal={hideModal} />
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </div>
    )
}

// export default SwiperAfisha;
import { useState } from "react"
import { useGetExhibitionsAfishaQuery, useGetExhibitionsArchiveQuery } from "../../features/CallApi"
import { useEffect } from "react"
import { useTranslation } from "react-i18next";
import { LoadScreenMin } from "../UI/LoadScreenMin";
import { Col, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ImageLoader } from "../UI/ImageLoader";
import { Link } from "react-router-dom";


const ExhibitionsArchive = ({ t, toNav, maxCount }) => {

    const [pageIndex] = useState(1)
    const [pageSize] = useState(maxCount ? maxCount : 10)
    const [stringFilter, setStringFilter] = useState("")
    const { data: exhibitions, isLoading } = useGetExhibitionsArchiveQuery({ pageIndex, pageSize, stringFilter })

    return (
        <>
            {exhibitions?.getExhibitions.length > 0 &&
                <div>
                    <h3>{t("afisha.exhibitionHeld")}</h3>
                    <Row className="justify-content-center">

                        {exhibitions.getExhibitions?.map(item =>
                            <Col key={item.id} sm={4} lg={3} className="mb-1">
                                <Link to={`/about-project/exhibition/${item.seoUrl}`} className='link-decor'>
                                    <Image src={`/api/exhibitions/afisha-img/${item.id}`} fluid />
                                    <h4 className="mt-3">
                                        {item.name}
                                    </h4>
                                    <p>
                                        {item.shortDescription}
                                    </p>
                                </Link>
                            </Col>
                        )}
                    </Row>
                </div>
            }
        </>
    )
}

const ExhibitionsAfisha = ({ maxCount, t, toNav }) => {

    const [pageIndex] = useState(1)
    const [pageSize] = useState(maxCount ? maxCount : 1)
    const [stringFilter, setStringFilter] = useState("")
    const [exhibitions, setExhibitions] = useState()
    const [getExhibitions, setGetExhibitions] = useState()
    const { data, isLoading, refetch, error, isError, status } = useGetExhibitionsAfishaQuery({ pageIndex, pageSize, stringFilter })

    useEffect(() => {
        setGetExhibitions(data?.getExhibitions)
    }, [data])

    useEffect(() => {
        refetch()
    }, [])


    useEffect(() => {
        if (data) {
            const delElement = data.getExhibitions[0]
            const newData = data.getExhibitions.filter((item) => item !== delElement);
            setExhibitions(newData)
        }
    }, [data])
    return (
        <>
            {!isLoading ? <>

                {getExhibitions?.length > 0 ?
                    <>
                        <div className="mt-1 mb-1 pt-3 pb-3 border-bottom">
                            <h2>{t("afisha.exhibition")}</h2>
                            <Row className="justify-content-center">
                                <Col key={data.getExhibitions[0].id} lg={7}>
                                    <Link to={`/about-project/exhibition/${data.getExhibitions[0].seoUrl}`} className='position-relative text-center link-decor'>
                                        <div className='d-md-none'>
                                            <ImageLoader
                                                src={`/api/exhibitions/afisha-img/${data.getExhibitions[0].id}`}
                                                name={data.getExhibitions[0].name}
                                                wide={true}
                                                mHeight={'60vh'} />
                                            <div className="text-start">
                                                <h4 className="mt-3">
                                                    {data.getExhibitions[0].name}
                                                </h4>
                                                <p>
                                                    {data.getExhibitions[0].shortDescription}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='d-none d-md-block'>
                                            <ImageLoader
                                                src={`/api/exhibitions/afisha-img/${data.getExhibitions[0].id}`}
                                                name={data.getExhibitions[0].name}
                                                wide={true}
                                                mHeight={'70vh'} />
                                            <div className="text-start">
                                                <h4 className="mt-3">
                                                    {data.getExhibitions[0].name}
                                                </h4>
                                                <p>
                                                    {data.getExhibitions[0].shortDescription}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </>
                    :
                    <ExhibitionsArchive t={t} toNav={toNav} />
                }
            </> : <LoadScreenMin height={20} />
            }
        </>
    )
}



export const Exhibitions = ({ maxCount }) => {
    const navigete = useNavigate()
    const [t] = useTranslation("global");
    const toNav = (link) => {
        window.scrollTo(0, 0)
        navigete(link)
    }


    return (
        <>
            <ExhibitionsAfisha maxCount={maxCount} t={t} toNav={toNav} />
        </>
    )
} 
import { Button, ButtonGroup, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { LoadScreenMin } from "../../UI/LoadScreenMin";
import { useAddShowMutation } from "../../../features/CallApi";
import { useState } from "react";

export const ShowAdd = ({ showAddForm, setShowAddForm, setShowEditForm, setShowId }) => {
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    
    const [addShow, { isLoading }] = useAddShowMutation()
    const onSubmit = (data) => {
        addShow(data).unwrap()
            .then((r) => {
                console.log(r.id)
                setShowId(r.id)
                setShowAddForm(false)
                setShowEditForm(true)
            })
            .catch((e) => { console.log(e) })
    }
    return (
        <Modal
            show={showAddForm}
            onHide={() => {
                reset()
                setShowAddForm(false)
            }}
            backdrop="static"
            keyboard={false}
        >

            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>Добавление шоу</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isLoading ?
                        <>
                            <div className="form-floating mb-3">
                                <input autoComplete="off" type="text" className="form-control" id="name" placeholder="Название шоу" {...register("name", { required: true })} />
                                <label htmlFor="name">Название шоу</label>
                            </div> </>
                        :
                        <LoadScreenMin height="30" />
                    }

                </Modal.Body>

                <Modal.Footer>
                    <ButtonGroup size="sm">
                        <Button variant="primary" onClick={() => {
                            reset()
                            setShowAddForm(!showAddForm)
                        }
                        }>Отменить</Button>
                        <Button type="submit" variant="success">
                            Добавить
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </form>

        </Modal>
    )
}

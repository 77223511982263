import { Fragment, useEffect, useRef, useState } from "react"
import { Button, ButtonGroup, Col, FloatingLabel, Form, InputGroup, Row, Spinner } from "react-bootstrap"
import { Check, Gear, Link, Trash, X } from "react-bootstrap-icons"
import { useForm } from "react-hook-form";
import { useDeleteSeatMutation, usePutEditSeatMutation } from "../../../../features/CallApi";


export const Seat = ({ seat, refetch }) => {

    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const seatRef = useRef()
    const [editing, setEditing] = useState(false)
    const [putEditSeat, { isLoading }] = usePutEditSeatMutation()
    const [deleteSeat, { isLoading: isDeleting }] = useDeleteSeatMutation()
    useEffect(() => {
        setValue("id", seat.id)
        // setValue("name", seat.name)
        setValue("type", seat.type)
        setValue("nameSeatPremiera", seat.nameSeatPremiera)
    }, [editing])

    const submit = (data) => {
        putEditSeat(data).then(
            () => {
                refetch()
                setEditing(false)
            }
        )
    }


    return (
        <>
            {/* <Form onSubmit={handleSubmit(submit)}>
                <Col>
                    <InputGroup style={{ lineHeight: '18px' }}>
                        <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">X</InputGroup.Text>
                        <Form.Control style={{ lineHeight: '18px' }} className="p-1" defaultValue={seat.x}
                            {...register("X", { required: { value: true } })}
                        />
                    </InputGroup>
                    <Button style={{ lineHeight: '10px' }} type="submit" className="p-1" variant="success"><Check size={16} /></Button>
                </Col>
            </Form> */}

            {!editing ? <>
                <Row key={seat.id} id={`${seat.id}`} ref={seatRef} className="g-1">
                    <Col>М: <strong>{seat.name}</strong></Col>
                    <Col>X: {seat.x}</Col>
                    <Col>Y: {seat.y}</Col>
                    <Col>
                        <ButtonGroup>
                            <Button size="sm"
                                style={{ lineHeight: '10px' }}
                                variant="light" className="p-1"
                                onClick={() => { setEditing(!editing) }}>
                                <Gear size={16} />
                            </Button>
                            <Button
                                className="p-1"
                                variant="outline-danger"
                                onClick={() => {
                                    if (window.confirm("Вы уверены?")) {
                                        deleteSeat(seat.id).then(() => {
                                            refetch()
                                        })
                                    }

                                }}>
                                <Trash />
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>

            </> :
                <Form onSubmit={handleSubmit(submit)} className="mb-3">
                    <Row className="pt-2 pb-2">
                        <Col>
                            <Row className="mb-1">
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">М</InputGroup.Text>
                                            <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1" defaultValue={seat.name}
                                                {...register("name", { required: { value: true } })}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1"><Link /></InputGroup.Text>
                                            <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1" defaultValue={seat.name}
                                                {...register("nameSeatPremiera", { required: { value: true } })}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">X</InputGroup.Text>
                                            <Form.Control size="sm" style={{ lineHeight: '18px' }} className="p-1" defaultValue={seat.x}
                                                {...register("X", { required: { value: true } })}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="d-flex">
                                        <InputGroup style={{ lineHeight: '18px' }}>
                                            <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">Y</InputGroup.Text>
                                            <Form.Control {...register("Y", { required: { value: true } })} style={{ lineHeight: '18px' }} className="p-1" defaultValue={seat.y} />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={'auto'}>
                            {!isLoading ? <>
                                <ButtonGroup>
                                    <Button style={{ lineHeight: '10px' }} type="submit" className="p-1" variant="success"><Check size={16} /></Button>
                                    <Button style={{ lineHeight: '10px' }} onClick={() => { setEditing(false); reset(); }} className="p-1" variant="ligth"><X size={16} /></Button>
                                </ButtonGroup>
                            </> : <Spinner />}
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <InputGroup>
                                <Form.Select size="sm" {...register("type")}>
                                    <option value={"singl"}>Одиночное</option>
                                    <option value={"topSingl"}>Вниз одиночное</option>
                                    <option value={"left"}>Левый</option>
                                    <option value={"right"}>Правый</option>
                                    <option value={"turnLeft"}>Влево</option>
                                    <option value={"turnRight"}>Вправо</option>
                                    <option value={"boxCenter"}>Центр ложи</option>
                                    <option value={"boxTopLeft"}>Верх-лева ложи</option>
                                    <option value={"boxTopRight"}>Верх-право ложи</option>
                                    <option value={"topLeft"}>Вниз левый</option>
                                    <option value={"topRight"}>Вниз правый </option>
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    {/* <Row key={seat.id} id={`${seat.id}`} ref={seatRef} className="g-1">

                        <Col>
                            <Form.Group className="d-flex">
                                <InputGroup style={{ lineHeight: '18px' }}>
                                    <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1">X</InputGroup.Text>
                                    <Form.Control style={{ lineHeight: '18px' }} className="p-1" defaultValue={seat.x}
                                        {...register("X", { required: { value: true } })}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="d-flex">
                                <InputGroup style={{ lineHeight: '18px' }}>
                                    <InputGroup.Text style={{ lineHeight: '18px' }} className="p-1"
                                        {...register("Y", { required: { value: true } })}
                                    >Y</InputGroup.Text>
                                    <Form.Control style={{ lineHeight: '18px' }} className="p-1" defaultValue={seat.y} />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col>
                        </Col>
                    </Row> */}
                </Form>
            }
        </>
    )
}
import { useEffect, useState } from "react";
import PhotoAlbum from 'react-photo-album';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";


export const Stage = ({ selLanguage }) => {

    const [index, setIndex] = useState(-1);
    const [indexSmall, setIndexSmall] = useState(-1);
    const photos = [
        { src: "/AboutProjcet/091A8161.jpg", width: 2500, height: 1667, alt: "Лениград Центра" },
        { src: "/AboutProjcet/091A8183.jpg", width: 2500, height: 1667, alt: "Лениград Центра" },
        { src: "/AboutProjcet/091A8205.jpg", width: 2500, height: 1667, alt: "Лениград Центра" },
        { src: "/AboutProjcet/091A8329.jpg", width: 2500, height: 1667, alt: "Лениград Центра" },
        { src: "/AboutProjcet/091A8339.jpg", width: 2500, height: 1667, alt: "Лениград Центра" },
        { src: "/AboutProjcet/091A8332.jpg", width: 2500, height: 1667, alt: "Лениград Центра" },
    ]
    // const photos2 = [

    // ]

    return (
        <>
            {selLanguage === "en" ?
                <>
                    <h4><strong>Main stage</strong></h4>
                    <p>
                        The Main stage of Leningrad Centre seats up to 300 people, 70 of which can be seated on the balcony and in the boxes. The hall is equipped with comfortable chairs and tables with beverages served. The stage house is a combination of 36 pieces of lifting and lowering devices, allowing to change the floor configuration for each performance. Due to the most intricate mechanical devices, any act can be turned into an unprecedented show. The stage is equipped with portable displays, a scrim and projectors with video mapping and 3D tracking functions. The Live curtain for Leningrad Centre was developed by famous theater artist Pavel Kaplevich. The hall is crowned with the motional light system made up of a thousand of LED spheres. Individually controlled spheres change their moving speed and color in accordance with the required program. All this was inspired by the works of Mark Fisher, the famous set designer, and kinetic sculptures.
                    </p>
                    <p>
                        Felix Mikhailov: “Leningrad Centre stage house captures the features of classical theater transformed into a modern high technology reality. We have a curtain as a stage icon; we have a chandelier as a lucky charm of the theater hall. The Leningrad Centre project uniquely combines many genres, styles and technologies. Therefore, our curtain is made of unique “live fabric”, and the chandelier in itself is a kinetic sculpture, a work of modern art.”
                    </p>

                    <h4 className="mt-4">
                        <strong>The Small stage</strong>
                    </h4>
                    <p>
                        The Small stage is a chamber space where there is room for improvisation and experimentation. 
                        The most striking event of the Small Stage is the provocative production of LOVESICK - an elegant and courtly show, tickets for which are always sold out instantly. 
                        The show “Old Hotel”, which shows the amorous and ironic adventures of guests of the most unusual hotel in the world - acrobat-receptionists, a singing metro d'hotel, seductive maids make the evening unforgettable and full of intense emotions. 
                        Also in the tenth season, the premiere of the “Talent Show” Bella Magica thundered on the Small Stage, where the world famous Italian magician Eddie Arduino appeared before the audience.
                    </p>
                </>
                :
                <>
                    <h4><strong>Основная сцена</strong></h4>
                    <p>Основная сцена «Ленинград Центра» вмещает 300 человек, из них 70 человек смогут разместиться на балконе и в ложах. Зал оснащен удобными креслами и столиками с сервисом подачи напитков.</p>
                    <p>Сценическая коробка представляет собой комбинацию 36 подъемно-опускных механизмов, позволяющих под каждую постановку менять конфигурацию пола. Сложнейшие устройства механизации позволяют превращать любое действие в беспрецедентное шоу. Сцена оснащена мобильными передвижными экранами, проекционной сеткой и проекторами с функциями video mapping и 3D tracking. «Живой» занавес для «Ленинград Центра» разработал известный театральный художник Павел Каплевич.</p>
                    <p>Кинетическая люстра из тысячи LED-шаров венчает зал. Индивидуально управляемые шары меняют скорость движения и цвет в соответствии с заданной программой. Вдохновением послужили работы известного художника-постановщика Марка Фишера и кинетические скульптуры.</p>
                    <p>
                        «Сценическая коробка «Ленинград Центра» заключает в себе признаки классического театра, трансформированные в современную высокотехнологичную реальность. У нас есть занавес, как визитная карточка сцены; у нас есть люстра, как талисман театрального зала. Но уникальность проекта «Ленинград Центра» в синтезе жанров, стилей и технологий. Поэтому занавес у нас выполнен из уникальной «живой ткани», а люстра является по сути кинетической скульптурой, предметом современного искусства»
                    </p>
                    <p className="text-end">
                        <span >
                            <strong>
                                Феликс Михайлов
                            </strong>
                        </span>
                    </p>
                    <div>
                    </div>
                    <h4 className="mt-4">
                        <strong>Малая сцена</strong>
                    </h4>

                    <p>Малая сцена — это камерное пространство, где есть место импровизации и экспериментам.</p>
                    <p>Самое яркое событие Малой сцены — провокационная постановка LOVESICK — изысканное и куртуазное шоу, билеты на которое раскупаются всегда моментально. Шоу «Старый отель», где показывают амурные и ироничные приключения гостей самого необычного в мире отеля — акробаты-портье, поющий метродотель, соблазнительные горничные делают вечер незабываемым и полным острых эмоций. Также в десятом сезоне на Малой сцене прогремела премьера – «Шоу талантов» Bella Magica, где перед зрителями предстал всемирно известный итальянский фокусник Эдди Ардуино.</p>
                </>
            }
            <div>
                <PhotoAlbum columns={3} layout="masonry" photos={photos} onClick={({ index }) => setIndex(index)} />
                <Lightbox
                    slides={photos}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    // enable optional lightbox plugins
                    plugins={[Slideshow, Thumbnails, Zoom, Captions]}
                />
            </div>
        </>
    )
}
import { Accordion, Container } from "react-bootstrap"
import { useTranslation } from "react-i18next"


export const CookiesPolicy = () => {
    const [t] = useTranslation('global')

    return (
        <>
            <div className="haed-layout">
                <div className="head-layout-img" style={{ backgroundImage: "url(/Show/091A8332.jpg" }}></div>

                <div className="head-mask">
                    <div className="rounded-3" style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem", inset: 0, margin: 'auto' }}>
                        <div className="text-white m-auto">
                            <div className="h1 text-center">
                                Политика использования Cookies
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Container>
                <h3 className="mt-3">
                    Политика использования Cookies
                </h3>
                <p>
                    В данном разделе Вы можете ознакомиться с информацией о том, как ООО "Демера" обрабатывает данные,
                    собранные в процессе пользования посетителями веб-сайта Шоу-пространство "Ленинград Центр".
                    Продолжая использовать данный сайт, Вы даете свое согласие на обработку Ваших Cookies.
                    Вы также можете отказаться от обработки Cookies, однако такой отказ может повлиять на корректность работы нашего сайта.
                </p>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Что такое Cookies?
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>Сookies — это небольшой фрагмент данных, который веб-сайт запрашивает у браузера, используемого на вашем компьютере или мобильном устройстве. Cookies отражают Ваши предпочтения или действия на веб-сайте, а также сведения об оборудовании пользователя, дата и время сессии. Сookies хранятся локально на Вашем компьютере или мобильном устройстве. При желании Вы можете удалить сохраненные Cookies в настройках браузера.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            Как мы используем Ваши Cookies?
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Наш сайт обрабатывает полученные данные, в том числе, с использованием метрических программ, таких как Яндекс.Метрика, ВКонтакте и CallTouch.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            Какие виды Сookies мы используем?
                        </Accordion.Header>
                        <Accordion.Body>
                            <h5>
                                <strong>
                                    Сессионные
                                </strong>
                            </h5>
                            <p>
                                Существуют только во временной памяти в течение времени, когда пользователь находится на странице веб-сайта. Браузеры обычно удаляют сессионные Cookies после того, как Вы закрываете окно веб-сайта. Сессионные Cookies позволяют веб-сайту помнить информацию о Вашем выборе на предыдущем сайте, чтобы избежать необходимости повторного ввода информации.
                            </p>
                            <h5>
                                <strong>
                                    Постоянные
                                </strong>
                            </h5>
                            <p>
                                Сookies, которые хранятся на Вашем компьютере и не удаляются при закрытии браузера. Постоянные Сookies могут сохранять пользовательские настройки для определенного веб-сайта, позволяя использовать эти предпочтения в будущих сеансах просмотра. Такие Cookies позволяют идентифицировать Вас как уникального пользователя веб-сайта, и при возвращении на веб-сайт помогают вспомнить информацию о Вас и ранее совершенных действиях.
                            </p>
                            <h5>
                                <strong>
                                    Статистические
                                </strong>
                            </h5>
                            <p>
                                Включают в себя информацию о том, как Вы используете веб-сайт. Например, какие страницы Вы посещаете, по каким ссылкам переходите. Главная цель таких Cookies — улучшение функций сайта.
                            </p>
                            <h5>
                                <strong>
                                    Обязательные
                                </strong>
                            </h5>
                            <p>
                                Минимальный набор Cookies, использование которых необходимо для корректной работы сайта.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                            Как отказаться от обработки Сookies?
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Вы можете отказаться от обработки Cookies в настройках своего браузера. В таком случае наш сайт будет использовать только те Cookies, которые строго необходимы для функционирования сайта и предлагаемых им сервисов.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>
        </>
    )
}
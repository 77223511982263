import { format } from "date-fns"
import { useEffect, useState } from "react"
import { Image } from "react-bootstrap"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';
import { ImageLoader } from "../UI/ImageLoader";
import parse from 'html-react-parser';

export const NewsPost = ({ item }) => {
    const [showMore, setShowMore] = useState(false)
    const [short, setShort] = useState()
    const [more, setMore] = useState()

    const hiddenContent = () => {
        setMore()
        setShort()
        const index = item.content.indexOf("</p>");
        setShort(item.content.substring(0, index + 3))
        if (item.content.substring(index + 4) !== "" & item.content.substring(index + 4) !== null) {
            setMore(item.content.substring(index + 4))
            setShowMore(false)
        }
        else {
            setShowMore(true)
        }
    }

    useEffect(() => {
        hiddenContent()
    }, [item])



    return (

        <div style={{ maxWidth: "640px" }} className="rounded border shadow mt-3 mb-3 m-auto">
            <div className="mt-2 pt-2 ps-3 pb-2">

                <div>
                    <span className="text-muted" style={{ fontSize: "12px" }}> {format(new Date(item.publishDate), "HH:mm dd.MM.yy")}</span>
                </div>
                <div>
                    <span className="text-muted" style={{ fontSize: "12px" }}>
                        {item.typeNews === 0 && "Новости"}
                        {item.typeNews === 1 && "СМИ о нас"}</span>
                </div>
            </div>
            <div>
                <Swiper
                    slidesPerView={'auto'}
                    loop={true}
                    pagination={true}
                    modules={[Pagination]}
                    className="news-slider"
                >
                    {item.gallery.map(item =>
                        <SwiperSlide key={item.id}>
                            <ImageLoader name={item.name} src={`/api/news/gallery-photo/${item.id}`} viewBoxCustom={"0 0 860 540"} />
                            {/* <Image className="w-100" src={`/api/news/gallery-photo/${item.id}`} fluid /> */}
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
            <div className="ps-3 pe-3 mt-3 mb-3" style={{ transition: '.3s' }}>
                <h5 className="mb-1">
                    <strong>{item.title}</strong>
                </h5>

                <div>{short && parse(short)}</div>
                {/* <div dangerouslySetInnerHTML={{ __html: short }} /> */}
                {!showMore &&
                    <div className="mt-2" style={{ cursor: 'pointer', color: "#694992" }} onClick={() => setShowMore(!showMore)}><u>Показать подробнее...</u></div>
                }
                {showMore &&
                    <div>{more && parse(more)}</div>
                }
            </div>
        </div>
    )
}
import { useEffect, useRef, useState } from "react"
import { Policy } from "./Policy"
import { Metrik } from "../features/Metrik"
import { NavMenu } from "./NavMenu"
import { Outlet } from "react-router-dom"
import { Footer } from "./Footer/Footer"


export const LayoutScheme = ({ changanLanguage, selLanguage, setAboutMobMenu }) => {

    // const footerRef = useRef()
    const [minHeightContent, setMinHeigthContent] = useState(100)
    // useEffect(() => {
    //     const resizeHandeler = () => {
    //         setMinHeigthContent(window.innerHeight - footerRef?.current.clientHeight)
    //     }
    //     setMinHeigthContent(window.innerHeight - footerRef?.current.clientHeight)
    //     window.addEventListener('resize', resizeHandeler)
    //     return function () {
    //         window.addEventListener('resize', resizeHandeler)
    //     }
    // }, [])

    return (
        <>
            <Policy />
            <Metrik />
            <div className='main-content' style={{ minHeight: minHeightContent + 'px' }}>
                <NavMenu changanLanguage={changanLanguage} selLanguage={selLanguage} setAboutMobMenu={setAboutMobMenu} />
                <Outlet />
            </div>
            {/* <Footer footerRef={footerRef} /> */}
            {/* <ServiceButton /> */}
            {/* {reserFormShow && */}
            {/* <TableReservationForm reserFormShow={reserFormShow} setReserFormShow={setReserFormShow} /> */}
            {/* } */}
            {/* <MobileNavMenu setReserFormShow={setReserFormShow} aboutMobMenu={aboutMobMenu} setAboutMobMenu={setAboutMobMenu} /> */}
            <div className="d-none d-lg-block">
                <Footer />
            </div>
        </>
    );
}


import { Button, ButtonGroup, FloatingLabel, Form, FormGroup, FormLabel, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useAddArtistMutation } from "../../../features/CallApi";
import { LoadScreenMin } from "../../UI/LoadScreenMin";


export const ArtistAdd = ({ artistsAddForm, setArtistsAddForm }) => {
    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [addArtist, { isLoading }] = useAddArtistMutation()

    const submitAddArtist = (data) => {
        const formData = new FormData()
        const file = data.photo[0]
        formData.append('name', data.name)
        formData.append('nameEN', data.nameEN)
        formData.append('description', data.description)
        formData.append('descriptionEN', data.descriptionEN)
        formData.append('photo', file)
        data.photo = formData
        addArtist(formData)
            .unwrap()
            .then((r) => {
                // console.log(r)
                reset()
                setArtistsAddForm(false)
            })
    }

    return (
        <>
            <Modal
                show={artistsAddForm}
                onHide={
                    () => {
                        reset()
                        setArtistsAddForm(false)
                    }
                }
                backdrop={`static`}
            >
                <Modal.Header closeButton>
                    <h3>Добавление артиста труппы</h3>
                </Modal.Header>
                {!isLoading ?
                    <form onSubmit={handleSubmit(submitAddArtist)}>
                        <Modal.Body>
                            <FloatingLabel
                                controlId="floatingName"
                                label="Ф.И.О."
                                className="mb-3"
                            >
                                <Form.Control type="text" isInvalid={errors.name} isValid={isValid.name} placeholder="Ф.И.О." {...register("name", { required: { value: true } })} />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingName"
                                label="Ф.И.О. EN"
                                className="mb-3"
                            >
                                <Form.Control type="text" isInvalid={errors.nameEN} isValid={isValid.nameEN} placeholder="Ф.И.О. EN" {...register("nameEN")} />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingName"
                                label="Описание"
                                className="mb-3"
                            >
                                <Form.Control type="text" isInvalid={errors.description} isValid={isValid.description} placeholder="Описание" {...register("description", { required: { value: true } })} />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingName"
                                label="Описание EN"
                                className="mb-3"
                            >
                                <Form.Control type="text" isInvalid={errors.descriptionEN} isValid={isValid.descriptionEN} placeholder="Описание EN" {...register("descriptionEN")} />
                            </FloatingLabel>
                            <FormGroup
                                className="mb-3"
                            >
                                <FormLabel>Фото</FormLabel>
                                <Form.Control type="file" isInvalid={errors.photo} accept=".jpg, .jpeg, .png" isValid={isValid.photo} {...register("photo", {required: {value: true}})} />
                            </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonGroup size="sm">
                                <Button variant="primary" onClick={() => {
                                    reset()
                                    setArtistsAddForm(!artistsAddForm)
                                }
                                }>Отменить</Button>
                                <Button type="submit" variant="success">
                                    Добавить
                                </Button>
                            </ButtonGroup>
                        </Modal.Footer>
                    </form>
                    : 
                    <LoadScreenMin height={20} />}
            </Modal>
        </>
    )
}
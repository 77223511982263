import { useState } from "react";
import { Button, Col, Container, FloatingLabel, Form, Row, Spinner } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBaseTokenMutation, usePostClientRegisterMutation } from "../../features/CallAppApi";


export const ClientRegistration = ({ selLanguage }) => {

    const { register, handleSubmit, reset, formState: { errors }, setError } = useForm();
    const [baseToken] = useBaseTokenMutation()
    const [postClientRegister, { isLoading, error }] = usePostClientRegisterMutation()
    const { t } = useTranslation("global");
    const navigate = useNavigate();
    const [agreement, setAgreement] = useState(false)
    const [latestNewsInformation, setLatestNewsInformation] = useState(false)
    const registration = (data) => {
        if (data.password !== data.passwordConfirm) {
            return setError("passwordConfirm", { message: "Подтверждение не совпадает с полем пароль " })
        }
        else {
            setError("passwordConfirm", null)
        }
        baseToken().unwrap().then((r) => {
            postClientRegister({ data, baseToken: r.token }).then((r) => {
                // console.log(r.error.data)
                if (r.error?.status === 409) {
                    return window.alert(r.error.data.message)
                }
                window.alert("Вы успешно зарегистрированы!")
                navigate("/client/profile")
            }).catch((e) => {
                window.alert(`${e.status}\n${e.titile}`)
            })
        })
        // console.log(data)
    }

    return (
        <>
            <div className="about-haed-layout mb-3">
                <div className="about-head-layout-img" style={{ backgroundImage: `url("/AboutProjcet/about-project.jpg")` }}>
                </div>
                <div className="about-head-mask">
                    <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3"
                        style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                    </div>
                </div>
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} lg={5}>
                        <h3>{t("form.registration")}</h3>
                        <form onSubmit={handleSubmit(registration)}>
                            <Row className="g-1">
                                <Col md={6}>
                                    <FloatingLabel className="mb-1" controlId="lastName" label={t("form.lastName")} >
                                        <Form.Control type="text" placeholder={t("form.lastName")}
                                            {...register("lastName", { required: { value: true, message: `${t("form.fieldIsRequired")} ${t("form.lastName")}` } })} isInvalid={errors?.lastName?.message != null} />
                                    </FloatingLabel>
                                    {errors?.lastName?.message &&
                                        <div>
                                            <span className="text-danger">
                                                {errors?.lastName?.message}
                                            </span>
                                        </div>
                                    }
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel className="mb-1" controlId="firstName" label={`${t("form.firstName")}`} >
                                        <Form.Control type="firstName" placeholder={t("form.firstName")}
                                            {...register("firstName", { required: { value: true, message: `${t("form.fieldIsRequired")} ${t("form.firstName")}` } })} isInvalid={errors?.firstName?.message != null} />
                                    </FloatingLabel>
                                    {errors?.firstName?.message &&
                                        <div>
                                            <span className="text-danger">
                                                {errors?.firstName?.message}
                                            </span>
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <Row className="g-1">
                                <Col md={6}>
                                    <FloatingLabel className="mb-1" controlId="phone" label={`${t("form.phone")}`} >
                                        <Form.Control type="tel" placeholder={`${t("form.phone")}`}
                                            {...register("phone", { required: { value: true, message: `${t("form.fieldIsRequired")} ${t("form.phone")}` } })} isInvalid={errors?.phone?.message != null} />
                                    </FloatingLabel>
                                    {errors?.phone?.message &&
                                        <div>
                                            <span className="text-danger">
                                                {errors?.phone?.message}
                                            </span>
                                        </div>
                                    }
                                </Col>
                                <Col md={6}>
                                    <FloatingLabel className="mb-1" controlId="email" label={`EMail`} >
                                        <Form.Control type="email" placeholder={`EMail`}
                                            {...register("email", { required: { value: true, message: "Поле E-Mail обязательное" }, pattern: /^\S+@\S+$/i })} isInvalid={errors?.email?.message != null} />
                                    </FloatingLabel>
                                    {errors?.email?.message &&
                                        <div>
                                            <span className="text-danger">
                                                {errors?.email?.message}
                                            </span>
                                        </div>
                                    }
                                </Col>

                            </Row>
                            <Row className="g-1">
                                <Col>
                                    <FloatingLabel className="mb-2" controlId="password" label={`${t("form.password")}`} >
                                        <Form.Control type="password" placeholder={`${t("form.password")}`} autoComplete={"on"}
                                            {...register("password",
                                                {
                                                    required: { value: true, message: "Поле пароль обязательное" },
                                                    minLength: { value: 8, message: "Мнимальная длина 8 символов" }
                                                })}
                                            isInvalid={errors?.password?.message != null} />
                                    </FloatingLabel>
                                    {errors?.password?.message && <> <span className="text-danger">{errors?.password?.message}</span> </>}
                                </Col>
                                <Col>
                                    <FloatingLabel className="mb-2" controlId="passwordConfirm" label={`${t("form.passwordConfirm")}`} >
                                        <Form.Control type="password" placeholder={`${t("form.passwordConfirm")}`} autoComplete={"on"}
                                            {...register("passwordConfirm",
                                                { required: { value: true, message: "Поле подтвержедния пароль обязательное" } })}
                                            isInvalid={errors?.passwordConfirm?.message != null} />
                                    </FloatingLabel>
                                    {errors?.passwordConfirm?.message && <> <span className="text-danger">{errors?.passwordConfirm?.message}</span> </>}
                                </Col>
                            </Row>
                            {selLanguage === "en" ?
                                <>
                                    <div className="form-check form-switch mt-3">
                                        <input className={`form-check-input ${errors.agreement && "is-invalid"}`} onClick={() => { setAgreement(!agreement) }}
                                            checked={agreement}
                                            type="checkbox"
                                            id="agreement" {...register("agreement", { required: true })} />
                                        <label className="form-check-label" htmlFor="agreement">
                                            I have read the Rules on Purchasing Tickets, the rules of visiting Leningrad Center and the Policy on Processing Personal Data.
                                        </label>
                                    </div>
                                    <div className="form-check form-switch mt-3">
                                        <input className={`form-check-input ${errors.latestNewsInformation && "is-invalid"}`} onClick={() => { setLatestNewsInformation(!latestNewsInformation) }}
                                            checked={latestNewsInformation}
                                            type="checkbox"
                                            id="latestNewsInformation" {...register("latestNewsInformation")} />
                                        <label className="form-check-label" htmlFor="latestNewsInformation">
                                            I want to receive the latest news and information on show programs of Leningrad Center.
                                        </label>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="form-check form-switch mt-3">
                                        <input className={`form-check-input ${errors.agreement && "is-invalid"}`} onClick={() => { setAgreement(!agreement) }}
                                            checked={agreement}
                                            type="checkbox"
                                            id="agreement" {...register("agreement", { required: true })} />
                                        <label className="form-check-label" htmlFor="agreement">
                                            Я ознакомлен и согласен с <a href="/api/redirecturls/ticket-purchase-rules" target="_blank">Правилами покупки билетов</a>, <a target="_blank" href="/api/redirecturls/visiting-rules">Правилами посещения Ленинград Центра</a> и <a href="/api/redirecturls/privacy-policy" target="_blank">Политикой обработки персональных данных</a>.
                                        </label>
                                    </div>
                                    <div className="form-check form-switch mt-3">
                                        <input className={`form-check-input ${errors.latestNewsInformation && "is-invalid"}`} onClick={() => { setLatestNewsInformation(!latestNewsInformation) }}
                                            checked={latestNewsInformation}
                                            type="checkbox"
                                            id="latestNewsInformation" {...register("latestNewsInformation")} />
                                        <label className="form-check-label" htmlFor="latestNewsInformation">
                                            Я желаю получать информацию об актуальных новостях и шоу программах Ленинград Центра
                                        </label>
                                    </div>
                                </>}
                            <div className={'d-flex justify-content-end mt-3'}>
                                {/* <Button type="submit" className="btn-lc btn-lc-rounded">{t("form.register")} {isLoading && <Spinner size="sm" />}</Button> */}
                                <Button type="submit" className="btn-lc btn-lc-rounded">{t("form.register")}</Button>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
import { useTranslation } from 'react-i18next';
import './AboutProject.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Suspense, useEffect, useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { useParams } from 'react-router-dom';
import { AboutMenu } from './MenuAbout';
import { Helmet } from 'react-helmet';


export const AboutProject = ({ selLanguage }) => {
    const [t] = useTranslation('global')
    const { name } = useParams()
    const [index, setIndex] = useState(-1);
    const photos = [
        { src: "/AboutProjcet/091A8144.jpg", width: 2500, height: 1667, alt: "Фойе Лениград Центра" },
        { src: "/AboutProjcet/091A8140.jpg", width: 2500, height: 1667, alt: "Фойе Лениград Центра" },
        { src: "/AboutProjcet/091A8259.jpg", width: 2500, height: 1667, alt: "Фойе Лениград Центра" },
        { src: "/AboutProjcet/091A8219.jpg", width: 2500, height: 1667, alt: "VIP ложа Лениград Центра" },
        { src: "/AboutProjcet/091A8210.jpg", width: 2500, height: 1667, alt: "VIP ложа Лениград Центра" },
        { src: "/AboutProjcet/091A8306.jpg", width: 2500, height: 1667, alt: "Фойе Лениград Центра" },
    ]

    return (

        <>
            <Row>
                <Col xs={"12"} xl={"5"}>
                    <div className="ratio ratio-16x9 mb-3">
                        <iframe src="https://www.youtube.com/embed/0dz9DMAapAc?si=ayxKz4htoG7kE4dB" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                </Col>
                <Col xs={"12"} xl={"7"}>
                    {selLanguage === "en" ?
                        <>
                            <p>
                                <strong>Leningrad Center</strong> is a center of modern entertainment culture, offering art beyond all formats and frameworks. Leningrad Centre is situated in a historical mansion, and the repertoire of this large-scale cultural project brought together and merged several formats of entertainment genre — from unique show programs to exhibitions and movie screenings.

                            </p>
                            <p className='d-none d-xxl-block'>
                                The mansion on Potemkinskaya Street is as old as a hundred years, and the history behind is closely connected with the culture, art and social life of the city.
                                Before the revolution it housed exhibitions of the Russian Imperial Society of Horticulture, while in the middle of the XX century the Leningrad panoramic movie theater was located here. In early 2000-s, the building was reconstructed by Ricardo Bofill, the living legend of architecture.
                                Having preserved the cultural function of the building, he filled it with modern content, which corresponds to the utmost current global trends in the field of urban cultural venues.
                            </p>
                        </>
                        :
                        <>
                            <p>
                                <strong>«Ленинград Центр»</strong> — шоу-пространство, театр, цель которого — вдохновлять и наполнять эмоциями зрителей. «Ленинград Центр» расположен в историческом особняке, а в репертуаре масштабного культурного проекта объединились несколько форматов развлекательного жанра — от уникальных шоу-программ до выставок и кинопоказов.
                                Особняк на Потемкинской улице имеет более чем столетнюю историю, тесно связанную с культурой, искусством и светской жизнью города.
                            </p>
                            <p className='d-none d-xxl-block'>
                                До революции в нем проходили выставки Императорского российского общества садоводства, а в середине XX столетия здесь разместился панорамный кинотеатр «Ленинград». В начале 2000-х здание реконструировал живая легенда архитектуры Рикардо Боффил. Сохранив культурную функцию здания, он наполнил его современным содержанием, которое соответствует самым актуальным мировым тенденциям в сфере городских культурных площадок.
                            </p>
                        </>
                    }
                </Col>
            </Row>
            {selLanguage === "en" ?
                <>
                    <p className='d-xxl-none'>
                        The mansion on Potemkinskaya Street is as old as a hundred years, and the history behind is closely connected with the culture, art and social life of the city.
                        Before the revolution it housed exhibitions of the Russian Imperial Society of Horticulture, while in the middle of the XX century the Leningrad panoramic movie theater was located here. In early 2000-s, the building was reconstructed by Ricardo Bofill, the living legend of architecture.
                        Having preserved the cultural function of the building, he filled it with modern content, which corresponds to the utmost current global trends in the field of urban cultural venues.
                    </p>
                    <p>
                        The Main stage of the Leningrad Center is one of the most technologically advanced Russian venues, designed for 300 theater spectators.
                        Its technical equipment includes dozens of moving mechanisms and screens, a 3D curtain from Pavel Kaplevich, and the largest kinetic chandelier in Europe.
                        All this allows us to create shows of incredible beauty, which have no analogues in our country. The Main Stage shows most of the repertoire of the Leningrad Center Theater - super-saturated dynamic shows that cannot be fit into the framework of any existing genre. The productions combine multimedia technologies, all types of choreography, virtuoso circus tricks, vocals, drama, comedy and physical theater. These shows are the calling card of the Leningrad Center.
                        Also, as part of the “Literature Lessons” project, famous young actors read classical poetry with multimedia accompaniment and live musical accompaniment.</p>
                    <p>
                        In addition to shows, over the ten years of the theater’s existence, the theater has shown original versions of films with subtitles, festival films, premieres in the presence of directors, actors and critics, as well as broadcasts of opera, ballet and iconic performances from the world’s leading theater stages.</p>
                    <p>
                        The Small stage is a chamber space where there is room for improvisation and experimentation.
                        The most striking event of the Small Stage is the provocative production of LOVESICK - an elegant and courtly show, tickets for which are always sold out instantly.
                        The show “Old Hotel”, which shows the amorous and ironic adventures of guests of the most unusual hotel in the world - acrobat-receptionists, a singing metro d'hotel, seductive maids make the evening unforgettable and full of intense emotions.
                        Also in the tenth season, the premiere of the “Talent Show” Bella Magica thundered on the Small Stage, where the world famous Italian magician Eddie Arduino appeared before the audience.</p>
                    <p>
                        The Leningrad Center Gallery hosts exhibitions in a variety of genres. The space is located on the second floor, the interior was designed by Ricardo Bofill in a minimalist manner, which allows it to be easily transformed for various creative projects.
                    </p>
                    <p>
                        On the roof of the building there is a meat restaurant “Blok” by Alexander Rappoport.
                        The restaurant offers modern Russian cuisine with domestic meat of the highest quality, which is in many ways superior to foreign ones.
                        The interior is decorated in the style of northern modernism and the Leningrad avant-garde, and is decorated with 19 fireplaces, multimedia light paintings of the avant-garde artists, two terraces and a view of the Tauride Garden.
                    </p>
                </>
                :
                <>
                    <p className='d-xxl-none'>
                        До революции в нем проходили выставки Императорского российского общества садоводства, а в середине XX столетия здесь разместился панорамный кинотеатр «Ленинград».
                        В начале 2000-х здание реконструировал живая легенда архитектуры Рикардо Боффил.
                        Сохранив культурную функцию здания, он наполнил его современным содержанием, которое соответствует самым актуальным мировым тенденциям в сфере городских культурных площадок.
                    </p>
                    <p>
                        На первом этаже расположено центральное фойе, над которым работала испанский дизайнер Марта де Виллавонго.
                        Сохранив торжественную атмосферу пространства, она разместила здесь самую большую видеостену в Европе, состоящую из 99 дисплеев.
                        Проходя сквозь стену, зрители попадают на Основную сцену.
                    </p>
                    <p>
                        Основная сцена «Ленинград Центра» — одна из самых технологичных российских площадок, рассчитанная на 300 театральных зрителей.
                        Ее техническое оснащение включает в себя десятки подвижных механизмов и экранов, 3D-занавес от Павла Каплевича, самую большую в Европе кинетическую люстру.
                        Все это позволяет создавать невероятные по красоте шоу, аналогов которым в нашей стране не существует.
                        На Основной сцене показывают большую часть репертуара театра «Ленинград Центр» — сверхнасыщенные динамичные шоу, которые невозможно вписать в рамки ни одного из существующих жанров.
                        Постановки сочетают в себе мультимедийные технологии, все виды хореографии, виртуозные цирковые трюки, вокал, драму, комедию и физический театр. Эти шоу — визитная карточка «Ленинград Центра».
                        Также в рамках проекта «Уроки литературы» известные молодые актёры читают классическую поэзию с мультимедийным сопровождением и живым музыкальным аккомпанементом.
                        Помимо шоу за десять лет существования театра здесь показывали оригинальные версии фильмов с субтитрами, фестивальное кино, премьеры в присутствии режиссеров, актеров и критиков, а также трансляции оперы, балета и знаковых спектаклей с ведущих театральных сцен мира.
                    </p>
                    <p>
                        Малая сцена — это камерное пространство, где есть место импровизации и экспериментам.
                        Самое яркое событие Малой сцены — провокационная постановка LOVESICK — изысканное и куртуазное шоу, билеты на которое раскупаются всегда моментально.
                        Шоу «Старый отель», где показывают амурные и ироничные приключения гостей самого необычного в мире отеля — акробаты-портье, поющий метродотель, соблазнительные горничные делают вечер незабываемым и полным острых эмоций.
                        Также в десятом сезоне на Малой сцене прогремела премьера – «Шоу талантов» Bella Magica, где перед зрителями предстал всемирно известный итальянский фокусник Эдди Ардуино.
                    </p>
                    <p>
                        В Галерее «Ленинград Центра» проходят экспозиции в самых разных жанрах.
                        Пространство расположено на втором этаже, интерьер выполнен по проекту Рикардо Бофилла в минималистичном ключе, что позволяет ему легко трансформироваться под различные креативные проекты.</p>
                    <p>
                        На крыше здания находится мясной ресторан «Блок» Александра Раппопорта.
                        Ресторан предлагает современную российскую кухню с отечественным мясом высочайшего качества, во многом превосходящим зарубежное.
                        Интерьер оформлен в стиле северного модерна и ленинградского авангарда, а украшают его 19 каминов, мультимедийные световые полотна авангардистов, две террасы и вид на Таврический сад.
                    </p>
                </>}
            <div>
                <Suspense>
                    <PhotoAlbum columns={3} layout="masonry" photos={photos} onClick={({ index }) => setIndex(index)} />
                    <Lightbox
                        slides={photos}
                        open={index >= 0}
                        index={index}
                        close={() => setIndex(-1)}
                        // enable optional lightbox plugins
                        plugins={[Slideshow, Thumbnails, Zoom, Captions]}
                    />
                </Suspense>
            </div>
        </>

    )

}
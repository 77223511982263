import React, { useEffect, useState } from 'react';
import { Button, Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { ArrowLeftCircleFill, TelephoneFill, TicketPerforated } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Footer } from './Footer/Footer';

export const NavMenu = ({ changanLanguage, selLanguage, setAboutMobMenu: setAboutMobMenu }) => {

  const [bgNav, setBgNav] = useState("nav-bg-black navbar-dark")
  const [logoColor, setLogoColot] = useState("logo-white")
  const [show, setShow] = useState(false);
  const navigete = useNavigate();
  const location = useLocation()
  const [t] = useTranslation("global")
  const loaction = useLocation();
  const [wide, setWide] = useState(true);

  useEffect(() => {
    const pathPay = loaction.pathname.includes("paytickets")
    // console.log(wide)
    const scrollHandeler = (e) => {
      if (e.target.documentElement.scrollTop > 15) {
        setBgNav("nav-bg-white box-shadow")
        setLogoColot("logo-black")
      }
      else {
        setBgNav("nav-bg-black navbar-dark")
        setLogoColot("logo-white")
        if (pathPay && window.innerWidth < window.innerHeight) {
          setBgNav("nav-bg-white box-shadow")
          setLogoColot("logo-black")
        }
      }
    }
    document.addEventListener('scroll', scrollHandeler)
    return function () {
      document.removeEventListener('scroll', scrollHandeler)
    }
  }, [loaction])

  useEffect(() => {
    if (window.innerWidth < window.innerHeight) {
      setWide(false)
    }
    if (window.innerWidth > window.innerHeight) {
      setWide(true)
    }
  }, [])

  useEffect(() => {
    setShow(false)
    setAboutMobMenu(false)
    const resizeHandeler = (e) => {
      if (e.target.window.innerWidth < e.target.window.innerHeight) {
        setWide(false)
      }
      if (e.target.window.innerWidth > e.target.window.innerHeight) {
        setWide(true)
      }
      // console.log(e.target.window.innerWidth)
      // console.log(e.target.window.innerHeight)
    }

    window.addEventListener('resize', resizeHandeler)
    return function () {
      window.addEventListener('resize', resizeHandeler)
    }
  }, [loaction])

  useEffect(() => {
    const pathPay = loaction.pathname.includes("paytickets")
    if (show === true) {
      setBgNav("nav-bg-white")
      setLogoColot("logo-black")
    }
    else {
      setBgNav("nav-bg-black navbar-dark")
      setLogoColot("logo-white")
      if (pathPay && window.innerWidth < window.innerHeight) {
        setBgNav("nav-bg-white box-shadow")
        setLogoColot("logo-black")
      }
    }
  }, [show])

  const toNav = (link) => {
    window.scrollTo(0, 0)
    navigete(link)
  }

  const toAbout = () => {
    if (wide) {
      toNav("/about-project")
    }
    else {
      setShow(false)
      setAboutMobMenu(true)
    }
  }

  return (
    <>
      <Navbar fixed='top' expand={"lg"} className={`transition-nav p-0 ${bgNav}`}>
        <Container fluid>
          {location.pathname !== '/' &&
            <div style={{ inset: 0, margin: 'auto' }} onClick={() => window.history.back()} className='ms-1 me-3 d-lg-none'>
              <ArrowLeftCircleFill className={`${logoColor}`} fill="#fff" size={24} />
            </div>
          }
          <Navbar.Brand onClick={() => {
            toNav("/")
          }}>
            <div className={`${logoColor} d-none d-lg-block`}>
              <svg className='img-fluid' style={{ maxWidth: "150px", maxHeight: "55px" }}>
                <use xlinkHref='lc-logo.svg#logo'></use>
              </svg>
            </div>
            <div className={`${logoColor} d-lg-none`}>
              <svg className='img-fluid' style={{ maxWidth: "55px", maxHeight: "55px" }}>
                <use xlinkHref='lc-logo.svg#logo-small'></use>
              </svg>
            </div>
          </Navbar.Brand>
          <div className="text-center text-lg-end flex-fill me-3">
            <a className="text-decoration-none nav-link" href="tel:+78122429999">
              <p className="m-0">{t("menu.callforbooking")}</p>
              <p className="m-0"><TelephoneFill size={16} /> 8 (812) 242 9999</p>
            </a>
          </div>
          <Navbar.Toggle onClick={() => { setShow(!show) }} aria-controls={`offcanvasNavbar-expand-lg`} />
          <Navbar.Offcanvas
            className="offcanvas-nav-lc"
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
            show={show}
            onHide={() => { setShow(false) }}
          >
            <Offcanvas.Header closeButton closeVariant='white'>
              <Offcanvas.Title className='d-flex w-100 justify-content-around' id={`offcanvasNavbarLabel-expand-lg`}>
                <div className='logo-white'>
                  <svg className='img-fluid' style={{ maxWidth: "150px", maxHeight: "55px" }}>
                    <use xlinkHref='lc-logo.svg#logo'></use>
                  </svg>
                </div>
                <div className='ms-3 pt-2 pb-2 text-center'>
                  {(selLanguage === "ru" || selLanguage === null) && <Button size='sm' variant='light' className='border' onClick={() => { changanLanguage("en") }}>EN</Button>}
                  {selLanguage === "en" && <Button size='sm' variant='light' className='border' onClick={() => { changanLanguage("ru") }}>RU</Button>}
                </div>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="ms-auto justify-content-end navbar-dark text-center">
                <Nav.Link onClick={() => {
                  toNav("/afisha")
                }}>{t("menu.tickets")}</Nav.Link>
                <Nav.Link onClick={() => {
                  // toNav("/about-project")
                  toAbout()
                }}>{t("menu.about")}</Nav.Link>
                <Nav.Link onClick={() => {
                  toNav("/about-project/corporate")
                }}>{t("menu.corporate")}</Nav.Link>
                <Nav.Link onClick={() => {
                  toNav("/news")
                }}>{t("menu.news")}</Nav.Link>
                <Nav.Link className='d-lg-none' onClick={() => {
                  toNav("/about-project/certificates")
                }}>{t("menu.gifCertificates")}</Nav.Link>
                <Nav.Link style={{}} className='rounded-pill shadow border ps-2 pe-2 d-none d-lg-block myTickets' onClick={() => {
                  toNav("/client/profile")
                }}><TicketPerforated style={{ transform: "rotate(-45deg)" }} className='me-1' size={20} />{t("menu.mytickets")}</Nav.Link>
                {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => {
                navigete("/")
                setShow(!show)
              }}>Action</NavDropdown.Item>
              <NavDropdown.Item onClick={() => {
                navigete("/")
                setShow(!show)
              }}>
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => {
                navigete("/")
                setShow(!show)
              }}>Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => {
                navigete("/")
                setShow(!show)
              }}>
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
              </Nav>
              <div className='ms-lg-2 pt-2 pb-2 text-center d-none d-lg-block'>
                {(selLanguage === "ru" || selLanguage === null) && <Button size='sm' variant='light' className='border' onClick={() => { changanLanguage("en") }}>EN</Button>}
                {selLanguage === "en" && <Button size='sm' variant='light' className='border' onClick={() => { changanLanguage("ru") }}>RU</Button>}
              </div>
              <div className='d-lg-none'>
                <Footer clearfix={true} />
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

const refreshTokenSlice = createSlice({
  name: "refreshToken",
  initialState,
  reducers: {
    setRefreshTokenInStore: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setRefreshTokenInStore } = refreshTokenSlice.actions;

export default refreshTokenSlice.reducer;

import { Col, Container, Row } from "react-bootstrap"
import { SwiperAfisha } from "../UI/SwiperAfisha"
import { Helmet } from "react-helmet"
import { useGetAfishaQuery } from "../../features/CallApi"
import { LoadScreenMin } from "../UI/LoadScreenMin"
import { Repertoire } from "../Show/Repertoire"
import { Exhibitions } from "../Exhibition/Exhibitions"
import { useTranslation } from "react-i18next"
import { Suspense } from "react"
import ContentLoader from "react-content-loader"




export const Afisha = ({ selLanguage }) => {

    const [t] = useTranslation("global");
    const { data: dataAfisha, isLoading: loadingAfisha } = useGetAfishaQuery({ selLanguage })


    return (
        <>
            <Helmet>
                <title>{`${t("menu.tickets")} - ${t("mainTag.title")}`}</title>
                <meta name="description" content='Первое в России шоу-пространство "Ленинград Центр"' />
                <meta name="og:title" content={`${t("menu.tickets")} - ${t("mainTag.title")}`} />
                <meta name="og:description" content='Первое в России шоу-пространство "Ленинград Центр"' />
                <meta name="keywords" content="шоу- пространство, шоу пространство, ленинград центр, центр ленинград, мюзикл, шоу, представление, афиша"></meta>
                {/* <meta property="og:image" content={data.headLayoutImageType ? `/api/shows/head-wide-img/${data.id})` : "/Show/091A8332.jpg"} /> */}
            </Helmet>
            <div className="news-haed-layout">
                <div className="news-head-layout-img" style={{ backgroundImage: 'url("/News/news.jpg")' }}>
                </div>
                <div className="news-head-mask">
                    <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3" style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                        <div className="text-white ms-auto justify-content-end">
                            <div className="h1 text-center">{t("menu.tickets")}</div>
                        </div>
                    </div>
                </div>
            </div>
            <Container>
                <Suspense fallback={<LoadScreenMin height={100} />}>
                    {!loadingAfisha ?
                        <>
                            {dataAfisha?.length > 0 &&
                                <div className="mt-1 mb-1 pt-3 pb-3 border-bottom">
                                    <h2>{t("general.shows")}</h2>
                                    <SwiperAfisha items={dataAfisha} />
                                </div>
                            }
                            {dataAfisha?.length === 0 &&
                                <div className="mt-1 mb-1 pt-3 pb-3 border-bottom">
                                    <h2>{t("general.repertoire")}</h2>
                                    <Repertoire selLanguage={selLanguage} />
                                </div>
                            }

                        </>
                        :
                        <div className="mt-1 mb-1 pt-3 pb-3 border-bottom" >
                            <h2>{t("general.playbill")}</h2>
                            <Row className="g-3 justify-content-around">
                                <Col
                                    xs={6}
                                    md={4}
                                >
                                    <ContentLoader
                                        className={'img-fluid'}
                                        backgroundColor="#dedede"
                                        foregroundColor="#ecebeb"
                                        speed={0.5}

                                        viewBox="0 0 1080 1620">
                                        {/* Only SVG shapes */}
                                        <rect x="0" y="0" rx="5" ry="5" width="100%" height={'100%'} />
                                    </ContentLoader>
                                </Col>
                                <Col
                                    xs={6}
                                    md={4}
                                >
                                    <ContentLoader
                                        className={'img-fluid'}
                                        backgroundColor="#dedede"
                                        foregroundColor="#ecebeb"
                                        speed={0.5}

                                        viewBox="0 0 1080 1620">
                                        {/* Only SVG shapes */}
                                        <rect x="0" y="0" rx="5" ry="5" width="100%" height={'100%'} />
                                    </ContentLoader>
                                </Col>
                            </Row>
                        </div>

                    }
                </Suspense>
                {/* <Exhibitions maxCount={1} /> */}
            </Container>
        </>

    )
}
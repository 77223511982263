import { Outlet, useNavigate } from 'react-router-dom';
import '../custom.css';
import { NavMenuAdmin } from './NavMenuAdmin';
import { useEffect, useState } from 'react';
import { LoadScreenMin } from './UI/LoadScreenMin';
import { LoadScreen } from './UI/LoadScreen';

export const LayoutAdmin = ({ role, token, getRefreshToken, loadingSite }) => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(loadingSite);

    useEffect(() => {
        if (!loadingSite) {
            console.log(loadingSite)
            setTimeout(() => {
                document.body.style.overflow = "visible";
                setLoading(false)
            }, 2500)
        }
        else {
            document.body.style.overflow = "hidden";
        }
    }, [loadingSite])
    useEffect(() => {

        if (!token && !getRefreshToken) {
            sessionStorage.setItem("returnUrl", window.location.pathname)
            navigate('/admin/login')
        }
    }, [token, getRefreshToken])

    return (
        <>
            {loading && <LoadScreen />}
            {!getRefreshToken ?
                <>
                    <NavMenuAdmin role={role} />
                    <Outlet />
                </>
                :
                <LoadScreenMin />
            }</>
    );
}
import { Button, ButtonGroup, Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { useGetSchemeHallQuery } from "../../../features/CallApi"
import { LoadScreenMin } from "../../UI/LoadScreenMin"
import { useEffect, useState } from "react"
import { MainStage } from "../../SchemeHall/MainStage"
import { SchemeSetingsEdit } from "./SchemeSetingsEdit"
import { SectorAdd } from "./Sector/SectorAdd"
import { RowAdd } from "./Rows/RowAdd"
import { AddSeats } from "./Seats/AddSeats"
import { TextList } from "./Texts/TextList"
import { SectorList } from "./Sector/SectorList"
import { StatictObjectsList } from "./StatictObjects/StatictObjectsList"





export const SchemeEdit = () => {

    const { id } = useParams()
    const { data, isLoading, refetch } = useGetSchemeHallQuery(id)
    const [showSetingsEdit, setShowSetingsEdit] = useState(false)
    const [showSectorAdd, setShowSectorAdd] = useState(false)
    // const [showSectorEdit, setShowSectorEdit] = useState(false)
    const [addRowShow, setAddRowShow] = useState(false)
    const [reloadScheme, setReloadScheme] = useState(true)
    const [sectionsId, setSectionsId] = useState()
    const [rowId, setRowId] = useState()
    const [addSeatsShow, setAddSeatsShow] = useState(false)
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 })

    useEffect(() => {
        if (!reloadScheme) {
            setReloadScheme(true)
        }
    }, [data])
    useEffect(() => {
        if (reloadScheme) {
            setTimeout(() => { setReloadScheme(false) }, 500)
        }
    }, [reloadScheme])
    // useEffect(() => {
    //     if (data) {
    //         console.log(data)
    //         if (data.staticObjects) {
    //             data.staticObjects.forEach(object => {
    //                 object.ctxs.forEach(ctxs => {
    //                     console.log(ctxs)
    //                 });
    //             });
    //         }
    //     }
    // }, [data])


    return (
        <Container fluid>
            {showSetingsEdit && <SchemeSetingsEdit refetch={refetch} data={data} showSetingsEdit={showSetingsEdit} setShowSetingsEdit={setShowSetingsEdit} />}
            {showSectorAdd && <SectorAdd refetch={refetch} schemeHallId={data.id} showSectorAdd={showSectorAdd} setShowSectorAdd={setShowSectorAdd} />}
            {addRowShow && <RowAdd
                refetch={refetch}
                sectionsId={sectionsId}
                addRowShow={addRowShow}
                setAddRowShow={setAddRowShow}
            />}
            {addSeatsShow && <AddSeats
                schemeHallId={data.id}
                addSeatsShow={addSeatsShow}
                setAddSeatsShow={setAddSeatsShow}
                rowId={rowId}
                refetch={refetch} />}
            {!isLoading ?
                <>
                    <div className="fs-5">Схема "{data.name}"</div>
                    <Row>
                        <Col lg={4} md={5} xl={3} className={'border-end border-bottom pb-2'}>
                            <Row>
                                <Col xs={12}>Кординаты курсора</Col>
                                <Col>x: {Math.round(cursorPos.x)}</Col>
                                <Col>y: {Math.round(cursorPos.y)}</Col>
                            </Row>
                            <ButtonGroup className="mb-1 mt-3 w-100">
                                <Button size="sm" className="w-100" onClick={() => { setShowSetingsEdit(!showSetingsEdit) }}>Настройки схемы</Button>
                            </ButtonGroup>
                            {data.sections.length < 1 &&
                                <div className="mb-1">
                                    <Button size="sm" className="w-100" onClick={() => { setShowSectorAdd(!showSectorAdd) }}>Добавить сектор</Button>
                                </div>
                            }
                            {data.sections.length > 0 ?
                                <div style={{ overflowY: "scroll", overflowX: "hidden", maxHeight: "65vh" }}>
                                    <SectorList
                                        sections={data.sections}
                                        schemeHallId={data.id}
                                        refetch={refetch}
                                        setAddRowShow={setAddRowShow}
                                        setSectionsId={setSectionsId}
                                        setRowId={setRowId}
                                        addSeatsShow={addSeatsShow}
                                        setAddSeatsShow={setAddSeatsShow}

                                    />
                                    <TextList refetch={refetch} schemeId={data.id} texts={data.texts} />
                                    <StatictObjectsList schemeId={data.id} refetch={refetch} staticObjects={data.staticObjects} />
                                </div>
                                : <div>Нет секторов</div>}
                        </Col>
                        <Col lg={8} md={7} xl={9}>
                            {!reloadScheme ?
                                <>
                                    <MainStage data={data} setCursorPos={setCursorPos} />
                                </>
                                :
                                <LoadScreenMin height={30} />}
                        </Col>
                    </Row>
                </>
                :
                <LoadScreenMin height={30} />
            }
        </Container>
    )
}
import { useEffect, useState } from "react"
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap"
import { X } from "react-bootstrap-icons"
import { useFieldArray, useForm } from "react-hook-form"
import { usePostAddCircleMutation, usePostAddLineMutation, usePostAddRectMutation, usePostAddShapeMutation } from "../../../../features/CallApi"
import { LoadScreenMin } from "../../../UI/LoadScreenMin"

const ShapeCordsForm = (props) => {
    const [selectType, setSelectType] = useState()

    return (
        <InputGroup className="mb-3">
            <InputGroup.Text>{props.index + 1}</InputGroup.Text>
            <Form.Select {...props.register(`objectCtxs.${props.index}.type`, { required: { value: true } })} onChange={(e) => setSelectType(e.target.value)}>
                <option value={'line'}>Прямая</option>
                <option value={'curve'}>Кривая</option>
            </Form.Select>
            <InputGroup.Text>X</InputGroup.Text>
            <Form.Control {...props.register(`objectCtxs.${props.index}.x`, {
                required: true
            })} placeholder="X" aria-label="X" aria-describedby="X" type="number" isInvalid={props.errors?.objectCtxs && props.errors.objectCtxs} />
            <InputGroup.Text>Y</InputGroup.Text>
            <Form.Control {...props.register(`objectCtxs.${props.index}.y`, {
                required: true
            })} placeholder="Y" aria-label="Y" aria-describedby="Y" type="number" isInvalid={props.errors?.objectCtxs && props.errors.objectCtxs} />
            {selectType == "curve" && <>
                <InputGroup.Text>CPX</InputGroup.Text>
                <Form.Control {...props.register(`objectCtxs.${props.index}.cpx`, {
                    required: true
                })} placeholder="CPX" aria-label="CPX" aria-describedby="CPX" type="number" isInvalid={props.errors?.objectCtxs && props.errors.objectCtxs} />
                <InputGroup.Text>CPY</InputGroup.Text>
                <Form.Control {...props.register(`objectCtxs.${props.index}.cpy`, {
                    required: true
                })} placeholder="CPY" aria-label="CPY" aria-describedby="XCPY" type="number" isInvalid={props.errors?.objectCtxs && props.errors.objectCtxs} />
            </>}
            <Button variant="outline-danger" type="button" onClick={() => {
                props.remove(props.index);
            }}>
                <X size={20} /></Button>
        </InputGroup>
    );

}

const ShapeForm = ({ register, errors, fields, append, remove }) => {
    return (
        <div className="mt-3">
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>Название</InputGroup.Text>
                        <Form.Control isInvalid={errors.name && true} placeholder="Название" type="text" {...register("name", { required: { value: true, message: "Укажите название" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.name?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            X
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.x && true} type="number" placeholder="X" {...register("x", { required: { value: true, message: "Укажите положение по X" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.x?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Y
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.y && true} type="number" placeholder="Y"{...register("y", { required: { value: true, message: "Укажите положение по Y" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.y?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Ширина
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.width && true} type="number" placeholder="Ширина" {...register("width", { required: { value: true, message: "Укажите ширину" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.width?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Цвет заливки
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.fill && true} type="color" placeholder="Цвет заливки"{...register("fill", { required: { value: true, message: "Укажите цвет заливки" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.fill?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Контур
                        </InputGroup.Text>
                        <Form.Control
                            isInvalid={errors.strokeWidth && true}
                            defaultValue={0}
                            type="number" placeholder="Ширина контур"
                            {...register("strokeWidth", { required: { value: true, message: "Ширина контур" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.strokeWidth?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Цвет контура
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.stroke && true} type="color" placeholder="Цвет контура"{...register("stroke", { required: { value: true, message: "Укажите цвет заливки" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.stroke?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Button size="sm" onClick={() => { append() }} className="mb-1">Добавить точку</Button>
            {fields.map((item, index) => (
                <ShapeCordsForm
                    key={item.id}
                    index={index}
                    errors={errors}
                    register={register}
                    objectCtxs={index}
                    remove={remove} />
            ))
            }
        </div >
    )
}

const CircleForm = ({ register, errors }) => {

    return (
        <div className="mt-3">
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>Название</InputGroup.Text>
                        <Form.Control isInvalid={errors.name && true} placeholder="Название" type="text" {...register("name", { required: { value: true, message: "Укажите название" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.name?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            X
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.x && true} type="number" placeholder="X" {...register("x", { required: { value: true, message: "Укажите положение по X" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.x?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Y
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.y && true} type="number" placeholder="Y"{...register("y", { required: { value: true, message: "Укажите положение по Y" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.y?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Радиус
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.width && true} type="number" placeholder="Радиус" {...register("width", { required: { value: true, message: "Укажите ширину" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.width?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Цвет заливки
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.fill && true} type="color" placeholder="Цвет заливки"{...register("fill", { required: { value: true, message: "Укажите цвет заливки" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.fill?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Контур
                        </InputGroup.Text>
                        <Form.Control
                            isInvalid={errors.strokeWidth && true}
                            defaultValue={0}
                            type="number" placeholder="Ширина контур"
                            {...register("strokeWidth", { required: { value: true, message: "Ширина контур" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.strokeWidth?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Цвет контура
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.stroke && true} type="color" placeholder="Цвет контура"{...register("stroke", { required: { value: true, message: "Укажите цвет заливки" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.stroke?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    )
}

const LineForm = ({ register, errors, fields, append, remove }) => {
    return (
        <div className="mt-3">
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>Название</InputGroup.Text>
                        <Form.Control isInvalid={errors.name && true} placeholder="Название" type="text" {...register("name", { required: { value: true, message: "Укажите название" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.name?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            X
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.x && true} type="number" placeholder="X" {...register("x", { required: { value: true, message: "Укажите положение по X" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.x?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Y
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.y && true} type="number" placeholder="Y"{...register("y", { required: { value: true, message: "Укажите положение по Y" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.y?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Ширина
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.width && true} type="number" placeholder="Ширина" {...register("width", { required: { value: true, message: "Укажите ширину" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.width?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Цвет заливки
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.fill && true} type="color" placeholder="Цвет заливки"{...register("fill", { required: { value: true, message: "Укажите цвет заливки" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.fill?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Button size="sm" onClick={() => { append() }} className="mb-1">Добавить точку</Button>
            {fields.map((item, index) => (
                <InputGroup className="mb-3" key={item.id}>
                    <InputGroup.Text>{index + 1}</InputGroup.Text>
                    <InputGroup.Text>X</InputGroup.Text>
                    <Form.Control
                        {...register(`objectCtxs.${index}.x`, { required: true })}
                        placeholder="X"
                        aria-label="X"
                        aria-describedby="X"
                        type="number"
                        isInvalid={errors?.objectCtxs && errors.objectCtxs[index]}
                    />
                    <InputGroup.Text>Y</InputGroup.Text>
                    <Form.Control
                        {...register(`objectCtxs.${index}.Y`, { required: true })}
                        placeholder="Y"
                        aria-label="Y"
                        aria-describedby="Y"
                        type="number"
                        isInvalid={errors?.objectCtxs && errors.objectCtxs[index]}
                    />
                    <InputGroup.Text>CPX</InputGroup.Text>
                    {/* <Form.Control
                        {...register(`objectCtxs.${index}.cpx`, { required: true })}
                        placeholder="CPX"
                        aria-label="CPX"
                        aria-describedby="CPX"
                        type="number"
                        isInvalid={errors?.objectCtxs && errors.objectCtxs[index]}
                    />
                    <InputGroup.Text>CPY</InputGroup.Text>
                    <Form.Control
                        {...register(`objectCtxs.${index}.cpy`, { required: true })}
                        placeholder="CPY"
                        aria-label="CPY"
                        aria-describedby="XCPY"
                        type="number"
                        isInvalid={errors?.objectCtxs && errors.objectCtxs[index]}
                    /> */}
                    <Button variant="outline-danger" type="button" onClick={() => {
                        remove(index)
                    }}><X size={20} /></Button>
                </InputGroup>
            ))}
        </div>
    )
}

const RectForm = ({ register, errors, fields, append, remove, reset }) => {
    useEffect(() => {
        if (fields.length > 0) {
            remove(fields)
            append()
        }
        else {
            append()
        }
    }, [])
    return (
        <div className="mt-3">
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>Название</InputGroup.Text>
                        <Form.Control isInvalid={errors.name && true} placeholder="Название" type="text" {...register("name", { required: { value: true, message: "Укажите название" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.name?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            X
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.x && true} type="number" placeholder="X" {...register("x", { required: { value: true, message: "Укажите положение по X" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.x?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Y
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.y && true} type="number" placeholder="Y"{...register("y", { required: { value: true, message: "Укажите положение по Y" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.y?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Ширина
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.width && true} type="number" placeholder="Ширина" {...register("width", { required: { value: true, message: "Укажите ширину" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.width?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Цвет заливки
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.fill && true} type="color" placeholder="Цвет заливки"{...register("fill", { required: { value: true, message: "Укажите цвет заливки" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.fill?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Контур
                        </InputGroup.Text>
                        <Form.Control
                            isInvalid={errors.strokeWidth && true}
                            defaultValue={0}
                            type="number" placeholder="Ширина контур"
                            {...register("strokeWidth", { required: { value: true, message: "Ширина контур" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.strokeWidth?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>
                            Цвет контура
                        </InputGroup.Text>
                        <Form.Control isInvalid={errors.stroke && true} type="color" placeholder="Цвет контура"{...register("stroke", { required: { value: true, message: "Укажите цвет заливки" } })} />
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.stroke?.message}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            {fields.map((item, index) => (
                <InputGroup className="mb-3" key={item.id}>
                    {/* <InputGroup.Text>{index + 1}</InputGroup.Text> */}
                    <InputGroup.Text>Ш</InputGroup.Text>
                    <Form.Control
                        {...register(`objectCtxs.${index}.x`, { required: true })}
                        placeholder="X"
                        aria-label="X"
                        aria-describedby="X"
                        type="number"
                        isInvalid={errors?.objectCtxs && errors.objectCtxs[index]}
                    />
                    <InputGroup.Text>В</InputGroup.Text>
                    <Form.Control
                        {...register(`objectCtxs.${index}.Y`, { required: true })}
                        placeholder="Y"
                        aria-label="Y"
                        aria-describedby="Y"
                        type="number"
                        isInvalid={errors?.objectCtxs && errors.objectCtxs[index]}
                    />
                </InputGroup>
            ))}
        </div>
    )
}

const GetForm = (props) => {
    switch (props.selectType) {
        case "shape":
            return <ShapeForm
                register={props.register}
                errors={props.errors}
                fields={props.fields}
                append={props.append}
                remove={props.remove}
            />
        case "circle":
            return <CircleForm
                register={props.register}
                errors={props.errors}
            />
        case "line":
            return <LineForm
                register={props.register}
                errors={props.errors}
                fields={props.fields}
                append={props.append}
                remove={props.remove}
            />
        case "rect":
            return <RectForm
                register={props.register}
                errors={props.errors}
                fields={props.fields}
                append={props.append}
                remove={props.remove}
                reset={props.reset}
            />

            return
        default: return null;
    }
}

export const StatictObjectsAdd = (props) => {

    const [showAdd, setShowAdd] = useState()
    const [selectType, setSelectType] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const { register, handleSubmit, reset, formState: { errors }, setValue, control } = useForm();
    const [postAddLine] = usePostAddLineMutation()
    const [postAddShape] = usePostAddShapeMutation()
    const [postAddCircle] = usePostAddCircleMutation()
    const [postAddRect] = usePostAddRectMutation()
    const { fields, append, remove } = useFieldArray({
        control,
        name: "objectCtxs"
    });
    const submit = (data) => {
        // setIsLoading(true)
        console.log(data.type)
        data.schemeHallId = props.schemeId;
        switch (data.type) {
            case "shape":
                return postAddShape(data).then(() => {
                    remove(fields)
                    reset()
                    setIsLoading(false)
                    props.refetch()
                    setShowAdd(false)
                })
            case "line":
                return postAddLine(data).then(() => {
                    remove()
                    reset()
                    setIsLoading(false)
                    props.refetch()
                    setShowAdd(false)
                })
            case "circle":
                return postAddCircle(data).then(() => {
                    remove()
                    reset()
                    setIsLoading(false)
                    props.refetch()
                    setShowAdd(false)
                })
            case "rect": return postAddRect(data).then(() => {
                remove()
                reset()
                setIsLoading(false)
                props.refetch()
                setShowAdd(false)
            })
            default: return () => {
                setIsLoading(false)
                console.log(data)
            }
        }


    }

    return (
        <>
            <Button size="sm" className="w-100" onClick={() => {
                setShowAdd(!showAdd)
                setIsLoading(false)
            }}
            >Добавить объект</Button>

            {showAdd &&
                <Modal size="lg" show={showAdd} onHide={() => {
                    setSelectType("none")
                    reset()
                    setShowAdd(false)
                }}>
                    <Modal.Header closeButton>Добавление объекта</Modal.Header>
                    {!isLoading ?
                        <Form onSubmit={handleSubmit(submit)}>
                            <Modal.Body>
                                <Form.Select {...register("type", { required: { value: true } })} value={selectType} defaultValue={"none"} onChange={(e) => { setSelectType(e.target.value) }}>
                                    <option value={"none"} disabled hidden>--- Выберите форму ---</option>
                                    <option value={"shape"}>Сложная фигура</option>
                                    <option value={"line"}>Линия</option>
                                    <option value={"circle"}>Круг</option>
                                    <option value={"rect"}>Квадрат</option>
                                </Form.Select>
                                <GetForm
                                    selectType={selectType}
                                    register={register}
                                    errors={errors}
                                    fields={fields}
                                    append={append}
                                    remove={remove}
                                    reset={reset}
                                    className="mt-3"
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="submit">Добавить</Button>
                            </Modal.Footer>
                        </Form>
                        :
                        <LoadScreenMin height={20} />
                    }
                </Modal >
            }
        </>
    )
}
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import locales_ru from "./locales/ru/translations.json";
import locales_en from "./locales/en/translations.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            ru: {
                global: locales_ru
            },
            en: {
                global: locales_en
            }
        },
        lng: "ru", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18next;
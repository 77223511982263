import Axios from "axios";
import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LoadScreenMin } from "../UI/LoadScreenMin";


export const LoginAdminForm = ({ setTokenData }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isDataLoading, setIsDataLoading] = useState(false)
    const navigate = useNavigate();
    const [storeToken, setStroreToken] = useState();

    const onSubmit = async (data) => {
        setIsDataLoading(true)
        const postLogin = async (userData) => {
            const response = await Axios.get('api/login/basic-token')
            const tResponse = await Axios.post('/api/login', userData
                , {
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                        Authorization: "Bearer " + response.data.token,
                    }
                }).catch((error) => {
                    console.log(error)
                    setIsDataLoading(false)
                    alert('Ошибка авторизации')
                }
                )
            return tResponse.data;
        }
        const token = await postLogin(data)
        setTokenData(token)
        if (token.ExpiresIn < Date) {
            const returnUrl = sessionStorage.getItem("returnUrl")
            if (returnUrl) {
                setTimeout(() => {
                    navigate(returnUrl)
                    setIsDataLoading(false)
                }, 2000)
            }
            else {
                navigate("/admin")
                setIsDataLoading(false)
            }
        }
    };
    const toNav = (link) => {
        navigate(link)
    }
    useEffect(() => {
        setStroreToken(sessionStorage.getItem("token"))
    }, [])
    useEffect(() => {
        setIsDataLoading(true)
        if (storeToken) {
            const token = JSON.parse(storeToken);
            if (token) {
                if (token?.ExpiresIn < Date) {
                    const returnUrl = sessionStorage.getItem("returnUrl")
                    if (returnUrl) {
                        // setTimeout(() => {
                        toNav(returnUrl)
                        // navigate()
                        // window.location.pathname = returnUrl;
                        setIsDataLoading(false)
                        // }, 2000)
                    }
                    else {
                        toNav("/admin")
                        // navigate()
                        // window.location.pathname = "/admin"
                        setIsDataLoading(false)
                    }
                }
            }
        }
        setIsDataLoading(false)
    }, [storeToken])

    // const onSubmit = (data) => {
    //     console.log(data);
    //     console.log(errors);
    // }

    return (
        <>{!isDataLoading ?
            <Container className="justify-content-center mt-5">
                <form onSubmit={handleSubmit(onSubmit)} className=" m-auto " style={{ maxWidth: "450px" }}>
                    <div className="form-floating mb-3">
                        <input autoComplete="on" type="email" className="form-control" id="email" placeholder="name@leningradcenter.ru"  {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
                        <label htmlFor="email">Email</label>
                    </div>
                    <div className="form-floating">
                        <input autoComplete="on" type="password" className="form-control" id="password" placeholder="Пароль" {...register("password", { required: true, min: 6 })} />
                        <label htmlFor="password">Пароль</label>
                    </div>
                    <div className="form-check form-switch mt-1">
                        <input className="form-check-input" type="checkbox" id="rememberMe" {...register("rememberMe", {})} />
                        <label className="form-check-label" htmlFor="rememberMe">Запомнить меня</label>
                    </div>
                    <Button type="submit" variant="success" className="w-100 mt-3">Войти</Button>
                </form>
            </Container>
            :
            <LoadScreenMin height={10} />}
        </>
    )
}
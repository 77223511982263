import { Button, Col, Container, Dropdown, Row } from "react-bootstrap"
import { useGetShowsListQuery } from "../../../features/CallApi"
import { LoadScreenMin } from "../../UI/LoadScreenMin"
import { useState } from "react"
import { useEffect } from "react"
import { BookmarkCheckFill, BookmarkX, Gear, Plus } from "react-bootstrap-icons"
import { ShowAdd } from "./ShowAdd"
import { ShowEdit } from "./ShowEdit"
import { ShowSchedule } from "./ShowSchedule"
import { ShowGallery } from "./ShowGallery"


export const ShowsList = () => {

    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [stringFilter, setStringFilter] = useState("")
    const [showAddForm, setShowAddForm] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false)
    const [showSchedule, setShowSchedule] = useState(false)
    const [showGallery, setShowGallery] = useState(false)
    const [showTitle, setShowTitle] = useState()
    const [showId, setShowId] = useState()
    const { data, isLoading, refetch } = useGetShowsListQuery({ pageIndex, pageSize, stringFilter })

    useEffect(() => { refetch() }, [])

    return (
        <Container>
            <div className="fs-3">
                Шоу
            </div>
            <div>
                <Button size="sm" variant="success" onClick={() => { setShowAddForm(!showAddForm) }}>Добавить</Button>
                {showAddForm &&
                    <ShowAdd showAddForm={showAddForm} setShowAddForm={setShowAddForm} setShowEditForm={setShowEditForm} setShowId={setShowId} />
                }
                {showEditForm &&
                    <ShowEdit refetchShows={refetch} showEditForm={showEditForm} setShowEditForm={setShowEditForm} showId={showId} setShowId={setShowId} />
                }
                {showSchedule &&
                    <ShowSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} showId={showId} setShowId={setShowId} showTitle={showTitle} setShowTitle={setShowTitle} />
                }
                {showGallery &&
                    <ShowGallery showGallery={showGallery} setShowGallery={setShowGallery} showId={showId} setShowId={setShowId} showTitle={showTitle} setShowTitle={setShowTitle} />
                }
            </div>
            <div className="d-flex mt-3">
                {/* <label htmlFor="stringFilter" className="form-label">E-Mail</label> */}
                <input type={`text`} id="stringFilter" onChange={
                    (e) => setTimeout(() => { setStringFilter(e.target.value) }, 2000)}
                    placeholder="Поиск.." name="stringFilter" className="form-control" />
            </div>
            {!isLoading ? <>
                {data?.getShows ?
                    <div className="pt-3">
                        {data.getShows.map(show =>
                            <Row key={show.id} className="border-bottom mt-3 pb-3">
                                <Col xs={'auto'}>
                                    <div>
                                        {show.isHidden ? <div className='link-danger'><BookmarkX /></div> : <div className='link-success'><BookmarkCheckFill /></div>}
                                    </div>
                                </Col>
                                <Col xs={'auto'}>
                                    {show.priorityId}
                                </Col>
                                <Col>
                                    {show.name}
                                </Col>
                                
                                <Col xs={'auto'}>
                                    <Dropdown className="mt-1">
                                        <Dropdown.Toggle variant="light" split={false}>
                                            <Gear className='' size='14' />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => {
                                                setShowId(show.id)
                                                setShowEditForm(!showEditForm)
                                            }}>Редактировать</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                setShowId(show.id)
                                                setShowSchedule(!showSchedule)
                                                setShowTitle(show.name)
                                            }}>Расписание</Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                setShowId(show.id)
                                                setShowGallery(!showGallery)
                                                setShowTitle(show.name)
                                            }}>Фото галерея</Dropdown.Item>
                                        </Dropdown.Menu>

                                    </Dropdown>
                                </Col>
                            </Row>
                        )}
                    </div>
                    : <div className="text-center">Список пуст</div>
                }
            </> : <LoadScreenMin />}
            <div className="d-flex flex-row justify-content-center align-content-center mt-3">
                <div className="btn-group">
                    <button
                        className={pageIndex == 1 ? `btn btn-primary disabled` : `btn btn-primary`}
                        onClick={() => {
                            if (pageIndex > 1) {
                                setPageIndex(pageIndex - 1)
                            }
                        }}>Назад</button>
                    <button
                        className={pageIndex == data?.totalPages || pageIndex == 0 ? `btn btn-primary disabled` : `btn btn-primary`}
                        onClick={
                            () => {
                                if (pageIndex <= data?.totalPages) {
                                    setPageIndex(pageIndex + 1)
                                }
                            }}
                    >Вперед</button>
                </div>
            </div>
        </Container>
    )
}
import { setClientTokenInStore } from "../features/tokenClientSlice";
import { setRefreshClientTokenInStore } from "../features/refreshClientTokenSlice"
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useEffect, useState } from "react";

export default function useClientToken() {
  const [refreshing, setRefreshing] = useState(true)
  const [token, setToken] = useState(JSON.parse(sessionStorage.getItem("clientToken")))
  const [timerRefresh, setTimerRefresh] = useState()

  const setClientTokenData = (userToken) => {
    if (userToken) {
      const dataToken = userToken
      const tokenData = {
        clientToken: dataToken.Token,
        clientExpiresIn: dataToken.ExpiresIn
      }
      setTimerRefresh(dataToken.ExpiresIn)
      const refreshTokenData = {
        clientRefreshToken: dataToken.RefreshToken,
        clientRefreshExpiresIn: dataToken.RefreshExpiresIn
      }
      sessionStorage.setItem("clientToken", JSON.stringify(userToken));
      if (tokenData != null) {
        setTimeout(() => {
          setRefreshing(false)
        }, 1000);
      }
      localStorage.setItem("clientTokenRefresh", JSON.stringify(refreshTokenData))
      dispatch(setClientTokenInStore(dataToken));
      dispatch(setRefreshClientTokenInStore(refreshTokenData));
    }
  };
  const getClientRefreshToken = async () => {
    if (!token || Date >= token?.ExpiresIn) {
      // setRefreshing(true)
      const refTokrn = JSON.parse(localStorage.getItem("clientTokenRefresh"))
      try {
        if (refTokrn?.clientRefreshExpiresIn < Date) {
          setToken(null)
          const refTokrnBody = {
            refreshToken: refTokrn.clientRefreshToken,
          }
          const response = await axios.post(
            'https://app.leningradcenter.ru/api/login/refreshtoken/client', refTokrnBody,
            // 'https://192.168.4.13:44415/api/login/refreshtoken/client', refTokrnBody,
            // 'https://localhost:44415/api/login/refreshtoken/client', refTokrnBody,
            {
              headers: {
                'Content-Type': 'application/json;charset=utf-8',
              }
            }

          )
          setClientTokenData(response.data)
          setToken(JSON.parse(sessionStorage.getItem("clientToken")))
        }
        setTimeout(() => {
          setRefreshing(false)
        }, 1000);
      } catch (e) {
        console.log(e)
        if (e.response.status !== 200) {
          sessionStorage.removeItem('clientToken');
          localStorage.removeItem('clientTokenRefresh')
          setToken(null)
        }
        setTimeout(() => {
          setRefreshing(false)
        }, 1000);
      }
    }
  }

  const clientTokenInStore = useSelector((store) => store.clientToken.value);
  // const token = getToken();
  useEffect(() => { getClientRefreshToken(); }, [])
  const dispatch = useDispatch();
  if (token && !clientTokenInStore) setClientTokenData(token);

  useEffect(() => {
    // console.log(token && timerRefresh)
    if (token && timerRefresh) {
      const dateNow = new Date(Date.now())
      const expiresIn = new Date(token?.ExpiresIn)
      const timerTime = expiresIn - dateNow
      // console.log(timerTime)
      if (timerTime > 0) {
        setTimeout(() => {
          console.log("Token is timeout!")
          getClientRefreshToken()
          const newDataToken = JSON.parse(sessionStorage.getItem("clientToken"))
          if (newDataToken) {
            setToken(newDataToken)
          }
        }, timerTime)
      }
      else if (timerTime <= 0) {
        console.log("Token is null!")
        getClientRefreshToken()
        const newDataToken = JSON.parse(sessionStorage.getItem("clientToken"))
        if (newDataToken) {
          setToken(newDataToken)
        }
      }
    }
  }, [timerRefresh])

  return {
    clientToken: token?.Token,
    fullName: token?.FullName,
    setClientTokenData,
    loadingSite: refreshing,
    getClientRefreshToken
  };
}

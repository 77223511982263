import { useEffect, useMemo } from "react";
import { Button, ButtonGroup, Col, Form, Modal, Row } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { usePutSchemeSetingsMutation } from "../../../features/CallApi";
import { LoadScreenMin } from "../../UI/LoadScreenMin";


export const SchemeSetingsEdit = ({ data, showSetingsEdit, setShowSetingsEdit, refetch, setReloadScheme }) => {
    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [putSchemeSetings, { isLoading }] = usePutSchemeSetingsMutation()

    useEffect(() => {
        setValue("id", data.id)
        setValue("name", data.name)
        setValue("nameEn", data.nameEn)
        setValue("color", data.color)
        setValue("width", data.width)
        setValue("height", data.height)
        setValue("offsetTop", data.offsetTop)
        setValue("color", data.color)
    }, [])

    const submitForm = (data) => {
        console.log(data)
        data.width = parseInt(data.width)
        data.height = parseInt(data.height)
        data.offsetTop = parseInt(data.offsetTop)
        putSchemeSetings(data).unwrap().then(() => { 
            refetch()
            // setReloadScheme(true)
            setShowSetingsEdit(false)
         })
    }

    return (
        <Modal show={showSetingsEdit} onHide={() => {
            setShowSetingsEdit(false)
        }}>
            <Modal.Header closeButton>
                Общие настройки схемы
            </Modal.Header>
            {!isLoading ?
                <Form onSubmit={(handleSubmit(submitForm))}>

                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Название</Form.Label>
                            <Form.Control type="text" placeholder="Название" {...register("name", { required: { value: true } })} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Название EN</Form.Label>
                            <Form.Control type="text" placeholder="Название" {...register("nameEn")} />
                        </Form.Group>
                        <Row>
                            <Col md>
                                <Form.Group>
                                    <Form.Label>Ширина</Form.Label>
                                    <Form.Control type="number" placeholder="Ширина" {...register("width", { required: { value: true } })} />
                                </Form.Group>
                            </Col>
                            <Col md>
                                <Form.Group>
                                    <Form.Label>Высота</Form.Label>
                                    <Form.Control type="number" placeholder="Ширина" {...register("height", { required: { value: true } })} />
                                </Form.Group>
                            </Col>
                            <Col md>
                                <Form.Group className="mb-3">
                                    <Form.Label>Верхний отступ</Form.Label>
                                    <Form.Control type="number" {...register("offsetTop", { required: { value: true } })} />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Основной цвет</Form.Label>
                            <Form.Control type="color" placeholder="Основной цвет"
                                {...register("color", { required: { value: true }, onChange: (e) => { setValue("color", e.target.value) } })} />
                        </Form.Group> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button variant="warning" onClick={() => { setShowSetingsEdit() }}>
                                Отменить
                            </Button>
                            <Button variant="success" type="submit">
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Form> :
                <LoadScreenMin height={30} />
            }

        </Modal>
    )
} 
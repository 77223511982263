import { useEffect, useRef, useState } from "react";
import { ArrowDownCircle } from "react-bootstrap-icons"
import { LoadScreenMin } from "./UI/LoadScreenMin";
import { format } from "date-fns";


export const HomeHeadVideo = () => {
    const videoRef = useRef(null);
    const [isLoading, setIsloading] = useState()

    useEffect(() => {
        // Optional: Play the video automatically after preload if needed
        const videoElement = videoRef.current;
        console.log(videoRef.current)
        if (videoElement) {
            videoElement.addEventListener('loadeddata', () => {
                setTimeout(() => {
                    setIsloading(false)
                }, 2000)
            });
        }

        // Cleanup: Remove event listener on component unmount
        return () => {
            if (videoElement) {
                videoElement.removeEventListener('loadeddata', () => {
                    setIsloading(true)
                });
            }
        };
    }, []);
    return (
        <div className="position-relative main-intro-section">
            <div className="main-wrraper-section">
                {isLoading &&
                    <div style={{
                        position: "absolute",
                        width: "100%",
                        top: 0,
                        left: 0,
                    }}>
                        <LoadScreenMin height={100} />
                    </div>
                }
                <video className={"main-intro-video"} autoPlay={true} playsInline={true} loop={true} muted={true} preload="none">
                    <source src={`https://leningradcenter.ru/api/redirecturls/lenta?${format(Date.now(),'ddHHmm')}`} type='video/mp4' codecs="vp8, vorbis" />
                </video>
            </div>
            <div className="position-absolute continue-section" onClick={() => { window.scrollTo(0, window.innerHeight) }}>
                {/* {t("helpers.continue")} */}
                <div className="continue-arrow">
                    <ArrowDownCircle size={24} />
                </div>
            </div>

        </div>
    )
};
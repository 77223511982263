import { useState } from "react"
import { useGetShowsQuery } from "../../features/CallApi"
import { useEffect } from "react"
import { Button, ButtonGroup, Card, Col, Image, Modal, Row } from "react-bootstrap"
import { LoadScreenMin } from "../UI/LoadScreenMin"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { format } from "date-fns"
import { ru, enUS } from 'date-fns/locale';

export const Shows = ({ selLanguage }) => {

    const [t] = useTranslation("global");
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [stringFilter, setStringFilter] = useState("")
    const [selLng, setSelLng] = useState('')
    const [showModel, setShowModel] = useState(false)
    const [dateList, setDateList] = useState()
    const navigate = useNavigate()

    const toNav = (link) => {
        window.scrollTo(0, 0)
        navigate(link)
    }

    useEffect(() => {
        if (selLanguage) {
            setSelLng(selLanguage)
        }
    }, [selLanguage])

    const { data, isLoading } = useGetShowsQuery({ pageIndex, pageSize, selLng })

    return (
        <>
            {showModel &&
                <Modal
                    show={showModel}
                    size="md"
                    fullscreen={"sm-down"}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    onHide={() => {
                        setShowModel(false)
                        setDateList(null)
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {t("afisha.selectdate")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {dateList &&
                            <>
                                {dateList.map(item =>
                                    <Card key={item.sessionId} className='mb-3 shadow' body>
                                        <Row>
                                            <Col>
                                                <div>
                                                    {format(new Date(item.startOfEvent), 'dd.MM')}
                                                    {/* <span>
                                                        {selLanguage === "ru" || selLanguage === null && format(new Date(item.startOfEvent), 'EEEE', { locale: ru })}
                                                        {selLanguage === "en" && format(new Date(item.startOfEvent), 'EEEE', { locale: enUS })}
                                                    </span> */}
                                                </div>
                                                <div>
                                                    {selLanguage === "ru" || selLanguage === null && format(new Date(item.startOfEvent), 'EEEE', { locale: ru })}
                                                    {selLanguage === "en" && format(new Date(item.startOfEvent), 'EEEE', { locale: enUS })}
                                                </div>
                                                <div>
                                                    {console.log(item.startOfEvent)}
                                                    {format(new Date(item.startOfEvent), 'HH:mm')}
                                                </div>
                                            </Col>
                                            <Col xs={"auto"} style={{ inset: 0, margin: 'auto' }}>
                                                <Button onClick={() => { navigate(`/tickets/${item.sessionId}`) }} className='btn-afisha border-0'>{t("afisha.select")}</Button>
                                            </Col>
                                        </Row>
                                    </Card>
                                )}
                            </>
                        }
                    </Modal.Body>
                    {/* <Modal.Footer>
                    <Button onClick={() => setShowModel(false)}>{t("general.close")}</Button>
                </Modal.Footer> */}
                </Modal>
            }
            {!isLoading ?
                <>
                    {data.getShows.length > 0 &&
                        <>
                            {data.getShows.map(item =>
                                <Row key={item.id} className='mb-3 justify-content-center' >
                                    <Col xs={5} lg={4}>
                                        <Image style={{ cursor: 'pointer' }} onClick={() => { toNav(`/about-project/show/${item.seoUrl}`) }} fluid src={`/api/shows/afisha-img/${item.id}`} alt={item.name} />
                                    </Col>
                                    <Col xs={7} lg={6} className="pt-3">
                                        <div style={{ cursor: 'pointer' }} onClick={() => { toNav(`/about-project/show/${item.seoUrl}`) }}>
                                            <h3>
                                                <strong>{item.name}</strong>
                                            </h3>
                                            <p className="mb-1">
                                                <strong>
                                                    {item.shortDescription}
                                                </strong>
                                            </p>
                                            <p className="mb-1">
                                                {t("show.director")} — <strong>
                                                    {item.director}
                                                </strong>
                                            </p>
                                            <p className="d-none d-lg-block mb-0">
                                                {t("show.duration")}: {item.duration}
                                            </p>
                                            <p className="mb-1">
                                                {t("show.age")} <strong>{item.ageCategory}+</strong>
                                            </p>
                                        </div>
                                        {/* <ButtonGroup size="sm" className="shadow-sm"> */}
                                        {item.showSchedules.length > 0 ?
                                            <Button className="btn-afisha"
                                                onClick={() => {
                                                    setShowModel(true)
                                                    // console.log(item.showSchedules)
                                                    setDateList(item.showSchedules)
                                                }}
                                            >{t("afisha.buytickets")}</Button>
                                            :
                                            <>
                                                {item.buyButtonStatus === true ?
                                                    <>
                                                        <div className="mb-1">
                                                            <span className="text-muted">{item.quote}</span>
                                                        </div>
                                                        <Button className='border shadow btn-afisha' style={{ borderColor: "#272929" }} onClick={() => {
                                                            window.open(item.buyButtonLink, '_blank').focus();
                                                        }} variant='light'>{item.buyButtonText}</Button>
                                                    </>
                                                    :

                                                    <Button onClick={() => { toNav(`/about-project/show/${item.seoUrl}`) }} className="border shadow" variant='white'>{t("afisha.more")}</Button>
                                                }
                                            </>
                                        }
                                        {/* </ButtonGroup> */}
                                    </Col>
                                </Row>
                            )}
                        </>
                    }
                </>
                :
                <LoadScreenMin />
            }
        </>
    )
}
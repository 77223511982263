import { Accordion, Button, ButtonGroup, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap"
import { Seat } from "./Seat"
import { Gear, PlusSquare, Clipboard2, Check, Trash, InputCursorText, Link } from "react-bootstrap-icons"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDeleteRowMutation, usePostCopyRowMutation, usePutEditRowMutation, usePutSeatsEditMutation } from "../../../../features/CallApi"


export const SeatList = ({ row, addSeatsShow, setAddSeatsShow, setRowId, refetch, schemeHallId }) => {
    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [showSeatsEdit, setShowSeatsEdit] = useState(false);
    const [showCopyRow, setShowCopyRow] = useState(false);
    const [postCopyRow, { isLoading }] = usePostCopyRowMutation()
    const [putSeatsEdit, { isLoading: isPutingSeatsEdit }] = usePutSeatsEditMutation()
    const [showInputRename, setShowInputRename] = useState()
    const [putEditRow, { isLoading: isRenaming }] = usePutEditRowMutation()
    const [deleteRow, { isLoading: isDeleting }] = useDeleteRowMutation()

    useEffect(() => {
        setValue("schemeHallId", schemeHallId)
        setValue("rowCopyid", row.id)
        setValue("rowId", row.id)
        setValue("id", row.id)
        setValue("nameRow", row.nameRow)
        setValue("nameRowEn", row.nameRowEn)
        setValue("nameRowPremiera", row.nameRowPremiera)
    }, [showInputRename])

    const submit = (data) => {
        data.rowCopyid = row.id
        postCopyRow(data).then(() => {
            setShowCopyRow(false)
            refetch()
            reset()
        })
    }

    const submitSeatsEdit = (data) => {
        data.rowId = row.id
        console.log(data)
        putSeatsEdit(data).then(() => {
            setShowSeatsEdit(false)
            refetch()
            reset()
        })
    }

    const submitRename = (data) => {
        putEditRow(data).then(() => {
            refetch()
            reset()
            setShowInputRename()
        })
    }

    return (
        <Accordion.Item key={`${row.id}`} eventKey={`${row.id}`}>
            <Accordion.Button className="p-1">{row.nameRow}</Accordion.Button>
            <Accordion.Body>
                <ButtonGroup className="mb-1 mt-1">
                    <Button size="sm" variant="outline-secondary" onClick={() => {
                        setRowId(row.id)
                        setAddSeatsShow(true)
                    }}><PlusSquare /></Button>
                    <Button variant="outline-secondary" onClick={() => { setShowInputRename(!showInputRename) }}><InputCursorText /></Button>
                    <Button variant="outline-secondary" size="sm" onClick={() => { setShowSeatsEdit(!showSeatsEdit) }}><Gear /></Button>
                    <Button variant="outline-secondary" onClick={() => { setShowCopyRow(!showCopyRow) }}><Clipboard2 /></Button>
                    <Button variant="outline-danger" onClick={() => {
                        if (window.confirm("Вы уверены?")) {
                            deleteRow(row.id).then(() => {
                                refetch()
                            })
                        }
                    }}>{!isDeleting ? <Trash /> : <Spinner />}</Button>
                </ButtonGroup>
                {showInputRename &&
                    <Form onSubmit={handleSubmit(submitRename)}>
                        {!isRenaming ?
                            <Row>
                                <Col>
                                    <div>Редактирование ряда</div>
                                    <InputGroup className="pb-1">
                                        <InputGroup.Text>#</InputGroup.Text>
                                        <Form.Control {...register("nameRow")} type='text' />
                                    </InputGroup>
                                    <InputGroup className="pb-1">
                                        <InputGroup.Text># EN</InputGroup.Text>
                                        <Form.Control {...register("nameRowEn")} type='text' />
                                    </InputGroup>
                                    <InputGroup className="pb-1">
                                        <InputGroup.Text><Link /></InputGroup.Text>
                                        <Form.Control placeholder="Название ряда в Премьере" {...register("nameRowPremiera")} type='text' />
                                    </InputGroup>
                                </Col>
                                <Col xs={"auto"} style={{ alignContent: "center" }}><Button size="sm" variant="success" type="submit"><Check /></Button></Col>
                            </Row>
                            : <Spinner />}
                    </Form>
                }
                {showCopyRow &&
                    <Form className="mb-3 mt-1" onSubmit={handleSubmit(submit)}>
                        {!isLoading ?
                            <>
                                <div>Создание копии ряда</div>
                                <InputGroup className="mb-1">
                                    <InputGroup.Text>Название</InputGroup.Text>
                                    <Form.Control defaultValue={null} {...register("name")} type='text' />
                                </InputGroup>
                                <InputGroup className="mb-1">
                                    <InputGroup.Text><Link /></InputGroup.Text>
                                    <Form.Control defaultValue={""} {...register("nameRowPremiera")} type='text' />
                                </InputGroup>

                                <InputGroup className="mb-1">
                                    <InputGroup.Text>X±</InputGroup.Text>
                                    <Form.Control defaultValue={0} {...register("offsetX")} type="number" />
                                    <InputGroup.Text>Y±</InputGroup.Text>
                                    <Form.Control defaultValue={0} {...register("offsetY")} type="number" />
                                    <Button type="submit"><Check /></Button>
                                </InputGroup>
                            </> :
                            <Spinner />
                        }

                    </Form>
                }
                {showSeatsEdit &&
                    <Form className="mb-3 mt-1" onSubmit={handleSubmit(submitSeatsEdit)}>
                        {!isPutingSeatsEdit ?
                            <>
                                <div>Редактирование ряда</div>

                                <InputGroup className="mb-3 mt-1">
                                    <InputGroup.Text>X±</InputGroup.Text>
                                    <Form.Control {...register("offsetX")} defaultValue={0} type='number' />
                                    <InputGroup.Text>Y±</InputGroup.Text>
                                    <Form.Control {...register("offsetY")} defaultValue={0} type="number" />
                                    <Button type="submit"><Check /></Button>
                                </InputGroup>
                                <InputGroup>
                                    <Form.Select size="sm" {...register("type")}>
                                        <option value={"noEdit"}>--- тип ---</option>
                                        <option value={"singl"}>Одиночное</option>
                                        <option value={"topSingl"}>Вниз одиночное</option>
                                        <option value={"left"}>Левый</option>
                                        <option value={"right"}>Правый</option>
                                        <option value={"turnLeft"}>Влево</option>
                                        <option value={"turnRight"}>Вправо</option>
                                        <option value={"boxCenter"}>Центр ложи</option>
                                        <option value={"boxTopLeft"}>Верх-лева ложи</option>
                                        <option value={"boxTopRight"}>Верх-право ложи</option>
                                        <option value={"topLeft"}>Вниз левый</option>
                                        <option value={"topRight"}>Вниз правый </option>
                                    </Form.Select>
                                </InputGroup>
                            </>
                            :
                            <Spinner />
                        }

                    </Form>
                }
                {row.seats.map(seat =>
                    <div className="shadow-sm mb-1 mt-1 pt-1 pb-1" key={`seatId-${seat.id}`}>
                        <Seat seat={seat} refetch={refetch} />
                    </div>
                )}
            </Accordion.Body>
        </Accordion.Item>
    )
}
import { useEffect, useState } from "react"
import { useDeleteRedirectUrlMutation, useGetRedirectUrlsQuery, usePostRedirectUrlMutation, usePutRedirectUrlMutation } from "../../../features/CallApi"
import { LoadScreenMin } from '../../UI/LoadScreenMin'
import { Button, ButtonGroup, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap"
import { Check, Gear, Trash, X } from "react-bootstrap-icons"
import { useForm } from "react-hook-form"

const RedirectUrlAdd = () => {
    const [show, setShow] = useState(false)
    const [postRedirectUrl, { isLoading }] = usePostRedirectUrlMutation()
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const submit = (data) => {
        postRedirectUrl(data)
            .then(() => {
                setShow(false)
            })
    }

    return (
        <>
            <Button className="mb-3 mt-3" variant="success" onClick={() => { setShow(!show) }}>Добавить</Button>
            <Modal show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(submit)}>
                        <Row>
                            <Col lg={"5"}>
                                <Form.Floating className="mb-3">
                                    <Form.Control
                                        id="urlFrom"
                                        type="text"
                                        placeholder="Url"
                                        {...register("url", { required: { value: true, message: "Укажить исходный адрес" } })}
                                    />
                                    <label htmlFor="urlFrom">Url</label>
                                </Form.Floating>
                            </Col>
                            <Col lg={"5"}>
                                <Form.Floating className="mb-3">
                                    <Form.Control
                                        id="urlTo"
                                        type="text"
                                        placeholder="Url"
                                        {...register("redirectToUrl", { required: { value: true, message: "Укажить адрес перенаправления" } })}
                                    />
                                    <label htmlFor="urlTo">Redirect to Url</label>
                                </Form.Floating>
                            </Col>

                            <Col xs={'auto'} style={{ alignItems: 'center', display: 'flex' }}>
                                {!isLoading ?
                                    <ButtonGroup size="sm">
                                        <Button type="submit" variant="success">
                                            <Check />
                                        </Button>
                                        <Button variant="danger" onClick={
                                            () => {
                                                reset()
                                                setShow(false)
                                            }}>
                                            <X />
                                        </Button>
                                    </ButtonGroup>
                                    :
                                    <Spinner />
                                }
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </>
    )
}

const RedirectUrlItem = ({ item }) => {

    const [edit, setEdit] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const [putRedirectUrl, { isLoading: isPuting }] = usePutRedirectUrlMutation()
    const [deleteRedirectUrl, { isLoading: isDeleting }] = useDeleteRedirectUrlMutation()
    useEffect(() => {
        setValue("id", item.id)
        setValue("url", item.url)
        setValue("redirectToUrl", item.redirectToUrl)
    }, [edit])

    const submit = (data) => {
        putRedirectUrl(data).then(() => setEdit(false))
    }

    return (
        <>
            {!edit ?
                <Row className="pb-2 mb-2 border-bottom">
                    <Col xs={'auto'}>
                        <div>{item.id}</div>
                    </Col>
                    <Col lg={"5"}>
                        <a href={`https://leningradcenter.ru/api/redirecturls/${item.url}`} target="_blank">https://leningradcenter.ru/api/redirecturls/{item.url}</a>
                    </Col>
                    <Col lg={"5"}>
                        <a href={item.redirectToUrl} target="_blank">
                            {item.redirectToUrl}
                        </a>
                    </Col>
                    <Col xs={'auto'}>
                        {!isDeleting ?
                            <ButtonGroup size="sm">
                                <Button variant="warning" onClick={() => { setEdit(!edit) }}>
                                    <Gear />
                                </Button>
                                <Button variant="danger" onClick={() => { deleteRedirectUrl(item.id) }}>
                                    <Trash />
                                </Button>
                            </ButtonGroup>
                            : <Spinner />}
                    </Col>
                </Row>
                :
                <Form onSubmit={handleSubmit(submit)}>
                    <Row className="pb-2 mb-2 border-bottom">
                        <Col xs={'auto'}>
                            <div>{item.id}</div>
                        </Col>
                        <Col lg={"5"}>
                            <Form.Floating className="mb-3">
                                <Form.Control
                                    id="urlFrom"
                                    type="text"
                                    placeholder="Url"
                                    {...register("url")}
                                />
                                <label htmlFor="urlFrom">Url</label>
                            </Form.Floating>
                        </Col>
                        <Col lg={"5"}>
                            <Form.Floating className="mb-3">
                                <Form.Control
                                    id="urlTo"
                                    type="text"
                                    placeholder="Url"
                                    {...register("redirectToUrl")}
                                />
                                <label htmlFor="urlTo">Redirect to Url</label>
                            </Form.Floating>
                        </Col>

                        <Col xs={'auto'} style={{ alignItems: 'center', display: 'flex' }}>
                            {!isPuting ?
                                <ButtonGroup size="sm">
                                    <Button type="submit" variant="success">
                                        <Check />
                                    </Button>
                                    <Button variant="danger" onClick={
                                        () => {
                                            reset()
                                            setEdit(false)
                                        }}>
                                        <X />
                                    </Button>
                                </ButtonGroup>
                                :
                                <Spinner />
                            }
                        </Col>
                    </Row>
                </Form>
            }
        </>
    )
}

export const RedirectUrls = ({ }) => {

    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize] = useState(10)
    const { data, isLoading } = useGetRedirectUrlsQuery({ pageIndex, pageSize })

    return (
        <>
            <Container>
                <RedirectUrlAdd />
                {!isLoading ?
                    <>
                        {data.redirectUrls?.length > 0 ?
                            <>
                                {data.redirectUrls.map(item =>
                                    <RedirectUrlItem item={item} />
                                )}
                            </>
                            :
                            <>
                                <p className="text-center">
                                    Список пуст
                                </p>
                            </>
                        }
                    </>
                    :
                    <>
                        <LoadScreenMin />
                    </>
                }
                <div className="d-flex flex-row justify-content-center align-content-center mt-3">
                    <div className="btn-group">
                        <button
                            className={pageIndex == 1 ? `btn btn-primary disabled` : `btn btn-primary`}
                            onClick={() => {
                                if (pageIndex > 1) {
                                    setPageIndex(pageIndex - 1)
                                }
                            }}>Назад</button>
                        <button
                            className={pageIndex == data?.totalPages || pageIndex == 0 ? `btn btn-primary disabled` : `btn btn-primary`}
                            onClick={
                                () => {
                                    if (pageIndex <= data?.totalPages) {
                                        setPageIndex(pageIndex + 1)
                                    }
                                }}
                        >Вперед</button>
                    </div>
                </div>
            </Container>
        </>
    )
}
import { Button, ButtonGroup, FloatingLabel, Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { LoadScreenMin } from "../../../UI/LoadScreenMin";
import { useEffect } from "react";
import { usePostAddrowMutation } from "../../../../features/CallApi";


export const RowAdd = ({ refetch, addRowShow, setAddRowShow, sectionsId }) => {
    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [postAddrow, { isLoading }] = usePostAddrowMutation()
    useEffect(() => {
        setValue("hallSectionId", sectionsId)
    }, [])

    const submit = (data) => {
        console.log(data)
        postAddrow(data).unwrap()
            .then(() => {
                refetch()
                setAddRowShow(false)
            })
    }

    return (
        <Modal show={addRowShow} onHide={() => { setAddRowShow(false) }}>
            <Modal.Header closeButton>Добавить ряд</Modal.Header>
            {!isLoading ?
                <Form onSubmit={handleSubmit(submit)}>
                    <Modal.Body>
                        <FloatingLabel
                            controlId="floatingTitile"
                            label="Название"
                            className="mb-3"
                        >
                            <Form.Control type="text" isInvalid={errors.name} isValid={isValid.name} placeholder="Название" {...register("nameRow", { required: { value: true } })} />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingTitile"
                            label="Название ряда в Премьере"
                            className="mb-3"
                        >
                            <Form.Control type="text"
                                isInvalid={errors.nameRowPremiera}
                                isValid={isValid.nameRowPremiera}
                                placeholder="Название ряда в Премьере" {...register("nameRowPremiera")} />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button variant="warning" onClick={() => { setAddRowShow(false) }}>
                                Отменить
                            </Button>
                            <Button variant="success" type="submit">
                                Сохранить
                            </Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Form> : <LoadScreenMin height={30} />}
        </Modal>
    )
}
import { Button, Col, Container, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useBaseTokenMutation, useClientLoginMutation } from "../../features/CallAppApi";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LoadScreenMin } from "../UI/LoadScreenMin";
import { ClientLogin } from "./ClientLogin";
import { ClientProfile } from "./ClientProfile";
import { Helmet } from "react-helmet";


export const Client = ({ clientToken, setClientTokenData, getClientRefreshToken }) => {
    const { t } = useTranslation("global");
    const [authorized, setAuthorized] = useState(false)



    useEffect(() => {
        if (!clientToken) {
            getClientRefreshToken()
            sessionStorage.setItem("returnUrl", window.location.pathname)
            setAuthorized(false)
        }
        else {
            setAuthorized(true)
        }
    }, [clientToken])

    return (
        <>
            <Helmet>
                <title>{t("mainTag.title")}</title>
                <meta name="description" content='Первое в России шоу-пространство "Ленинград Центр"'></meta>
                <meta name="og:title" content={`${t("mainTag.title")}`} />
                <meta name="og:description" content='Первое в России шоу-пространство "Ленинград Центр"' />
                <meta name="keywords" content="шоу- пространство, шоу пространство, ленинград центр, центр ленинград, мюзикл, шоу, представление, афиша" c />
                {/* <meta property="og:image" content={data.headLayoutImageType ? `/api/shows/head-wide-img/${data.id})` : "/Show/091A8332.jpg"} /> */}
            </Helmet>
            <div className="about-haed-layout mb-3">
                <div className="about-head-layout-img" style={{ backgroundImage: `url("/AboutProjcet/about-project.jpg")` }}>
                </div>
                <div className="about-head-mask">
                    <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3"
                        style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                    </div>
                </div>
            </div>

            <Container>
                {authorized ?
                    <>
                        <ClientProfile t={t} />
                    </>
                    :
                    <>
                        <ClientLogin setClientTokenData={setClientTokenData} setAuthorized={setAuthorized} authorized={authorized} t={t} />
                    </>
                }
            </Container>
        </>
    )
}
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

const refreshClientTokenSlice = createSlice({
  name: "refreshClientToken",
  initialState,
  reducers: {
    setRefreshClientTokenInStore: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setRefreshClientTokenInStore } = refreshClientTokenSlice.actions;

export default refreshClientTokenSlice.reducer;

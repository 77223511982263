import { Nav } from "react-bootstrap"
import { ArrowThroughHeart, Bank, Easel, Gift, ImageAlt, PatchCheck, People, Stack } from "react-bootstrap-icons"
import { useNavigate } from "react-router-dom";


export const AboutMenu = ({ t }) => {

    const iconSize = 18;
    const navigate = useNavigate()

    return (
        <Nav className="flex-column about-menu">
            <Nav.Link onClick={() => { navigate("/about-project") }}>
                <div className='icon-menu'>
                    <svg style={{ fill: "currentColor" }} width={iconSize} height={iconSize}>
                        <use xlinkHref='lc-logo.svg#logo-small'></use>
                    </svg>
                </div>
                <span>
                    {t("menu.about")}
                </span>
            </Nav.Link>
            <Nav.Link onClick={() => { navigate("/about-project/history") }}>
                <div className='icon-menu'>
                    <Bank size={iconSize} />
                </div>
                <span>
                    {t("menu.history")}
                </span>
            </Nav.Link>
            <Nav.Link onClick={() => { navigate("/about-project/stage") }}>
                <div className='icon-menu'>
                    <svg width={iconSize} height={iconSize}>
                        <use style={{ fill: "currentColor" }} xlinkHref='lc-logo.svg#stage'></use>
                    </svg>
                </div>
                <span>
                    {t("menu.stage")}
                </span>
            </Nav.Link>
            <Nav.Link onClick={() => { navigate("/about-project/gallery") }}>
                <div className='icon-menu'>
                    <Easel size={iconSize} />
                </div>
                <span>
                    {t("menu.gallery")}
                </span>
            </Nav.Link>
            <Nav.Link onClick={() => { navigate("/about-project/troupe") }}>
                <div className='icon-menu'>
                    <People size={iconSize} />
                </div>
                <span>
                    {t("menu.troupe")}
                </span>
            </Nav.Link>
            <Nav.Link onClick={() => { navigate("/about-project/shows") }}>
                <div className='icon-menu'>
                    <ArrowThroughHeart size={iconSize} />
                </div>
                <span>
                    {t("menu.shows")}
                </span>
            </Nav.Link>
            <Nav.Link onClick={() => { navigate("/about-project/exhibitions") }}>
                <div className='icon-menu'>
                    <ImageAlt size={iconSize} />
                </div>
                <span>
                    {t("menu.exhibitions")}
                </span>
            </Nav.Link>
            <Nav.Link onClick={() => { navigate("/about-project/bars") }}>
                <div className='icon-menu'>
                    <svg width={iconSize} height={iconSize}>
                        <use xlinkHref='lc-logo.svg#drink'></use>
                    </svg>
                </div>
                <span>
                    {t("menu.bars")}
                </span>
            </Nav.Link>

            <Nav.Link onClick={() => { navigate("/about-project/restaurant") }}>
                <div className='icon-menu'>
                    <svg width={iconSize} height={iconSize}>
                        <use xlinkHref='lc-logo.svg#blokSm'></use>
                    </svg>
                </div>
                <span>
                    {t("menu.restaurant")}
                </span>
            </Nav.Link>
            {/* <Nav.Link onClick={() => { navigate("/about-project/certificates") }}> */}
            <Nav.Link onClick={() => { navigate("/about-project/certificates")}}>
                <Gift size={iconSize} />
                {t("menu.certificates")}
            </Nav.Link>
            <Nav.Link onClick={() => { navigate("/about-project/znak-kachestva") }}>
                <div className='icon-menu'>
                    <svg width={iconSize} height={iconSize}>
                        <use xlinkHref='lc-logo.svg#ABRgold'></use>
                    </svg>
                </div>
                <span>
                    {t("menu.qualitymark")}
                </span>
            </Nav.Link>
            <Nav.Link onClick={() => { navigate("/about-project/partners") }}>
                <div className='icon-menu'>
                    <PatchCheck size={iconSize} />
                </div>
                <span>
                    {t("menu.partners")}
                </span>
            </Nav.Link>
            <Nav.Link onClick={() => { navigate("/about-project/corporate") }}>
                <div className='icon-menu'>
                    <Stack size={iconSize} />
                </div>
                <span>
                    {t("menu.corporate")}
                </span>
            </Nav.Link>
        </Nav>
    )
}
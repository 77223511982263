import { Check2All, XCircle } from 'react-bootstrap-icons'
import { LoadScreenMin } from '../UI/LoadScreenMin'



export const CertCompletion = ({ dataOrder, orderStatus, resetForm, t }) => {

    return (
        <div>
            <div className="container">
                {orderStatus ?
                    <div>
                        {
                            orderStatus.data == "approved" &&
                            <div>
                                <div className="offset-md-2 col-sm-8">
                                    <h2 className="text-center mt-5">{t("form.orderCompFirst")} {dataOrder.orderId} {t("form.orderCompLast")}</h2>
                                    <div className="text-center">
                                        <Check2All size={80} className={"text-success"} />
                                    </div>
                                    <div className="text-center">
                                        <p>{t("form.sendOrderInfo")}</p>
                                        <p>
                                            {t("form.thankForOrder")}
                                            <br />
                                            {t("form.waintInLc")}
                                        </p>
                                        <button className="btn btn-lc btn-lc-cert mb-3" onClick={() => { resetForm() }}>{t("form.creactNewOrder")}</button>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            orderStatus.data != "approved" &&
                            <div>
                                <div className="offset-md-2 col-sm-8">
                                    <h2 className="text-center mt-5">Заказ № {dataOrder.orderId} отменен!</h2>
                                    <div className="text-center ">
                                        <XCircle size={80} className={"text-danger mt-3 mb-3"} />
                                    </div>
                                    <div className="text-center">
                                        <p className="text-center">{t("form.placeNeworder")}</p>

                                        <button className="btn btn-lc btn-lc-cert mb-3" onClick={() => { resetForm() }}>{t("form.creactNewOrder")}</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div> : <LoadScreenMin />
                }
                <hr />
            </div>
        </div>
    )
}
import { Col, Image, Row } from "react-bootstrap"


export const Partners = ({ selLanguage }) => {

    const partners = [
        {
            name: "Ресторан «Блок»",
            desc: "На крыше «Ленинград Центра» расположился ресторан Александра Раппопорта «Блок» с панорамным видом на Таврический сад. Концепция ресторана — с одной стороны, современная российская кухня, с другой — высочайшего качества российское мясо, которое по многим параметрам превосходит все существующие зарубежные аналоги. Атмосферу в ресторане задает интерьер в стиле северного модерна и ленинградского авангардизма — светлое, многоуровневое и торжественное пространство, залитое рекой света.",
            link: "",
            logoLink: "/partnersLogo/blok_logo_simple.jpg",
            href: "https://blok.restaurant/"
        },
        {
            name: "Молодёжный центр «Валдай»",
            desc: "Молодёжный центр «Место. Валдай» — это уникальное место для детей и молодёжи в городе Валдай. Центр включает в себя клубные занятия по гуманитарным, техническим и художественным направлениям, уютную библиотеку с большим выбором художественной, исторической и специальной литературы.",
            link: "",
            logoLink: "/partnersLogo/9.MST_Valday_Logo.jpg",
            href: "https://www.valday.center/"
        },
        {
            name: "Парк-отель «Дача Винтера»",
            desc: "Парк-отель «Дача Винтера» — расположен в Карелии на живописном берегу Ладожского озера. Включает комфортные номера и коттеджи, банный комплекс, панорамный ресторан «Густав Винтер».",
            link: "",
            logoLink: "/partnersLogo/2.DW_Logo-1.jpg",
            href: "https://www.dachawintera.ru/"
        },
        {
            name: "Всесезонный курорт «Игора»",
            desc: "На всесезонном курорте «Игора» находится 10 горнолыжных трасс длиной более 4-х километров, один из лучших ледовых дворцов северо-запада России, коттеджи и апартаменты, отель «Игора. Времена года», СПА и панорамный бассейн, Озеро развлечений и стильный пляж INKERMAN, детский клуб IGORA KIDS CLUB, бары, кафе и рестораны с авторской кухней. Ежегодно его посещают более 450 тыс. гостей.",
            link: "",
            logoLink: "/partnersLogo/3.IGA_lg.jpg",
            href: "https://igora.ru/"
        },
        {
            name: "Автодром «Игора Драйв»",
            desc: "Комплекс мирового уровня, объединяющий технические виды спорта и развлечения. Инфраструктура позволяет принимать разные виды авто и мотогонок - от туринга до ралли-кросса и супербайка.",
            link: "",
            logoLink: "/partnersLogo/igora-drive.jpg",
            href: "https://drive-igora.ru/"
        },
        {
            name: "Туроператор «Игора Тур»",
            desc: "«Игора Тур» - официальный туроператор, специализирующийся на путешествиях по Приладожью. В коллекции «Игора Тур» большое количество разноплановых программ: гастрономические путешествия, активные туры и маршруты вокруг сердца Карелии – Ладожского озера – с размещением в парк-отеле «Дача Винтера» и сети отелей «Точка на карте».",
            link: "",
            logoLink: "/partnersLogo/4.IGA_TR_logo_1115_.jpg",
            href: "https://igoratour.ru/"
        },
        {
            name: "Яхт-клуб «Лагуна»",
            desc: "«Лагуна» - обновленный яхт-клуб на мысе Маячный в Приозерске, где все предусмотрено для отдыха, необходимого экипажу после долгих плаваний. «Лагуна» вмещает несколько десятков судов размером до 45 метров в акватории, полностью защищенной от ветрового воздействия и волнений.",
            link: "",
            logoLink: "/partnersLogo/Laguna.png",
            href: "https://lagunaladoga.ru/"
        },
        {
            name: "Молодёжный центр «Сердоболь»",
            desc: "Молодёжный центр «Сердоболь» — это уникальное место для детей и молодёжи в городе Сортавала. Здесь любой может найти занятия по вкусу и посещать их совершенно бесплатно. В центре есть кафе с панорамным видом на Ладогу.",
            link: "",
            logoLink: "/partnersLogo/SDBMSTLogo1.jpg",
            href: "http://serdobol.center/"
        },
        {
            name: "Центр активного отдыха «Терра Нордика»",
            desc: "Авторские маршруты с краеведческим уклоном от профессионалов в экстриме и знатоков региона. Туры на квадроциклах и снегоходах – для тех, кто впервые решил исследовать Приладожье, и тех, кто давно мечтал почувствовать себя первооткрывателем загадочной Карелии. Два веревочных парка для незабываемых приключений и эмоций на высоте. ",
            link: "",
            logoLink: "/partnersLogo/TRA_logo_0702-05.png",
            href: "https://terra-nordica.ru/"
        },
        {
            name: "Сеть отелей «Точка на карте»",
            desc: "Сеть отелей «Точка на карте» - пример создания современной гостиничной инфраструктуры для развития туризма в России. Четыре отеля вокруг Ладожского озера: Приозерск, Сортавала, Видлица, Лодейное Поле – каждая «Точка» расположена рядом с главными достопримечательностями региона и позволяет совершить большое путешествие вокруг Ладоги.",
            link: "",
            logoLink: "/partnersLogo/1.T4K_Logo_SO.jpg",
            href: "https://tochkanakarte.ru/"
        },
        {
            name: "Ресторан «Фаворит»",
            desc: "Видовой ресторан «Фаворит» находится на автодроме «Игора Драйв»: из окон ресторана открывается панорамный вид прямо на гоночную трассу, и это идеальная локация для наблюдения с максимальным комфортом за захватывающими состязаниями и различными авто-мото-событиями.",
            link: "",
            logoLink: "/partnersLogo/logo_favorit.png",
            href: "https://favorit-igora.ru/"
        },
        {
            name: "Группа банка «Россия»",
            desc: "Программа лояльности «Знак Качества Группы Банка «Россия». Пользуйтесь привилегиями любимых брендов, получайте незабываемый опыт от путешествий!",
            link: "",
            logoLink: "/partnersLogo/logo-gbr-white.png",
            href: "https://rossiya-group.ru/"
        }
    ]
    const ifonPartners = [
        {
            name: "5 канал",
            // desc: "Видовой ресторан «Фаворит» находится на автодроме «Игора Драйв»: из окон ресторана открывается панорамный вид прямо на гоночную трассу, и это идеальная локация для наблюдения с максимальным комфортом за захватывающими состязаниями и различными авто-мото-событиями.",
            link: "",
            logoLink: "/partnersLogo/logo_2023.svg",
            href: "https://www.5-tv.ru/"
        },
        {
            name: "Деловой Петербург",
            // desc: "Видовой ресторан «Фаворит» находится на автодроме «Игора Драйв»: из окон ресторана открывается панорамный вид прямо на гоночную трассу, и это идеальная локация для наблюдения с максимальным комфортом за захватывающими состязаниями и различными авто-мото-событиями.",
            link: "",
            logoLink: "/partnersLogo/logo_dp.png",
            href: "https://www.dp.ru/"
        }
    ]

    return (
        <>
            {partners.map(partner =>
                <Row key={partner.name} className="border-bottom pt-3">
                    <Col md={4} xl={2}>
                        <Image fluid alt={partner.name} src={partner.logoLink} className="mb-3 w-100" />
                    </Col>
                    <Col>
                        <h3>
                            {partner.name}
                        </h3>
                        <p>
                            {partner.desc}
                        </p>
                        {partner.href &&
                            <p>
                                {partner.href}
                            </p>
                        }
                    </Col>
                </Row>
            )}
            <h4 className="mt-3">Информационные партнеры:</h4>
            {ifonPartners.map(partner =>
                <Row key={partner.name} className="border-bottom pt-3">
                    <Col md={4} xl={2}>
                        <Image fluid alt={partner.name} src={partner.logoLink} className="mb-3 w-100" />
                    </Col>
                    <Col>
                        <h3>
                            {partner.name}
                        </h3>
                        <p>
                            {partner.desc}
                        </p>
                        {partner.href &&
                            <p>
                                {partner.href}
                            </p>
                        }
                    </Col>
                </Row>
            )}
        </>
    )
}
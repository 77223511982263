import { useState } from "react"
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


export const Cabinet = () => {
    const [statusLoading, setStatusLoading] = useState(true)
    const addJq = () => {
        const script = document.createElement("script");
        script.src = "https://code.jquery.com/jquery-3.7.1.js";
        script.integrity = "sha256-eKhayi8LEQwp4NKxN+CfCh+3qOVUtJn3QNZ0TciWLP4==";
        script.crossOrigin = "anonymous";
        document.head.appendChild(script);

        const scriptYM = document.createElement("script")
        scriptYM.src = '/ym.js'
        scriptYM.type = 'text/javascript'

        document.head.appendChild(scriptYM);

        const scriptCT = document.createElement("script");
        scriptCT.type = 'text/javascript'
        scriptCT.append(`(function(w,d,n,c){w.CalltouchDataObject=n;w[n]=function(){w[n]["callbacks"].push(arguments)};if(!w[n]["callbacks"]){w[n]["callbacks"]=[]}w[n]["loaded"]=false;if(typeof c!=="object"){c=[c]}w[n]["counters"]=c;for(var i=0;i<c.length;i+=1){p(c[i])}function p(cId){var a=d.getElementsByTagName("script")[0],s=d.createElement("script"),i=function(){a.parentNode.insertBefore(s,a)},m=typeof Array.prototype.find === 'function',n=m?"init-min.js":"init.js";s.type="text/javascript";s.async=true;s.src="https://mod.calltouch.ru/"+n+"?id="+cId;if(w.opera=="[object Opera]"){d.addEventListener("DOMContentLoaded",i,false)}else{i()}}})(window,document,"ct","2leyj89q");`)
        document.head.appendChild(scriptCT);
        addJN()
    }

    const addJN = () => {
        const script = document.createElement("script");
        script.async = true;
        //https://buy.leningradcenter.etobilet.ru
        script.src = "https://buy.leningradcenter.ru/js/loadnikolas.js";
        setTimeout(() => {
            document.head.appendChild(script);
            setStatusLoading(false)
        }, 1000)

    }
    const { t } = useTranslation("global");


    return (
        <>
            <Helmet>
                <title>{t("mainTag.title")}</title>
                <meta name="description" content='Первое в России шоу-пространство "Ленинград Центр"'></meta>
                <meta name="og:title" content={`${t("mainTag.title")}`} />
                <meta name="og:description" content='Первое в России шоу-пространство "Ленинград Центр"' />
                <meta name="keywords" content="шоу- пространство, шоу пространство, ленинград центр, центр ленинград, мюзикл, шоу, представление, афиша" c />
                {/* <meta property="og:image" content={data.headLayoutImageType ? `/api/shows/head-wide-img/${data.id})` : "/Show/091A8332.jpg"} /> */}
            </Helmet>
            <div className="about-haed-layout mb-3">
                <div className="about-head-layout-img" style={{ backgroundImage: `url("/AboutProjcet/about-project.jpg")` }}>
                </div>
                <div className="about-head-mask">
                    <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3"
                        style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                    </div>
                </div>
            </div>
            <Container fluid className="lc-gutter">
                <div className="seances personal loading">
                </div>
                {statusLoading === true && <div className="text-center"><h1>

                    {addJq()}
                    Идет загрузка...</h1></div>}

            </Container>
        </>
    )

}
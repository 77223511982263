import { useEffect } from "react"

import PhotoAlbum from 'react-photo-album';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { useState } from "react";


export const History = ({selLanguage}) => {
    const [index, setIndex] = useState(-1);
    const photos = [
        { src: "/AboutProjcet/photo_2024-07-17_14-20-42.jpg", width: 2278, height: 1709, alt: "Кинотеатр Лениград Центра" },
        { src: "/AboutProjcet/07160ef48c360e71b0bfe5cc4467f94f.jpg", width: 720, height: 540, alt: "Кинотеатр Лениград Центра" },
        { src: "/AboutProjcet/3a4748a3ce4d0f6b5f24226f6e010c5d.jpg", width: 720, height: 540, alt: "Кинотеатр Лениград Центра" },
    ]

    return (
        <>

            {selLanguage === "en" ?
                <>
                    <h4><strong>EXHIBITION HALL</strong></h4>
                    <p>
                        After the Great Exhibition of the Works of Industry of All Nations had first been held in London's Hyde Park, fashion for all sorts of exhibition events was established throughout Europe. The most successful organizer of this new secular entertainment in St. Petersburg was the Russian Imperial Society of Horticulture. It was for them that an exhibition hall was builtin 1914 in Potemkinskaya Street, near Tavrichesky Garden. The project of the hall was developed by civil engineer N.N. Ignatyev, and the construction was entrusted to engineer E.F. Rydzevsky. The building met the all requirements of world exhibition practice: there was a huge glass lantern above the main hall, special ramps for unloading heavy tubs with plants and there were rooms for lectures. The exhibition hall immediately became a new fashionable location, taking the place that in the minds of secular public was associated with art galleries and showrooms.
                    </p>
                    <p>
                        In May 1914, it served as a venue for the International Jubilee Gardening Exhibition under the auspices of Emperor Nicholas II.
                    </p>
                    <h4><strong>PANORAMIC CINEMA THEATER</strong></h4>
                    <p>
                        After the exhibition hall had been closed, the building was left unattended for almost half a century. During the revolution, the place hosted an armored squadron defending the Smolny Palace. Later it was used as a garage and storages. In 1959, the building was rebuilt into a cinema theater.
                    </p>
                    <p>
                        Leningrad panoramic cinema theater seating 1,128 people, designed by architect I.I. Chashnik was one of the first large scale projects in this segment. The hall was equipped with the largest screen in the country and a stereo sound system. The most sensational first nights took place here. For a long time Leningrad panoramic cinema theater was a trend-setting venue in the city’s cultural life. By the end of the twentieth century, the technical part of the movie hall had grown out of date, while the city cinema life moved over to shopping malls.
                    </p>
                </>
                :
                <>
                    <h4><strong>ВЫСТАВОЧНЫЙ ПАВИЛЬОН</strong></h4>
                    <p>
                        С тех пор как в лондонском Гайд-парке впервые прошла Всемирная выставка промышленных достижений, в Европе воцарилась мода на всевозможные экспомероприятия. В Петербурге самым успешным организатором новой светской забавы было Императорское российское общество садоводства. Именно для него в 1914 году рядом с Таврическим садом на Потемкинской улице был построен выставочный павильон. Проект павильона был выполнен гражданским инженером Н.Н. Игнатьевым, а строительство поручено инженеру Э.Ф. Рыдзевскому. Здание отвечало всем требованиям мировой выставочной практики: огромный стеклянный фонарь над главным залом, специальные пандусы для выгрузки тяжелых кадок с растениями, помещения для лекций. Выставочный павильон сразу стал новой модной локацией, заняв место, которое в сознании светской публики занимали картинные галереи и салоны.
                    </p>
                    <p>
                        В мае 1914 года здесь разместилась Международная юбилейная выставка садоводства под патронатом Николая II.
                    </p>
                    <h4><strong>ПАНОРАМНЫЙ КИНОТЕАТР</strong></h4>
                    <p>
                        После закрытия выставочного павильона здание находилось в забвении почти полвека. Во время революции здесь размещался бронебатальон, защищавший Смольный. Позже здесь располагались гараж и склады. С 1959 года здание было перестроено в кинотеатр.
                    </p>
                    <p>
                        Панорамный кинотеатр «Ленинград» на 1128 мест по проекту архитектора И.И. Чашника был одним из первых масштабных проектов в этом сегменте. Зал был оборудован самым большим в стране экраном и стереофоническим звуком. Здесь проходили самые громкие премьеры. «Ленинград» долгое время задавал тон культурной жизни города. К концу XX века техническая сторона кинозала устарела, а киножизнь города переместилась в торгово-развлекательные центры.
                    </p>
                </>}
            <div>
                <PhotoAlbum columns={3} layout="masonry" photos={photos} onClick={({ index }) => setIndex(index)} />
                <Lightbox
                    slides={photos}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    // enable optional lightbox plugins
                    plugins={[Slideshow, Thumbnails, Zoom, Captions]}
                />
            </div>
        </>
    )
}
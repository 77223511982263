import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const callApi = createApi({
    reducerPath: 'callApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'api',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().token.value;
            if (token) {
                headers.set("Authorization", `Bearer ${token.Token}`);
            }
            return headers;
        },
    }),
    keepUnusedDataFor: 0,
    // global configuration for the api
    refetchOnMountOrArgChange: 0,
    tagTypes: ['Users', 'Shows', 'News', 'Troupes', 'Exhibitions', 'DocumentationsList', 'Documents', 'SchemeHall', 'RedirectUrls'],
    endpoints: (builder) => ({
        refreshToken: builder.mutation({
            query: (refTokrn) => ({
                url: '/login/refreshtoken',
                method: `POST`,
                body: refTokrn
            })
        }),
        checkToken: builder.query({
            query: () => 'users/checktoken'
        }),
        getUsers: builder.query({
            query: ({ pageIndex, pageSize, stringFilter }) => `users/getusers?pageIndex=${pageIndex}&pageSize=${pageSize}${stringFilter && `&stringFilter=` + stringFilter}`,
            providesTags: ['Users']
        }),
        addUser: builder.mutation({
            query: (body) => ({
                url: 'users/adduser',
                method: 'POST',
                body: body,
            }),
            invalidatesTags: ['Users']
        }),
        getUserById: builder.query({
            query: (user_id) => `users/${user_id}`,
        }),
        putUserById: builder.mutation({
            query: ({ id, data }) => ({
                url: `users/${id}`,
                method: 'PUT',
                body: data,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }),
            invalidatesTags: ['Users']
        }),
        chengePasswordUser: builder.mutation({
            query: (data) => (
                {
                    url: `users/chengePassword`,
                    method: `PUT`,
                    body: data,
                }),
            invalidatesTags: ['Users']
        }),
        getShowsList: builder.query({
            query: ({ pageIndex, pageSize, stringFilter }) => `shows/list?pageIndex=${pageIndex}&pageSize=${pageSize}${stringFilter && `&stringFilter=` + stringFilter}`,
            providesTags: ['Shows']
        }),
        addShow: builder.mutation({
            query: (data) => ({
                url: `/shows`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Shows']
        }),
        getShows: builder.query(
            { query: ({ pageIndex, pageSize, selLng }) => ({ url: `/shows/?pageIndex=1&pageSize=10${selLng !== null && `&selLng=${selLng}`}` }) }
        ),
        getShowById: builder.query(
            { query: ({ seoUrl, selLng }) => `/shows/${seoUrl}${selLng !== null && `?selLng=${selLng}`}` }
        ),
        getShowDetailsById: builder.query(
            { query: (id) => `shows/details/${id}` }
        ),
        putShowById: builder.mutation({
            query: (data) => ({
                url: `/shows/${data.id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['Shows']
        }),
        addShowAfisha: builder.mutation(
            {
                query: ({ id, imageBlock, ...form }) => ({
                    url: `shows/add-afisha-img/${id}?imageBlock=${imageBlock}`,
                    method: `POST`,
                    body: form,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
            }
        ),
        deleteShowAfisha: builder.mutation(
            {
                query: (id) => ({
                    url: `shows/deletephoto/${id}`,
                    method: `PUT`
                })
            }
        ),
        getShowFromPrimera: builder.query({ query: () => ({ url: `shows/get-shows-list` }) }),
        getScheduleFromPrimera: builder.mutation({ query: (id) => ({ url: `shows/get-schedule-list/${id}` }) }),
        addSchedule: builder.mutation({
            query: (body) => ({
                url: `shows/add-schedule`,
                method: 'POST',
                body: body
            })
        }),
        addAllSchedule: builder.mutation({
            query: (body) => ({
                url: `shows/add-all-schedule`,
                method: 'POST',
                body: body
            })
        }),
        getScheduleShow: builder.query({ query: (id) => ({ url: `shows/get-schedule-show/${id}` }) }),
        setNewStatus: builder.mutation({ query: ({ id, newStatus }) => ({ url: `shows/session-swith-status/${id}?newStatus=${newStatus}` }) }),
        setUsageScheme: builder.mutation({ query: ({ id, usageScheme }) => ({ url: `shows/session-set-usage-scheme/${id}?usageScheme=${usageScheme}` }) }),
        deleteSessionShow: builder.mutation({
            query: (id) => ({
                url: `shows/delete-session/${id}`,
                method: "DELETE"
            })
        }),
        getAfisha: builder.query({
            query: ({ selLanguage }) => `/shows/get-afisha?selLng=${selLanguage}`
        }),
        getShowGallery: builder.query({
            query: (id) => `/shows/show-gallery/${id}`
        }),
        getShowGalleryDetails: builder.query({
            query: (seoUrl) => `/shows/show-gallery-detail/${seoUrl}`
        }),
        deletePhotoGallery: builder.mutation({
            query: (id) => ({ url: `/shows/delete-photo/${id}`, method: "delete" })
        }),
        // getNewsList: builder.query({
        //     query: () => ({ url: `/news` }),
        //     providesTags: ['News']
        // }),
        addNews: builder.mutation({
            query: (data) => ({
                url: `/news`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['News']
        }),
        getNewsGallery: builder.query({
            query: (id) => `/news/news-gallery/${id}`
        }),
        deleteNewsPhotoGallery: builder.mutation({
            query: (id) => ({ url: `/news/delete-photo/${id}`, method: "delete" })
        }),
        getNewsList: builder.query({
            query: ({ pageIndex, pageSize, stringFilter, selLng }) => `news/list?pageIndex=${pageIndex}&pageSize=${pageSize}${stringFilter && `&stringFilter=` + stringFilter}${selLng && `&selLng=${selLng}`}`,
        }),
        getNewsDetails: builder.query({
            query: (id) => `news/${id}`
        }),
        getNewsPost: builder.query({
            query: ({ seoUrl, selLng }) => `news/post/${seoUrl}${selLng && `?selLng=${selLng}`}`
        }),
        putNewsById: builder.mutation({
            query: ({ id, body }) => ({
                url: `news/${id}`,
                method: 'PUT',
                body: body
            }),
            invalidatesTags: ['News']
        }),
        getTroupeList: builder.query({
            query: ({ pageIndex, pageSize, stringFilter }) => (`troupes?pageIndex=${pageIndex}&pageSize=${pageSize}${stringFilter && `&stringFilter=` + stringFilter}`),
            providesTags: ['Troupes']
        }),
        addArtist: builder.mutation({
            query: (body) => ({
                url: "troupes/artist",
                method: `post`,
                body: body,
            }),
            invalidatesTags: ['Troupes']

        }),
        getArtistById: builder.query({
            query: (id) => `troupes/${id}`
        }),
        deleteArtist: builder.mutation({
            query: (id) => ({
                url: `troupes/${id}`,
                method: `Delete`,
            }),
            invalidatesTags: ['Troupes']
        }),
        putArtistById: builder.mutation({
            query: ({ id, data }) => ({
                url: `troupes/${id}`,
                method: `PUT`,
                body: data
            }),
            invalidatesTags: ['Troupes']
        }),
        getSessionDetails: builder.query(
            { query: ({ id, selLng }) => `/shows/get-session-details/${id}${selLng !== null ? `?selLng=${selLng}` : ''}` }
        ),
        getSessionDetailsAdmin: builder.query(
            { query: ({ id, selLng }) => `/shows/get-session-details-admin/${id}${selLng !== null ? `?selLng=${selLng}` : ''}` }
        ),
        getExhibitionsList: builder.query({
            query: ({ pageIndex, pageSize, stringFilter }) => (`/exhibitions?pageIndex=${pageIndex}&pageSize=${pageSize}${stringFilter && `&stringFilter=` + stringFilter}`),
            providesTags: ['Exhibitions']
        }),
        addExhibition: builder.mutation({
            query: (data) => ({
                url: `/exhibitions`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Exhibitions']

        }),
        getExhibitionById: builder.query({
            query: (id) => (`/exhibitions/${id}`)
        }),
        getExhibitionBySeoUrl: builder.query({
            query: ({ seoUrl, selLng }) => (`/exhibitions/details/${seoUrl}${selLng && `?&selLng=${selLng}`}`)
        }),
        putExhibition: builder.mutation({
            query: ({ id, data }) => ({
                url: `/exhibitions/${id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['Exhibitions']
        }),
        addExhibitionAfisha: builder.mutation(
            {
                query: ({ id, imageBlock, ...form }) => ({
                    url: `exhibitions/add-afisha-img/${id}?imageBlock=${imageBlock}`,
                    method: `POST`,
                    body: form,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
            }
        ),
        getExhibitionsAfisha: builder.query(
            {
                query: ({ pageIndex, pageSize, stringFilter }) => (`/exhibitions/afisha?pageIndex=${pageIndex}&pageSize=${pageSize}${stringFilter && `&stringFilter=` + stringFilter}`),
                providesTags: ['Exhibitions']
            }
        ),
        getExhibitionsArchive: builder.query(
            {
                query: ({ pageIndex, pageSize, stringFilter }) => (`/exhibitions/archive?pageIndex=${pageIndex}&pageSize=${pageSize}${stringFilter && `&stringFilter=` + stringFilter}`),
                providesTags: ['Exhibitions']
            }
        ),
        getUploadFiles: builder.query({ query: () => `/uploadfiles`, providesTags: ['UploadFilesList'] }),
        deleteUploadFile: builder.mutation({
            query: (id) => ({
                url: `/uploadfiles/${id}`,
                method: 'DELETE'
            }), invalidatesTags: ['UploadFilesList']
        }),
        getDocuments: builder.query({ query: () => '/documents', providesTags: ["Documents"] }),
        putDocuments: builder.mutation({
            query: (html) => ({
                url: '/documents',
                method: 'PUT',
                body: html
            }), invalidatesTags: ["Documents"]
        }),
        getSchemesHall: builder.query({
            query: ({ pageIndex, pageSize, stringFilter }) => (`/schemehall?pageIndex=${pageIndex}&pageSize=${pageSize}${stringFilter && `&stringFilter=` + stringFilter}`),
            providesTags: ['SchemeHall']
        }),
        addSchemeHall: builder.mutation({
            query: (data) => ({
                url: `/schemehall`,
                body: data,
                method: 'POST',
            }), invalidatesTags: ["SchemeHall"]
        }),
        getSchemeHall: builder.query({
            query: (id) => (`/schemehall/${id}`)
        }),
        putSchemeSetings: builder.mutation({
            query: (data) => ({
                url: `/schemehall/schemesetingsput/${data.id}`,
                method: 'PUT',
                body: data
            })
        }),
        postSchemeSector: builder.mutation({
            query: (data) => ({
                url: `/schemehall/addsector`,
                method: `POST`,
                body: data
            })
        }),
        postAddrow: builder.mutation({
            query: (data) => ({
                url: `/schemehall/addrow`,
                method: `POST`,
                body: data
            })
        }),
        postAddSeat: builder.mutation({
            query: (data) => ({
                url: `/schemehall/addseat`,
                method: 'POST',
                body: data,
            })
        }),
        putEditSeat: builder.mutation({
            query: (data) => ({
                url: `/schemehall/editseat`,
                method: 'PUT',
                body: data
            })
        }),
        postCopyRow: builder.mutation({
            query: (data) => ({
                url: `/schemehall/copyrow`,
                method: 'POST',
                body: data
            })
        }),
        putSeatsEdit: builder.mutation({
            query: (data) => ({
                url: `/schemehall/editseats`,
                method: 'PUT',
                body: data
            })
        }),
        postAddText: builder.mutation({
            query: (data) => ({
                url: "/schemehall/addtext",
                method: "POST",
                body: data
            })
        }),
        putEditText: builder.mutation({
            query: (data) => ({
                url: "/schemehall/edittext",
                method: "PUT",
                body: data
            })
        }),
        postAddShape: builder.mutation({
            query: (data) => ({
                url: `/schemehall/addshape`,
                method: `POST`,
                body: data
            })
        }),
        postAddLine: builder.mutation({
            query: (data) => ({
                url: `/schemehall/addline`,
                method: `POST`,
                body: data
            })
        }),
        postAddCircle: builder.mutation({
            query: (data) => ({
                url: `/schemehall/addcircle`,
                method: `POST`,
                body: data
            })
        }),
        postAddRect: builder.mutation({
            query: (data) => ({
                url: `/schemehall/addrect`,
                method: `POST`,
                body: data
            })
        }),
        deleteObject: builder.mutation({
            query: (id) => ({
                url: `schemehall/deleteobject/${id}`,
                method: 'DELETE'
            })
        }),
        deleteText: builder.mutation({
            query: (id) => ({
                url: `schemehall/deletetext/${id}`,
                method: 'DELETE'
            })
        }),
        putEditCtx: builder.mutation({
            query: (data) => ({
                url: `/schemehall/editctx`,
                method: `PUT`,
                body: data
            })
        }),
        putEditObject: builder.mutation({
            query: (data) => ({
                url: `/schemehall/editobject`,
                method: `PUT`,
                body: data
            })
        }),
        postAddCtx: builder.mutation({
            query: (data) => ({
                url: `/schemehall/addctx`,
                method: `POST`,
                body: data
            })
        }),
        deleteCtx: builder.mutation({
            query: (id) => ({
                url: `/schemehall/deletectx/${id}`,
                method: `DELETE`,
            })
        }),
        putEditRow: builder.mutation({
            query: (data) => ({
                url: `/schemehall/editrow`,
                method: `PUT`,
                body: data
            })
        }),
        deleteRow: builder.mutation({
            query: (id) => ({
                url: `/schemehall/deleterow/${id}`,
                method: `DELETE`,
            })
        }),
        deleteSeat: builder.mutation({
            query: (id) => ({
                url: `/schemehall/deleteseat/${id}`,
                method: `DELETE`,
            })
        }),
        postCopyObject: builder.mutation({
            query: (data) => ({
                url: `/schemehall/copyobject`,
                method: 'POST',
                body: data
            })
        }),
        getPayScheme: builder.query({
            query: ({ id, selLng }) => ({
                url: `/shows/pay-scheme-hall/${id}?${selLng ? `selLng=${selLng}`: ``}`,
                method: 'GET',
            })
        }),
        putEditCategorySeat: builder.mutation({
            query: (data) => ({
                url: `/schemehall/edit-category-seat`,
                method: 'PUT',
                body: data
            })
        }),
        deleteSessionSeat: builder.mutation({
            query: (id) => ({
                url: `/shows/delete-session-seat/${id}`,
                method: 'DELETE'
            })
        }),
        addSchemeToSessions: builder.mutation({
            query: (data) => ({
                url: `/shows/add-scheme-sessions`,
                method: 'POST',
                body: data
            })
        }),
        addSchemeToSession: builder.mutation({
            query: (data) => ({
                url: `/shows/add-scheme-session`,
                method: 'POST',
                body: data
            })
        }),
        setGroupTag: builder.mutation({
            query: (data) => ({
                url: "/shows/set-group-tag",
                method: "POST",
                body: data
            })
        }),
        getTypeSeat: builder.mutation({
            query: (data) => ({
                url: "/shows/get-type-seat",
                method: "POST",
                body: data
            })
        }),
        getCopyScheme: builder.mutation({
            query: (data) => ({
                url: `/schemehall/copy-scheme/${data.id}?name=${data.name}`,

            }), invalidatesTags: ["SchemeHall"]
        }),
        getVersion: builder.query({
            query: () => `/users/current-version`
        }),
        getRedirectUrls: builder.query({
            query: ({ pageIndex, pageSize }) => `/redirecturls/?${pageIndex && `pageIndex=${pageIndex}&`}${pageSize && `pageSize=${pageSize}`}`,
            providesTags: ['RedirectUrls']
        }),
        putRedirectUrl: builder.mutation({
            query: (data) => ({
                url: "/redirecturls",
                body: data,
                method: "PUT"
            }), invalidatesTags: ['RedirectUrls']
        }),
        postRedirectUrl: builder.mutation({
            query: (data) => ({
                url: "/redirecturls",
                body: data,
                method: 'POST'
            }), invalidatesTags: ['RedirectUrls']
        }),
        deleteRedirectUrl: builder.mutation({
            query: (id) => ({
                url: `/redirecturls/${id}`,
                method: 'DELETE'
            }), invalidatesTags: ['RedirectUrls']
        })
    }),
}

)

export const {
    useRefreshTokenMutation,
    useGetUsersQuery,
    useCheckTokenQuery,
    useGetUserByIdQuery,
    usePutUserByIdMutation,
    useChengePasswordUserMutation,
    useAddUserMutation,
    useGetShowsListQuery,
    useAddShowMutation,
    useGetShowByIdQuery,
    usePutShowByIdMutation,
    useGetShowDetailsByIdQuery,
    useAddShowAfishaMutation,
    useDeleteShowAfishaMutation,
    useGetShowFromPrimeraQuery,
    useGetScheduleFromPrimeraMutation,
    useAddScheduleMutation,
    useGetScheduleShowQuery,
    useAddAllScheduleMutation,
    useSetNewStatusMutation,
    useDeleteSessionShowMutation,
    useGetAfishaQuery,
    useGetShowGalleryQuery,
    useGetShowGalleryDetailsQuery,
    useDeletePhotoGalleryMutation,
    useGetNewsListQuery,
    useAddNewsMutation,
    useGetNewsGalleryQuery,
    useGetNewsDetailsQuery,
    usePutNewsByIdMutation,
    useGetTroupeListQuery,
    useAddArtistMutation,
    useDeleteArtistMutation,
    useGetArtistByIdQuery,
    usePutArtistByIdMutation,
    useGetShowsQuery,
    useGetSessionDetailsQuery,
    useGetExhibitionsListQuery,
    useAddExhibitionMutation,
    useGetExhibitionByIdQuery,
    useGetExhibitionBySeoUrlQuery,
    usePutExhibitionMutation,
    useAddExhibitionAfishaMutation,
    useGetExhibitionsAfishaQuery,
    useGetExhibitionsArchiveQuery,
    useDeleteUploadFileMutation,
    useGetUploadFilesQuery,
    useGetDocumentsQuery,
    usePutDocumentsMutation,
    useDeleteNewsPhotoGalleryMutation,
    useGetSchemesHallQuery,
    useAddSchemeHallMutation,
    useGetSchemeHallQuery,
    usePutSchemeSetingsMutation,
    usePostSchemeSectorMutation,
    usePostAddrowMutation,
    usePostAddSeatMutation,
    usePutEditSeatMutation,
    usePostCopyRowMutation,
    usePutSeatsEditMutation,
    usePostAddTextMutation,
    usePutEditTextMutation,
    usePostAddShapeMutation,
    usePostAddLineMutation,
    usePostAddCircleMutation,
    usePostAddRectMutation,
    useDeleteObjectMutation,
    useDeleteTextMutation,
    usePutEditCtxMutation,
    usePutEditObjectMutation,
    usePostAddCtxMutation,
    useDeleteCtxMutation,
    usePutEditRowMutation,
    useDeleteRowMutation,
    useDeleteSeatMutation,
    usePostCopyObjectMutation,
    useGetPaySchemeQuery,
    usePutEditCategorySeatMutation,
    useDeleteSessionSeatMutation,
    useAddSchemeToSessionsMutation,
    useAddSchemeToSessionMutation,
    useSetGroupTagMutation,
    useGetTypeSeatMutation,
    useGetCopySchemeMutation,
    useGetVersionQuery,
    useGetNewsPostQuery,
    useGetRedirectUrlsQuery,
    usePostRedirectUrlMutation,
    usePutRedirectUrlMutation,
    useDeleteRedirectUrlMutation,
    useGetSessionDetailsAdminQuery,
    useSetUsageSchemeMutation,
} = callApi
import { Button, ButtonGroup, FloatingLabel, Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useAddExhibitionMutation } from "../../../features/CallApi";
import { LoadScreenMin } from "../../UI/LoadScreenMin";


export const ExhibitionAdd = ({ exhibitionAddForm, setExhibitionAddForm, setExhibitionEditForm, setExhibitionId }) => {

    const { register, handleSubmit, reset, formState: { errors, isValid }  } = useForm();
    const [addExhibition, { isLoading }] = useAddExhibitionMutation()

    const onSubmit = (data) => {
        console.log(data)
        // const fb = new FormData
        addExhibition(data).unwrap().then((r)=>{
            console.log(r)
            reset()
            setExhibitionId(r.id)
            setExhibitionEditForm(true)
            setExhibitionAddForm(false)
        })
    }

    return (
        <Modal
            show={exhibitionAddForm}
            onHide={() => {
                reset()
                setExhibitionAddForm(false)
            }}
            backdrop="static"
            keyboard={false}
        >

            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>Добавление шоу</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isLoading ?
                        <>
                            <FloatingLabel
                                controlId="floatingTitile"
                                label="Название выставки"
                                className="mb-3"
                            >
                                <Form.Control autoComplete="off" type="text" isInvalid={errors.name}
                                    isValid={isValid.name}
                                    placeholder="Название выставки" {...register("name", { required: { value: true } })} />
                            </FloatingLabel>
                        </>
                        :
                        <LoadScreenMin height="30" />
                    }

                </Modal.Body>

                <Modal.Footer>
                    <ButtonGroup size="sm">
                        <Button variant="primary" onClick={() => {
                            reset()
                            setExhibitionAddForm(!exhibitionAddForm)
                        }
                        }>Отменить</Button>
                        <Button type="submit" variant="success">
                            Добавить
                        </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </form>

        </Modal>
    )
}
import { Button, Col, Container, Image, Row } from "react-bootstrap"
import { NewsPost } from "./NewsPost"
import "./News.css"
import { Suspense, useEffect, useState } from "react"
import { useGetNewsListQuery } from "../../features/CallApi"
import { useTranslation } from "react-i18next"
import { LoadScreenMin } from "../UI/LoadScreenMin"
import { Helmet } from "react-helmet"


export const News = ({ selLanguage }) => {
    const [t] = useTranslation("global");
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [stringFilter, setStringFilter] = useState("")
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState([]);
    const { data: dataResponse, isLoading } = useGetNewsListQuery({ pageIndex, pageSize, stringFilter, selLng: selLanguage })

    const test = async () => {
        await new Promise(resolve => {
            setTimeout(resolve, 3000);
        });
    };

    useEffect(() => {
        setFetching(isLoading)
    }, [isLoading])
    useEffect(() => { setData([]) }, [selLanguage])

    useEffect(() => {
        if (data.length === 0 && dataResponse) {
            setData([...dataResponse.getNews])
        }
        else if (data.length > 0) {
            setData([...data, ...dataResponse.getNews])
        }
        setFetching(false)
        // setFiltering(false)
    }, [dataResponse])

    useEffect(() => {
        if (dataResponse && !fetching) {
            const scrollHandeler = (e) => {
                if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 5 && pageIndex < dataResponse.totalPages) {
                    // console.log("loading...")
                    setFetching(true)
                    setPageIndex(pageIndex => pageIndex + 1)
                }
            }
            document.addEventListener('scroll', scrollHandeler)
            return function () {
                document.removeEventListener('scroll', scrollHandeler)
            }

        }
    }, [dataResponse, fetching])


    return (
        <div>
            <Helmet>
                <title>{t("general.news")} - {t("mainTag.title")}</title>
                <meta name="description" content='Первое в России шоу-пространство "Ленинград Центр"'></meta>
                <meta name="og:title" content={`${t("general.news")} - ${t("mainTag.title")}`} />
                <meta name="og:description" content='Первое в России шоу-пространство "Ленинград Центр"' />
                <meta name="keywords" content="шоу- пространство, шоу пространство, ленинград центр, центр ленинград, мюзикл, шоу, представление, афиша" c />
                {/* <meta property="og:image" content={data.headLayoutImageType ? `/api/shows/head-wide-img/${data.id})` : "/Show/091A8332.jpg"} /> */}
            </Helmet>
            <div className="news-haed-layout">
                <div className="news-head-layout-img" style={{ backgroundImage: 'url("/News/news.jpg")' }}>
                </div>
                <div className="news-head-mask">
                    <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3" style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                        <div className="text-white ms-auto justify-content-end">
                            <div className="h1 text-center">{t("menu.news")}</div>
                        </div>
                    </div>
                </div>
            </div>
            <Container>

                {data.length > 0 ?
                    <div className="mt-1 mb-1 pt-3 pb-3 border-bottom ">
                        {data.map(item =>
                            <Row key={item.id} className="justify-content-center">
                                <Col lg={6} >
                                    <Suspense fallback={<LoadScreenMin />}>
                                        <NewsPost item={item} />
                                    </Suspense>
                                </Col>
                            </Row>
                        )}
                    </div>
                    :
                    <>
                        {!fetching &&
                            <h4 className="text-center">По вашему запросу ничего не найдено</h4>
                        }
                    </>
                }
                {fetching && <LoadScreenMin height={20} />}
                {pageIndex < dataResponse?.totalPages && <div className={'w-100 text-center'}><Button variant="light" onClick={() => { setPageIndex(pageIndex => pageIndex + 1) }}>Показать другие новости</Button></div>}
            </Container>
        </div>
    )
}
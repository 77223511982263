import { SubSection } from './SubSection';
import { useRef } from 'react';
import { Group, Rect } from 'react-konva';
import { StaticObjects } from './StaticObjects';

export const Section = ({
    width,
    section,
    height,
    texts,
    x,
    y,
    onHoverSeat,
    onSelectSeat,
    onDeselectSeat,
    selectedSeatsIds,
    objects,
    handleCursor
}) => {
    const containerRef = useRef();
    return (
        <Group y={y} x={x} ref={containerRef}

            onMouseMove={(e) => {
                handleCursor(e.currentTarget.getRelativePointerPosition())
            }}
        >
            <Rect
                width={width}
                height={height}
                // fill="white"
                // strokeWidth={1}
                // stroke="lightgrey"
                cornerRadius={5}
            />
            <StaticObjects texts={texts} objects={objects} />

            {section.map((seatsByRows) => {
                return (
                    <SubSection
                        key={seatsByRows.id + "t"}
                        data={seatsByRows.seats}
                        nameRow={seatsByRows.nameRow}
                        onHoverSeat={onHoverSeat}
                        onSelectSeat={onSelectSeat}
                        onDeselectSeat={onDeselectSeat}
                        selectedSeatsIds={selectedSeatsIds}
                        handleCursor={handleCursor}

                    />
                );
            })}
        </Group>
    );
}


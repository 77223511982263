import axios from "axios"
import { useRef } from "react"
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap"
import { useDeleteNewsPhotoGalleryMutation, useGetNewsGalleryQuery } from "../../../features/CallApi"
import { LoadScreenMin } from "../../UI/LoadScreenMin"
import { X } from "react-bootstrap-icons"



export const NewsGallery = ({newsGallery, setNewsGallery, newsId, setNewsId, newsTitle, setNewsTitle}) => {

    const fileInput = useRef()
    const { data, isLoading, refetch } = useGetNewsGalleryQuery(newsId)
    
    const [deleteNewsPhotoGallery] = useDeleteNewsPhotoGalleryMutation()
    const postingImg = async () => {
        const formData = new FormData()

        const files = fileInput.current.files
        for (let i = 0; i < files.length; i++) {
            formData.append(`files`, files[i])
        }
        const token = JSON.parse(sessionStorage.getItem("token"));
        console.log(formData)
        const response = await axios.post(`/api/news/add-photo/${newsId}`, formData, {
            headers: {
                method: 'POST',
                Authorization: `Bearer ${token.Token}`
            }
        })
        refetch()
        fileInput.current.value = null;
    }



    return (
        <Modal
            show={newsGallery}
            size={"xl"}
            onHide={() => {
                setNewsId(null)
                setNewsGallery(false)
                setNewsTitle()
            }}
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <h3>{newsTitle}</h3>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label></Form.Label>
                    <Form.Control className="mb-3" ref={fileInput} accept=".jpg, .jpeg, .png" multiple type="file" />
                    <Button type="submit" onClick={() => { postingImg() }}>Загрузить</Button>
                </Form.Group>
                {!isLoading ? <>
                    {data && <>
                        {
                            <Row>
                                {data.map(item =>
                                    <Col md={4} className="" key={item.id}>
                                        <Row className="border m-3">
                                            <Col style={{ inset: 0, margin: 'auto' }}>
                                                <Image style={{ maxHeight: "30vh" }} src={`/api/news/gallery-photo/${item.id}`} className="m-1" fluid />
                                            </Col>
                                            <Col style={{ inset: 0, margin: 'auto' }} xs={'auto'} className="text-end">
                                                <Button onClick={() => { deleteNewsPhotoGallery(item.id).unwrap().then(() => refetch()) }} size="sm" variant="danger">
                                                    <X size={25} />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                            </Row>
                        }
                    </>
                    }
                </> : <LoadScreenMin height={10} />}
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    )
}
import { useState, useCallback, useEffect, useRef } from "react";
import { Layer, Stage } from "react-konva";
import SeatPopup from "./SeatPopup";
import { Section } from "./Section";
import { useImportScheme } from "./useImportScheme";
import { LoadScreenMin } from "../UI/LoadScreenMin";
import { Button, ButtonGroup } from "react-bootstrap";

export const MainStage = ({ data, setCursorPos }) => {
    const { setUrl, schemeData, setSchemeData } = useImportScheme();
    const stageRef = useRef(null)
    const containerRef = useRef()
    const [scale, setScale] = useState(1);
    const [scaleToFit, setScaleToFit] = useState(4);
    const [popup, setPopup] = useState({ seat: null, row: null });
    const [selectedSeatsIds, setSelectedSeatsIds] = useState([]);
    const [size, setSize] = useState({
        width: 1000,
        height: 2000,
        virtualWidth: 800
    });
    const [heightBox, setHeightBox] = useState(500)
    const [virtualWidth, setVirtualWidth] = useState(1000);
    const [virtualHeight, setVirtualHeight] = useState(1000);
    useEffect(() => {
        const newSize = {
            width: containerRef.current.offsetWidth,
            height: containerRef.current.offsetHeight
        };
        if (newSize.width !== size.width || newSize.height !== size.height) {
            setSize(newSize);
        }
    });
    // const toggleScale = useCallback(() => {
    //     if (scale === 1) {
    //         setScale(scaleToFit);
    //     } else {
    //         setScale(1);
    //     }
    // }, [scale, scaleToFit]);

    useEffect(() => {
        if (data) {
            setSchemeData(data)
        }
        else {
            setUrl("/seats-data-2.json")
        }
    }, [data])

    useEffect(() => {
        if (!stageRef.current) {
            return;
        }
        const stage = stageRef.current;
        const clientRect = stage.getClientRect({ skipTransform: true });
        const clientRectTranform = stage.getClientRect();
        const scaleToFit = size.width / clientRect.width / 1.250;
        setHeightBox(clientRectTranform.height * 1.25)
        setScale(scaleToFit);
        setScaleToFit(scaleToFit);
        setVirtualWidth(clientRect.width);
        setVirtualHeight(clientRect.height);
        const newSize = {
            width: containerRef.current.offsetWidth,
            height: containerRef.current.offsetHeight
        };
        if (newSize.width !== size.width || newSize.height !== size.height) {
            setSize(newSize);
        }
    }, [schemeData, size]);

    useEffect(() => {
        const resizeHandeler = (e) => {
            const stage = stageRef.current;
            const clientRect = stage.getClientRect({ skipTransform: true });
            const clientRectTranform = stage.getClientRect();
            const scaleToFit = size.width / 1.250;
            setHeightBox(clientRectTranform.height * 1.25)
            setScale(scaleToFit);
            setScaleToFit(scaleToFit);
            setVirtualWidth(clientRect.width);
            setVirtualHeight(clientRect.height);
        }

        window.addEventListener('resize', resizeHandeler)
        return function () {
            window.addEventListener('resize', resizeHandeler)
        }
    }, [])


    const [lastScale, setLastScale] = useState(1);
    const [isScaling, setIsScaling] = useState(false);

    const getCenter = (p1, p2) => {
        return {
            x: (p1.x + p2.x) / 2,
            y: (p1.y + p2.y) / 2,
        };
    }

    const handleTouchStart = (event) => {
        const touches = event.evt.touches;
        if (touches.length === 2) {
            setIsScaling(true);
            setLastScale(scale);
        }
    };

    // Handle touch move
    const handleTouchMove = (event) => {
        if (isScaling) {
            const touches = event.evt.touches;
            if (touches.length === 2) {
                var lastCenter = null
                const dx = touches[0].clientX - touches[1].clientX;
                const dy = touches[0].clientY - touches[1].clientY;
                // console.log(dx)
                const distance = Math.sqrt(dx * dx + dy * dy);
                // Math.max(pos.x, -virtualWidth * scale + size.width / 1.25)
                // Calculate a new scale based on distance
                const newScale = lastScale * distance / 100; // Adjust 100 for sensitivity
                setScale(newScale);
                console.log(newScale)


                if (!lastCenter) {
                    lastCenter = getCenter(touches[0].clientX, touches[0].clientY);
                    return;
                }
                // local coordinates of center point


                const attrs = stageRef.current.attrs

                console.log(attrs)
                var newCenter = getCenter(touches[0].clientX, touches[0].clientY);
                var pointTo = {
                    x: (newCenter.x - attrs.x) / attrs.scaleX,
                    y: (newCenter.y - attrs.y) / attrs.scaleX,
                };
                var newPos = {
                    x: newCenter.x - pointTo.x * scale + dx,
                    y: newCenter.y - pointTo.y * scale + dy,
                };

                console.log(newPos)

                setSize(newPos)
            }
        }
    };

    // Handle touch end
    const handleTouchEnd = () => {
        setIsScaling(false);
    };

    // useEffect(() => {
    //     console.log(containerRef
    //     )
    // }, [containerRef])

    const handleHover = useCallback((seat, pos, row) => {
        setPopup({
            seat: seat,
            row: row,
            position: pos
        });
    }, []);

    const handleCursor = useCallback((pos) => {
        // setCursorPos(pos)
        setCursorPos({ x: pos.x, y: pos.y })
    })

    const handleSelect = useCallback(seatId => {
        // console.log(seatId)
        const newIds = selectedSeatsIds.concat([seatId]);
        setSelectedSeatsIds(newIds);
    },
        [selectedSeatsIds]
    );
    const handleDeselect = useCallback(
        seatId => {
            const ids = selectedSeatsIds.slice();
            ids.splice(ids.indexOf(seatId), 1);
            setSelectedSeatsIds(ids);
        },
        [selectedSeatsIds]
    );



    if (schemeData === null) {
        return <LoadScreenMin refcontanet={containerRef} height={20} />;
    }



    return (
        <div style={{
            position: "relative",
            backgroundColor: "#f3f3f3",
            width: "100%",
            height: heightBox,
        }}
            ref={containerRef}
            id={"schemeHellcontainer"}
        >
            <div className="ps-3 pt-3 position-absolute" style={{ left: 0, right: 0, zIndex: 100, width: 'fit-content' }}>
                <ButtonGroup>
                    <Button onClick={() => setScale(scale + 0.10 * 1.250)}>+</Button>
                    <Button onClick={() => {
                        if (scale - 0.1 > 0) {
                            console.log(scale);
                            setScale(scale - 0.10)
                        }
                    }}>-</Button>
                </ButtonGroup>
            </div>
            <Stage
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                ref={stageRef}
                width={size.width}
                height={size.height}
                draggable={!isScaling}
                x={size.width / 10}
                y={size.height / 10}
                // dragBoundFunc={pos => {
                //     // console.log(pos)
                //     pos.x = Math.min(
                //         size.width / 2,
                //         Math.max(pos.x, -virtualWidth * scale + size.width / 1.25)
                //     );
                //     pos.y = Math.min(
                //         size.height / 1.25,
                //         Math.max(pos.y, -virtualHeight * scale + size.height / 1.25)
                //     );
                //     return pos;
                // }}
                // onDblTap={toggleScale}
                // onDblClick={toggleScale}
                scaleX={scale}
                scaleY={scale}
            >
                <Layer>

                    {schemeData.sections.map(section => {
                        const offset = 30;
                        return (
                            <Section
                                handleCursor={handleCursor}
                                x={offset}
                                y={schemeData.offsetTop}
                                width={schemeData.width}
                                height={schemeData.height}
                                texts={schemeData.texts}
                                objects={schemeData.staticObjects}
                                key={section.id}
                                section={section.seatsByRows}
                                staticObjects={section.staticObjects}
                                selectedSeatsIds={selectedSeatsIds}
                                onHoverSeat={handleHover}
                                onSelectSeat={handleSelect}
                                onDeselectSeat={handleDeselect}
                            />
                        );
                    })}
                </Layer>
            </Stage>
            {popup.seat && (
                <SeatPopup
                    position={popup.position}
                    seatId={popup.seat}
                    nameRow={popup.row}
                    onClose={() => {
                        setPopup({ seat: null });
                    }}
                />
            )}
        </div>
    )
}


import { useState } from "react"
import { Col, Image, Modal, Row } from "react-bootstrap"
import { useGetTroupeListQuery } from "../../features/CallApi"
import { LoadScreenMin } from "../UI/LoadScreenMin"


export const Troupe = ({selLanguage}) => {
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(200)
    const [stringFilter, setStringFilter] = useState("")
    const { data: artists, isLoading, refetch } = useGetTroupeListQuery({ pageIndex, pageSize, stringFilter, selLng: selLanguage})


    // const artists = [
    //     {
    //         id: 1,
    //         name: "Вера Арбузова",
    //         description: "Актриса, хореограф-постановщик",
    //         imgUrl: "/ArtistSlider/arbuzova-vera.png"
    //     },
    //     {
    //         id: 2,
    //         name: "Нона Булыгина",
    //         description: "Актриса, хореограф-постановщик",
    //         imgUrl: "/ArtistSlider/bulygina-nona.jpg"
    //     },
    //     {
    //         id: 3,
    //         name: "Ольга Ворожейкина",
    //         description: "Актриса, хореограф-постановщик",
    //         imgUrl: "/ArtistSlider/vorozeykena-olga.jpg"
    //     },
    // ]
    return (
        <>
            <Modal>

            </Modal>
            <p>
                <strong>Труппа «Ленинград Центра»</strong> - это драгоценная коллекция специалистов в разных жанрах и областях искусств. Ежегодно к команде присоединяются новые участники. Финалисты вокальных, хореографических конкурсов и телевизионных шоу, чемпионы страны и мира в цирковых, акробатических дисциплинах. Каждый трюк, каждая песня - все исполняется артистами без использования страховок, фонограмм или сторонней помощи. Для каждого артиста ставится амбициозная задача роста. Так появляются поющие танцоры, музыканты, исполняющие акробатические трюки и вокалисты, вставшие на пуанты. Талантливый состав труппы «Ленинград Центра» развивается и становится ярче от сезона к сезону.
            </p>
            <Row className="justify-content-center g-3 border-top border-bottom shadow-sm mt-5 mb-5 pb-3">
                <Col md={"auto"} className="text-center" style={{ cursor: "pointer" }}>
                    <Image style={{ borderRadius: "50%", maxHeight: "125px", maxWidth: "125px" }} alt="Феликс Михайлов" fluid src="https://leningradcenter.ru/api/redirecturls/flex-photo" />
                    <div>
                        <h3>Феликс Михайлов</h3>
                    </div>
                    <p className="text-muted">
                        Художественный руководитель <br /> «Ленинград Центра»
                    </p>
                </Col>
                <Col md={"6"} className="">
                    <strong>
                        <div className="d-none d-md-block" style={{ height: "63px" }}></div>
                        Российский режиссёр театра, кино и телевидения, сценарист и продюсер, лауреат Национальной премии в области телевидения «ТЭФИ». Режиссёр-постановщик всех шоу «Ленинград Центра».
                    </strong>
                </Col>
            </Row>
            <Row className="justify-content-evenly">
                {!isLoading ? <>
                {artists.troupe.length > 0 &&
                    artists.troupe.map(artist =>
                        <Col key={artist.id} xs={6} md={"auto"} className="text-center artist-box">
                            <Image className="mb-2" alt={artist.name} style={{ borderRadius: "50%", maxHeight: "125px", maxWidth: "125px" }} fluid src={`/api/troupes/artist-photo/${artist.id}`} />
                            <div className="artist-name">
                                <h4>{artist.name}</h4>
                            </div>
                            <p className="text-muted">
                                {artist.description}
                            </p>
                        </Col>
                    )
                }
                </> : 
                <LoadScreenMin />}
            </Row>
        </>
    )
}
import { useEffect, useState } from "react";
import ReactSelect from "react-select"
import { useAddSchemeToSessionsMutation, useGetSchemesHallQuery, useGetTypeSeatMutation } from "../../../features/CallApi";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";

const GetData = (data) => {
    return {
        value: data.id,
        label: `# ${data.id} ${data.name}`
    }
}

export const SchemeSelect = ({ showId, refetch, selSchedule }) => {
    // const [isClearable, setIsClearable] = useState(true);
    // const [isSearchable, setIsSearchable] = useState(true);
    // const [isDisabled, setIsDisabled] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    // const [isRtl, setIsRtl] = useState(false);
    const [selScheme, setSchemeId] = useState()
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [stringFilter, setStringFilter] = useState("")
    const [selectData, setSelectData] = useState()
    const [addSchemeToSessions, { isLoading }] = useAddSchemeToSessionsMutation()
    const [getTypeSeat, { isLoading: isGetTypeSeat}] = useGetTypeSeatMutation()

    const { data } = useGetSchemesHallQuery({ pageIndex, pageSize, stringFilter })
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();


    useEffect(() => {
        if (data) {
            const dataMap = data.getSchemeHalls.map(item => GetData(item))
            setSelectData(dataMap)
        }
        setValue("showId", showId)
    }, [data])

    const submit = (data) => {
        console.log(data)
        if (data.schemeId !== null) {

            addSchemeToSessions(data).then(() => { refetch() })
            return;
        }
        else {
            return window.alert("Выберите схему")
        }
    }

    const submitGetTypeSeat = () => {
        getTypeSeat({
            schemeId: selScheme,
            showId: selSchedule
        }).then(()=>{
            // reset
            refetch()
        })
        console.log()
    }


    return (
        <>
            <Form onSubmit={handleSubmit(submit)}>
                <ReactSelect
                    name="schemeselect"
                    className="basic-single mb-1"
                    placeholder={"Схема зала..."}
                    classNamePrefix="Схема зала..."
                    // isLoading={isLoading}
                    // isClearable={isClearable}
                    // isRtl={isRtl}
                    // isSearchable={isSearchable}
                    options={selectData}
                    onInputChange={(e) => { setStringFilter(e) }}
                    onChange={(target) => {
                        // console.log(target)
                        if (target) {
                            setSchemeId(target.value)
                            setValue("schemeId", target.value)
                        }
                    }}
                    required={true}
                />
                <Button className="mb-1" size="sm" variant={'success'} type={"submit"}>Связать схему</Button>
            </Form>
            <Button size="sm" className="mt-2" variant={'success'} disabled={!(selScheme && selSchedule)} onClick={
                () => {
                    if (window.confirm("Все места сессии будут вновь добавлены на схему, продолжить?"))
                        submitGetTypeSeat()
                }
            }>
                Связать места на схеме
            </Button>
        </>
    )
}
import { Button, ButtonGroup, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap"
import { useAddExhibitionAfishaMutation, useGetExhibitionByIdQuery, usePutExhibitionMutation } from "../../../features/CallApi"
import { useEffect } from "react"
import { LoadScreenMin } from "../../UI/LoadScreenMin"
import { useForm } from "react-hook-form"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useState } from "react"
import { format } from "date-fns"
import { ReactQuillLC } from "../../UI/ReactQuillLC"


export const ExhibitionEdit = ({ exhibitionEditForm, setExhibitionEditForm, exhibitionId, setExhibitionId }) => {

    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue, setError, clearErrors } = useForm();
    const { data, isLoading: isLoadingData, refetch } = useGetExhibitionByIdQuery(exhibitionId)
    const [valueEditor, setValueEditor] = useState()
    const [valueEditorEn, setValueEditorEn] = useState()
    const [imgSizeError, setImgSizeError] = useState();
    const [changingPhoto, setChangingPhoto] = useState();
    const [changingHeadPhoto, setChangingHeadPhoto] = useState();
    const [changingHeadWidePhoto, setChangingHeadWidePhoto] = useState();

    const [addExhibitionAfisha, { isLoading: postingImg }] = useAddExhibitionAfishaMutation()

    const [hidden, setHidden] = useState(true)
    const [isLoading, setIsLoading] = useState()
    const [putExhibition, { isLoading: isPuting }] = usePutExhibitionMutation()

    useEffect(() => {
        setIsLoading((isLoadingData || isPuting))
    }, [isLoadingData, isPuting])

    const onSubmit = (data) => {
        if (data.endOfEvent < data.startOfEvent) {
            setError("startOfEvent")
            return window.alert("Дата начала не может быть больше завершения")
        }
        else {
            clearErrors("startOfEvent")
        }
        data.hidden = hidden;
        data.description = valueEditor;
        data.descriptionEN = valueEditorEn;
        putExhibition({ id: exhibitionId, data: data }).unwrap().then(() => {
            refetch()
        })
    }

    const onImageChange = (event) => {
        setChangingPhoto(true)
        if (event.target.files[0].size < 2097153) {
            setImgSizeError(null)
            if (event.target.files && event.target.files[0]) {
                const reader = new FileReader(event.target.files[0]);
                reader.onloadend = () => {
                    const body = {
                        id: exhibitionId,
                        image: reader.result,
                        imageType: event.target.files[0].type
                    }
                    addExhibitionAfisha({ id: exhibitionId, imageBlock: "afisha", ...body })
                }
                reader.readAsDataURL(event.target.files[0])
            }
            setTimeout(() => {
                refetch()
                setChangingPhoto(false)
            }, 2000)
        } else {
            setImgSizeError("Максимальный размер фото 2 Мб")
            alert("Максимальный размер фото 2 Мб")
            setChangingPhoto(false)
        }
    }

    const onHeadImageChange = (event) => {
        setChangingHeadPhoto(true)
        if (event.target.files[0].size < 2097153) {
            setImgSizeError(null)
            if (event.target.files && event.target.files[0]) {
                const reader = new FileReader(event.target.files[0]);
                reader.onloadend = () => {
                    const body = {
                        id: exhibitionId,
                        image: reader.result,
                        imageType: event.target.files[0].type
                    }
                    addExhibitionAfisha({ id: exhibitionId, imageBlock: "head", ...body })
                }
                reader.readAsDataURL(event.target.files[0])
            }
            setTimeout(() => {
                refetch()
                setChangingHeadPhoto(false)
            }, 2000)
        } else {
            setImgSizeError("Максимальный размер фото 2 Мб")
            alert("Максимальный размер фото 2 Мб")
            setChangingHeadPhoto(false)
        }
    }
    const onHeadImageWideChange = (event) => {
        setChangingHeadWidePhoto(true)
        if (event.target.files[0].size < 2097153) {
            setImgSizeError(null)
            if (event.target.files && event.target.files[0]) {
                const reader = new FileReader(event.target.files[0]);
                reader.onloadend = () => {
                    const body = {
                        id: exhibitionId,
                        image: reader.result,
                        imageType: event.target.files[0].type
                    }
                    addExhibitionAfisha({ id: exhibitionId, imageBlock: "headWide", ...body })
                }
                reader.readAsDataURL(event.target.files[0])
            }
            setTimeout(() => {
                refetch()
                setChangingHeadWidePhoto(false)
            }, 2000)
        } else {
            setImgSizeError("Максимальный размер фото 2 Мб")
            alert("Максимальный размер фото 2 Мб")
            setChangingHeadWidePhoto(false)
        }
    }

    useEffect(() => {
        if (data) {
            setValue("id", data.id)
            setValue("name", data.name)
            setValue("nameEN", data.nameEN)
            setValue("ageCategory", parseInt(data.ageCategory))
            setValue("shortDescription", data.shortDescription)
            setValue("shortDescriptionEN", data.shortDescriptionEN)
            setValue("startOfEvent", format(new Date(data.startOfEvent), "yyyy-MM-dd"))
            setValue("endOfEvent", format(new Date(data.endOfEvent), "yyyy-MM-dd"))
            setHidden(data.hidden)
            setValueEditor(data.description)
            setValueEditorEn(data.descriptionEN)
        }
    }, [data])

    return (<>
        <Modal
            size="xl"
            show={exhibitionEditForm}
            onHide={() => {
                setExhibitionEditForm(false)
            }}>
            <Modal.Header closeButton>Редактирование выставки</Modal.Header>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>

                    {!isLoading ?
                        <>
                            <Row>

                                <Col>
                                    <FloatingLabel
                                        controlId="floatingTitile"
                                        label="Название выставки"
                                        className="mb-3"
                                    >
                                        <Form.Control autoComplete="off" type="text" isInvalid={errors.name}
                                            isValid={isValid.name}
                                            placeholder="Название выставки" {...register("name", { required: { value: true } })} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingTitile"
                                        label="Название выставки EN"
                                        className="mb-3"
                                    >
                                        <Form.Control autoComplete="off" type="text"
                                            isValid={isValid.name}
                                            placeholder="Название выставки EN" {...register("nameEN")} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingTitile"
                                        label="Краткое описание"
                                        className="mb-3"
                                    >
                                        <Form.Control autoComplete="off" type="text"
                                            isValid={isValid.shortDescription}
                                            placeholder="Краткое описание" {...register("shortDescription")} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingTitile"
                                        label="Краткое описание EN"
                                        className="mb-3"
                                    >
                                        <Form.Control autoComplete="off" type="text"
                                            isValid={isValid.shortDescriptionEN}
                                            placeholder="Краткое описание EN" {...register("shortDescriptionEN")} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FloatingLabel
                                        controlId="floatingTitile"
                                        label="Возрастная категория"
                                        className="mb-3"
                                    >
                                        <Form.Control autoComplete="off" type="number"
                                            isValid={isValid.ageCategory}
                                            placeholder="Возрастная категория" {...register("ageCategory")} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingTitile"
                                        label="Дата начала"
                                        className="mb-3"
                                    >
                                        <Form.Control autoComplete="off" type="date"
                                            isInvalid={errors.startOfEvent}
                                            isValid={isValid.startOfEvent}
                                            // defaultValue={data.startOfEvent}
                                            placeholder="Дата начала" {...register("startOfEvent", { required: { value: true } })} />
                                    </FloatingLabel>
                                </Col>
                                <Col>
                                    <FloatingLabel
                                        controlId="floatingTitile"
                                        label="Дата завершения"
                                        className="mb-3"
                                    >
                                        <Form.Control autoComplete="off" type="date"
                                            isInvalid={errors.endOfEvent}
                                            isValid={isValid.endOfEvent}
                                            // defaultValue={data.endOfEvent}
                                            placeholder="Дата завершения" {...register("endOfEvent", { required: { value: true } })} />
                                    </FloatingLabel>
                                </Col>

                            </Row>

                            <div className="form-check form-switch mt-1 mb-3">
                                <input className="form-check-input" onClick={() => { setHidden(!hidden) }} checked={hidden} type="checkbox" id="status" {...register("hidden", {})} />
                                <label className="form-check-label" htmlFor="rememberMe">Скрыть</label>
                            </div>




                            <div className="mb-3">
                                <label>Описание</label>
                                <ReactQuillLC valueEditor={valueEditor} setValueEditor={setValueEditor} />
                            </div>
                            <div className="mb-3">
                                <label>Описание EN</label>
                                <ReactQuillLC valueEditor={valueEditorEn} setValueEditor={setValueEditorEn} />
                            </div>
                            <div>
                                <Row className="justify-content-center g-2">
                                    <Col lg={4} xs={6}>
                                        <div>
                                            <label htmlFor="imageHead" className="form-label">Изображение верхнего блока</label>
                                            <div className="card mb-3">
                                                {changingHeadPhoto ? <LoadScreenMin height={20} /> :
                                                    <div className="mb-3 text-center">
                                                        <img key={Date.now()} src={data?.headLayoutImageType ? `/api/exhibitions/head-img/${data.id}` : `/NoPhoto.png`} className="img-fluid" alt="exhibitions head img" />
                                                    </div>
                                                }
                                            </div>
                                            <div className="mt-3 mb-1">
                                                <u><span>Рекомендованное соотношние сторон: 16:9</span></u>
                                            </div>
                                            <div className='input-group'>
                                                <input onChange={(e) => onHeadImageChange(e)}
                                                    id="photo"
                                                    name="photo"
                                                    className="form-control form-control-sm"
                                                    accept=".jpg, .jpeg, .png" type="file" />
                                            </div>
                                            <div>
                                                {imgSizeError && <span className='text-danger'>{imgSizeError}</span>}
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="imageHead" className="form-label">Изображение верхнего блока</label>
                                            <div className="card mb-3">
                                                {changingHeadWidePhoto ? <LoadScreenMin height={20} /> :
                                                    <div className="mb-3 text-center">
                                                        <img key={Date.now()} src={data?.headLayoutWideImageType ? `/api/exhibitions/head-wide-img/${data.id}` : `/NoPhoto.png`} className="img-fluid" alt="exhibitions head img" />
                                                    </div>
                                                }
                                            </div>
                                            <div className="mt-3 mb-1">
                                                <u><span>Рекомендованное соотношние сторон: 16:9</span></u>
                                            </div>
                                            <div className='input-group'>
                                                <input onChange={(e) => onHeadImageWideChange(e)}
                                                    id="photo"
                                                    name="photo"
                                                    className="form-control form-control-sm"
                                                    accept=".jpg, .jpeg, .png" type="file" />
                                            </div>
                                            <div>
                                                {imgSizeError && <span className='text-danger'>{imgSizeError}</span>}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} xs={6}>
                                        <label htmlFor="image" className="form-label">Изображение афиши</label>
                                        <div className="card mb-3">
                                            {changingPhoto ? <LoadScreenMin height={20} /> :
                                                <div className="mb-3 text-center">
                                                    <img key={Date.now()} src={data?.afishaImageType ? `/api/exhibitions/afisha-img/${data.id}` : `/NoPhoto.png`} className="img-fluid" alt="exhibitions afisha img" />
                                                </div>
                                            }
                                        </div>
                                        <div className="mt-3 mb-1">
                                            <u><span>Рекомендованное соотношние сторон: 2∶3</span></u>
                                        </div>
                                        <div className='input-group'>
                                            <input onChange={(e) => onImageChange(e)}
                                                id="photo"
                                                name="photo"
                                                className="form-control form-control-sm"
                                                accept=".jpg, .jpeg, .png" type="file" />
                                        </div>
                                        <div>
                                            {imgSizeError && <span className='text-danger'>{imgSizeError}</span>}
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        </>
                        :
                        <LoadScreenMin height="30" />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup size="sm">
                        <Button variant="primary" onClick={() => {
                            reset()
                            setExhibitionEditForm(!exhibitionEditForm)
                        }
                        }>Отменить</Button>
                        <Button type="submit" variant="success">
                            Сохранить
                        </Button>
                    </ButtonGroup></Modal.Footer>
            </form>
        </Modal>
    </>
    )
}
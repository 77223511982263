import { useEffect } from "react";
import { useState } from "react";
import { Button, ButtonGroup, Card, FloatingLabel, Form, FormGroup, FormLabel, Image, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useGetArtistByIdQuery, usePutArtistByIdMutation } from "../../../features/CallApi";
import { LoadScreenMin } from "../../UI/LoadScreenMin";


export const ArtistEdit = ({ artistsEditForm, setArtistsEditForm, artistsId, setArtistsId, }) => {
    const { register, handleSubmit, reset, formState: { errors, isValid }, setValue } = useForm();
    const [isLoading, setIsloading] = useState()
    const { data, isLoading: loadingArtis, refetch } = useGetArtistByIdQuery(artistsId)
    const [putArtistById] = usePutArtistByIdMutation()

    useEffect(() => {
        refetch()
    }, [])

    useEffect(() => {
        if (loadingArtis) {
            setIsloading(true)
        }
        else (
            setIsloading(false)
        )
    }, [loadingArtis])
    useEffect(() => {
        if (data) {
            setValue("name", data.name)
            setValue("nameEN", data.nameEN)
            setValue("description", data.description)
            setValue("descriptionEN", data.descriptionEN)
        }
    }, [data])

    const putEditArtist = (data) => {
        const formData = new FormData()
        const file = data.photo[0]
        formData.append('name', data.name)
        formData.append('nameEN', data.nameEN === "null" ? "" : data.nameEN)
        formData.append('description', data.description)
        formData.append('descriptionEN', data.descriptionEN === "null" ? "" : data.descriptionEN)
        formData.append('photo', file)
        data.photo = formData

        putArtistById({ id: artistsId, data: formData }).unwrap()
            .then((r) => {
                reset()
                setArtistsEditForm(false)
            })
    }

    return (
        <>
            <Modal
                show={artistsEditForm}
                onHide={
                    () => {
                        reset()
                        setArtistsEditForm(false)
                    }
                }
                backdrop={`static`}
            >
                <Modal.Header closeButton>
                    <h3>Добавление артиста труппы</h3>
                </Modal.Header>
                {!isLoading ?
                    <form onSubmit={handleSubmit(putEditArtist)}>
                        <Modal.Body>
                            <FloatingLabel
                                controlId="floatingName"
                                label="Ф.И.О."
                                className="mb-3"
                            >
                                <Form.Control type="text" isInvalid={errors.name} isValid={isValid.name} placeholder="Ф.И.О." {...register("name", { required: { value: true } })} />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingName"
                                label="Ф.И.О. EN"
                                className="mb-3"
                            >
                                <Form.Control type="text" isInvalid={errors.nameEN} isValid={isValid.nameEN} placeholder="Ф.И.О. EN" {...register("nameEN")} />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingName"
                                label="Описание"
                                className="mb-3"
                            >
                                <Form.Control type="text" isInvalid={errors.description} isValid={isValid.description} placeholder="Описание" {...register("description", { required: { value: true } })} />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingName"
                                label="Описание EN"
                                className="mb-3"
                            >
                                <Form.Control type="text" isInvalid={errors.descriptionEN} isValid={isValid.descriptionEN} placeholder="Описание EN" {...register("descriptionEN")} />
                            </FloatingLabel>
                            <Card>
                                <Image fluid src={`/api/troupes/artist-photo/${artistsId}`} />
                            </Card>
                            <FormGroup
                                className="mb-3"
                            >
                                <FormLabel>Фото</FormLabel>
                                <Form.Control type="file" isInvalid={errors.photo} accept=".jpg, .jpeg, .png" isValid={isValid.photo} {...register("photo")} />
                            </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonGroup size="sm">
                                <Button variant="primary" onClick={() => {
                                    reset()
                                    setArtistsEditForm(!artistsEditForm)
                                    setArtistsId(!artistsId)
                                }
                                }>Отменить</Button>
                                <Button type="submit" variant="success">
                                    Сохранить
                                </Button>
                            </ButtonGroup>
                        </Modal.Footer>
                    </form>
                    :
                    <LoadScreenMin height={20} />}
            </Modal>
        </>
    )
}
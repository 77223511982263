import { useEffect, useState } from "react"
import { Button, Card, Col, Container, FloatingLabel, Form, Row, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import { CardBody } from "reactstrap"
import { usePostCheckStatusTokenMutation, usePostRecoverPasswordMutation } from "../../features/CallAppApi"
import { useForm } from "react-hook-form"


export const ClientResetPass = () => {

    const [searchParams] = useSearchParams()
    const [postCheckStatusToken] = usePostCheckStatusTokenMutation()
    const [postRecoverPassword, { isLoading }] = usePostRecoverPasswordMutation()
    const [message, setMessage] = useState()
    const { t } = useTranslation("global");
    const navigate = useNavigate()
    const [tokenData] = useState({
        token: searchParams.get("token"),
        login: searchParams.get("login"),
    })
    const [infoMsgShow] = useState(searchParams.get("infoMsgShow"))
    const [showForm, setShowForm] = useState(false)
    const [showError, setShowError] = useState(false)
    const { register, handleSubmit, reset, formState: { errors }, setValue, setError } = useForm();

    useEffect(() => {
        if (tokenData.login != null && tokenData.token) {
            postCheckStatusToken(tokenData).unwrap().then(
                (r) => {
                    setShowForm(
                        { showForm: true }
                    )
                }
            ).catch(
                (e) => {
                    setShowError({
                        show: true,
                        message: e.title,
                        status: e.status,
                        error: e.error
                    })
                }
            );
        }
        setValue("email", searchParams.get("login"))
        setValue("token", searchParams.get("token"))
    }, [searchParams])
    const recoverPass = (data) => {
        if (data.password !== data.passwordConfirm) {
            return setError("passwordConfirm", { message: "Подтверждение не совпадает с полем пароль " })
        }
        else {
            setError("passwordConfirm", null)
        }
        setValue("email", searchParams.get("login"))
        setValue("token", searchParams.get("token"))
        postRecoverPassword(data).unwrap().then(() => {
            reset()
            setMessage("Пароль успешно восстановлен")
            navigate('/client/profile')
        }).catch((e) => {
            window.alert(`${e.data.title}`)
        })
    }


    return (
        <>
            <div className="about-haed-layout mb-3">
                <div className="about-head-layout-img" style={{ backgroundImage: `url("/AboutProjcet/about-project.jpg")` }}>
                </div>
                <div className="about-head-mask">
                    <div className="justify-content-end ms-auto me-auto justify-content-end rounded-3"
                        style={{ width: "70%", backgroundColor: "#00000006", padding: "1rem" }}>
                    </div>
                </div>
            </div>
            {infoMsgShow === "true" &&
                <Container style={{ minHeight: '50vh', alignItems: 'center', display: 'grid' }}>

                    <Row className="justify-content-center">
                        <Col lg='8'>
                            <Card>
                                <CardBody>
                                    <div className="text-center fs-5">Инструкция для сброса пароля отправлена на электронный адрес:</div>
                                    <div className="text-center fs-4 pt-3 pb-3"><strong>{tokenData.login}</strong></div>
                                    <div>
                                        Если Вы не получили письмо в течение 10 минут, обратитесь, пожалуйста, в Cлужбу гостевого сервиса <a href="mailto:reservation@leningradcenter.ru">reservation@leningradcenter.ru</a>
                                    </div>
                                    <div>
                                        При обращении указывайте адрес электронной почты, на который вы запросили письмо.
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            }

            {showError.show &&
                <>
                    <Container>
                        <div>Status: {showError.status}</div>
                        <div>title: {showError.title}</div>
                        <div>error: {showError.error}</div>
                    </Container>
                </>
            }
            {showForm &&
                <Container style={{ minHeight: '50vh', alignItems: 'center', display: 'grid' }}>

                    <Row className="justify-content-center">
                        <Col lg='8'>
                            <Card>
                                <CardBody>
                                    <form onSubmit={handleSubmit(recoverPass)}>
                                        <h3>Востановление пароля</h3>
                                        {message && <h3>{message}</h3>}
                                        {errors?.password?.message && <> <span className="text-danger">{errors?.password?.message}</span> </>}
                                        <FloatingLabel className="mb-2" controlId="password" label={`${t("form.password")}`} >
                                            <Form.Control type="password" placeholder={`${t("form.password")}`} autoComplete={"on"}
                                                {...register("password",
                                                    {
                                                        required: { value: true, message: "Поле пароль обязательное" },
                                                        minLength: { value: 8, message: "Мнимальная длина 8 символов" }
                                                    })}
                                                isInvalid={errors?.password?.message != null} />
                                        </FloatingLabel>
                                        {errors?.passwordConfirm?.message && <> <span className="text-danger">{errors?.passwordConfirm?.message}</span> </>}
                                        <FloatingLabel className="mb-2" controlId="passwordConfirm" label={`${t("form.passwordConfirm")}`} >
                                            <Form.Control type="password" placeholder={`${t("form.passwordConfirm")}`} autoComplete={"on"}
                                                {...register("passwordConfirm",
                                                    { required: { value: true, message: "Поле подтвержедния пароль обязательное" } })}
                                                isInvalid={errors?.passwordConfirm?.message != null} />
                                        </FloatingLabel>
                                        <div className={'d-flex justify-content-end mt-3'}>
                                            <Button type="submit" className="btn-lc btn-lc-rounded">{t("form.confirm")} {isLoading && <Spinner size="sm" />}</Button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}